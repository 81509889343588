import { gql, useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import { IChartingLibraryWidget } from "charting_library";
import TradingViewChart, { TradingViewButton, TradingViewChartRef } from "components/common/Chart/TradingViewChart";
import DelayRenderUntilVisible from "components/common/DelayRenderUntilVisible";
import Error from 'components/common/Error';
import { ChartHeight, TickerDatesLayoutContext } from "contexts/TickerDatesLayoutContext";
import { Signal, SignalGridChartStateQuery, SignalGridChartStateQueryVariables } from "graphql/generated";
import moment from "moment";
import { RefObject, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";

const GRID_CHART_STATE_QUERY = gql`
  query SignalGridChartState($tickerDateId: Int!) {
    userTickerDateChartState(tickerDateId: $tickerDateId, index: 1) {
      state
    }
    userChartStudyTemplate(index: 1) {
      template
    }
  }
`;

export const ON_EDIT_SIGNAL_DATA = 'onEditSignalData';

interface SignalGridChartProps {
  signal: Signal;
  onEditClick: (o: Signal) => void;
}

export interface SignalGridChartRef {
  gridChartRef: RefObject<TradingViewChartRef>;
}

const SignalGridChart = forwardRef<SignalGridChartRef, SignalGridChartProps>(({ signal, onEditClick }, ref) => {
  const gridChartRef = useRef<TradingViewChartRef>(null);
  const [widgetObject, setWidgetObject] = useState<IChartingLibraryWidget | undefined>(undefined);
  const layoutContext = useContext(TickerDatesLayoutContext);

  useImperativeHandle(ref, () => ({
    gridChartRef: gridChartRef,
  }));

  useEffect(() => {
    if (!widgetObject) return;

    const listener = (event: MessageEvent<any>) => {
      if (event.data.id !== gridChartRef.current?.id) return;

      if (event.data.event === ON_EDIT_SIGNAL_DATA) {
        onEditClick(signal);
      }
    };
    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  });

  const { loading, error, data } = useQuery<SignalGridChartStateQuery, SignalGridChartStateQueryVariables>(GRID_CHART_STATE_QUERY, {
    variables: {
      tickerDateId: signal.TickerDate.id,
    },
  });

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const intradayTimeframe = {
    from: moment.tz(signal.TickerDate.date, 'America/New_York').unix(),
    to: moment.tz(signal.TickerDate.date, 'America/New_York').add(1, 'day').unix(),
  }

  const customButtons: TradingViewButton[] = [
    {
      title: 'View data',
      text: 'View data',
      onClick: (id: string) => window.parent.postMessage({ event: ON_EDIT_SIGNAL_DATA, id }),
    },
  ];

  return (
    <DelayRenderUntilVisible>
      <TradingViewChart
        tickerDate={signal.TickerDate}
        defaultResolution={'1'}
        resolutions={['1']}
        hasIntraday={true}
        ref={gridChartRef}
        timeframe={intradayTimeframe}
        stateIndex={1}
        initialState={data.userTickerDateChartState.state || undefined}
        initialStudyTemplate={data.userChartStudyTemplate.template || undefined}
        minHeight={layoutContext.chartHeight === ChartHeight.Large ? 720 : 420}
        zoomEnabled={false}
        drawingEnabled={false}
        setWidgetObject={setWidgetObject}
        toggleZoomButtonEnabled={false}
        customButtons={customButtons}
        selectedSignal={signal}
      />
    </DelayRenderUntilVisible>
  );
});

export default SignalGridChart;