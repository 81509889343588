import * as yup from 'yup';

export interface SaveBacktestFormInputs {
  id?: number;
  name: string;
  feesPercentagePerExecution?: string;
  feesPercentagePerTrade?: string;
}

export const validationSchema = yup.object({
  id: yup.number(),
  name: yup.string().required(),
  feesPercentagePerExecution: yup.string(),
  feesPercentagePerTrade: yup.string(),
}).required();
