import BacktestsList from "components/Backtests/BacktestsList";
import RequireAuthentication from "components/common/RequireAuthentication";
import { FC, useEffect } from "react";

const Backtests: FC = () => {

  useEffect(() => {
    document.title = `Backtests - Charts and Stats`;
  });

  return (
    <div className='p-6'>
      <RequireAuthentication>
        <BacktestsList />
      </RequireAuthentication>
    </div>
  );
}

export default Backtests;
