import { useCallback, useEffect, useMemo, useState } from "react";

export default function useOnScreen(): [(node: HTMLElement | null) => void, boolean] {
  const [isIntersecting, setIntersecting] = useState(false);
  const [element, setElement] = useState<HTMLElement | null>(null);

  const ref = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setElement(node);
    }
  }, []);

  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  ), []);

  useEffect(() => {
    if (element !== null) {
      observer.observe(element)
      return () => observer.disconnect()
    }
  }, [element])

  return [ref, isIntersecting]
}