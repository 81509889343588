import Option from "components/common/Form/Option";
import { formattedValueOptionalValidator } from "util/valueFormat";
import * as yup from 'yup';

export interface FeaturesForm {
  feature: Option;
  minValue?: string;
  maxValue?: string;
}

export const validationSchema = yup.object({
  feature: yup.object({
    label: yup.string().required(),
    value: yup.number().required('Select data poinit'),
  }).required(),
  minValue: formattedValueOptionalValidator,
  maxValue: formattedValueOptionalValidator,
}).required();