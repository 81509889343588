import { gql } from "@apollo/client";

export const TICKER_DATE_DETAILS_QUERY = gql`
  query TickerDateDetails($ticker: String!, $date: Date!, $collectionId: Int, $signalId: Int!, $skipSignal: Boolean!, $backtestTradeId: Int!, $skipBacktestTrade: Boolean!, $intradayBarsInput: BarsInput!) {
    userTickerDate(ticker: $ticker, date: $date, collectionId: $collectionId) {
      notes
      TickerDate {
        id
        date
        ticker {
          id
          ticker
        }
        open
        high
        low
        close
        volume
        volumeWeighted
        adjustmentCoefficient
      }
    }
    signal(id: $signalId) @skip(if: $skipSignal) {
      id
      name
      price
      timestamp
      TickerDate {
        id
        ticker {
          id
          ticker
        }
        date
      }
      TargetVariables {
        value
        TargetVariable {
          id
          name
        }
      }
    }
    backtestTrade(id: $backtestTradeId) @skip(if: $skipBacktestTrade) {
      id
      direction
      netProfitRisk
      maxRiskSize
      riskPriceMissing
      riskSizeInsufficient
      riskSizeNotDecreasing
      positionNotClosed

      Backtest {
        id
        name
        userId
        feesPercentagePerExecution
        feesPercentagePerTrade
      }
      TickerDate {
        id
        ticker {
          id
          ticker
        }
        date
      }
      notes
      BacktestTradeExecutions {
        id
        type
        timestamp
        price
        riskPrice
        positionRiskSize
        positionAverageCost
        positionDollarSize
        executionDollarSize
        realizedNetProfitRisk
        riskPriceMissing
        riskSizeInsufficient
        riskSizeNotDecreasing

        Signal {
          id
          name
          price
          timestamp
          TickerDate {
            id
            ticker {
              id
              ticker
            }
            date
          }
        }
      }
    }
    targetVariables {
      id
      name
    }
    features {
      id
      name
      userId
      type
      valueType
    }
    intradayBars: bars(input: $intradayBarsInput, collectionId: $collectionId) {
      ticker
      timeSeconds
      open
      high
      low
      close
      volume
      volumeWeighted
    }
  }
`;