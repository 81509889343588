import { gql, useMutation } from '@apollo/client';
import { getOperationName } from "@apollo/client/utilities";
import { ChartingLibraryFeatureset, ChartingLibraryWidgetOptions, IChartingLibraryWidget, ResolutionString, TimeFrameValue, widget } from 'charting_library';
import TickerDateDatafeed, { Resolution } from 'datafeeds/TickerDateDatafeed';
import { BacktestTrade, Collection, DailyChartStateDocument, IntradayChartStateDocument, SaveUserChartStudyTemplateMutation, SaveUserChartStudyTemplateMutationVariables, SaveUserTickerDateChartStateMutation, SaveUserTickerDateChartStateMutationVariables, Signal, TickerDate } from 'graphql/generated';
import { forwardRef, useEffect, useId, useImperativeHandle, useState } from 'react';
import { DEFAULT_STUDY_TEMPLATE } from './defaultStudyTemplate';

interface Timeframe {
  from: number;
  to: number;
}

interface BottomTimeframe {
  text: string;
  resolution: ResolutionString;
  title: string;
  description: string;
}

export interface TradingViewButton {
  text: string;
  title: string;
  onClick: (id: string) => void;
}

export interface TradingViewChartProps {
  tickerDate: TickerDate;
  collection?: Partial<Collection>;
  defaultResolution: Resolution;
  resolutions: Resolution[];
  hasIntraday: boolean;
  timeframe?: Timeframe;
  bottomTimeframes?: BottomTimeframe[];
  stateIndex: number;
  initialState?: string;
  initialStudyTemplate?: string;
  isPublic?: boolean;
  selectedSignal?: Signal;
  setWidgetObject?: (widget: IChartingLibraryWidget | undefined) => void;
  onStateChange?: (state: object, id: string) => void;
  minHeight?: number;
  drawingEnabled?: boolean;
  zoomEnabled?: boolean;
  toggleZoomButtonEnabled?: boolean;
  customButtons?: TradingViewButton[];
  backtestTrade?: Partial<BacktestTrade>;
}

export interface TradingViewChartRef {
  id: string;
  widget: IChartingLibraryWidget | undefined;
  resetZoom: () => void;
}

export const ON_TOGGLE_SCROLL_MESSAGE = 'onToggleScroll';
export const ON_RESET_ZOOM = 'onResetZoom';

const SAVE_USER_TICKER_DATE_CHART_STATE_MUTATION = gql`
  mutation SaveUserTickerDateChartState($tickerDateId: Int!, $index: Int!, $state: String!) {
    saveUserTickerDateChartState(tickerDateId: $tickerDateId, index: $index, state: $state)
  }
`;

const SAVE_USER_CHART_STUDY_TEMPLATE_MUTATION = gql`
  mutation SaveUserChartStudyTemplate($index: Int!, $template: String!) {
    saveUserChartStudyTemplate(index: $index, template: $template)
  }
`;

const TradingViewChart = forwardRef<TradingViewChartRef, TradingViewChartProps>(({
  tickerDate, collection, defaultResolution, resolutions, hasIntraday, timeframe, bottomTimeframes, stateIndex, initialState, initialStudyTemplate, isPublic, selectedSignal, setWidgetObject: setExternalWidgetObject, onStateChange, minHeight, zoomEnabled, drawingEnabled, toggleZoomButtonEnabled, customButtons, backtestTrade
}, ref) => {
  const id = useId();
  const [widgetObject, setWidgetObject] = useState<IChartingLibraryWidget | undefined>(undefined);
  const [chartScrollEnabled, setChartScrollEnabled] = useState<boolean>(zoomEnabled === false ? false : true);
  const [saveUserTickerDateStateMutation] = useMutation<SaveUserTickerDateChartStateMutation, SaveUserTickerDateChartStateMutationVariables>(SAVE_USER_TICKER_DATE_CHART_STATE_MUTATION);
  const [saveUserChartStudyTemplateMutation] = useMutation<SaveUserChartStudyTemplateMutation, SaveUserChartStudyTemplateMutationVariables>(SAVE_USER_CHART_STUDY_TEMPLATE_MUTATION);

  useEffect(() => {
    const drawingControls: ChartingLibraryFeatureset[] = drawingEnabled === false ? ['left_toolbar', 'timeframes_toolbar', 'edit_buttons_in_legend', 'context_menus', 'control_bar', 'border_around_the_chart'] : [];

    const options: ChartingLibraryWidgetOptions = {
      // debug: true, // uncomment this line to see Library errors and warnings in the console
      fullscreen: false,
      symbol: tickerDate.ticker.ticker,
      interval: defaultResolution as ResolutionString,
      container: id,
      auto_save_delay: 2,

      datafeed: new TickerDateDatafeed(tickerDate, collection, resolutions, hasIntraday, selectedSignal, backtestTrade),
      library_path: "/charting_library/",
      locale: "en",

      disabled_features: [
        'use_localstorage_for_settings',
        'study_templates',
        'header_symbol_search',
        'symbol_search_hot_key',
        'header_chart_type',
        'header_compare',
        'header_screenshot',
        'header_fullscreen_button',
        'display_market_status',
        'countdown',
        'go_to_date',
        'popup_hints',
        'study_templates',
        'header_saveload',

        ...drawingControls,
      ],

      enabled_features: [
        'pre_post_market_sessions',
      ],

      timezone: "America/New_York",

      autosize: true,

      drawings_access: { tools: [], type: drawingEnabled === false ? 'white' : 'black' },

      overrides: {
        'scalesProperties.showSeriesLastValue': false,
        'scalesProperties.showStudyLastValue': false,
        'mainSeriesProperties.sessionId': 'extended',
      },
    }

    if (timeframe) {
      options.timeframe = { from: timeframe.from, to: timeframe.to }
    }

    if (bottomTimeframes) {
      options.time_frames = bottomTimeframes;
    } else {
      options.time_frames = [];
    }

    const w = new widget(options);

    w.headerReady().then(() => {
      if (toggleZoomButtonEnabled) {
        w.createButton({
          align: 'left',
          useTradingViewStyle: true,
          text: 'Toggle scroll',
          title: 'Enable/disable chart scroll - useful if chart prevents you from scrolling the page',
          onClick: () => window.parent.postMessage({ event: ON_TOGGLE_SCROLL_MESSAGE, id }),
        });
      }

      if (timeframe) {
        w.createButton({
          align: 'left',
          useTradingViewStyle: true,
          text: 'Reset zoom',
          title: 'Reset zoom to the original time interval',
          onClick: () => window.parent.postMessage({ event: ON_RESET_ZOOM, id }),
        });
      }

      customButtons?.forEach(({ text, title, onClick }) => {
        w.createButton({
          align: 'left',
          useTradingViewStyle: true,
          text,
          title,
          onClick: () => onClick(id),
        });
      });
    });

    w.onChartReady(() => {
      if (zoomEnabled === false) w.activeChart().setZoomEnabled(zoomEnabled);

      if (initialState) w.load(JSON.parse(initialState));
      if (initialStudyTemplate) {
        w.activeChart().applyStudyTemplate(JSON.parse(initialStudyTemplate));
      } else {
        w.activeChart().applyStudyTemplate(JSON.parse(DEFAULT_STUDY_TEMPLATE));
      }

      w.applyOverrides({ 'mainSeriesProperties.sessionId': 'extended' });
      if (timeframe) {
        w.activeChart().setVisibleRange({ from: timeframe.from, to: timeframe.to },)
      }

      const tradingViewEmbeddedIframe = document.querySelector<HTMLIFrameElement>(`#${id.replaceAll(':', '\\:')} iframe[name^=tradingview_]`);
      if (tradingViewEmbeddedIframe){
        const dateRangeWrapper = tradingViewEmbeddedIframe.contentWindow?.document.querySelector<HTMLDivElement>('div[class^=dateRangeWrapper-]');
        if (dateRangeWrapper) dateRangeWrapper.style.display = 'none';
      }

      w.activeChart().onIntervalChanged().subscribe(null,
        (interval, timeframeObj) => {
          const { from, to } = w.activeChart().getVisibleRange();
          timeframeObj.timeframe = {
            from,
            to,
            type: "time-range",
          } as TimeFrameValue
        });

      if (!isPublic) {
        w.subscribe('onAutoSaveNeeded', () => {
          w.save(async (state) => {
            if (onStateChange) onStateChange(state, id);

            const saveState = saveUserTickerDateStateMutation({
              variables: {
                tickerDateId: tickerDate.id,
                state: JSON.stringify(state),
                index: stateIndex,
              },
              refetchQueries: [
                getOperationName(DailyChartStateDocument)!,
                getOperationName(IntradayChartStateDocument)!,
              ],
            });

            const template = w.activeChart().createStudyTemplate({ saveInterval: false, saveSymbol: false });
            const saveTemplate = saveUserChartStudyTemplateMutation({
              variables: {
                index: stateIndex,
                template: JSON.stringify(template),
              },
              refetchQueries: [
                getOperationName(DailyChartStateDocument)!,
                getOperationName(IntradayChartStateDocument)!,
              ],
            });

            await Promise.all([saveState, saveTemplate]);
          });
        });
      }
    });

    setWidgetObject(w);
    if (setExternalWidgetObject) setExternalWidgetObject(w);
  }, [id]);

  useEffect(() => {
    if (!widgetObject) return;

    const listener = (event: MessageEvent<any>) => {
      if (event.data.id !== id) return;

      if (event.data.event === ON_TOGGLE_SCROLL_MESSAGE) {
        widgetObject.activeChart().setZoomEnabled(!chartScrollEnabled);
        setChartScrollEnabled(!chartScrollEnabled);
      } else if (event.data.event === ON_RESET_ZOOM) {
        if (timeframe) {
          widgetObject.activeChart().setVisibleRange({ from: timeframe.from, to: timeframe.to },)
        }
      }
    };
    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  });

  useImperativeHandle(ref, () => ({
    id,
    widget: widgetObject,
    resetZoom: () => {
      if (timeframe) {
        widgetObject?.activeChart().setVisibleRange({ from: timeframe.from, to: timeframe.to },)
      }
    }
  }));

  return (
    <div className='relative' style={{ minHeight: minHeight || 720 }}>
      <div id={id} className='absolute top-0 left-0 w-full h-full' />
    </div>
  );
});

export default TradingViewChart;