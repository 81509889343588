import { gql } from "@apollo/client";

export const BACKTEST_TRADE_QUERY = gql`
  query BacktestTrade($id: Int!) {
    backtestTrade(id: $id) {
      id
      direction
      backtestId
      netProfitRisk
      maxRiskSize
      riskPriceMissing
      riskSizeInsufficient
      riskSizeNotDecreasing
      positionNotClosed
      TickerDate {
        id
        ticker {
          ticker
        }
        date
      }
      Backtest {
        id
        name
      }
      notes
      BacktestTradeExecutions {
        id
        type
        timestamp
        price
        riskPrice
        positionRiskSize
        positionAverageCost
        positionDollarSize
        executionDollarSize
        realizedNetProfitRisk
        riskPriceMissing
        riskSizeInsufficient
        riskSizeNotDecreasing
        Signal {
          name
          price
          timestamp
          TickerDate {
            id
            ticker {
              ticker
            }
            date
          }
        }
      }
    }
  }
`;