import Option from 'components/common/Form/Option';
import update from 'immutability-helper';
import { FC, useCallback } from 'react';
import FeatureRow from './FeatureRow';

interface FeaturesContainerProps {
  selectedFeatures: any[];
  setSelectedFeatures: React.Dispatch<React.SetStateAction<any[]>>;
  options: Option[];
  onDelete: (field: string) => void;
}

const FeaturesContainer: FC<FeaturesContainerProps> = ({ selectedFeatures, setSelectedFeatures, options, onDelete }) => {
  const moveFeatureRow = useCallback((dragIndex: number, hoverIndex: number) => {
    setSelectedFeatures((prevFeatures) =>
      update(prevFeatures, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevFeatures[dragIndex]],
        ],
      }),
    )
  }, [setSelectedFeatures]);
  const renderFeatureRow = useCallback((featureRow: any, index: number) => {
    return (
      <FeatureRow
        key={featureRow.fieldId}
        index={index}
        fieldId={featureRow.fieldId}
        options={options}
        onDelete={onDelete}
        moveFeatureRow={moveFeatureRow}
      />
    )
  }, [moveFeatureRow, onDelete, options])
  return (
    <div>
      {selectedFeatures.map((row, i) => renderFeatureRow(row, i))}
    </div>
  );
};

export default FeaturesContainer;