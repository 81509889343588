import { yupResolver } from '@hookform/resolvers/yup';
import { ForwardedRef, forwardRef, ReactNode, useEffect, useImperativeHandle } from 'react';
import { DeepPartial, FieldValues, FormProvider, useForm, UseFormReturn, WatchObserver } from 'react-hook-form';

type UseFormReturnFunc<T extends FieldValues> = (methods: UseFormReturn<T, any>) => ReactNode | ReactNode[];

interface FormProps<T extends FieldValues> {
  children: ReactNode | ReactNode[] | UseFormReturnFunc<T>;
  validationSchema?: any;
  defaultValues?: DeepPartial<T>;
  onChange?: WatchObserver<T>;
  className?: string;
}

export interface FormRef<T extends FieldValues> extends UseFormReturn<T> {

}

const Component = <T extends FieldValues,>({ children, validationSchema, defaultValues, onChange, className }: FormProps<T>, ref: ForwardedRef<FormRef<T>>) => {
  const methods = useForm<T>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  useImperativeHandle(ref, () => methods);

  useEffect(() => {
    if (!onChange) return;

    const subscription = methods.watch(onChange);
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  return (
    <FormProvider {...methods}>
      <div className={className}>
        {typeof children === 'function' ? children(methods) : children}
      </div>
    </FormProvider>
  );
};

const Form = forwardRef(Component) as <T extends FieldValues>(props: FormProps<T> & { ref?: ForwardedRef<FormRef<T>> }) => ReturnType<typeof Component>;

export default Form;
