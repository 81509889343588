import Option from 'components/common/Form/Option';
import * as yup from 'yup';

export interface FeatureFormInputs {
  name: string;
  valueType: Option;
  error?: string;
}

export const validationSchema = yup.object({
  name: yup.string().required(),
  valueType: yup.object({
    label: yup.string().required(),
    value: yup.string().required('Select data type'),
  }).required(),
}).required();
