import { FC } from "react";
import { NavLink } from "react-router-dom";

const Footer: FC = () => {
  return (
    <footer className="p-4 bg-white md:p-8 lg:p-10 dark:bg-gray-800">
      <div className="mx-auto max-w-screen-xl text-center">
        <div className="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white">
          Chart and Stats
        </div>
        <p className="my-6 text-gray-500 dark:text-gray-400">
          GROWTH CONSULTING OÜ<br />
          Sepapaja tn 6, 15551 Tallinn, Estonia<br />
          Registry code: 14314959<br />
          VAT: EE101998370
        </p>
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2023 GROWTH CONSULTING OÜ. All Rights Reserved.
        </span>
        <div className="text-sm text-gray-500 sm:text-center dark:text-gray-400 mt-6 flex gap-2 justify-center">
          <NavLink to='/terms' className='text-blue-700' onClick={() => window.scrollTo(0, 0)}>Terms of Service</NavLink>
          <NavLink to='/privacy' className='text-blue-700' onClick={() => window.scrollTo(0, 0)}>Privacy Policy</NavLink>
        </div>
      </div>
    </footer>
  );
}

export default Footer;