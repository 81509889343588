import Option from 'components/common/Form/Option';
import { formattedValueValidator } from 'util/valueFormat';
import * as yup from 'yup';

export interface FeatureInputs {
  feature: Option;
  type: Option;
  value: string | boolean;
}

const FEATURE_ERROR = 'Select data point or enter new name';
const FEATURE_VALUE_TYPE_ERROR = 'Select data type';

export const featureValidationSchema = yup.object({
  feature: yup.object({
    label: yup.string().required(),
    value: yup.string().required(FEATURE_ERROR),
  }).required().typeError(FEATURE_ERROR),
  type: yup.object({
    label: yup.string().required(),
    value: yup.string().required(FEATURE_VALUE_TYPE_ERROR),
  }).required().typeError(FEATURE_VALUE_TYPE_ERROR),
  value: formattedValueValidator,
});