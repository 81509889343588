import Form from "components/common/Form";
import DateInput from "components/common/Form/DateInput";
import Label from "components/common/Form/Label";
import Submit from "components/common/Form/Submit";
import { FC } from "react";
import { FieldValues } from "react-hook-form";
import * as yup from 'yup';

interface ChangeDateFormProps {
  date: string,
  onSubmit: (date: string) => void;
}

const validationSchema = yup.object({
  date: yup.lazy(v => v === '' ? yup.string() : yup.string().trim().matches(/^\d{4}-\d{2}-\d{2}$/, 'Must be a date'))
});

const ChangeDateForm: FC<ChangeDateFormProps> = ({ onSubmit, date }) => {
  const handleSubmit = (fields: FieldValues) => {
    onSubmit(fields.date);
  };

  return (
    <Form defaultValues={{ date }} validationSchema={validationSchema}>
      <Label label='Intraday date' />
      <DateInput field='date' placeholder={"Intraday date"} />
      <Submit onSubmit={handleSubmit} className='mt-4'>Load</Submit>
    </Form>
  );
};

export default ChangeDateForm;