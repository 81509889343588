import { useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import Error from 'components/common/Error';
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import Form, { FormRef } from "components/common/Form";
import Select from "components/common/Form/Select";
import TextInput from "components/common/Form/TextInput";
import { Tabs } from "flowbite-react";
import { Feature, FeatureValueType, FeaturesQuery, TargetVariableValueType, TargetVariablesQuery } from "graphql/generated";
import { FEATURES_QUERY } from 'graphql/queries/features.query';
import { TARGET_VARIABLES_QUERY } from "graphql/queries/targetVariables.query";
import useDebounce from "hooks/useDebounce";
import { FC, RefObject, useRef, useState } from "react";
import { formattedValueToRawNumber } from "util/valueFormat";
import SignalChartStats from "./SignalChartStats";
import SignalFeatureAndTargetVariablesStats from "./SignalFeatureAndTargetVariableStats";
import SignalFeatureStats from "./SignalFeatureStats";
import SignalStatsTable from "./SignalStatsTable";
import { FeaturesForm, validationSchema } from "./schema";

interface SignalStatsProps {
  inputs: FiltersFormFields;
  targetVariableValueType: TargetVariableValueType;
}

interface StateProps {
  featureId?: number;
  minValue?: number;
  maxValue?: number;
}

const SignalStats: FC<SignalStatsProps> = ({ inputs, targetVariableValueType }) => {
  const [preDebounceState, setPreDebounceState] = useState<StateProps>({});
  const [state, setState] = useState<StateProps>({});

  const formRef1 = useRef<FormRef<FeaturesForm>>(null);
  const formRef2 = useRef<FormRef<FeaturesForm>>(null);

  useDebounce(() => {
    setState(preDebounceState);
  }, [preDebounceState], 500);

  const { loading, error, data } = useQuery<FeaturesQuery>(FEATURES_QUERY);
  const { loading: targetVariablesLoading, error: targetVariablesError, data: targetVariablesData } = useQuery<TargetVariablesQuery>(TARGET_VARIABLES_QUERY);

  if (loading || targetVariablesLoading) {
    return <span>Loading...</span>
  }

  if (error || !data || targetVariablesError || !targetVariablesData) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const handleChange = (formRef: RefObject<FormRef<FeaturesForm>>) => () => {
    const values = formRef.current?.getValues();
    if (!values) return;

    const featureId = values.feature.value as number;
    const minValue = formattedValueToRawNumber(values.minValue);
    const maxValue = formattedValueToRawNumber(values.maxValue);

    if (preDebounceState.featureId === featureId && preDebounceState.minValue === minValue && preDebounceState.maxValue === maxValue) return;

    setPreDebounceState({
      featureId,
      minValue,
      maxValue,
    });

    const otherFormRef = formRef === formRef1 ? formRef2 : formRef1;
    otherFormRef.current?.setValue('feature', values.feature);
    otherFormRef.current?.setValue('minValue', values.minValue);
    otherFormRef.current?.setValue('maxValue', values.maxValue);
  };

  const options = data.features.map(f => ({ label: f.name, value: f.id }));

  let selectedFeature: Feature | undefined;
  if (state.featureId) {
    selectedFeature = data.features.find(f => f.id === state.featureId);
  }

  return (
    <>
      <Tabs.Group className='mt-6'>
        <Tabs.Item title='Future returns' active>
          <Tabs.Group>
            <Tabs.Item title='Table'>
              <SignalStatsTable inputs={inputs} targetVariables={targetVariablesData.targetVariables} targetVariableValueType={targetVariableValueType} />
            </Tabs.Item>
            <Tabs.Item title='Chart'>
              <SignalChartStats
                title='Continuous targets'
                targetVariableValueType={targetVariableValueType}
                inputs={inputs}
                targetVariables={targetVariablesData.targetVariables}
                allowedTargetVariableBases={[
                  'Future 1 min',
                  'Future 2 min',
                  'Future 5 min',
                  'Future 10 min',
                  'Future 15 min',
                  'Future 30 min',
                  'Future 1 hour',
                  'Future 2 hour',
                  'Future 4 hour',
                  'Future intraday',
                  'Future intraday until end of AH',
                  'Future today and tomorrow',
                  'Future today and 2 days after',
                  'Future today and 3 days after',
                  'Future today and 7 days after',
                  'Future today and 14 days after',
                ]}
              />
            </Tabs.Item>
          </Tabs.Group>
        </Tabs.Item>
        <Tabs.Item title='Signal data'>
          <Form<FeaturesForm> validationSchema={validationSchema} ref={formRef1}>
            <Select field='feature' options={options} onChange={handleChange(formRef1)} />
            {(selectedFeature && selectedFeature.valueType !== FeatureValueType.Tag) && (
              <>
                <TextInput field='minValue' placeholder='min value' onChange={handleChange(formRef1)} />
                <TextInput field='maxValue' placeholder='max value' onChange={handleChange(formRef1)} />
              </>
            )}
          </Form>
          {selectedFeature && <SignalFeatureStats inputs={inputs} feature={selectedFeature} minValue={state.minValue} maxValue={state.maxValue} targetVariableValueType={targetVariableValueType} />}
        </Tabs.Item>
        <Tabs.Item title='Relationship between signal data and future returns'>
          <Form<FeaturesForm> validationSchema={validationSchema} ref={formRef2}>
            <Select field='feature' options={options} onChange={handleChange(formRef2)} />
            {(selectedFeature && selectedFeature.valueType !== FeatureValueType.Tag) && (
              <>
                <TextInput field='minValue' placeholder='min value' onChange={handleChange(formRef2)} />
                <TextInput field='maxValue' placeholder='max value' onChange={handleChange(formRef2)} />
              </>
            )}
          </Form>
          {selectedFeature && <SignalFeatureAndTargetVariablesStats inputs={inputs} feature={selectedFeature} minValue={state.minValue} maxValue={state.maxValue} targetVariableValueType={targetVariableValueType} />}
        </Tabs.Item>
      </Tabs.Group>
    </>
  );
};

export default SignalStats;