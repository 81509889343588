import Option from 'components/common/Form/Option';
import { BacktestTrade, BarPayload, TradeDirection } from 'graphql/generated';
import moment from 'moment';
import { TIME_REGEX, formattedValueValidator, timeValidator } from 'util/valueFormat';
import * as yup from 'yup';

export interface BacktestTradeExecutionFormInputs {
  type?: Option;
  price: string;
  time: string;
  riskPrice: string;
  positionRiskSize: string;
}

export const getBarFromTime = (bars: BarPayload[], time: string) => {
  if (!time || !TIME_REGEX.test(time)) return;

  const startOfDay = moment.unix(bars[0].timeSeconds).tz("America/New_York").startOf('day');
  const secondsOfDayInput = moment(time, 'HH:mm').diff(moment().startOf('day'), 'seconds');
  const unixSecondsOfDayInput = startOfDay.add(secondsOfDayInput, 'seconds').set('seconds', 0).unix();
  const bar = bars.find(b => b.timeSeconds === unixSecondsOfDayInput);
  return bar;
}

export const validationSchema = (bars: BarPayload[], trade: Partial<BacktestTrade>) => {
  return yup.object({
    price: yup.number().typeError('Must be a number').when('time', (time, schema) => {
      const bar = getBarFromTime(bars, time);
      if (!bar) return schema;
      return schema.min(bar.low).max(bar.high).typeError('Must be a number');
    }),
    time: timeValidator,
    riskPrice: yup.string().when('type', (type, schema) => {
      if (trade.direction === TradeDirection.Long) {
        return schema.test('riskPrice', 'Risk price must be lower than execution price when buying', function (this: yup.TestContext, riskPrice: string) {
          if (!riskPrice || riskPrice === "") return true;
          const price = Number(this.resolve(yup.ref('price')));
          return Number(riskPrice) < price;
        });
      } else if (trade.direction === TradeDirection.Short) {
        return schema.test('riskPrice', 'Risk price must be higher than execution price when selling', function (this: yup.TestContext, riskPrice: string) {
          if (!riskPrice || riskPrice === "") return true;
          const price = Number(this.resolve(yup.ref('price')));
          return Number(riskPrice) > price;
        });
      } else {
        return schema;
      }
    }),
    positionRiskSize: formattedValueValidator,
    type: yup.object({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  }).required();
}