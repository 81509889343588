import moment from "moment";
import { FC, forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useFormContext } from "react-hook-form";
import Error from './Error';

interface DateInputProps {
  field: string;
  placeholder: string;
  onChange?: (date: string | undefined) => void;
  className?: string;
}

const CustomInput = forwardRef((props: any, ref) => {
  const { field, inputPlaceholder, ...rest } = props;

  return (
    <div>
      <div className="relative">
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
        </div>
        <input {...rest} ref={ref} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={inputPlaceholder} />
      </div>
      <Error field={field} />
    </div>
  );
});

const DateInput: FC<DateInputProps> = ({ field, placeholder, onChange: propsOnChange, className }) => {
  const { control } = useFormContext();

  return (
    <div className={className}>
      <Controller
        control={control}
        name={field}
        render={({ field: { onChange, value } }) => {
          const momentDate = moment(value, 'yyyy-MM-DD');
          const date = momentDate.isValid() ? momentDate.toDate() : undefined;
          return (
            <ReactDatePicker
              dateFormat="yyyy-MM-dd"
              onChange={date => {
                const momentDate = moment(date);
                const value = momentDate.isValid() ? momentDate.format('yyyy-MM-DD') : undefined;
                onChange(value);
                if (propsOnChange) propsOnChange(value);
              }}
              selected={date}
              customInput={<CustomInput field={field} inputPlaceholder={placeholder} />}
              popperModifiers={[
                {
                  name: 'arrow',
                  options: {
                    padding: ({ popper, reference, placement }) => ({
                      right: Math.min(popper.width, reference.width) - 24,
                    }),
                  },
                },
              ]}
            />
          )
        }}
      />
    </div>
  );
};

export default DateInput;