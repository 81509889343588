import { ResolutionString } from "charting_library";
import { BarTimespan } from "graphql/generated";

export const timespanMultiplierFromResolution: (resolution: ResolutionString) => [BarTimespan | undefined, number | undefined] = (resolution: ResolutionString) => {
  let timespan: BarTimespan;
  let multiplier: number;
  if (resolution.indexOf('W') !== -1) {
    timespan = BarTimespan.Week;
    multiplier = Number(resolution.replace('W', ''));
  } else if (resolution.indexOf('D') !== -1) {
    timespan = BarTimespan.Day;
    multiplier = Number(resolution.replace('D', ''));
  } else if (resolution.indexOf('H') !== -1) {
    timespan = BarTimespan.Hour;
    multiplier = Number(resolution.replace('H', ''));
  } else {
    timespan = BarTimespan.Minute;
    multiplier = Number(resolution);
  };

  return [timespan, multiplier];
}