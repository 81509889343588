import { gql } from "@apollo/client";
import { apolloClient } from "App";
import Option from 'components/common/Form/Option';
import { TickersQuery } from "graphql/generated";
import { OptionsOrGroups } from 'react-select';

const TICKERS_QUERY = gql`
  query Tickers($cursor: String, $prefix: String) {
    tickers(cursor: $cursor, prefix: $prefix) {
      id
      ticker
    }
  }
`;

export default async function loadTickers(inputString: string, loadedOptions: OptionsOrGroups<Option, any>) {
  const result = await apolloClient.query<TickersQuery>({
    query: TICKERS_QUERY,
    variables: {
      prefix: inputString,
      cursor: loadedOptions.length > 0 ? loadedOptions[loadedOptions.length - 1].label : undefined,
    }
  });

  return {
    options: result.data.tickers.map(t => ({ label: t.ticker, value: t.id })),
    hasMore: result.data.tickers.length > 0,
  }
};