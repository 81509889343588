import { useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import Error from 'components/common/Error';
import { BacktestSearchParamsContext } from "contexts/BacktestSearchParamsContext";
import { Tabs } from "flowbite-react";
import { Backtest, BacktestQuery, BacktestQueryVariables, BacktestTrade, BacktestTradeQuery, BacktestTradeQueryVariables } from "graphql/generated";
import { BACKTEST_QUERY } from "graphql/queries/backtest.query";
import { BACKTEST_TRADE_QUERY } from "graphql/queries/backtestTrade.query";
import { FC, useContext, useEffect, useState } from "react";
import BacktestExecutionsFromSignalsForm from "../BacktestExecutionsFromSignalsForm";
import BacktestSearchParams from "../BacktestSearchParams";
import BacktestStats from "../BacktestStats";
import BacktestTradesTable from "../BacktestTradesTable";
import SaveBacktestForm from "../SaveBacktestForm";

interface BacktestDetailsProps {
  id: number;
}

const BacktestDetailsInner: FC<BacktestDetailsProps> = ({ id }) => {
  const [viewingBacktestTrade, setViewingBacktestTrade] = useState<BacktestTrade | undefined>(undefined);
  const backtestSearchParamsContext = useContext(BacktestSearchParamsContext);

  const { loading, error, data } = useQuery<BacktestQuery, BacktestQueryVariables>(BACKTEST_QUERY, {
    variables: { id },
  });

  const { error: backtestTradeError, data: backtestTradeData } = useQuery<BacktestTradeQuery, BacktestTradeQueryVariables>(BACKTEST_TRADE_QUERY, {
    variables: {
      id: backtestSearchParamsContext.backtestTradeId || 0,
    },
    skip: backtestSearchParamsContext.backtestTradeId === undefined,
  });

  useEffect(() => {
    if (backtestTradeData?.backtestTrade && backtestSearchParamsContext.backtestTradeId !== undefined) {
      setViewingBacktestTrade(backtestTradeData.backtestTrade as BacktestTrade);
    }
  }, [backtestTradeData]);

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data || backtestTradeError) {
    if (error) {
      Sentry.captureException(error);
      return <Error error={error} />
    } else if (backtestTradeError) {
      Sentry.captureException(backtestTradeError);
      return <Error error={backtestTradeError} />
    }
    return <Error error={error} />
  }

  const handleViewingBacktestTradeSelected = (trade?: BacktestTrade) => {
    setViewingBacktestTrade(trade);
    backtestSearchParamsContext.setBacktestTradeId(trade?.id);
  };

  const backtest: Partial<Backtest> = data.backtest;

  return (
    <>
      <h1 className="mb-4 text-2xl font-bold tracking-tight leading-none text-gray-900 lg:mb-6 md:text-5xl xl:text-4xl dark:text-white">Backtest: {backtest.name}</h1>

      <Tabs.Group onActiveTabChange={backtestSearchParamsContext.setActiveTab}>
        <Tabs.Item title="Settings" active={!backtestSearchParamsContext.activeTab}>
          <SaveBacktestForm backtest={data.backtest} />
        </Tabs.Item>

        <Tabs.Item title="Stats" active={backtestSearchParamsContext.activeTab === 1}>
          <BacktestStats id={data.backtest.id} />
        </Tabs.Item>

        <Tabs.Item title="Trades" active={backtestSearchParamsContext.activeTab === 2}>
          <BacktestTradesTable backtestId={data.backtest.id} selectedBacktestTrade={viewingBacktestTrade} setSelectedBacktestTrade={handleViewingBacktestTradeSelected} />
        </Tabs.Item>

        <Tabs.Item title="Create trades from Signals" active={backtestSearchParamsContext.activeTab === 3}>
          <BacktestExecutionsFromSignalsForm backtest={data.backtest} />
        </Tabs.Item>
      </Tabs.Group>
    </>
  );
};

const BacktestDetails: FC<BacktestDetailsProps> = ({ id }) => {
  return (
    <BacktestSearchParams>
      <BacktestDetailsInner id={id} />
    </BacktestSearchParams>
  );
};

export default BacktestDetails;
