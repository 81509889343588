import { ApolloClient, ApolloError, ApolloLink, ApolloProvider, createHttpLink, gql, InMemoryCache, ServerError, useQuery } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorBoundary } from '@sentry/react';
import Error from "components/common/Error";
import { Flowbite } from 'flowbite-react';
import Maintenance from 'Maintenance';
import React, { ReactElement } from 'react';
import { RouterProvider } from "react-router-dom";
import { UserContext } from './contexts/UserContext';
import { CurrentUserQueryQuery, UserPayload } from './graphql/generated';
import './index.css';
import router from './routes/router';

const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    currentUser {
      id
      email
      name
      signedEmail
      admin
    }
  }
`;

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  const viewedUserId = localStorage.getItem('viewedUserId');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      ...(viewedUserId ? { vieweduserid: viewedUserId } : {}),
    }
  }
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

function CurrentUserQueryComponent(props: { children: (loading: boolean, error: ApolloError | undefined, data: CurrentUserQueryQuery | undefined) => ReactElement }) {
  const { loading, error, data } = useQuery<CurrentUserQueryQuery>(CURRENT_USER_QUERY);
  if (loading) return null;

  if ((error?.networkError as ServerError)?.result.errors[0].message === "Context creation failed: invalid signature") {
    localStorage.removeItem('token');
  }
  else if (error?.networkError) {
    return <Maintenance />;
  }

  return props.children(loading, error, data);
}

class App extends React.Component {
  state: { user?: UserPayload, overwriteUser: boolean } = { overwriteUser: false };

  constructor(props: {}) {
    super(props);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  login(token: string, user: UserPayload) {
    localStorage.setItem('token', token);
    this.setState({ user, overwriteUser: true });
    // TODO update navbar and redirect to /
  }

  logout() {
    localStorage.removeItem('token');
    this.setState({ user: undefined, overwriteUser: true });
    // TODO update navbar and redirect to /
  }

  render() {
    return (
      <React.StrictMode>
        <ErrorBoundary fallback={({ error }) => <Error error={error} />}>
          <Flowbite
            theme={{
              dark: false,
              theme: {
                tab: {
                  base: 'flex flex-col gap-2',
                  tablist: {
                    base: 'flex text-center',
                    styles: {
                      default: 'flex-wrap border-b border-gray-200 dark:border-gray-700',
                      underline: 'flex-wrap -mb-px border-b border-gray-200 dark:border-gray-700',
                      pills: 'flex-wrap font-medium text-sm text-gray-500 dark:text-gray-400',
                      fullWidth:
                        'hidden text-sm font-medium rounded-lg divide-x divide-gray-200 shadow sm:flex dark:divide-gray-700 dark:text-gray-400',
                    },
                    tabitem: {
                      base: 'flex items-center justify-center p-4 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500',
                      styles: {
                        default: {
                          base: 'rounded-t-lg',
                          active: {
                            on: 'bg-gray-100 text-blue-600 dark:bg-gray-800 dark:text-blue-500',
                            off: 'text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800  dark:hover:text-gray-300',
                          },
                        },
                        underline: {
                          base: 'rounded-t-lg',
                          active: {
                            on: 'text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500',
                            off: 'border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300',
                          },
                        },
                        pills: {
                          base: '',
                          active: {
                            on: 'rounded-lg bg-blue-600 text-white',
                            off: 'rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white',
                          },
                        },
                        fullWidth: {
                          base: 'ml-2 first:ml-0 w-full first:rounded-l-lg last:rounded-r-lg',
                          active: {
                            on: 'inline-block p-4 w-full text-gray-900 bg-gray-100 focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white',
                            off: 'bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700',
                          },
                        },
                      },
                      icon: 'mr-2 h-5 w-5',
                    },
                  },
                  tabpanel: 'p-4',
                },
                carousel: {
                  indicators: {
                    active: {
                      off: 'bg-slate-200',
                      on: 'bg-slate-400',
                    },
                  }
                }
              }
            }}>
            <ApolloProvider client={apolloClient}>
              <CurrentUserQueryComponent key={localStorage.getItem('token')}>
                {(loading, error, data) => {
                  const value = {
                    user: this.state.overwriteUser ? this.state.user : data?.currentUser || undefined,
                    login: this.login,
                    logout: this.logout,
                  };

                  if (value.user?.signedEmail) {
                    // @ts-ignore
                    if (typeof $crisp !== 'undefined') {
                      // @ts-ignore
                      $crisp.push(["set", "user:email", [value.user.email, value.user.signedEmail]]);
                    }
                  }

                  return (
                    <UserContext.Provider value={value}>
                      <RouterProvider router={router} />
                    </UserContext.Provider>
                  )
                }}
              </CurrentUserQueryComponent>
            </ApolloProvider>
          </Flowbite>
        </ErrorBoundary>
      </React.StrictMode>
    );
  }
}

export default App;
