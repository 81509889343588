import Label from 'components/common/Form//Label';
import Option from 'components/common/Form//Option';
import Error from 'components/common/Form/Error';
import update from 'immutability-helper';
import { FC, useId } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect, { createFilter, MultiValueProps } from 'react-select';
import Creatable from 'react-select/creatable';
import SortableMultiValue from './SortableMultiValue';

interface SelectProps {
  field: string,
  options: Option[],
  label?: string,
  isMulti?: boolean,
  readOnly?: boolean,
  isSortable?: boolean,
  className?: string;
  handleCreate?: (inputValue: string) => void,
  onChange?: (value: any) => void,
  placeholder?: string;
}

const Select: FC<SelectProps> = ({ field, options, label, isMulti, readOnly, isSortable, handleCreate, onChange, className, placeholder }) => {
  const { control } = useFormContext();

  const id = useId();

  return (
    <div className={className}>
      {label && <Label htmlFor={id} label={label} />}

      <Controller
        control={control}
        name={field}
        render={({ field: { onChange: controllerOnChange, value } }) => {
          const selectProps = {
            value: value || null,
            onChange: (val: any) => {
              controllerOnChange(val);
              if (onChange) onChange(val);
            },
            options: options,
            isMulti: isMulti || false,
            filterOption: createFilter({ ignoreAccents: false }),
            isDisabled: readOnly,
            placeholder,
          };

          if (isSortable) {
            const swapItems = (dragIndex: number, hoverIndex: number) => {
              const dragItem = (value as any[])[dragIndex];

              controllerOnChange(update(value as any[], {
                $splice: [
                  [dragIndex, 1],
                  [hoverIndex, 0, dragItem]
                ]
              }));
            };

            return (
              <DndProvider backend={HTML5Backend}>
                <ReactSelect
                  {...selectProps}
                  isMulti
                  components={{
                    MultiValue: (props: MultiValueProps<unknown, true>) => <SortableMultiValue {...props} swapItems={swapItems} />
                  }}
                />
              </DndProvider>
            );
          } else if (handleCreate) {
            return (
              <Creatable {...selectProps} onCreateOption={handleCreate} />
            );
          } else {
            return (
              <ReactSelect {...selectProps} />
            );
          }
        }}
      />

      <Error field={`${field}.value`} />
    </div>
  );
}

export default Select;