import { gql } from "@apollo/client";

export const TICKER_DATES_FROM_FILTERS_QUERY = gql`
  query TickerDatesFromFilters($input: ScannerFiltersInput!, $take: Int!, $cursor: DateIdCursorInput) {
    tickerDatesFromFilters(input: $input, take: $take, cursor: $cursor) {
      id
      tickerDate {
        id
        date
        ticker {
          id
          ticker
        }
        open
        high
        low
        close
        volume
        volumeWeighted
        adjustmentCoefficient
        assetType
        marketCap
      }
      tags
      signalTimes
    }
  }
`;