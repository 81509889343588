import { useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import Error from 'components/common/Error';
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import Form, { FormRef } from "components/common/Form";
import Select from "components/common/Form/Select";
import TextInput from "components/common/Form/TextInput";
import { Tabs } from "flowbite-react";
import { Feature, FeatureValueType, FeaturesQuery, TargetVariableValueType } from "graphql/generated";
import { FEATURES_QUERY } from "graphql/queries/features.query";
import useDebounce from "hooks/useDebounce";
import { FC, useRef, useState } from "react";
import { formattedValueToRawNumber } from "util/valueFormat";
import TickerFilterFeatureStats from "../TickerFilterFeatureStats";
import TickerFilterStatsTable from "../TickerFilterStatsTable";
import { FeaturesForm, validationSchema } from './schema';

interface TickerFilterStatsProps {
  inputs: FiltersFormFields;
  targetVariableValueType: TargetVariableValueType;
}

interface StateProps {
  featureId?: number;
  minValue?: number;
  maxValue?: number;
}

const TickerFilterStats: FC<TickerFilterStatsProps> = ({ inputs, targetVariableValueType }) => {
  const [preDebounceState, setPreDebounceState] = useState<StateProps>({});
  const [state, setState] = useState<StateProps>({});

  const formRef = useRef<FormRef<FeaturesForm>>(null);

  useDebounce(() => {
    setState(preDebounceState);
  }, [preDebounceState], 2000);

  const { loading, error, data } = useQuery<FeaturesQuery>(FEATURES_QUERY);

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const handleChange = () => {
    const values = formRef.current?.getValues();
    if (!values) return;

    const featureId = values.feature.value as number;
    const minValue = formattedValueToRawNumber(values.minValue);
    const maxValue = formattedValueToRawNumber(values.maxValue);

    if (preDebounceState.featureId === featureId && preDebounceState.minValue === minValue && preDebounceState.maxValue === maxValue) return;

    setPreDebounceState({
      featureId,
      minValue,
      maxValue,
    });
  };

  const options = data.features.map(f => ({ label: f.name, value: f.id }));
  const featureById: { [key: number]: Feature } = data.features.reduce((p, c) => ({ ...p, [c.id]: c }), {});

  const selectedFeature = state.featureId !== undefined ? featureById[state.featureId] : undefined;

  return (
    <Tabs.Group>
      <Tabs.Item title='Future returns' active>
        <TickerFilterStatsTable inputs={inputs} targetVariableValueType={targetVariableValueType} />
      </Tabs.Item>
      <Tabs.Item title='Ticker data'>
        <Form<FeaturesForm> validationSchema={validationSchema} ref={formRef}>
          <Select field='feature' options={options} onChange={handleChange} />
          {(selectedFeature && selectedFeature.valueType !== FeatureValueType.Tag) && (
            <>
              <TextInput field='minValue' placeholder='min value' onChange={handleChange} />
              <TextInput field='maxValue' placeholder='max value' onChange={handleChange} />
            </>
          )}
        </Form>
        {selectedFeature && <TickerFilterFeatureStats filters={inputs} feature={selectedFeature} minValue={state.minValue} maxValue={state.maxValue} targetVariableValueType={targetVariableValueType} />}
      </Tabs.Item>
    </Tabs.Group>
  );
};

export default TickerFilterStats;