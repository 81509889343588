import { gql, useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import Button, { ButtonStyle } from 'components/common/Button';
import Form, { FormRef } from 'components/common/Form';
import Error from 'components/common/Form/Error';
import Label from 'components/common/Form/Label';
import Select from 'components/common/Form/Select';
import Submit from 'components/common/Form/Submit';
import TextInput from 'components/common/Form/TextInput';
import { useTickerDateColumnContext } from 'contexts/TickerDateColumnContext';
import { Tooltip } from 'flowbite-react';
import { Backtest, BacktestStatsDocument, BacktestTrade, BacktestTradeExecution, BarPayload, Collection, ExecutionType, FeatureValueType, RemoveBacktestExecutionsMutation, RemoveBacktestExecutionsMutationVariables, SaveBacktestTradeExecutionMutation, SaveBacktestTradeExecutionMutationVariables, TickerDate, TickerDateDetailsDocument } from 'graphql/generated';
import { REMOVE_BACKTEST_EXECUTIONS } from 'graphql/mutations/removeBacktestExecutions.mutation';
import moment from 'moment';
import { forwardRef, useImperativeHandle, useRef } from "react";
import { DeepPartial, UseFormReturn } from 'react-hook-form';
import { formatRawValue, formattedValueToRawNumber } from 'util/valueFormat';
import { BacktestTradeExecutionFormInputs, getBarFromTime, validationSchema } from './schema';

const SAVE_BACKTEST_TRADE_EXECUTION_MUTATION = gql`
  mutation SaveBacktestTradeExecution($id: Int, $tradeId: Int!, $type: ExecutionType!, $positionRiskSize: Float!, $riskPrice: Float, $price: Float!, $timestamp: DateTime!) {
    saveBacktestTradeExecution(id: $id, tradeId: $tradeId, type: $type, positionRiskSize: $positionRiskSize, riskPrice: $riskPrice, price: $price, timestamp: $timestamp)
  }
`;

export interface BacktestTradeExecutionFormProps {
  backtest: Partial<Backtest>;
  backtestTrade: Partial<BacktestTrade>;
  intradayBars: BarPayload[],
  selectedExecution?: BacktestTradeExecution;
  tickerDate: TickerDate;
  isPublic: boolean;
  onSave?: () => void;
  collection?: Partial<Collection>;
}

export interface ExecutionFormRef {
  formMethods?: UseFormReturn<BacktestTradeExecutionFormInputs>;
  resetForm: () => void;
}

const BacktestTradeExecutionForm = forwardRef<ExecutionFormRef, BacktestTradeExecutionFormProps>(({ backtest, backtestTrade, intradayBars, selectedExecution, tickerDate, isPublic, onSave, collection }, ref) => {
  const formRef = useRef<FormRef<BacktestTradeExecutionFormInputs>>(null);

  const context = useTickerDateColumnContext();

  const defaultValues: DeepPartial<BacktestTradeExecutionFormInputs> = {
    price: typeof selectedExecution?.price === 'number' ? "" + selectedExecution?.price : undefined,
    riskPrice: typeof selectedExecution?.riskPrice === 'number' ? "" + selectedExecution?.riskPrice : undefined,
    positionRiskSize: typeof selectedExecution?.positionRiskSize === 'number' ? formatRawValue(selectedExecution?.positionRiskSize, FeatureValueType.Percentage) : undefined,
    time: selectedExecution ? moment.tz(selectedExecution.timestamp, 'America/New_York').format('HH:mm') : undefined,
    type: selectedExecution ? { label: "" + selectedExecution.type, value: "" + selectedExecution.type } : undefined,
  };

  const [saveExecutionMutation] = useMutation<SaveBacktestTradeExecutionMutation, SaveBacktestTradeExecutionMutationVariables>(SAVE_BACKTEST_TRADE_EXECUTION_MUTATION, {
    refetchQueries: [
      getOperationName(TickerDateDetailsDocument)!,
      getOperationName(BacktestStatsDocument)!,
    ],
  });

  const [removeExecutionMutation] = useMutation<RemoveBacktestExecutionsMutation, RemoveBacktestExecutionsMutationVariables>(REMOVE_BACKTEST_EXECUTIONS, {
    refetchQueries: [
      getOperationName(TickerDateDetailsDocument)!,
      getOperationName(BacktestStatsDocument)!,
    ],
  });

  const resetForm = () => {
    formRef.current?.reset({
      price: '',
      riskPrice: '',
      time: '',
      positionRiskSize: '',
      type: undefined,
    });
  };

  useImperativeHandle(ref, () => ({
    formMethods: formRef.current || undefined,
    resetForm,
  }));

  const saveExecution = async (fields: BacktestTradeExecutionFormInputs) => {
    const timeFormat = fields.time.split(':').length === 3 ? 'HH:mm:ss' : 'HH:mm';
    const dateFormatted = moment(tickerDate.date).format('YYYY-MM-DD');
    const timestamp = moment.tz(`${dateFormatted} ${fields.time}`, `YYYY-MM-DD ${timeFormat}`, 'America/New_York').toDate();

    await saveExecutionMutation({
      variables: {
        id: selectedExecution?.id,
        tradeId: backtestTrade.id!,
        type: fields.type!.value as ExecutionType,
        positionRiskSize: formattedValueToRawNumber(fields.positionRiskSize)!,
        riskPrice: formattedValueToRawNumber(fields.riskPrice),
        price: Number(fields.price),
        timestamp,
      },
    });

    if (onSave) onSave();
    resetForm();
    context.refreshMarks();
  };

  const handleExecutionDelete = async () => {
    if (!selectedExecution) return;
    await removeExecutionMutation({
      variables: {
        executionIds: selectedExecution.id,
      },
    });
    if (onSave) onSave();
    resetForm();
    context.refreshMarks();
  };

  const typeOptions = Object.keys(ExecutionType).map(t => ({ value: t, label: t }));

  const updateExecutionButton = (execution: BacktestTradeExecution) => {
    const executionDate = moment.tz(execution.timestamp, 'America/New_York').format('YYYY-MM-DD');
    const tickerDateDate = moment(tickerDate.date).format('YYYY-MM-DD');
    const disableUpdate = executionDate !== tickerDateDate;
    const updateButton = <Submit<BacktestTradeExecutionFormInputs> onSubmit={saveExecution} style={ButtonStyle.Primary} disabled={disableUpdate}>Update</Submit>;

    if (disableUpdate) {
      return (
        <Tooltip content={`Please switch to ${executionDate} to update this execution`}>
          {updateButton}
        </Tooltip>
      );
    } else {
      return updateButton;
    }
  };

  return (
    <Form<BacktestTradeExecutionFormInputs> validationSchema={validationSchema(intradayBars, backtestTrade)} defaultValues={defaultValues} ref={formRef}>
      {(formMethods) => {
        const time = formMethods.watch('time');
        const barFromTime = getBarFromTime(intradayBars, time);
        const price = formMethods.watch('price');
        const riskPrice = formMethods.watch('riskPrice');

        return <>
          <Label label='Type' />
          <Select field='type' readOnly={isPublic} options={typeOptions} />
          <Label label='Time' />
          <TextInput field='time' readOnly={isPublic} />
          <Label label={barFromTime ? `Price ${barFromTime.low} to ${barFromTime.high}` : 'Price'} />
          <TextInput field='price' readOnly={isPublic} />
          <Label label={`${Number(riskPrice) && Number(price) ? `Risk price (${formatRawValue(Number(riskPrice) / Number(price) - 1, FeatureValueType.Percentage)} from price)` : 'Risk price'}`} />
          <TextInput field='riskPrice' readOnly={isPublic} />
          <Label label='Position risk size' />
          <TextInput field='positionRiskSize' readOnly={isPublic} />

          {!isPublic && (
            <div className='flex gap-2 mt-6'>
              {selectedExecution ? (
                <>
                  {updateExecutionButton(selectedExecution)}
                  {context?.handleExecutionEditClick && <Button onClick={() => context.handleExecutionEditClick!(undefined)} style={ButtonStyle.Alternative}>Cancel update</Button>}
                  <Button onClick={handleExecutionDelete} style={ButtonStyle.Alternative}>Delete</Button>
                </>
              ) : (
                <Submit<BacktestTradeExecutionFormInputs> onSubmit={saveExecution} style={selectedExecution ? ButtonStyle.Alternative : ButtonStyle.Primary}>Create</Submit>
              )}
            </div>
          )}

          <Error field='error' />
        </>
      }}
    </Form>
  );
});

export default BacktestTradeExecutionForm;
