import Form from "components/common/Form";
import SearchInput from "components/common/Form/SearchInput";
import PaginatedTable from "components/common/Table/PaginatedTable";
import { FeatureValueType, SignalTargetVariable, TargetVariableValueType } from "graphql/generated";
import { FC, useState } from "react";
import { formatRawValue } from "util/valueFormat";
import * as yup from 'yup';

interface SignalTargetVariablesTableProps {
  signalTargetVariables: SignalTargetVariable[];
  targetVariableValueType: TargetVariableValueType;
  allowedTargetVariableBases: string[];
}

interface SearchFormInputs {
  input: string;
}

const searchValidationSchema = yup.object({
  input: yup.string().required(),
}).required();


const targetVariableBaseFromName = (name: string) => name.replace(' high', '').replace(' low', '').replace(' close', '');

const SignalTargetVariablesTable: FC<SignalTargetVariablesTableProps> = ({ signalTargetVariables, targetVariableValueType, allowedTargetVariableBases }) => {
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);

  const allowedTargetVariableBasesSet = new Set(allowedTargetVariableBases);
  const allowedSignalTargetVariables = signalTargetVariables.filter(x => allowedTargetVariableBasesSet.has(targetVariableBaseFromName(x.TargetVariable?.name!)))!;
  const allowedTargetVariableIndexByBase: { [key: string]: number } = allowedTargetVariableBases.reduce((result, item, index) => ({ ...result, [item]: index }), {});
  const sortedSignalTargetVariables = allowedSignalTargetVariables?.sort((a, b) => allowedTargetVariableIndexByBase[targetVariableBaseFromName(a.TargetVariable?.name!)] - allowedTargetVariableIndexByBase[targetVariableBaseFromName(b.TargetVariable?.name!)]);

  const targetVariablesWithId = !sortedSignalTargetVariables ? [] : sortedSignalTargetVariables.map(x => ({
    ...x,
    id: `${x.signalId}-${x.targetVariableId}`
  }));

  type TargetVariableRowType = typeof targetVariablesWithId[0];

  const targetVariableColumns = [
    {
      key: 'name',
      header: () => 'Name',
      cell: (o: TargetVariableRowType) => o.TargetVariable?.name,
    },
    {
      key: 'value',
      header: () => 'Value',
      cell: (o: TargetVariableRowType) => {
        if (targetVariableValueType === TargetVariableValueType.Percentage) {
          return formatRawValue(o.value, FeatureValueType.Percentage);
        } else {
          return `${formatRawValue(o.value, FeatureValueType.Number)} ATRs`;
        }
      },
    },
  ];

  const displayedTargetVariables = targetVariablesWithId.filter(f => !searchInput || searchInput === '' || f.TargetVariable?.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1);

  return (
    <>
      <Form<SearchFormInputs> validationSchema={searchValidationSchema}>
        <SearchInput<SearchFormInputs> field='input' placeholder="Search..." onChange={input => setSearchInput(input)} />
      </Form>
      <PaginatedTable<TargetVariableRowType> columns={targetVariableColumns} data={displayedTargetVariables} pageSize={10} />
    </>
  );
};

export default SignalTargetVariablesTable;