import { ErrorCallback, ResolutionString, ResolveCallback, SymbolResolveExtension } from "charting_library";
import TickerDateDatafeed from "..";

export const resolveSymbol = (
  datafeed: TickerDateDatafeed,
  symbolName: string,
  onResolve: ResolveCallback,
  onError: ErrorCallback,
  extension?: SymbolResolveExtension
) => {
  setTimeout(() => {
    onResolve({
      name: symbolName,
      full_name: symbolName,
      description: symbolName,
      type: "stock",
      exchange: "",
      listed_exchange: "",
      timezone: "America/New_York",
      format: "price",
      pricescale: 100,
      minmov: 1,
      supported_resolutions: datafeed.resolutions as ResolutionString[],
      has_intraday: datafeed.hasIntraday,
      intraday_multipliers: datafeed.hasIntraday ? ['1', '2', '5', '15', '30', '60'] : undefined,
      session: extension?.session === 'extended' ? "0400-2000" : '0930-1600',
      subsession_id: extension?.session || "regular",
      subsessions: [
        {
          "description": "Regular Trading Hours",
          "id": "regular",
          "session": "0930-1600"
        },
        {
          "description": "Extended Trading Hours",
          "id": "extended",
          "session": "0400-2000"
        },
        {
          "description": "Premarket",
          "id": "premarket",
          "session": "0400-0930"
        },
        {
          "description": "Postmarket",
          "id": "postmarket",
          "session": "1600-2000"
        }
      ],
    });
  }, 50);
};
