import { gql, useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import SignalFeatures, { SignalFeaturesRef } from 'components/Signals/SignalFeatures';
import Button, { ButtonStyle } from 'components/common/Button';
import EditorFormInput from 'components/common/Editor/EditorFormInput';
import Form, { FormRef } from 'components/common/Form';
import Error from 'components/common/Form/Error';
import Label from 'components/common/Form/Label';
import Submit from 'components/common/Form/Submit';
import TextInput from 'components/common/Form/TextInput';
import { useTickerDateColumnContext } from 'contexts/TickerDateColumnContext';
import { BarPayload, Collection, Feature, FeaturesDocument, RemoveSignalMutation, SaveSignalMutation, Signal, SignalsDocument, SignalsFromFiltersDocument, TickerDate, TickerDateDetailsDocument, TickerDatesFromFiltersDocument } from 'graphql/generated';
import moment from 'moment';
import { forwardRef, useImperativeHandle, useRef } from "react";
import { DeepPartial, UseFormReturn } from 'react-hook-form';
import { saveSignal } from './functions/saveSignal';
import { ISignalInputs, getBarFromTime, validationSchema } from './schema';

const SAVE_SIGNAL_MUTATION = gql`
  mutation SaveSignal($signal: SignalInput!,$features: [FeatureInput!]!, $tags: [FeatureInput!]!, $notes: String!) {
    saveSignal(signal: $signal, features: $features, tags: $tags, notes: $notes)
  }
`;

const REMOVE_SIGNAL_MUTATION = gql`
  mutation RemoveSignal($signalId: Int!) {
    removeSignal(signalId: $signalId)
  }
`;

export interface SignalFormProps {
  intradayBars: BarPayload[],
  features: Feature[];
  selectedSignal?: Signal;
  tickerDate: TickerDate;
  isPublic: boolean;
  onSave?: () => void;
  collection?: Partial<Collection>;
}

export interface SignalFormRef {
  formMethods?: UseFormReturn<ISignalInputs>;
  resetForm: () => void;
}

const SignalForm = forwardRef<SignalFormRef, SignalFormProps>(({ intradayBars, features, selectedSignal, tickerDate, isPublic, onSave, collection }, ref) => {
  const formRef = useRef<FormRef<ISignalInputs>>(null);
  const signalFeaturesRef = useRef<SignalFeaturesRef>(null);
  const context = useTickerDateColumnContext();

  const defaultValues: DeepPartial<ISignalInputs> = {
    price: selectedSignal?.price ? "" + selectedSignal?.price : undefined,
    time: selectedSignal ? moment(selectedSignal.timestamp).tz('America/New_York').format('HH:mm') : undefined,
    notes: selectedSignal?.notes || '',
    name: selectedSignal?.name || '',
  };

  const [saveSignalMutation] = useMutation<SaveSignalMutation>(SAVE_SIGNAL_MUTATION, {
    refetchQueries: [
      getOperationName(FeaturesDocument)!,
      getOperationName(TickerDateDetailsDocument)!,
      getOperationName(SignalsDocument)!,
      getOperationName(TickerDateDetailsDocument)!,
      getOperationName(TickerDatesFromFiltersDocument)!,
      getOperationName(SignalsFromFiltersDocument)!,
    ],
  });

  const [removeSignalMutation] = useMutation<RemoveSignalMutation>(REMOVE_SIGNAL_MUTATION, {
    refetchQueries: [
      getOperationName(FeaturesDocument)!,
      getOperationName(TickerDateDetailsDocument)!,
      getOperationName(SignalsDocument)!,
      getOperationName(TickerDateDetailsDocument)!,
      getOperationName(TickerDatesFromFiltersDocument)!,
      getOperationName(SignalsFromFiltersDocument)!,
    ],
  });

  const resetForm = () => {
    formRef.current?.reset({
      price: '',
      time: '',
      features: {},
      tags: undefined,
      notes: undefined,
      name: '',
    });

    signalFeaturesRef.current?.setSignalFeatures([]);
  };

  useImperativeHandle(ref, () => ({
    formMethods: formRef.current || undefined,
    resetForm,
  }));

  const handleSave = async (fields: ISignalInputs, formMethods: UseFormReturn<ISignalInputs>) => {
    await saveSignal(fields, selectedSignal, tickerDate, formRef, saveSignalMutation, onSave);
    resetForm();
    context.refreshMarks();
  };

  const handleSignalDelete = async () => {
    await removeSignalMutation({ variables: { signalId: selectedSignal!.id } });
    if (onSave) onSave();
    resetForm();
    context.refreshMarks();
  };

  return (
    <Form<ISignalInputs> validationSchema={validationSchema(intradayBars)} defaultValues={defaultValues} ref={formRef}>
      {(formMethods) => {
        const time = formMethods.watch('time');
        const barFromTime = getBarFromTime(intradayBars, time);

        return <>
          <Label label='Time' />
          <TextInput field='time' readOnly={isPublic} />
          <Label label={barFromTime ? `Price ${barFromTime.low} to ${barFromTime.high}` : 'Price'} />
          <TextInput field='price' readOnly={isPublic} />
          <Label label='Name' />
          <TextInput field='name' readOnly={isPublic} />
          <Label label='Data' />
          <SignalFeatures field='features' signalId={selectedSignal?.id} features={features} isPublic={isPublic} ref={signalFeaturesRef} collection={collection} />
          <Label label='Notes' />
          <EditorFormInput field='notes' className='mb-20' readOnly={isPublic} />

          {!isPublic && (
            <div className='flex gap-2'>
              {selectedSignal && <Submit<ISignalInputs> onSubmit={handleSave} style={ButtonStyle.Primary}>Update</Submit>}
              {context?.handleExecutionEditClick && selectedSignal && <Button onClick={() => context.handleSignalEditClick!(undefined)} style={ButtonStyle.Alternative}>Cancel update</Button>}
              {selectedSignal && <Button onClick={handleSignalDelete} style={ButtonStyle.Alternative}>Delete</Button>}
              {!selectedSignal && <Submit<ISignalInputs> onSubmit={handleSave} style={ButtonStyle.Primary}>Create</Submit>}
            </div>
          )}

          <Error field='error' />
        </>
      }}
    </Form>
  );
});

export default SignalForm;
