import Form, { FormRef } from "components/common/Form";
import SearchInput from "components/common/Form/SearchInput";
import Modal from "components/common/Modal";
import { FC, useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import FilterInput from "./FilterInput";
import { FiltersFormFields } from "./schema";

interface FiltersModalProps {
  show: boolean,
  filtersExpressionIndex: number;
  defaultValues: FiltersFormFields,
  onClose?: (values: FiltersFormFields | undefined) => void,
}

interface SearchFormInputs {
  input: string;
}

const searchValidationSchema = yup.object({
  input: yup.string().required(),
}).required();

const featuresValidationSchema = yup.object({
  features: yup.array(yup.object({
    featureId: yup.number().integer().required(),
    minValue: yup.lazy(v => v === '' ? yup.string() : yup.number().typeError('Must be a number')),
    maxValue: yup.lazy(v => v === '' ? yup.string() : yup.number().typeError('Must be a number')),
  })),
}).required();

const FiltersModal: FC<FiltersModalProps> = ({ filtersExpressionIndex, show, onClose, defaultValues }) => {
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);
  const filterVisibility = filtersExpressionIndex >= 0 ? defaultValues.filtersExpressions[filtersExpressionIndex].filters.map(f => !searchInput || f.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1) : [];
  const formRef = useRef<FormRef<FiltersFormFields>>(null);

  useEffect(() => {
    setSearchInput(undefined);
  }, [defaultValues]);

  const handleClose = () => {
    if (onClose) onClose(formRef.current?.getValues());
  };

  return (
    <Modal show={show} onClose={handleClose}>
      <div className='flex mb-5'>
        <span className="material-icons cursor-pointer ml-auto" onClick={handleClose}>close</span>
      </div>
      <Form<SearchFormInputs> validationSchema={searchValidationSchema} className='mb-10'>
        <SearchInput<SearchFormInputs> field='input' placeholder="Search..." onChange={input => setSearchInput(input)} />
      </Form>
      <Form<FiltersFormFields> validationSchema={featuresValidationSchema} defaultValues={defaultValues} ref={formRef}>
        <div className='grid gap-x-20 gap-y-6 xl:grid-cols-2 grid-cols-1'>
          {filtersExpressionIndex >= 0 && defaultValues.filtersExpressions[filtersExpressionIndex].filters.map((filter, filterIndex) => {
            if (!filterVisibility[filterIndex]) return null;

            return (
              <FilterInput type={filter.type} name={filter.name} filtersExpressionIndex={filtersExpressionIndex} filterIndex={filterIndex} key={filterIndex} tag={filter.tag} />
            );
          })}
        </div>
      </Form>
    </Modal>
  );
};

export default FiltersModal;