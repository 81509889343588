import React from 'react';

export type BacktestSearchParamsContextType = {
  backtestTradeId?: number
  activeTab?: number;

  setBacktestTradeId: (id?: number) => void;
  setActiveTab: (tab?: number) => void;
  reset: () => void;
};

const defaultValue: BacktestSearchParamsContextType = {
  setBacktestTradeId: () => {},
  setActiveTab: () => {},
  reset: () => {},
}

const BacktestSearchParamsContext = React.createContext(defaultValue);

export { BacktestSearchParamsContext };
