import { BacktestSearchParamsContext } from "contexts/BacktestSearchParamsContext";
import { FC, ReactNode } from "react";
import { useSearchParams } from "react-router-dom";

interface BacktestTradeSearchParamsProps {
  children: ReactNode;
}

const BacktestSearchParams: FC<BacktestTradeSearchParamsProps> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const BACKTEST_TRADE_ID_SEARCH_PARAM_KEY = `btid`;
  const TAB_SEARCH_PARAM_KEY = `bttab`;

  const setBacktestTradeId = (id?: number) => {
    if (id !== undefined) {
      searchParams.set(BACKTEST_TRADE_ID_SEARCH_PARAM_KEY, "" + id);
    } else {
      searchParams.delete(BACKTEST_TRADE_ID_SEARCH_PARAM_KEY);
    }

    setSearchParams(searchParams);
  };

  const setActiveTab = (tab?: number) => {
    if (tab !== undefined) {
      searchParams.set(TAB_SEARCH_PARAM_KEY, "" + tab);
    } else {
      searchParams.delete(TAB_SEARCH_PARAM_KEY);
    }

    setSearchParams(searchParams);
  }

  const reset = () => {
    searchParams.delete(BACKTEST_TRADE_ID_SEARCH_PARAM_KEY);
    searchParams.delete(TAB_SEARCH_PARAM_KEY);
    setSearchParams(searchParams);
  };

  const backtestTradeId = searchParams.get(BACKTEST_TRADE_ID_SEARCH_PARAM_KEY);
  const activeTab = searchParams.get(TAB_SEARCH_PARAM_KEY);

  const value = {
    backtestTradeId: backtestTradeId ? Number(backtestTradeId) : undefined,
    activeTab: activeTab ? Number(activeTab) : undefined,
    setBacktestTradeId,
    setActiveTab,
    reset,
  };

  return (
    <BacktestSearchParamsContext.Provider value={value}>
      {children}
    </BacktestSearchParamsContext.Provider>
  );
};

export default BacktestSearchParams;
