import { ChangeEvent, FC } from "react";
import { useFormContext } from "react-hook-form";
import Error from './Error';

interface CheckboxInputProps {
  field: string;
  placeholder?: string;
  readOnly?: boolean;
  onChange?: (input: boolean) => void;
  className?: string;
}

const CheckboxInput: FC<CheckboxInputProps> = ({ field, placeholder, readOnly, onChange, className }) => {
  const { register } = useFormContext();

  let wrappedOnChange = undefined;
  if (onChange) {
    wrappedOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange!(event.target.checked);
    };
  }

  return (
    <div className={className}>
      <input {...register(field, { onChange: wrappedOnChange })} type="checkbox" placeholder={placeholder} disabled={readOnly} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
      <Error field={field} />
    </div>
  );
}

export default CheckboxInput;