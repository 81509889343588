import Button, { ButtonStyle } from "components/common/Button";
import { ASSET_TYPES } from "components/common/FiltersForm/asset_types";
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import { Column } from "components/common/Table";
import BackendTable from "components/common/Table/BackendTable";
import { FiltersSearchParamsContext } from "contexts/FiltersSearchParamsContext";
import { DateIdCursorInput, FeatureValueType, QueryTickerDatesFromFiltersArgs, TickerDate, TickerDatesWithTagsPayload } from "graphql/generated";
import { TICKER_DATES_FROM_FILTERS_QUERY } from "graphql/queries/tickerDatesFromFilters.query";
import { FC, useContext } from "react";
import { ViewingTickerDate } from "routes/Scanner";
import { formatRawValue } from "util/valueFormat";
import TickerDatesNextPrevControls from "../TickerDatesNextPrevControls";
import { scannerFormFieldsToInput } from "../util/scannerFormFieldsToInput";

interface TickerDatesTableFromFiltersProps {
  inputs: FiltersFormFields;
  viewingTickerDate?: ViewingTickerDate;
  onViewClick: (o: TickerDate) => void;
  pageSize?: number;
}

const TickerDatesTableFromFilters: FC<TickerDatesTableFromFiltersProps> = ({ inputs, viewingTickerDate, onViewClick, pageSize }) => {
  const filtersSearchParamsContext = useContext(FiltersSearchParamsContext);

  const inputVariables = scannerFormFieldsToInput(inputs);

  const columns: Column<TickerDatesWithTagsPayload>[] = [
    {
      key: 'ticker',
      header: () => 'Ticker',
      cell: (o: TickerDatesWithTagsPayload) => o.tickerDate.ticker.ticker,
    },
    {
      key: 'assetType',
      header: () => 'Asset type',
      cell: (o: TickerDatesWithTagsPayload) => o.tickerDate.assetType ? ASSET_TYPES[o.tickerDate.assetType] : '',
    },
    {
      key: 'marketCap',
      header: () => 'Market cap',
      cell: (o: TickerDatesWithTagsPayload) => o.tickerDate.marketCap ? formatRawValue(o.tickerDate.marketCap) : '',
    },
    {
      key: 'date',
      header: () => 'Date',
      cell: (o: TickerDatesWithTagsPayload) => o.tickerDate.date,
    },
    {
      key: 'open',
      header: () => 'Open',
      cell: (o: TickerDatesWithTagsPayload) => o.tickerDate.open,
    },
    {
      key: 'high',
      header: () => 'High',
      cell: (o: TickerDatesWithTagsPayload) => o.tickerDate.high,
    },
    {
      key: 'low',
      header: () => 'Low',
      cell: (o: TickerDatesWithTagsPayload) => o.tickerDate.low,
    },
    {
      key: 'close',
      header: () => 'Close',
      cell: (o: TickerDatesWithTagsPayload) => o.tickerDate.close,
    },
    {
      key: 'volume',
      header: () => 'Volume',
      cell: (o: TickerDatesWithTagsPayload) => formatRawValue(o.tickerDate.volume, FeatureValueType.Number),
    },
    {
      key: 'tags',
      header: () => 'Tags',
      cell: (o: TickerDatesWithTagsPayload) => o.tags.join(', '),
    },
    {
      key: 'times',
      header: () => 'Signal times',
      cell: (o: TickerDatesWithTagsPayload) => o.signalTimes.join(', '),
    },
    {
      key: 'view',
      header: () => '',
      cell: (o: TickerDatesWithTagsPayload) => {
        const viewing = viewingTickerDate?.ticker === o.tickerDate.ticker.ticker && viewingTickerDate?.date === o.tickerDate.date;
        return (
          <Button disabled={viewing} onClick={() => onViewClick(o.tickerDate)} style={ButtonStyle.Alternative}>{viewing ? 'Viewing' : 'View'}</Button>
        );
      }
    }
  ];

  const handleClick = (cursor: DateIdCursorInput, take: number) => {
    filtersSearchParamsContext.setCursor(cursor.id, cursor.date);
    filtersSearchParamsContext.setTake(take);
  };

  const variables: Partial<QueryTickerDatesFromFiltersArgs> = { input: inputVariables };
  if (filtersSearchParamsContext.cursorId && filtersSearchParamsContext.cursorDate) {
    variables.take = filtersSearchParamsContext.take;
    variables.cursor = {
      id: filtersSearchParamsContext.cursorId,
      date: filtersSearchParamsContext.cursorDate,
    };
  }

  return (
    <div className='my-10' >
      <BackendTable<TickerDatesWithTagsPayload, Partial<QueryTickerDatesFromFiltersArgs>, DateIdCursorInput>
        variables={variables}
        query={TICKER_DATES_FROM_FILTERS_QUERY}
        pageSize={pageSize || 5}
        columns={columns}
        dataKey='tickerDatesFromFilters'
        mapObjectToCursor={(object: TickerDatesWithTagsPayload) => ({ id: object.tickerDate.id, date: object.tickerDate.date })}
        onClickNext={handleClick}
        onClickPrevious={handleClick}
      />
      {viewingTickerDate && <TickerDatesNextPrevControls variables={inputVariables} viewingTickerDate={viewingTickerDate} onViewClick={onViewClick} />}
    </div >
  );
}

export default TickerDatesTableFromFilters;
