import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import Button, { ButtonStyle } from 'components/common/Button';
import PaginatedTable from 'components/common/Table/PaginatedTable';
import { TickerDateColumnContext } from 'contexts/TickerDateColumnContext';
import { Tooltip } from 'flowbite-react';
import { Backtest, BacktestStatsDocument, BacktestTradeExecution, FeatureValueType, RemoveBacktestExecutionsMutation, RemoveBacktestExecutionsMutationVariables, TickerDateDetailsDocument } from 'graphql/generated';
import { REMOVE_BACKTEST_EXECUTIONS } from 'graphql/mutations/removeBacktestExecutions.mutation';
import moment from 'moment';
import { FC, useContext } from 'react';
import { formatRawValue, formatRiskValue } from 'util/valueFormat';

interface BacktestTradeExecutionsTableProps {
  backtest: Partial<Backtest>;
  editingExecution?: BacktestTradeExecution;
  onEditClick: (o: BacktestTradeExecution) => void;
  executions: BacktestTradeExecution[];
}

const BacktestTradeExecutionsTable: FC<BacktestTradeExecutionsTableProps> = ({ backtest, executions, editingExecution, onEditClick }) => {
  const context = useContext(TickerDateColumnContext);
  const [removeExecutionMutation] = useMutation<RemoveBacktestExecutionsMutation, RemoveBacktestExecutionsMutationVariables>(REMOVE_BACKTEST_EXECUTIONS, {
    refetchQueries: [
      getOperationName(TickerDateDetailsDocument)!,
      getOperationName(BacktestStatsDocument)!,
    ],
  });

  const executionDates = new Set(executions.map(x => moment.tz(x.timestamp, 'America/New_York').format('YYYY-MM-DD')));

  const columns = [
    {
      key: 'type',
      header: () => 'Type',
      cell: (o: BacktestTradeExecution) => {
        const notes = [];
        if (o.riskPriceMissing) notes.push('Risk price missing on one or more executions');
        if (o.riskSizeInsufficient) notes.push('Risk size is not sufficient for adds - increase risk size or make risk tighter');
        if (o.riskSizeNotDecreasing) notes.push('Risk is not decreasing on covers');

        const tooltipContent = (
          <>
            {notes.map(n => (
              <div>{n}</div>
            ))}
          </>
        );

        return (
          <>
            {o.type}
            {notes.length > 0 && (
              <div className='inline-block ml-1'>
                <Tooltip content={tooltipContent}>
                  <svg className="w-[16px] h-[16px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                </Tooltip>
              </div>
            )}
          </>
        );
      },
    },
    {
      key: 'time',
      header: () => 'Time',
      cell: (o: BacktestTradeExecution) => {
        if (executionDates.size === 1) {
          return moment.tz(o.timestamp, 'America/New_York').format('HH:mm');
        } else {
          return moment.tz(o.timestamp, 'America/New_York').format('YYYY-MM-DD HH:mm');
        }
      },
    },
    {
      key: 'price',
      header: () => 'Price',
      cell: (o: BacktestTradeExecution) => formatRawValue(o.price, FeatureValueType.Number)
    },
    {
      key: 'positionAverageCost',
      header: () => 'Position average cost',
      cell: (o: BacktestTradeExecution) => formatRawValue(o.positionAverageCost, FeatureValueType.Number),
    },
    {
      key: 'riskPrice',
      header: () => 'Risk price',
      cell: (o: BacktestTradeExecution) => formatRawValue(o.riskPrice, FeatureValueType.Number)
    },
    {
      key: 'positionRiskSize',
      header: () => 'Position risk size',
      cell: (o: BacktestTradeExecution) => formatRawValue(o.positionRiskSize, FeatureValueType.Percentage),
    },
    {
      key: 'realizedNetProfitRisk',
      header: () => 'Realized',
      cell: (o: BacktestTradeExecution) => formatRiskValue(o.realizedNetProfitRisk),
    },
    {
      key: 'view',
      header: () => '',
      cell: (o: BacktestTradeExecution) => <Button disabled={editingExecution?.id === o.id} onClick={() => onEditClick(o)} style={ButtonStyle.Alternative}>{editingExecution?.id === o.id ? 'Viewing' : 'View'}</Button>
    }
  ];

  const sortedExecutions = [...executions].sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

  const handleDeleteAllExecutions = async () => {
    await removeExecutionMutation({
      variables: {
        executionIds: executions.map(e => e.id),
      },
    });
    context?.refreshMarks();
  };

  return (
    <>
      <PaginatedTable<BacktestTradeExecution> columns={columns} data={sortedExecutions} pageSize={10} />
      <Button style={ButtonStyle.Alternative} onClick={handleDeleteAllExecutions}>Delete all executions</Button>
    </>
  );
}

export default BacktestTradeExecutionsTable;