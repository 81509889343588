import { ApolloError } from "@apollo/client";
import { FC } from "react";

interface ErrorProps {
  error?: ApolloError | Error;
  className?: string;
  style?: React.CSSProperties;
}

const Error: FC<ErrorProps> = ({ error, className, style }) => {
  const userMessageError = error instanceof ApolloError ? error?.graphQLErrors.find(e => !!e.extensions.userMessage) : undefined;
  if (userMessageError) {
    return (
      <div className={className} style={style}>
        {userMessageError.extensions.userMessage as string}
      </div>
    );
  } else {
    return (
      <div className={className} style={style}>
        Something went wrong... Developers have been notified.
      </div>
    );
  }
};

export default Error;