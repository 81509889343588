import { gql } from "@apollo/client";

export const BACKTEST_QUERY = gql`
  query Backtest($id: Int!) {
    backtest(id: $id) {
      id
      name
      userId
      feesPercentagePerExecution
      feesPercentagePerTrade
    }
  }
`;