import { gql, useMutation } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import * as Sentry from "@sentry/react";
import Button, { ButtonStyle } from "components/common/Button";
import Form, { FormRef } from "components/common/Form";
import Error from "components/common/Form/Error";
import Label from "components/common/Form/Label";
import Submit from "components/common/Form/Submit";
import TextInput from "components/common/Form/TextInput";
import { Feature, FeaturesAndFeatureSetsDocument, FeatureSet, FeatureValueType, RemoveFeatureSetMutation, RemoveFeatureSetMutationVariables, SaveFeatureSetMutation, SaveFeatureSetMutationVariables } from "graphql/generated";
import { forwardRef, useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { UseFormReturn } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import FeaturesContainer from "./FeaturesContainer";
import { FeatureSetFormInputsProps, validationSchema } from "./schema";

const SAVE_FEATURE_SET_MUTATION = gql`
  mutation SaveFeatureSet($input: FeatureSetInput!) {
    saveFeatureSet(input: $input)
  }
`;

const REMOVE_FEATURE_SET_MUTATION = gql`
  mutation RemoveFeatureSet($featureSetId: Int!) {
    removeFeatureSet(featureSetId: $featureSetId)
  }
`;

interface FeatureSetFormProps {
  features: Feature[];
  featureSets: FeatureSet[];
  editingFeatureSet?: FeatureSet;
  setEditingFeatureSet: (featureSet: FeatureSet | undefined) => void;
}

const FeatureSetForm = forwardRef<FormRef<FeatureSetFormInputsProps>, FeatureSetFormProps>(({ features, editingFeatureSet, setEditingFeatureSet }, ref) => {
  const formRef = useRef<FormRef<FeatureSetFormInputsProps>>(null);

  const [saveFeatureSetMutation] = useMutation<SaveFeatureSetMutation, SaveFeatureSetMutationVariables>(SAVE_FEATURE_SET_MUTATION);
  const [removeFeatureSetMutation] = useMutation<RemoveFeatureSetMutation, RemoveFeatureSetMutationVariables>(REMOVE_FEATURE_SET_MUTATION);

  const featuresById: { [key: number]: Feature } = features.reduce((p, c) => ({ ...p, [c.id]: c }), {});

  const [selectedFeatures, setSelectedFeatures] = useState<any[]>([]);

  useEffect(() => {
    if (!editingFeatureSet) return;

    const newFeatures = editingFeatureSet.features.map((f: any) => ({
      ...f,
      fieldId: uuidv4(),
    }));
    setSelectedFeatures(newFeatures);

    const updateFormValues = (featureSet: FeatureSet, updatedFeatures: any[]) => {
      const formMethods = formRef.current;
      if (!formMethods) return;

      formMethods.setValue('name', featureSet.name);
      formMethods.setValue('updating', undefined);
      formMethods.setValue('selectedFeatureSetId', undefined);
      formMethods.setValue('features', {}); // TODO create new field ids

      formMethods.setValue(`features`, {});
      updatedFeatures.forEach(f => {
        formMethods.setValue(`features.${f.fieldId}`, { value: f.featureId, label: featuresById[f.featureId].name });
      });
    };
    updateFormValues(editingFeatureSet, newFeatures);
  }, [editingFeatureSet]);

  const handleCreateNewFeature = () => {
    setSelectedFeatures([
      ...selectedFeatures,
      {
        fieldId: uuidv4(),
      },
    ]);
  };

  const handleDeleteFeatureFromSet = (field: string) => {
    const fieldSplit = field.split('.');
    const fieldId = fieldSplit[fieldSplit.length - 1];
    const newSelectedFeatures = selectedFeatures.filter((s: any) => s.fieldId !== fieldId);
    setSelectedFeatures(newSelectedFeatures);

    const formMethods = formRef.current;
    if (!formMethods) return;
    const newValues = formMethods.getValues();
    delete newValues.features[fieldId];
    formMethods.reset(newValues);
  };

  const featureOptions = features.map(f => ({ label: f.name, value: f.id }));

  const resetForm = (formMethods: UseFormReturn<FeatureSetFormInputsProps>) => {
    formMethods.reset({
      name: '',
      updating: undefined,
      selectedFeatureSetId: undefined,
      features: {},
    });
    setEditingFeatureSet(undefined);
    setSelectedFeatures([]);
  };

  const saveFeatureSet = async (fields: FeatureSetFormInputsProps, formMethods: UseFormReturn<FeatureSetFormInputsProps>) => {
    await saveFeatureSetMutation({
      variables: {
        input: {
          featureSetName: fields.name,
          featureSetId: fields.selectedFeatureSetId,
          features: selectedFeatures.map(f => ({ featureId: fields.features[f.fieldId].value })),
        },
      },
      refetchQueries: [getOperationName(FeaturesAndFeatureSetsDocument)!],
    }).then(() => {
      resetForm(formMethods);
    }).catch((err: any) => {
      formMethods.setError('error', { message: 'Something went wrong..' });
      Sentry.captureException(err);
    });
  };

  const handleUpdate = async (fields: FeatureSetFormInputsProps, formMethods: UseFormReturn<FeatureSetFormInputsProps>) => {
    formMethods.setValue('updating', true);
    formMethods.setValue('selectedFeatureSetId', editingFeatureSet!.id);
    fields.updating = true;
    fields.selectedFeatureSetId = editingFeatureSet!.id;
    saveFeatureSet(fields, formMethods);
  };

  const handleCreate = async (fields: FeatureSetFormInputsProps, formMethods: UseFormReturn<FeatureSetFormInputsProps>) => {
    formMethods.setValue('updating', false);
    formMethods.setValue('selectedFeatureSetId', undefined);
    fields.updating = false;
    fields.selectedFeatureSetId = undefined;
    saveFeatureSet(fields, formMethods);
  };

  const handleDeleteFeatureSet = async (fields: FeatureSetFormInputsProps, formMethods: UseFormReturn<FeatureSetFormInputsProps>) => {
    await removeFeatureSetMutation({
      variables: {
        featureSetId: editingFeatureSet!.id,
      },
      refetchQueries: [getOperationName(FeaturesAndFeatureSetsDocument)!],
    });
    resetForm(formMethods);
  };

  return (
    <Form<FeatureSetFormInputsProps> validationSchema={validationSchema} ref={formRef}>
      <Label label='Data view name' />
      <TextInput field='name' />
      <Label label='Data values' />
      <DndProvider backend={HTML5Backend}>
        <FeaturesContainer selectedFeatures={selectedFeatures} setSelectedFeatures={setSelectedFeatures} options={featureOptions} onDelete={handleDeleteFeatureFromSet} />
      </DndProvider>

      <Button onClick={handleCreateNewFeature} style={ButtonStyle.Alternative} className='mt-4'>Add data value</Button>
      <div className='flex gap-2 mt-6'>
        {editingFeatureSet && <Submit<FeatureSetFormInputsProps> onSubmit={handleUpdate} style={ButtonStyle.Alternative}>Update {editingFeatureSet.name}</Submit>}
        {editingFeatureSet && <Submit<FeatureSetFormInputsProps> onSubmit={handleDeleteFeatureSet} style={ButtonStyle.Alternative}>Delete {editingFeatureSet.name}</Submit>}
        <Submit<FeatureSetFormInputsProps> onSubmit={handleCreate} style={ButtonStyle.Alternative}>Create new</Submit>
      </div>
      <Error field='error' />
    </Form>
  );
});

export default FeatureSetForm;
