import useMediaQuery from "./useMediaQuery";

/**
 * Get a set of boolean representing which breakpoint is active
 * and which breakpoints are inactive.
 *
 * Inspired by: https://github.com/contra/react-responsive/issues/162#issuecomment-592082035
 */

export enum Breakpoints {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
};

export default function useBreakpoints() {
  const breakpoints = {
    xs: useMediaQuery("(max-width: 639px)"),
    sm: useMediaQuery("(min-width: 640px) and (max-width: 767px)"),
    md: useMediaQuery("(min-width: 768px) and (max-width: 1023px)"),
    lg: useMediaQuery("(min-width: 1024px) and (max-width: 1279px"),
    xl: useMediaQuery("(min-width: 1280px) and (max-width: 1535px"),
    xxl: useMediaQuery("(min-width: 1536px)"),
    active: Breakpoints.xs,
  };

  [...Object.entries(Breakpoints)].forEach(([k, breakpoint]) => {
    if (breakpoints[breakpoint]) {
      breakpoints.active = breakpoint;
    }
  });

  return breakpoints;
};
