import { gql } from "@apollo/client";

export const FEATURES_QUERY = gql`
  query Features {
    features {
      id
      name
      userId
      type
      valueType
    }
  }
`;