import { apolloClient } from 'App';
import { UserContext } from 'contexts/UserContext';
import { Navbar as FlowbiteNavbar } from 'flowbite-react';
import { FC, useState } from 'react';
import { NavLinkProps, NavLink as RouterNavLink, useNavigate } from 'react-router-dom';
import AdminModal from '../AdminModal';

const NavLink: FC<NavLinkProps> = (props) => {
  const baseClasses = 'block py-2 pl-3 pr-4 rounded';
  const activeClasses = "text-white bg-blue-700  md:bg-transparent md:text-blue-700 md:p-0 dark:text-white";
  const inactiveClasses = "text-gray-700  hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent";
  return <RouterNavLink {...props} className={({ isActive }) => `${baseClasses} ${isActive ? activeClasses : inactiveClasses}`} />
}

interface NavTextProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const NavText: FC<NavTextProps> = ({ children, onClick }) => {
  const baseClasses = 'block py-2 pl-3 pr-4 rounded';
  const inactiveClasses = "text-gray-700  hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent";
  return <div onClick={onClick} className={`${baseClasses} ${inactiveClasses} ${onClick ? 'cursor-pointer' : ''}`}>{children}</div>
}

const Navbar: FC = () => {
  const navigate = useNavigate();
  const [showAdminModal, setShowAdminModal] = useState<boolean>(false);

  return (
    <UserContext.Consumer>
      {({ user, logout }) => (
        <FlowbiteNavbar fluid={true} rounded={true}>
          <RouterNavLink to='/' className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            Charts and Stats
          </RouterNavLink>
          <FlowbiteNavbar.Toggle />
          <FlowbiteNavbar.Collapse>
            {user?.email ? (
              <>
                {user.admin && (
                  <div onClick={() => setShowAdminModal(true)} className='cursor-pointer block py-2 pl-3 pr-4 rounded text-gray-700  hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'>
                    Admin
                  </div>
                )}
                <NavLink to='/scanner'>Scanner</NavLink>
                <NavLink to='/data'>Your data</NavLink>
                {process.env.NODE_ENV !== 'production' && <NavLink to='/collections'>Collections</NavLink>}
                <NavLink to='/backtests'>Backtests</NavLink>
                <NavText onClick={async () => {
                  logout();
                  await apolloClient.clearStore();
                  navigate('/');
                }}>Logout</NavText>
              </>
            ) : (<>
              <NavLink to='/'>Home</NavLink>
              <NavLink to='/login'>Login</NavLink>
            </>)
            }

          </FlowbiteNavbar.Collapse>
          <AdminModal show={showAdminModal} onClose={() => setShowAdminModal(false)} />
        </FlowbiteNavbar>
      )}
    </UserContext.Consumer>
  );
};

export default Navbar;
