import Option from "components/common/Form/Option";
import { ChartHeight, TickerDatesLayout, TickerDatesLayoutContext, TickerDatesLayoutContextType } from "contexts/TickerDatesLayoutContext";
import { FC, ReactNode, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Form from "../Form";
import Label from "../Form/Label";
import RadioGroup from "../Form/RadioGroup";
import Select from "../Form/Select";

interface TickerDatesLayoutFormProps {
  keyPrefix: string;
  searchParamsPrefix?: string;
  children: ReactNode;
}

const TickerDatesLayoutForm: FC<TickerDatesLayoutFormProps> = ({ keyPrefix, searchParamsPrefix, children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsLayoutKey = `${searchParamsPrefix}`;
  const searchParamsTableResultsPerPageKey = `${searchParamsPrefix}t`;
  const searchParamsChartColumnsPerPageKey = `${searchParamsPrefix}cc`;
  const searchParamsChartRowsPerPageKey = `${searchParamsPrefix}cr`;
  const searchParamsChartHeightKey = `${searchParamsPrefix}cs`;

  const searchParamsLayout = searchParamsPrefix ? searchParams.get(searchParamsLayoutKey) : null;
  const searchParamstableResultsPerPage = searchParamsPrefix ? searchParams.get(searchParamsTableResultsPerPageKey) : null;
  const searchParamschartColumnsPerPage = searchParamsPrefix ? searchParams.get(searchParamsChartColumnsPerPageKey) : null;
  const searchParamschartRowsPerPage = searchParamsPrefix ? searchParams.get(searchParamsChartRowsPerPageKey) : null;
  const searchParamsChartHeight = searchParamsPrefix ? searchParams.get(searchParamsChartHeightKey) : null;

  const [layout, setLayout] = useState<TickerDatesLayout>(searchParamsLayout !== null ? searchParamsLayout as TickerDatesLayout : TickerDatesLayout.Table);
  const [tableResultsPerPage, setTableResultsPerPage] = useState<number>(searchParamstableResultsPerPage !== null ? Number(searchParamstableResultsPerPage) : 5);
  const [chartColumnsPerPage, setChartColumnsPerPage] = useState<number>(searchParamschartColumnsPerPage !== null ? Number(searchParamschartColumnsPerPage) : 3);
  const [chartRowsPerPage, setChartRowsPerPage] = useState<number>(searchParamschartRowsPerPage !== null ? Number(searchParamschartRowsPerPage) : 3);
  const [chartHeight, setChartHeight] = useState<ChartHeight>(searchParamsChartHeight !== null ? searchParamsChartHeight as ChartHeight : ChartHeight.Small);

  const resetSearchParams = () => {
    searchParams.delete('cid');
    searchParams.delete('cd');
    searchParams.delete('ftt');
  };

  const handleLayoutChange = (layout: Option) => {
    setLayout(layout.value as TickerDatesLayout);
    if (searchParamsPrefix) {
      resetSearchParams();
      searchParams.set(searchParamsLayoutKey, "" + layout.value);
      setSearchParams(searchParams);
    }
  };

  const handleTableResultsPerPageChange = (option: Option) => {
    setTableResultsPerPage(Number(option.value));
    if (searchParamsPrefix) {
      resetSearchParams();
      searchParams.set(searchParamsTableResultsPerPageKey, "" + option.value);
      setSearchParams(searchParams);
    }
  };

  const handleChartColumnsPerPageChange = (option: Option) => {
    setChartColumnsPerPage(Number(option.value));
    if (searchParamsPrefix) {
      resetSearchParams();
      searchParams.set(searchParamsChartColumnsPerPageKey, "" + option.value);
      setSearchParams(searchParams);
    }
  };

  const handleChartRowsPerPageChange = (option: Option) => {
    setChartRowsPerPage(Number(option.value));
    if (searchParamsPrefix) {
      resetSearchParams();
      searchParams.set(searchParamsChartRowsPerPageKey, "" + option.value);
      setSearchParams(searchParams);
    }
  };

  const handleChartHeightChange = (option: Option) => {
    setChartHeight(option.value as ChartHeight);
    if (searchParamsPrefix) {
      resetSearchParams();
      searchParams.set(searchParamsChartHeightKey, "" + option.value);
      setSearchParams(searchParams);
    }
  };

  const layoutOptions = [
    { label: TickerDatesLayout.Table, value: TickerDatesLayout.Table },
    { label: TickerDatesLayout.Charts, value: TickerDatesLayout.Charts },
  ];

  const tableResultsPerPageOptions: Option[] = [...Array(10)].map((_, i) => 5 + i * 5).map(x => ({ label: "" + x, value: x }));
  const chartColumnsPerPageOptions: Option[] = [...Array(4)].map((_, i) => 1 + i).map(x => ({ label: "" + x, value: x }));
  const chartRowsPerPageOptions: Option[] = [...Array(12)].map((_, i) => 1 + i).map(x => ({ label: "" + x, value: x }));
  const chartHeightOptions: Option[] = [
    { label: ChartHeight.Small, value: ChartHeight.Small },
    { label: ChartHeight.Large, value: ChartHeight.Large },
  ]

  const contextValue: TickerDatesLayoutContextType = {
    layout,
    tableResultsPerPage,
    chartColumnsPerPage,
    chartRowsPerPage,
    chartHeight: chartHeight,
  };

  const layoutField = `${keyPrefix}-layout`;
  const tableResultsPerPageField = `${keyPrefix}-tableResultsPerPage`;
  const chartColumnsPerPageField = `${keyPrefix}-chartColumnsPerPage`;
  const chartRowsPerPageField = `${keyPrefix}-chartRowsPerPage`;
  const chartHeightField = `${keyPrefix}-chartHeight`;

  const defaultValues = {
    [layoutField]: { label: layout, value: layout },
    [tableResultsPerPageField]: { label: tableResultsPerPage, value: tableResultsPerPage },
    [chartColumnsPerPageField]: { label: chartColumnsPerPage, value: chartColumnsPerPage },
    [chartRowsPerPageField]: { label: chartRowsPerPage, value: chartRowsPerPage },
    [chartHeightField]: { label: chartHeight, value: chartHeight },
  };

  return (
    <>
      <Form defaultValues={defaultValues}>
        <RadioGroup field={layoutField} options={layoutOptions} inline onChange={handleLayoutChange} />
        {layout === TickerDatesLayout.Table && (
          <>
            <Label label='Results per page' />
            <Select field={tableResultsPerPageField} options={tableResultsPerPageOptions} onChange={handleTableResultsPerPageChange} />
          </>
        )}
        {layout === TickerDatesLayout.Charts && (
          <div className='grid grid-cols-3 gap-10'>
            <div>
              <Label label='Columns' />
              <Select field={chartColumnsPerPageField} options={chartColumnsPerPageOptions} onChange={handleChartColumnsPerPageChange} />
            </div>
            <div>
              <Label label='Rows' />
              <Select field={chartRowsPerPageField} options={chartRowsPerPageOptions} onChange={handleChartRowsPerPageChange} />
            </div>
            <div>
              <Label label='Chart size' />
              <Select field={chartHeightField} options={chartHeightOptions} onChange={handleChartHeightChange} />
            </div>
          </div>
        )}
      </Form>
      <TickerDatesLayoutContext.Provider value={contextValue}>
        {children}
      </TickerDatesLayoutContext.Provider>
    </>
  );
};

export default TickerDatesLayoutForm;