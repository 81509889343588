import { gql, useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import chroma from "chroma-js";
import Error from 'components/common/Error';
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import Table, { Column } from "components/common/Table";
import { QueryTickerFilterStatsArgs, StatsBucketPayload, TargetVariableValueType, TickerFilterStatsQuery } from "graphql/generated";
import { FC } from "react";
import { scannerFormFieldsToInput } from "../util/scannerFormFieldsToInput";

const TICKER_FILTER_STATS_QUERY = gql`
  query TickerFilterStats($input: ScannerFiltersInput!, $targetVariableValueType: TargetVariableValueType!) {
    tickerFilterStats(input: $input, targetVariableValueType: $targetVariableValueType) {
      numberOfObjects
      columnNames
      buckets {
        name
        values
      }
      footer {
        name
        values
      }
    }
  }
`;

function transpose(matrix: any[][]) {
  return matrix[0].map((col, i) => matrix.map(row => row[i]));
}

interface TickerFilterStatsProps {
  inputs: FiltersFormFields;
  targetVariableValueType: TargetVariableValueType;
}

const TickerFilterStatsTable: FC<TickerFilterStatsProps> = ({ inputs, targetVariableValueType }) => {
  const variables = scannerFormFieldsToInput(inputs);
  let { loading, error, data } = useQuery<TickerFilterStatsQuery, QueryTickerFilterStatsArgs>(TICKER_FILTER_STATS_QUERY, {
    variables: {
      input: variables,
      targetVariableValueType,
    },
  });

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  if (data.tickerFilterStats.buckets.length === 0) {
    return <div>No data</div>;
  }

  const displayData = data.tickerFilterStats.buckets.map(b => ({
    ...b,
    id: b.name
  }));

  const footerData = data.tickerFilterStats.footer.map(b => ({
    ...b,
    id: b.name
  }));

  type StatsBucketPayloadWithId = typeof displayData[0];

  const textColorScale = chroma.scale(['#6B7280', 'white']).domain([0, 0.1]);
  const bgColorScale = chroma.scale(['#E1EFFE', '#1C64F2']);

  const transposed = transpose(data.tickerFilterStats.buckets.map(b => b.values));
  const maxValues = transposed.map(c => Math.max(...c));
  const minValues = transposed.map(c => Math.min(...c));

  const columns: Column<StatsBucketPayloadWithId>[] = [
    {
      key: 'bucket',
      header: () => '',
      cell: (o: StatsBucketPayload) => o.name,
    },

    ...data.tickerFilterStats.columnNames.map((column, index) => ({
      key: column,
      header: () => column,
      cell: (o: StatsBucketPayload, rowIndex: number, columnIndex: number) => {
        if (rowIndex >= displayData.length) {
          if (targetVariableValueType === TargetVariableValueType.Percentage) {
            return `${(o.values[index] * 100).toFixed(2)}%`;
          } else {
            return `${(o.values[index]).toFixed(2)} ATRs`;
          }
        } else {
          return `${(o.values[index] * 100).toFixed(2)}%`;
        }
      },
      textColor: (o: StatsBucketPayload) => textColorScale((o.values[index] - minValues[index]) / (maxValues[index] - minValues[index])).hex(),
      backgroundColor: (o: StatsBucketPayload) => bgColorScale((o.values[index] - minValues[index]) / (maxValues[index] - minValues[index])).hex(),
    }))
  ];

  return (
    <div className='my-10'>
      <div>Number of tickers: {data?.tickerFilterStats.numberOfObjects}</div>
      <Table<StatsBucketPayloadWithId> columns={columns} data={displayData} footerData={footerData} />
    </div>
  );
}

export default TickerFilterStatsTable;