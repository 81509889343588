import { FiltersFormFields } from "components/common/FiltersForm/schema";
import BackendGrid from "components/common/Grid/BackendGrid";
import { FiltersSearchParamsContext } from "contexts/FiltersSearchParamsContext";
import { DateIdCursorInput, QueryTickerDatesFromFiltersArgs, TickerDate, TickerDatesWithTagsPayload } from "graphql/generated";
import { TICKER_DATES_FROM_FILTERS_QUERY } from "graphql/queries/tickerDatesFromFilters.query";
import useBreakpoints, { Breakpoints } from "hooks/useBreakpoints";
import { FC, useContext, useState } from "react";
import { ViewingTickerDate } from "routes/Scanner";
import TickerDateGridChart from "../TickerDateGridChart";
import NextPrevTickerControls from "../TickerDatesNextPrevControls";
import { scannerFormFieldsToInput } from "../util/scannerFormFieldsToInput";
import TickerDatesChartsFromFiltersModal from "./modal";

interface TickerDatesChartsFromFiltersProps {
  inputs: FiltersFormFields;
  viewingTickerDate?: ViewingTickerDate;
  chartColumnsPerPage?: number;
  chartRowsPerPage?: number;
  onViewClick: (o: TickerDate) => void;
}

const TickerDatesChartsFromFilters: FC<TickerDatesChartsFromFiltersProps> = ({ inputs, viewingTickerDate, onViewClick, chartColumnsPerPage, chartRowsPerPage }) => {
  const filtersSearchParamsContext = useContext(FiltersSearchParamsContext);
  const [tickerDateModal, setTickerDateModal] = useState<TickerDate | undefined>(undefined);
  const breakpoints = useBreakpoints();

  const inputVariables = scannerFormFieldsToInput(inputs);

  const handleClick = (cursor: DateIdCursorInput, take: number) => {
    filtersSearchParamsContext.setCursor(cursor.id, cursor.date);
    filtersSearchParamsContext.setTake(take);
  };

  const variables: Partial<QueryTickerDatesFromFiltersArgs> = { input: inputVariables };
  if (filtersSearchParamsContext.cursorId && filtersSearchParamsContext.cursorDate) {
    variables.take = filtersSearchParamsContext.take;
    variables.cursor = {
      id: filtersSearchParamsContext.cursorId,
      date: filtersSearchParamsContext.cursorDate,
    };
  }

  const handleAddDataClick = (tickerDate: TickerDate) => setTickerDateModal(tickerDate);

  const renderCell = (payload: TickerDatesWithTagsPayload) => {
    return (
      <TickerDateGridChart tickerDate={payload.tickerDate} tags={payload.tags} onViewClick={onViewClick} onAddDataClick={handleAddDataClick} />
    );
  };

  let maxColumns = 4;
  if ([Breakpoints.lg, Breakpoints.md].includes(breakpoints.active)) {
    maxColumns = 2;
  } else if ([Breakpoints.xs, Breakpoints.sm].includes(breakpoints.active)) {
    maxColumns = 1;
  }

  const columns = chartColumnsPerPage ? Math.min(chartColumnsPerPage, maxColumns) : maxColumns;
  const rows = chartRowsPerPage ? chartRowsPerPage : 3;
  const pageSize = columns * rows;

  return (
    <div className='my-10' >
      <BackendGrid<TickerDatesWithTagsPayload, Partial<QueryTickerDatesFromFiltersArgs>, DateIdCursorInput>
        variables={variables}
        query={TICKER_DATES_FROM_FILTERS_QUERY}
        pageSize={pageSize}
        columns={columns}
        dataKey='tickerDatesFromFilters'
        mapObjectToCursor={(object: TickerDatesWithTagsPayload) => ({ id: object.tickerDate.id, date: object.tickerDate.date })}
        onClickNext={handleClick}
        onClickPrevious={handleClick}
        renderCell={renderCell}
        className="gap-4"
      />
      {viewingTickerDate && <NextPrevTickerControls variables={inputVariables} viewingTickerDate={viewingTickerDate} onViewClick={onViewClick} />}
      {tickerDateModal && <TickerDatesChartsFromFiltersModal ticker={tickerDateModal.ticker.ticker} date={tickerDateModal.date} show={tickerDateModal !== undefined} onClose={() => setTickerDateModal(undefined)} />}
    </div>
  );
}

export default TickerDatesChartsFromFilters;
