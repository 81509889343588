import { jsx } from 'slate-hyperscript'

const deserialize = (el: any, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent)
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null
  }

  const nodeAttributes = { ...markAttributes }

  // define attributes for text nodes
  switch (el.nodeName) {
    case 'STRONG':
      (nodeAttributes as any).bold = true
      break;
    case 'EM':
      (nodeAttributes as any).italic = true
      break;
    case 'U':
      (nodeAttributes as any).underline = true
      break;
    case 'A':
      (nodeAttributes as any).href = el.getAttribute('href');
      break;
  }

  const children: any[] = Array.from(el.childNodes)
    .map(node => deserialize(node, nodeAttributes))
    .flat()

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''))
  }

  let styles: { [key: string]: string } = {};
  if (el.getAttribute('style')) {
    styles = el.getAttribute('style').split(';').filter((s: string) => s !== '').reduce((p: any, c: string) => {
      const [property, value] = c.split(':').map((s: string) => s.trim());
      return { ...p, [property]: value };
    }, {});
  }
  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'BR':
      return '\n'
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'block-quote', align: styles['text-align'] }, children)
    case 'P':
      return jsx('element', { type: 'paragraph', align: styles['text-align'] }, children)
    case 'H1':
      return jsx('element', { type: 'heading-one', align: styles['text-align'] }, children)
    case 'H2':
      return jsx('element', { type: 'heading-two', align: styles['text-align'] }, children)
    case 'UL':
      return jsx('element', { type: 'bulleted-list', align: styles['text-align'] }, children)
    case 'OL':
      return jsx('element', { type: 'numbered-list', align: styles['text-align'] }, children)
    case 'LI':
      return jsx('element', { type: 'list-item', align: styles['text-align'] }, children)
    case 'IMG':
      let align = undefined;
      if (styles['margin-left'] === 'auto' && styles['margin-right'] === 'auto') align = 'center';
      else if (styles['margin-left'] === 'auto') align = 'right';
      return jsx('element', { type: 'image', align: align, url: el.getAttribute('src') }, children)
    default:
      return children
  }
}

export default deserialize;