import { FC, ReactNode } from "react";

interface ButtonProps {
  children: ReactNode;
  type?: "button" | "submit" | "reset" | undefined;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  style?: ButtonStyle;
}

export enum ButtonStyle {
  Primary = 'Primary',
  Alternative = 'Alternative',
}

const Button: FC<ButtonProps> = ({ children, type, onClick, className, disabled, style }) => {
  const styleOrDefault = style || ButtonStyle.Primary;
  let finalClassNames: string = '';
  switch (styleOrDefault) {
    case ButtonStyle.Primary:
      const primaryBaseClasses = "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800";
      finalClassNames = `${primaryBaseClasses}`;
      break;
    case ButtonStyle.Alternative:
      // TODO dark dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700
      let baseClasses = "py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none rounded-lg border border-gray-200 focus:z-10 focus:ring-4 focus:ring-gray-200"
      let propClasses = '';
      if (disabled) {
        propClasses = 'bg-gray-100 cursor-not-allowed'
      } else {
        propClasses = 'bg-white hover:bg-gray-100 hover:text-blue-700';
      }
      finalClassNames = `${baseClasses} ${propClasses}`;
      break;
  }

  return (
    <button type={type ? type : 'button'} onClick={onClick} className={className ? `${finalClassNames} ${className}` : finalClassNames} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
