import Form, { FormRef } from "components/common/Form";
import CheckboxInput from "components/common/Form/CheckboxInput";
import Label from "components/common/Form/Label";
import Option from 'components/common/Form/Option';
import Select from "components/common/Form/Select";
import { SignalTargetVariable, TargetVariableValueType } from "graphql/generated";
import useOnScreen from "hooks/useOnScreen";
import Plotly, { Data, PlotType } from "plotly.js";
import { FC, useEffect, useId, useRef, useState } from "react";
import Plot from "react-plotly.js";

interface SignalTargetVariablesChartProps {
  signalTargetVariables: SignalTargetVariable[];
  title: string;
  allowedTargetVariableBases: string[];
  targetVariableValueType: TargetVariableValueType;
}

interface FormInputs {
  targetVariables: Option[];
  high: boolean;
  low: boolean;
  close: boolean;
}

const targetVariableBaseFromName = (name: string) => name.replace(' high', '').replace(' low', '').replace(' close', '');

const SignalTargetVariablesChart: FC<SignalTargetVariablesChartProps> = ({ signalTargetVariables, title, allowedTargetVariableBases, targetVariableValueType }) => {
  const [ref, isVisible] = useOnScreen();
  const plotId = useId();
  useEffect(() => {
    if (isVisible) Plotly.Plots.resize(plotId);
  }, [isVisible]);

  const formRef = useRef<FormRef<FormInputs>>(null);
  const allowedTargetVariableBasesSet = new Set(allowedTargetVariableBases);
  const allowedSignalTargetVariables = signalTargetVariables.filter(x => allowedTargetVariableBasesSet.has(targetVariableBaseFromName(x.TargetVariable?.name!)))!;

  const allowedTargetVariableIndexByBase: { [key: string]: number } = allowedTargetVariableBases.reduce((result, item, index) => ({ ...result, [item]: index }), {});
  const sortedSignalTargetVariables = allowedSignalTargetVariables?.sort((a, b) => allowedTargetVariableIndexByBase[targetVariableBaseFromName(a.TargetVariable?.name!)] - allowedTargetVariableIndexByBase[targetVariableBaseFromName(b.TargetVariable?.name!)]);

  let targetVariableNames = sortedSignalTargetVariables.map(x => targetVariableBaseFromName(x.TargetVariable?.name!));
  const targetVariableNamesSet = new Set();
  targetVariableNames = targetVariableNames.filter(item => {
    if (!targetVariableNamesSet.has(item)) {
      targetVariableNamesSet.add(item);
      return true;
    }
    return false;
  });

  const [targetVariableBases, setTargetVariableBases] = useState<string[]>(targetVariableNames);
  const [showHigh, setShowHigh] = useState<boolean>(true);
  const [showLow, setShowLow] = useState<boolean>(true);
  const [showClose, setShowClose] = useState<boolean>(true);

  const targetVariableBasesSet = new Set(targetVariableBases);
  const sortedAndFilteredSignalTargetVariables = sortedSignalTargetVariables.filter(x => targetVariableBasesSet.has(targetVariableBaseFromName(x.TargetVariable?.name!)));

  const highTargetVariables = sortedAndFilteredSignalTargetVariables?.filter(x => x.TargetVariable?.name.endsWith(' high'))!;
  const highValues = highTargetVariables.map(x => x.value);
  const highNames = highTargetVariables.map(x => targetVariableBaseFromName(x.TargetVariable?.name!));

  const lowTargetVariables = sortedAndFilteredSignalTargetVariables?.filter(x => x.TargetVariable?.name.endsWith(' low'))!;
  const lowValues = lowTargetVariables.map(x => x.value);
  const lowNames = lowTargetVariables.map(x => targetVariableBaseFromName(x.TargetVariable?.name!));

  const closeTargetVariables = sortedAndFilteredSignalTargetVariables?.filter(x => x.TargetVariable?.name.endsWith(' close'))!;
  const closeValues = closeTargetVariables.map(x => x.value);
  const closeNames = closeTargetVariables.map(x => targetVariableBaseFromName(x.TargetVariable?.name!));

  const targetVariableOptions: Option[] = targetVariableNames.map(x => ({ label: x, value: x }));

  const data: Data[] = [
    ...(showHigh ? [{
      x: highNames,
      y: highValues,
      name: 'High targets',
      type: 'scatter' as PlotType,
      showlegend: true,
    }] : []),
    ...(showLow ? [{
      x: lowNames,
      y: lowValues,
      name: 'Low targets',
      type: 'scatter' as PlotType,
      showlegend: true,
    }] : []),
    ...(showClose ? [{
      x: closeNames,
      y: closeValues,
      name: 'Close targets',
      type: 'scatter' as PlotType,
      showlegend: true,
    }] : []),
  ];

  const defaultValues: FormInputs = {
    targetVariables: targetVariableOptions,
    high: true,
    low: true,
    close: true,
  };

  const handleTargetVariablesChanged = (options: Option[]) => {
    const sortedOptions = [...options];
    sortedOptions.sort((a, b) => allowedTargetVariableIndexByBase[a.value] - allowedTargetVariableIndexByBase[b.value]);

    const alreadySorted = options.every((element, index) => element === sortedOptions[index]);

    if (alreadySorted) {
      setTargetVariableBases(sortedOptions.map(x => "" + x.value));
    } else {
      formRef.current?.setValue('targetVariables', sortedOptions);
      setTargetVariableBases(sortedOptions.map(x => "" + x.value));
    }
  };

  return (
    <>
      <Form<FormInputs> defaultValues={defaultValues} ref={formRef}>
        <Select field="targetVariables" options={targetVariableOptions} isMulti onChange={handleTargetVariablesChanged} />
        <Label label='High' className="inline-block mr-2" />
        <CheckboxInput field='high' onChange={(value) => setShowHigh(value)} className="inline-block mr-2" />
        <Label label='Low' className="inline-block mr-2" />
        <CheckboxInput field='low' onChange={(value) => setShowLow(value)} className="inline-block mr-2" />
        <Label label='Close' className="inline-block mr-2" />
        <CheckboxInput field='close' onChange={(value) => setShowClose(value)} className="inline-block" />
      </Form>

      <div ref={ref}>
        <Plot
          divId={plotId}
          data={data}
          layout={{
            title,
            autosize: true,
            height: 720,
            xaxis: {
              automargin: true,
            },
            yaxis: {
              tickformat: targetVariableValueType === TargetVariableValueType.Percentage ? ',.2%' : '.2f',
              ticksuffix: targetVariableValueType === TargetVariableValueType.Percentage ? undefined : ' ATRs',
              automargin: true,
            },
          }}
          style={{ width: "100%", height: "100%" }}
          config={{ displayModeBar: false }}
          useResizeHandler
        />
      </div>
    </>
  );
};

export default SignalTargetVariablesChart;