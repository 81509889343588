import { gql, useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import Button, { ButtonStyle } from "components/common/Button";
import Error from 'components/common/Error';
import FeatureInput, { FeatureValue } from "components/common/FeatureInput";
import Table from "components/common/Table";
import { Collection, Feature, FeatureType, SignalFeaturesQuery, SignalFeaturesQueryVariables } from "graphql/generated";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useFormContext } from "react-hook-form";
import { formatRawValue } from "util/valueFormat";
import { v4 as uuidv4 } from 'uuid';

const SIGNAL_FEATURES_QUERY = gql`
  query SignalFeatures($signalId: Int!, $collectionId: Int) {
    signalFeatures(signalId: $signalId, collectionId: $collectionId) {
      value
      Feature {
        id
        name
        userId
        type
        valueType
      }
      Signal {
        id
      }
    }
  }
`

interface SignalFeaturesProps {
  field: string;
  collection?: Partial<Collection>;
  signalId?: number;
  features: Feature[];
  isPublic: boolean;
}


interface SignalFeatureValueWithIdAndIndex {
  signalFeatureValue?: FeatureValue;
  id: string;
  fieldId: string;
}

export interface SignalFeaturesRef {
  setSignalFeatures: (signalFeatures: SignalFeatureValueWithIdAndIndex[]) => void;
}

const SignalFeatures = forwardRef<SignalFeaturesRef, SignalFeaturesProps>(({ field, signalId, features, isPublic, collection }, ref) => {
  const { loading, error, data } = useQuery<SignalFeaturesQuery, SignalFeaturesQueryVariables>(SIGNAL_FEATURES_QUERY, {
    variables: {
      signalId: signalId!,
      collectionId: collection?.id,
    },
    skip: signalId === undefined,
    fetchPolicy: 'network-only',
  });

  const [signalFeatures, setSignalFeatures] = useState<SignalFeatureValueWithIdAndIndex[]>([]);

  useImperativeHandle(ref, () => ({
    setSignalFeatures,
  }));

  const formMethods = useFormContext();

  const updateFormValues = (updateSignalFeatures: SignalFeatureValueWithIdAndIndex[]) => {
    formMethods.setValue(`${field}`, undefined);
    updateSignalFeatures.filter(f => !!f.signalFeatureValue).forEach(f => {
      formMethods.setValue(`${field}.${f.fieldId}.feature`, { label: f.signalFeatureValue?.featureName, value: f.signalFeatureValue?.featureId });
      formMethods.setValue(`${field}.${f.fieldId}.type`, { label: f.signalFeatureValue?.valueType, value: f.signalFeatureValue?.valueType });
      formMethods.setValue(`${field}.${f.fieldId}.value`, formatRawValue(f.signalFeatureValue?.value, f.signalFeatureValue?.valueType));
    });
  };

  useEffect(() => {
    if (!data) return;

    const newSignalFeatures: SignalFeatureValueWithIdAndIndex[] = data.signalFeatures.map(f => {
      const id = uuidv4();
      return {
        signalFeatureValue: {
          featureId: f.Feature.id,
          featureName: f.Feature.name,
          value: f.value,
          type: f.Feature.type!,
          valueType: f.Feature.valueType!,
          userId: f.Feature.userId!,
        },
        fieldId: id,
        id,
      }
    });
    setSignalFeatures(newSignalFeatures);
    updateFormValues(newSignalFeatures);
  }, [data]);

  if (signalId && loading) {
    return <span>Loading...</span>
  }

  if (signalId && (error || !data)) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const filteredFeatures: FeatureValue[] = features.filter(f => f.type === FeatureType.Signal).map(f => ({
    featureId: f.id,
    featureName: f.name,
    type: f.type!,
    valueType: f.valueType!,
    userId: f.userId!,
  }));

  const handleCreateNewFeature = () => {
    const id = uuidv4();
    setSignalFeatures([
      ...signalFeatures,
      {
        fieldId: id,
        id,
      },
    ]);
  };

  const handleDelete = (field: string) => {
    const fieldSplit = field.split('.');
    const fieldId = fieldSplit[fieldSplit.length - 1];
    const newSignalFeatures = signalFeatures.filter((s: any) => s.fieldId !== fieldId);
    setSignalFeatures(newSignalFeatures);

    const newValues = formMethods.getValues();
    delete newValues.features[fieldId];
    formMethods.reset(newValues);
  };

  if (isPublic && signalFeatures.length === 0) {
    return null;
  }

  const renderSignalFeatureInput = (t: SignalFeatureValueWithIdAndIndex) => {
    return (
      <FeatureInput
        field={`${field}.${t.id}`}
        features={filteredFeatures}
        feature={t.signalFeatureValue || undefined}
        key={t.fieldId}
        onDelete={handleDelete}
        isPublic={isPublic}
      />
    );
  };

  const columns = [
    {
      key: 'data',
      header: () => 'Data',
      cell: (o: SignalFeatureValueWithIdAndIndex) => renderSignalFeatureInput(o),
    },
  ];

  return (
    <div>
      <Table<SignalFeatureValueWithIdAndIndex> columns={columns} data={signalFeatures} />
      {!isPublic && <Button onClick={handleCreateNewFeature} style={ButtonStyle.Alternative} className='mt-4'>Add row</Button>}
    </div>
  );
});

export default SignalFeatures;
