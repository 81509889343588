import { gql } from "@apollo/client";

export const BACKTEST_TRADES_QUERY = gql`
  query BacktestTrades($backtestId: Int, $tickerDateId: Int, $take: Int!, $cursor: DateIdCursorInput) {
    backtestTrades(backtestId: $backtestId, tickerDateId: $tickerDateId, take: $take, cursor: $cursor) {
      id
      direction
      backtestId
      netProfitRisk
      maxRiskSize
      riskPriceMissing
      riskSizeInsufficient
      riskSizeNotDecreasing
      positionNotClosed
      TickerDate {
        id
        ticker {
          ticker
        }
        date
      }
      Backtest {
        id
        name
      }
      notes
      BacktestTradeExecutions {
        id
        type
        timestamp
        price
        riskPrice
        positionRiskSize
        positionAverageCost
        positionDollarSize
        executionDollarSize
        realizedNetProfitRisk
        riskPriceMissing
        riskSizeInsufficient
        riskSizeNotDecreasing
        Signal {
          name
          price
          timestamp
          TickerDate {
            id
            ticker {
              ticker
            }
            date
          }
        }
      }
    }
  }
`;