import { ChangeEvent, FC } from "react";
import { useFormContext } from "react-hook-form";

interface RangeInputProps {
  field: string;
  min?: number;
  max?: number;
  onChange?: (input: string) => void;
}

const RangeInput: FC<RangeInputProps> = ({ field, onChange, min, max }) => {
  const { register } = useFormContext();

  let wrappedOnChange = undefined;
  if (onChange) {
    wrappedOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange!(event.target.value);
    };
  }

  return (
    <input {...register(field, { onChange: wrappedOnChange })} type="range" min={min} max={max} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"></input>
  );
};

export default RangeInput;