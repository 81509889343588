import { FiltersFormFields } from 'components/common/FiltersForm/schema';
import Option from 'components/common/Form/Option';
import { formattedValueValidator } from 'util/valueFormat';
import * as yup from 'yup';

export interface BacktestExecutionsFromSignalsFormInputs {
  signalIds: number[];
  signalFilters?: FiltersFormFields;
  createNewTrades?: boolean;
  type: Option;
  direction: Option;
  positionRiskSize: string;
}

export const validationSchema = yup.object({
  signalIds: yup.array(yup.number().integer()),
  createNewTrades: yup.boolean(),
  type: yup.object({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  direction: yup.object({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  positionRiskSize: formattedValueValidator,
}).required();
