import { gql } from "@apollo/client";

export const FILTERS_QUERY = gql`
query ScannerFilters {
  scannerFilters {
    id
    name
    FiltersExpressions {
      assetTypes
      Tickers {
        id
        ticker
      }
      date
      dateOperator
      signalTime
      signalTimeOperator
      FeatureFilters {
        operator
        value
        Feature {
          id
          name
          valueType
        }
      }
      TargetVariableFilters {
        operator
        value
        TargetVariable {
          id
          name
        }
      }
    }
  }
}
`;