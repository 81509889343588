import { useMutation, useQuery } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import FiltersForm from "components/common/FiltersForm";
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import { FiltersSearchParamsContext } from "contexts/FiltersSearchParamsContext";
import { FeaturesQuery, SaveScannerFiltersMutation, SaveScannerFiltersMutationVariables, ScannerFiltersDocument, ScannerFiltersInput, ScannerFiltersQuery, ScannerFiltersQueryVariables, TargetVariablesQuery } from "graphql/generated";
import { SAVE_SCANNER_FILTERS_MUTATION } from "graphql/mutations/saveScannerFilters.mutation";
import { FEATURES_QUERY } from 'graphql/queries/features.query';
import { FILTERS_QUERY } from "graphql/queries/scannerFilters.query";
import { TARGET_VARIABLES_QUERY } from "graphql/queries/targetVariables.query";
import { FC, useContext, useEffect, useState } from "react";
import { filtersFormFieldsFromSearchParams } from "../FiltersSearchParams";

interface TickerFiltersFormProps {
  onTickersLoad: (inputs: FiltersFormFields, tab?: number) => void;
}

const TickerFiltersForm: FC<TickerFiltersFormProps> = ({ onTickersLoad }) => {
  const filtersSearchParamsContext = useContext(FiltersSearchParamsContext);
  const [firstLoadCompleted, setFirstLoadCompleted] = useState(false);

  const featuresQueryRes = useQuery<FeaturesQuery>(FEATURES_QUERY);
  const targetVariablesQueryRes = useQuery<TargetVariablesQuery>(TARGET_VARIABLES_QUERY);
  const filtersQueryRes = useQuery<ScannerFiltersQuery, ScannerFiltersQueryVariables>(FILTERS_QUERY);

  useEffect(() => {
    if (featuresQueryRes.data && targetVariablesQueryRes.data && filtersSearchParamsContext.activeTab !== undefined && !firstLoadCompleted) {
      setFirstLoadCompleted(true);
      const defaultValues = filtersFormFieldsFromSearchParams(filtersSearchParamsContext.filters || [{}], featuresQueryRes.data.features, targetVariablesQueryRes.data.targetVariables);
      if (Object.keys(defaultValues).length > 0) {
        onTickersLoad(defaultValues, filtersSearchParamsContext.activeTab);
      }
    }
  }, [featuresQueryRes, targetVariablesQueryRes, firstLoadCompleted]);

  const [saveFiltersMutation] = useMutation<SaveScannerFiltersMutation, SaveScannerFiltersMutationVariables>(SAVE_SCANNER_FILTERS_MUTATION, {
    refetchQueries: [getOperationName(ScannerFiltersDocument)!],
  });

  if (featuresQueryRes.loading || targetVariablesQueryRes.loading || filtersQueryRes.loading) {
    return <span>Loading...</span>
  }

  if (featuresQueryRes.error || filtersQueryRes.error || !featuresQueryRes.data || !filtersQueryRes.data || !targetVariablesQueryRes.data || targetVariablesQueryRes.error) {
    if (featuresQueryRes.error) throw featuresQueryRes.error;
    if (targetVariablesQueryRes.error) throw targetVariablesQueryRes.error;
    else throw filtersQueryRes.error;
  }

  const handleSaveFilters = async (input: ScannerFiltersInput) => {
    const res = await saveFiltersMutation({
      variables: {
        input,
      },
    });
    return res.data?.saveScannerFilters;
  };

  return (
    <FiltersForm
      savedFilters={filtersQueryRes.data}
      features={featuresQueryRes.data.features}
      handleLoadData={onTickersLoad}
      handleSaveFilters={handleSaveFilters}
      targetVariables={targetVariablesQueryRes.data.targetVariables}
    />
  );
}

export default TickerFiltersForm;