import { gql } from "@apollo/client";
import { apolloClient } from "App";
import { GetMarksCallback, LibrarySymbolInfo, ResolutionString, TimescaleMark } from "charting_library";
import { SplitsQuery, SplitsQueryVariables } from "graphql/generated";
import moment from "moment";
import TickerDateDatafeed from "..";

const SPLITS_QUERY = gql`
  query Splits($ticker: String!, $from: Int!, $to: Int!, $collectionId: Int) {
    splits(ticker: $ticker, from: $from, to: $to, collectionId: $collectionId) {
      time
      split_from
      split_to
    }
  }
`;

export const getTimescaleMarks = async (
  datafeed: TickerDateDatafeed,
  symbolInfo: LibrarySymbolInfo,
  from: number,
  to: number,
  onDataCallback: GetMarksCallback<TimescaleMark>,
  resolution: ResolutionString
) => {
  const ticker = symbolInfo.ticker!;
  const res = await apolloClient.query<SplitsQuery, SplitsQueryVariables>({
    query: SPLITS_QUERY,
    variables: {
      ticker,
      from,
      to,
      collectionId: datafeed.collection?.id,
    },
    fetchPolicy: 'network-only',
  });

  if (res.error || !res.data) {
    onDataCallback([]);
    return;
  }

  const marks = res.data.splits.map(s => ({
    id: `split-${ticker}-${s.time}`,
    time: s.time,
    color: 'orange',
    label: 'S',
    tooltip: [
      `Split ${s.split_from}/${s.split_to}`,
      `Date: ${moment.unix(s.time).format('YYYY-MM-DD')}`,
    ],
  }));

  onDataCallback(marks);
};