import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { scannerFormFieldsToInput } from 'components/Scanner/util/scannerFormFieldsToInput';
import Error from 'components/common/Error';
import { FiltersFormFields } from 'components/common/FiltersForm/schema';
import Form, { FormRef } from "components/common/Form";
import CheckboxInput from "components/common/Form/CheckboxInput";
import Label from "components/common/Form/Label";
import Option from 'components/common/Form/Option';
import Select from "components/common/Form/Select";
import { SignalFilterChartStatsQuery, SignalFilterChartStatsQueryVariables, StatisticType, TargetVariable, TargetVariableValueType } from "graphql/generated";
import { SIGNAL_FILTER_CHART_STATS_QUERY } from 'graphql/queries/signalFilterChartStats.query';
import useOnScreen from 'hooks/useOnScreen';
import Plotly, { Data, PlotType } from "plotly.js";
import { FC, useEffect, useId, useRef, useState } from "react";
import Plot from "react-plotly.js";

interface SignalChartStatsProps {
  inputs: FiltersFormFields;
  targetVariables: TargetVariable[];
  title: string;
  allowedTargetVariableBases: string[];
  targetVariableValueType: TargetVariableValueType;
}

interface FormInputs {
  targetVariables: Option[];
  high: boolean;
  low: boolean;
  close: boolean;
  mean: boolean;
  median: boolean;
  percentile25: boolean;
  percentile75: boolean;
}

const targetVariableBaseFromName = (name: string) => name.replace(' high', '').replace(' low', '').replace(' close', '');

const SignalChartStats: FC<SignalChartStatsProps> = ({ inputs, targetVariables, title, allowedTargetVariableBases, targetVariableValueType }) => {
  const [ref, isVisible] = useOnScreen();
  const plotId = useId();
  useEffect(() => {
    if (isVisible) Plotly.Plots.resize(plotId);
  }, [isVisible]);

  const formRef = useRef<FormRef<FormInputs>>(null);
  const allowedTargetVariableBasesSet = new Set(allowedTargetVariableBases);
  const allowedTargetVariables = targetVariables.filter(x => allowedTargetVariableBasesSet.has(targetVariableBaseFromName(x.name)));

  const allowedTargetVariableIndexByBase: { [key: string]: number } = allowedTargetVariableBases.reduce((result, item, index) => ({ ...result, [item]: index }), {});
  const sortedTargetVariables = allowedTargetVariables.sort((a, b) => allowedTargetVariableIndexByBase[targetVariableBaseFromName(a.name)] - allowedTargetVariableIndexByBase[targetVariableBaseFromName(b.name)]);

  let targetVariableNames = sortedTargetVariables.map(x => targetVariableBaseFromName(x.name));
  const targetVariableNamesSet = new Set();
  targetVariableNames = targetVariableNames.filter(item => {
    if (!targetVariableNamesSet.has(item)) {
      targetVariableNamesSet.add(item);
      return true;
    }
    return false;
  });

  const inputVariables = scannerFormFieldsToInput(inputs);

  const [targetVariableBases, setTargetVariableBases] = useState<string[]>(targetVariableNames);
  const [showHigh, setShowHigh] = useState<boolean>(true);
  const [showLow, setShowLow] = useState<boolean>(true);
  const [showClose, setShowClose] = useState<boolean>(false);
  const [showHighToLow, setShowHighToLow] = useState<boolean>(false);
  const [showCloseToLow, setShowCloseToLow] = useState<boolean>(false);
  const [showLowToHigh, setShowLowToHigh] = useState<boolean>(false);
  const [showCloseToHigh, setShowCloseToHigh] = useState<boolean>(false);
  const [showMean, setShowMean] = useState<boolean>(false);
  const [showMedian, setShowMedian] = useState<boolean>(true);
  const [showPercentile25, setShowPercentile25] = useState<boolean>(false);
  const [showPercentile75, setShowPercentile75] = useState<boolean>(false);

  const { loading, error, data } = useQuery<SignalFilterChartStatsQuery, SignalFilterChartStatsQueryVariables>(SIGNAL_FILTER_CHART_STATS_QUERY, {
    variables: {
      targetVariableIds: allowedTargetVariables.map(x => x.id),
      input: inputVariables,
      targetVariableValueType,
    },
  });

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const chartData: Data[] = [];

  const targetVariableNameById: { [key: number]: string } = targetVariables.reduce((result, item, index) => ({ ...result, [item.id]: item.name }), {});
  const targetVariableBasesById: { [key: number]: string } = targetVariables.reduce((result, item, index) => ({ ...result, [item.id]: targetVariableBaseFromName(item.name) }), {});
  const sortedTargetVariableStatValues = [...data.signalFilterChartStats.targetVariableStatValues].sort((a, b) => allowedTargetVariableIndexByBase[targetVariableBasesById[a.targetVariableId]] - allowedTargetVariableIndexByBase[targetVariableBasesById[b.targetVariableId]]);
  const targetVariableBasesSet = new Set(targetVariableBases);
  const sortedAndFilteredTargetVariableStatValues = sortedTargetVariableStatValues.filter(x => targetVariableBasesSet.has(targetVariableBasesById[x.targetVariableId]));

  const directions = [
    ...(showHigh ? ['High'] : []),
    ...(showLow ? ['Low'] : []),
    ...(showClose ? ['Close'] : []),
    ...(showHighToLow ? ['High/Low'] : []),
    ...(showCloseToLow ? ['Close/Low'] : []),
    ...(showLowToHigh ? ['Low/High'] : []),
    ...(showCloseToHigh ? ['Close/High'] : []),
  ];

  const statisticTypes = [
    ...(showMean ? [StatisticType.Mean] : []),
    ...(showMedian ? [StatisticType.Median] : []),
    ...(showPercentile25 ? [StatisticType.Percentile25] : []),
    ...(showPercentile75 ? [StatisticType.Percentile75] : []),
  ];

  let secondaryAxisRequired = false;

  let y1Max: number | undefined;
  let y1Min: number | undefined;
  let y2Max: number | undefined;
  let y2Min: number | undefined;

  const countsByTargetVariableId: {[key: number]: number} = {};
  data.signalFilterChartStats.targetVariableStatValues.forEach(x => {
    const count = x.values.find(y => y.type === StatisticType.Count)!.value
    countsByTargetVariableId[x.targetVariableId] = count;
  });

  directions.forEach(direction => {
    statisticTypes.forEach(statisticType => {
      if (direction.includes('/')) {
        const [direction1, direction2] = direction.split('/');
        const filteredStatValues1 = sortedAndFilteredTargetVariableStatValues.filter(x => targetVariableNameById[x.targetVariableId].endsWith(` ${direction1.toLowerCase()}`));
        const filteredStatValues2 = sortedAndFilteredTargetVariableStatValues.filter(x => targetVariableNameById[x.targetVariableId].endsWith(` ${direction2.toLowerCase()}`));
        const x = filteredStatValues1.map(x => `${targetVariableBasesById[x.targetVariableId]}<br>(count: ${countsByTargetVariableId[x.targetVariableId]})`);
        const y = filteredStatValues1.map((x, index) => {
          return -x.values.find(y => y.type === statisticType)?.value! / filteredStatValues2[index].values.find(y => y.type === statisticType)?.value!;
        });
        chartData.push({
          x,
          y,
          name: `${direction} ${statisticType}`,
          type: 'scatter' as PlotType,
          showlegend: true,
          yaxis: 'y2'
        });
        secondaryAxisRequired = true;
        y2Max = y2Max === undefined ? Math.max(...y) : Math.max(y2Max, ...y);
        y2Min = y2Min === undefined ? Math.min(...y) : Math.min(y2Min, ...y);
      } else {
        const filteredStatValues = sortedAndFilteredTargetVariableStatValues.filter(x => targetVariableNameById[x.targetVariableId].endsWith(` ${direction.toLowerCase()}`));
        const x = filteredStatValues.map(x => `${targetVariableBasesById[x.targetVariableId]}<br>(count: ${countsByTargetVariableId[x.targetVariableId]})`);
        const y = filteredStatValues.map(x => {
          return x.values.find(y => y.type === statisticType)?.value!;
        });

        y1Max = y1Max === undefined ? Math.max(...y) : Math.max(y1Max, ...y);
        y1Min = y1Min === undefined ? Math.min(...y) : Math.min(y1Min, ...y);

        chartData.push({
          x,
          y,
          name: `${direction} ${statisticType}`,
          type: 'scatter' as PlotType,
          showlegend: true,
        });
      }
    });
  });

  // Ideas from https://stackoverflow.com/a/76290278
  let y1Range: number[] | undefined;
  let y2Range: number[] | undefined;
  if (y1Max !== undefined && y1Min !== undefined && y2Max !== undefined && y2Min !== undefined) {
    let y1Padding = (y1Max - y1Min) / 16;
    y1Range = [y1Min - y1Padding, y1Max + y1Padding];
    let y1RelativeZero = (0 - y1Range[0]) / (y1Range[1] - y1Range[0]);

    let y2Padding = (y2Max - y2Min) / 16;
    let y2RangeUnadjusted = [y2Min - y2Padding, y2Max + y2Padding];
    let y2RelativeZero = (0 - y2RangeUnadjusted[0]) / (y2RangeUnadjusted[1] - y2RangeUnadjusted[0]);

    if (y2RelativeZero > y1RelativeZero) {
      // Add to the top of y2 range
      // y1_relative_zero = (0 - min) / (x_new_max - min)
      // (x_new_max - min) * y1_relative_zero = -min
      // x_new_max * y1_relative_zero - min*y1_relatie_zero = -min
      // x_new_max = (-min + min * y1_relative_zero) / y1_relative_zero = min - (min/y_relative_zero)
      // x_new_max = min - (min/y_relative_zero)
      const y2NewMax = y2RangeUnadjusted[0] - (y2RangeUnadjusted[0] / y1RelativeZero);
      y2Range = [y2RangeUnadjusted[0], y2NewMax];
    } else if (y2RelativeZero < y1RelativeZero) {
      // Add to the bottom of y2 range
      // y1_relative_zero = (0 - x_new_min) / (max - x_new_min)
      // (max - x_new_min) * y1_relative_zero = -x_new_min
      // y1_relative_zero * max - x_new_min * y1_relative_zero = -x_new_min
      // x_new_min - x_new_min * y1_relative_zero = y1_relative_zero * max
      // x_new_min(1 - y1_relative_zero) = y1_relative_zero * max
      // x_new_min = y1_relative_zero * max / (1 - y1_relative_zero)
      const y2NewMin = -y1RelativeZero * y2RangeUnadjusted[1] / (1 - y1RelativeZero);
      y2Range = [y2NewMin, y2RangeUnadjusted[1]];
    } else {
      y2Range = y2RangeUnadjusted;
    }
  }

  const targetVariableOptions: Option[] = targetVariableNames.map(x => ({ label: x, value: x }));

  const defaultValues: FormInputs = {
    targetVariables: targetVariableOptions,
    high: showHigh,
    low: showLow,
    close: showClose,
    mean: showMean,
    median: showMedian,
    percentile25: showPercentile25,
    percentile75: showPercentile75,
  };

  const handleTargetVariablesChanged = (options: Option[]) => {
    const sortedOptions = [...options];
    sortedOptions.sort((a, b) => allowedTargetVariableIndexByBase[a.value] - allowedTargetVariableIndexByBase[b.value]);

    const alreadySorted = options.every((element, index) => element === sortedOptions[index]);

    if (alreadySorted) {
      setTargetVariableBases(sortedOptions.map(x => "" + x.value));
    } else {
      formRef.current?.setValue('targetVariables', sortedOptions);
      setTargetVariableBases(sortedOptions.map(x => "" + x.value));
    }
  };

  const setIntradayReturns = () => {
    const options = [
      'Future 1 min',
      'Future 2 min',
      'Future 5 min',
      'Future 10 min',
      'Future 15 min',
      'Future 30 min',
      'Future 1 hour',
      'Future 2 hour',
      'Future 4 hour',
      'Future intraday',
    ].map(x => ({ label: x, value: x }));
    formRef.current?.setValue('targetVariables', options);
    handleTargetVariablesChanged(options);
  };

  const setDailyReturns = () => {
    const options = [
      'Future intraday',
      'Future intraday until end of AH',
      'Future today and tomorrow',
      'Future today and 2 days after',
      'Future today and 3 days after',
      'Future today and 7 days after',
      'Future today and 14 days after'
    ].map(x => ({ label: x, value: x }));;
    formRef.current?.setValue('targetVariables', options);
    handleTargetVariablesChanged(options);
  };

  const setAllReturns = () => {
    const options = allowedTargetVariableBases.map(x => ({ label: x, value: x }));;
    formRef.current?.setValue('targetVariables', options);
    handleTargetVariablesChanged(options);
  };

  return (
    <>
      <div>Number of signals: {data.signalFilterChartStats.numberOfObjects}</div>

      <Form<FormInputs> defaultValues={defaultValues} ref={formRef}>
        <Label label="Future returns" />
        <div className='text-sm my-2'>
          <span>Templates: </span>
          <span onClick={setIntradayReturns} className='cursor-pointer text-blue-700 mr-2'>Intraday</span>
          <span onClick={setDailyReturns} className='cursor-pointer text-blue-700 mr-2'>Daily</span>
          <span onClick={setAllReturns} className='cursor-pointer text-blue-700'>All</span>
        </div>
        <Select field="targetVariables" options={targetVariableOptions} isMulti onChange={handleTargetVariablesChanged} />
        <div className='mt-6'>
          <Label label='Future return values' />
        </div>
        <div className='flex gap-6 items-end mt-2'>
          <div>
            <Label label='High' className="mb-0 mt-0 inline-block" />
            <CheckboxInput field='high' onChange={(value) => setShowHigh(value)} className="inline-block ml-2" />
          </div>
          <div>
            <Label label='Low' className="mb-0 mt-0 inline-block" />
            <CheckboxInput field='low' onChange={(value) => setShowLow(value)} className="inline-block ml-2" />
          </div>
          <div>
            <Label label='Close' className="mb-0 mt-0 inline-block" />
            <CheckboxInput field='close' onChange={(value) => setShowClose(value)} className="inline-block ml-2" />
          </div>
        </div>
        <div className='mt-6'>
          <Label label='Reward to risk estimates' />
        </div>
        <div className='flex gap-6 items-end mt-2'>
          <div>
            <Label label='High/Low (long)' className="mb-0 mt-0 inline-block" />
            <CheckboxInput field='highToLow' onChange={(value) => setShowHighToLow(value)} className="inline-block ml-2" />
          </div>
          <div>

            <Label label='Close/Low (long)' className="mb-0 mt-0 inline-block" />
            <CheckboxInput field='closeToLow' onChange={(value) => setShowCloseToLow(value)} className="inline-block ml-2" />
          </div>
          <div>
            <Label label='Low/High (short)' className="mb-0 mt-0 inline-block" />
            <CheckboxInput field='lowToHigh' onChange={(value) => setShowLowToHigh(value)} className="inline-block ml-2" />
          </div>
          <div>
            <Label label='Close/High (short)' className="mb-0 mt-0 inline-block" />
            <CheckboxInput field='closeToHigh' onChange={(value) => setShowCloseToHigh(value)} className="inline-block ml-2" />
          </div>
        </div>
        <div className='mt-6'>
          <Label label='Statistics' />
        </div>
        <div className='flex gap-6 items-end mt-2'>
          <div>
            <Label label='Mean' className="mb-0 mt-0 inline-block" />
            <CheckboxInput field='mean' onChange={(value) => setShowMean(value)} className="inline-block ml-2" />
          </div>
          <div>
            <Label label='Median' className="mb-0 mt-0 inline-block" />
            <CheckboxInput field='median' onChange={(value) => setShowMedian(value)} className="inline-block ml-2" />
          </div>
          <div>
            <Label label='25th percentile' className="mb-0 mt-0 inline-block" />
            <CheckboxInput field='percentile25' onChange={(value) => setShowPercentile25(value)} className="inline-block ml-2" />
          </div>
          <div>
            <Label label='75th percentile' className="mb-0 mt-0 inline-block" />
            <CheckboxInput field='percentile75' onChange={(value) => setShowPercentile75(value)} className="inline-block ml-2" />
          </div>
        </div>
      </Form>

      <div ref={ref}>
        <Plot
          divId={plotId}
          data={chartData}
          layout={{
            title,
            autosize: true,
            height: 720,
            xaxis: {
              automargin: true,
              fixedrange: true,
            },
            yaxis: {
              tickformat: targetVariableValueType === TargetVariableValueType.Percentage ? ',.2%' : '.2f',
              ticksuffix: targetVariableValueType === TargetVariableValueType.Percentage ? undefined : ' ATRs',
              automargin: true,
              range: y1Range ? y1Range : undefined,
              fixedrange: true,
            },
            ...(secondaryAxisRequired ? {
              yaxis2: {
                title: 'Reward to risk',
                overlaying: 'y', // Overlay the secondary axis on the primary axis
                side: 'right',   // Display the secondary axis on the right side
                tickformat: '.2f',
                automargin: true,
                showgrid: false,
                range: y2Range ? y2Range : undefined,
                fixedrange: true,
              },
            } : {}),
          }}
          style={{ width: "100%", height: "100%" }}
          config={{ displayModeBar: false }}
          useResizeHandler
        />
      </div>
    </>
  );
};

export default SignalChartStats;