import { FeatureValueType } from "graphql/generated";
import moment from "moment";
import * as yup from 'yup';

export const TIME_ERROR_MESSAGE = 'Must be time: HH:mm';
export const TIME_REGEX = /^\d{2}:\d{2}(:\d{2})?$/;

export const timeValidator = yup.string().required(TIME_ERROR_MESSAGE).typeError(TIME_ERROR_MESSAGE).trim().matches(TIME_REGEX, TIME_ERROR_MESSAGE);

export const formattedValueValidator = yup.lazy(value => {
  if (typeof value === 'string') {
    return yup.string().required('Must not be empty').test('is-value', 'Must be a number, e.g. 23.47%, 0.2347, 100k, 20M etc.', (value) => value !== undefined && value !== '' && !Number.isNaN(formattedValueToRawNumber(value)));
  } else {
    return yup.boolean().required();
  }
});

export const formattedValueOptionalValidator = yup.lazy(value => {
  if (typeof value === 'string') {
    return yup.string().test('is-value', 'Must be a number, e.g. 23.47%, 0.2347, 100k, 20M etc.', (value) => value === undefined || value === '' || !Number.isNaN(formattedValueToRawNumber(value)));
  } else {
    return yup.boolean();
  }
});

export const timeStringToDate = (value: string) => {
  if (value.indexOf(':') !== -1) {
    if (value.split(':').length === 2) {
      return moment.utc(value, 'HH:mm').toDate();
    } else {
      return moment.utc(value, 'HH:mm:ss').toDate();
    }
  }

  return undefined;
}

export const formattedValueToRawNumber = (value: string | boolean | undefined) => {
  if (value === undefined) return undefined;
  if (value === '') return undefined;
  if (typeof value === 'boolean') return Number(value);
  if (typeof value === 'number') return value;

  value = value.replaceAll(',', '').toLowerCase();
  let multiplier = 1;
  const multipliersMap = {
    'k': 1e3,
    'm': 1e6,
    'b': 1e9,
    't': 1e12,
  };

  for (const [key, m] of Object.entries(multipliersMap)) {
    while (value.indexOf(key) !== -1) {
      value = value.replace(key, '');
      multiplier = multiplier * m;
    }
  }

  const hasPercentage = value.indexOf('%') !== -1;
  const isNegative = value.trim()[0] === '-';

  const matches = value.replaceAll('-', '').match(/[0-9]*\.?[0-9]*/g)?.filter(x => x !== '');
  if (!matches) return Number.NaN;

  value = matches[0];
  let number = Number(value) * multiplier;
  if (isNegative) number *= -1;
  if (hasPercentage) number /= 100;
  return number;
};

export const formatRawValue = (value?: number | undefined | null, type: FeatureValueType | undefined | null = FeatureValueType.Number) => {
  if (value === undefined || value === null) return "";

  switch (type) {
    case FeatureValueType.Number:
      if (value < 1000) {
        if (Number(value.toFixed(2)) % 1 === 0) {
          let formatter = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 0 });
          return formatter.format(value);
        } else {
          let formatter = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 2, maximumFractionDigits: 2 });
          return formatter.format(value);
        }
      } else {
        let formatter = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 2 });
        return formatter.format(value);
      }
    case FeatureValueType.Percentage:
      if (Number((value * 100).toFixed(2)) % 1 === 0) {
        let formatter = Intl.NumberFormat('en', { style: 'percent', maximumFractionDigits: 0 });
        return formatter.format(value);
      } else {
        let formatter = Intl.NumberFormat('en', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return formatter.format(value);
      }
    case FeatureValueType.Time:
      const hours = Math.floor(value / 3600).toString().padStart(2, '0');
      const minutes = Math.floor(value % 3600 / 60).toString().padStart(2, '0');
      const seconds = Math.floor(value % 3600 % 60).toString().padStart(2, '0');
      if (seconds === '00') {
        return `${hours}:${minutes}`;
      } else {
        return `${hours}:${minutes}:${seconds}`;
      }
    default:
      return "" + value;
  }
};

export const formatRiskValue = (value?: number | undefined | null) => {
  if (value === undefined || value === null) return "";

  return `${formatRawValue(value, FeatureValueType.Number)}R`;
};