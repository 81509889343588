import Select from "components/common/Form/Select";
import TextInput from "components/common/Form/TextInput";
import { UserContext } from "contexts/UserContext";
import { FeatureType, FeatureValueType } from "graphql/generated";
import { FC, useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { formatRawValue, formattedValueToRawNumber } from "util/valueFormat";
import CheckboxInput from "../Form/CheckboxInput";
import { FeatureInputs } from "./schema";

export interface FeatureValue {
  featureId: number;
  featureName: string;
  value?: number;
  type: FeatureType;
  valueType: FeatureValueType;
  userId: number;
}

export interface FeatureInputProps {
  field: string;
  feature?: FeatureValue;
  features: FeatureValue[];
  isPublic: boolean;
  className?: string;
  onChange?: (inputs: Partial<FeatureInputs>) => void;
  onDelete?: (field: string) => void;
  onSave?: (field: string) => void;
}

const FeatureInput: FC<FeatureInputProps> = ({ field, feature, features, onChange, onSave, onDelete, className, isPublic }) => {
  const { user } = useContext(UserContext);
  const canEdit = !isPublic && (!feature || (user && user.id === feature.userId));
  const [editing, setEditing] = useState<boolean>(!feature);

  const formMethods = useFormContext();

  const name = formMethods.watch(`${field}.feature`)?.label;
  const valueType = formMethods.watch(`${field}.type`)?.label;
  const value = formMethods.watch(`${field}.value`);

  const selectedFeatureId = formMethods.watch(`${field}.feature`)?.value;
  const selectedFeature = features.find(f => f.featureId === selectedFeatureId);

  const featureOptions = features.map(f => ({ value: f.featureId, label: f.featureName }));
  const typeOptions = Object.keys(FeatureValueType).map(t => ({ value: t, label: t })).filter(t => !feature || t.value !== FeatureValueType.Tag);

  const handleCreate = (inputString: string) => {
    const value = { 'label': inputString, 'value': inputString };
    formMethods.setValue(`${field}.feature`, value);
    handleChange('feature', value)
  };

  const [formInputs, setFormInputs] = useState<Partial<FeatureInputs>>({});

  const handleChange = (changedField: string, value: any) => {
    const fieldSplit = changedField.split('.');
    let newValues;
    if (fieldSplit[fieldSplit.length - 1] === 'feature') {
      const feature = features.find(f => f.featureId === value.value);
      if (feature) {
        const valueTypeOption = { label: feature!.valueType!, value: feature!.valueType! };
        formMethods.setValue(`${field}.type`, valueTypeOption);
        newValues = { ...formInputs, [changedField]: value, type: valueTypeOption };
      } else {
        newValues = { ...formInputs, [changedField]: value };
      }
    } else {
      newValues = { ...formInputs, [changedField]: value };
    }

    setFormInputs(newValues);
    if (onChange) onChange(newValues);
  };

  const handleSave = async () => {
    const isValid = await formMethods.trigger([`${field}.feature`, `${field}.type`, `${field}.value`]);

    if (!isValid) return;

    if (onSave) onSave(field);
    setEditing(false);
  };

  const handleDelete = () => {
    if (onDelete) onDelete(field);
    setEditing(false);
  }

  return (
    <div className={className}>
      {canEdit && editing ? (
        <>
          <div className='flex gap-4'>
            <div className="grid grid-cols-2 gap-4 w-full">
              <div className="grid grid-cols-2 gap-4">
                {!!feature ?
                  <div>{name}</div>
                  :
                  <div>
                    <Select field={`${field}.feature`} options={featureOptions} handleCreate={handleCreate} onChange={(value: any) => handleChange('feature', value)} placeholder='Select or type new...' />
                    {(selectedFeature?.valueType !== FeatureValueType.Tag || selectedFeature?.value === 1) && selectedFeature?.value !== undefined && (
                      <div className='text-red-700 mt-2'>
                        {selectedFeature.valueType === FeatureValueType.Tag && selectedFeature.value === 1 ? (
                          <>
                            {selectedFeature.featureName} tag is already checked
                          </>
                        ) : (
                          <>
                            {selectedFeature.featureName} already has value of {formatRawValue(selectedFeature.value, selectedFeature.valueType)}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                }
                {feature?.valueType !== FeatureValueType.Tag && <Select field={`${field}.type`} options={typeOptions} onChange={(value: any) => handleChange('type', value)} />}
              </div>
              <div className="flex gap-4">
                {valueType === FeatureValueType.Tag ? (
                  <CheckboxInput field={`${field}.value`} className='grow' onChange={(value: any) => handleChange('value', value)} />
                ) : (
                  <TextInput field={`${field}.value`} className='grow' onChange={(value: any) => handleChange('value', value)} />
                )}
                <div className='flex'><span className={`material-icons cursor-pointer ${!!onSave ? 'visible' : 'invisible'}`} onClick={handleSave}>done</span></div>
                <div className='flex'><span className={`material-icons cursor-pointer ${!!onDelete ? 'visible' : 'invisible'}`} onClick={handleDelete}>delete</span></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="grid grid-cols-2 gap-4 w-full">
          <div>{name}</div>
          <div className="flex gap-4">
            {valueType === FeatureValueType.Tag || feature?.valueType === FeatureValueType.Tag ? (
              <div className='grow'>
                <input disabled checked={Number(value) === 1} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
              </div>
            ) : (
              <div className='grow'>{typeof value === 'string' ? formatRawValue(formattedValueToRawNumber(value), valueType) : formatRawValue(value, valueType)}</div>
            )}
            {canEdit && <div className='flex gap-4'>
              <span className='material-icons cursor-pointer' onClick={() => setEditing(true)}>edit</span>
              <span className='material-icons cursor-pointer invisible'>edit</span>
            </div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default FeatureInput;
