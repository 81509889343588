import { Column, ObjectWithId } from './index';

interface FooterRowProps<T extends ObjectWithId> {
  columns: Column<T>[];
  object: T;
  rowIndex: number;
}

const FooterRow = <T extends ObjectWithId>({ columns, object, rowIndex }: FooterRowProps<T>) => {
  return (
    <tr className="font-semibold text-gray-900 dark:text-white">
      {columns.map((column, columnIndex) => (
        <td className="py-4 px-6" key={`${column.key}-${object.id}`}>
          {column.cell(object, rowIndex, columnIndex)}
        </td>
      ))}
    </tr>
  );
};

export default FooterRow;