import { bin } from 'd3';
import { Feature, FeatureValueForStatsPayload, FeatureValueType, TargetVariableValueType } from "graphql/generated";
import useOnScreen from 'hooks/useOnScreen';
import Plotly, { PlotData } from "plotly.js";
import { FC, useEffect, useId, useState } from "react";
import Plot from "react-plotly.js";
import Statistics from 'statistics.js';
import { formatRawValue } from 'util/valueFormat';
import Form from "../Form";
import Label from "../Form/Label";
import Option from "../Form/Option";
import RangeInput from '../Form/RangeInput';
import Select from "../Form/Select";

interface FeatureValuesToTargetVariableChartProps {
  feature: Feature;
  values: FeatureValueForStatsPayload[];
  targetVariableValueType: TargetVariableValueType;
}

const DEFAULT_BIN_COUNT = 5;

const FeatureValuesToTargetVariableChart: FC<FeatureValuesToTargetVariableChartProps> = ({ feature, values, targetVariableValueType }) => {
  const [selectedTargetVariable, setSelectedTargetVariable] = useState<string | undefined>(undefined);
  const [selectedPlotType, setSelectedPlotType] = useState<number>(0);
  const [binCount, setBinCount] = useState<number>(DEFAULT_BIN_COUNT);

  const [ref, isVisible] = useOnScreen();
  const plotId = useId();
  useEffect(() => {
    if (isVisible) Plotly.Plots.resize(plotId);
  }, [isVisible]);

  const targetVariables = [...new Set(values.map(v => v.targetVariables.map(t => t.name)).flat())];
  const options = targetVariables.map(v => ({ label: v, value: v }));

  const valueObjects: { x: number, y: number }[] = values.map(v => {
    const targetVariableValue = v.targetVariables.find(t => t.name === selectedTargetVariable);
    if (targetVariableValue === undefined) return undefined;
    return {
      x: v.value,
      y: targetVariableValue.value,
    }
  }).filter(v => !!v) as { x: number, y: number }[];

  const handleBinCountChange = (input: string) => {
    const inputNumber = Number(input);
    if (!Number.isNaN(inputNumber)) {
      setBinCount(inputNumber);
    } else {
      setBinCount(DEFAULT_BIN_COUNT);
    }
  };

  let targetVariableRelationshipData: Partial<PlotData>[] | undefined = undefined;
  if (binCount !== undefined && binCount > 0) {
    const bins = bin().thresholds(binCount)(values.map(v => v.value));
    targetVariableRelationshipData = bins.map(b => ({
      y: valueObjects.filter(v => v.x >= (b.x0 as number) && v.x < (b.x1 as number)).map(v => v.y),
      name: `from ${formatRawValue(b.x0, feature.valueType)} to ${formatRawValue(b.x1, feature.valueType)}`,
      type: 'box',
      showlegend: false,
    }));
  }

  const plotOptions = [{ label: 'Scatter plot', value: 0 }, { label: 'Binned boxplots', value: 1 }];

  var regression: any;
  if (selectedTargetVariable) {
    var stats = new Statistics(valueObjects, { x: 'metric', y: 'metric' });
    regression = stats.linearRegression('x', 'y');
  }

  return (
    <>
      <Form defaultValues={{ plotType: plotOptions[0] }}>
        <Label label='Future return variable' />
        <Select field='targetVariable' options={options} onChange={(option: Option) => setSelectedTargetVariable(option.label)} />
        <Label label='Plot type' />
        <Select field='plotType' options={plotOptions} onChange={(option: Option) => setSelectedPlotType(option.value as number)} />
      </Form>
      {selectedTargetVariable && (
        <>
          {regression && (
            <div className='mt-10'>
              <div>Correlation Coefficient: {regression.correlationCoefficient.toFixed(2)}</div>
              <div>Coefficient of Determination (R<span className='sups'>2</span>): {regression.coefficientOfDetermination.toFixed(2)}</div>
            </div>
          )}
          <div ref={ref}>
            {selectedPlotType === 0 ? (
              <Plot
                divId={plotId}
                data={
                  regression ?
                    [{
                      x: valueObjects.map(v => v.x),
                      y: valueObjects.map(v => v.y),
                      type: 'scatter',
                      mode: 'markers',
                      showlegend: false,
                    }, {
                      x: valueObjects.map(v => v.x),
                      y: valueObjects.map(v => regression.regressionFirst.beta1 + v.x * regression.regressionFirst.beta2),
                      type: 'scatter',
                      mode: 'lines',
                      name: 'Regression line',
                      showlegend: true,
                    }]
                    :
                    [{
                      x: valueObjects.map(v => v.x),
                      y: valueObjects.map(v => v.y),
                      type: 'scatter',
                      mode: 'markers',
                      showlegend: false,
                    }]
                }
                layout={{
                  title: `Relationship between ${feature.name} and ${selectedTargetVariable}`,
                  autosize: true,
                  height: 720,
                  xaxis: {
                    title: feature.name,
                    tickformat: feature.valueType === FeatureValueType.Percentage ? ',.2%' : undefined,
                    automargin: true,
                  },
                  yaxis: {
                    title: selectedTargetVariable,
                    tickformat: targetVariableValueType === TargetVariableValueType.Percentage ? ',.2%' : '.2f',
                    ticksuffix: targetVariableValueType === TargetVariableValueType.Percentage ? undefined : ' ATRs',
                    automargin: true,
                  },
                }}
                style={{ width: "100%", height: "100%" }}
                config={{ displayModeBar: false }}
                useResizeHandler
              />
            ) : (
              <>
                <Form defaultValues={{ binCount: DEFAULT_BIN_COUNT }}>
                  <Label label="Desired number of bins" />
                  <RangeInput field='binCount' onChange={handleBinCountChange} min={2} max={20} />
                </Form>
                {targetVariableRelationshipData && (
                  <Plot
                    divId={plotId}
                    data={targetVariableRelationshipData}
                    layout={{
                      title: `Relationship between ${feature.name} and ${selectedTargetVariable} in ${targetVariableRelationshipData.filter(t => t.y!.length > 0).length} bins`,
                      autosize: true,
                      height: 720,
                      xaxis: {
                        title: feature.name,
                        tickformat: feature.valueType === FeatureValueType.Percentage ? ',.2%' : undefined,
                        automargin: true,
                      },
                      yaxis: {
                        title: selectedTargetVariable,
                        tickformat: targetVariableValueType === TargetVariableValueType.Percentage ? ',.2%' : '.2f',
                        ticksuffix: targetVariableValueType === TargetVariableValueType.Percentage ? undefined : ' ATRs',
                        automargin: true,
                      },
                    }}
                    style={{ width: "100%", height: "100%" }}
                    config={{ displayModeBar: false }}
                    useResizeHandler
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default FeatureValuesToTargetVariableChart;