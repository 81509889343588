import { ReactNode } from "react";
import Button, { ButtonStyle } from "../Button";

export type ObjectWithId = {
  id: string | number;
}

interface GridProps<T extends ObjectWithId> {
  className?: string;
  columns: number;
  data: T[];
  renderCell: (object: T) => ReactNode,
  onClickNext?: () => void;
  onClickPrevious?: () => void;
}

const Grid = <T extends ObjectWithId>({ renderCell, data, columns, className, onClickNext, onClickPrevious }: GridProps<T>) => {
  // Typical number of columns so that tailwind css compiles the classes
  // grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4

  return (
    <div>
      <div className={`grid grid-cols-${columns} ${className}`}>
        {data.map((cell) => {
          return (
            <div key={`cell-${cell.id}`}>
              {renderCell(cell)}
            </div>
          );
        })}
      </div>
      <div className="flex justify-center select-none gap-2">
        <Button onClick={onClickPrevious} style={ButtonStyle.Alternative} className={`inline-flex items-center ${onClickPrevious ? 'visible' : 'invisible'}`}>
          Previous
        </Button>

        <Button onClick={onClickNext} style={ButtonStyle.Alternative} className={`inline-flex items-center ${onClickNext ? 'visible' : 'invisible'}`}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default Grid;