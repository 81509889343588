import { FC, ReactNode } from "react";
import ReactModal from 'react-modal';

interface ModalProps {
  children: ReactNode;
  show: boolean;
  onClose?: () => void;
  style?: ReactModal.Styles;
}

const Modal: FC<ModalProps> = ({ children, show, onClose, style}) => {
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onClose}
      ariaHideApp={false}
      style={style}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;