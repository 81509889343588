import { FeatureValue } from "components/common/FeatureInput";
import TickerDateFeatureInput from "components/common/FeatureInput/TickerDateFeatureInput";
import Form from "components/common/Form";
import Option from 'components/common/Form/Option';
import SearchInput from "components/common/Form/SearchInput";
import Table from "components/common/Table";
import { UserContext } from "contexts/UserContext";
import { Collection, Feature, FeatureSet, FeatureType, FeatureValueType, UserTickerDate } from "graphql/generated";
import { FC, useContext, useState } from "react";

interface TickerDateFeaturesProps {
  userTickerDate: UserTickerDate;
  collection?: Partial<Collection>;
  features: Feature[];
  selectedFeatureSetOption?: Option;
  selectedFeatureSet?: FeatureSet;
  values: FeatureValue[];
  showEmptyFeatures?: boolean;
  isPublic?: boolean;
}

interface FeatureSearchFormProps {
  input: string;
}

interface TickerDateFeaturesWithIdAndIndex {
  tickerDateFeatureValue?: FeatureValue;
  id: number
  index: number;
}

const TickerDateFeatures: FC<TickerDateFeaturesProps> = ({ userTickerDate, collection, features, selectedFeatureSet, selectedFeatureSetOption, values, showEmptyFeatures, isPublic }) => {
  const { user } = useContext(UserContext);
  const [featureSearchInput, setFeatureSearchInput] = useState<string | undefined>(undefined);

  if (!user && !collection) return null;

  const valuesWithoutEmpty = [...values];
  const valuesByFeatureId: { [key: number]: FeatureValue } = {};
  valuesWithoutEmpty.forEach(v => valuesByFeatureId[v.featureId] = v);

  let valuesWithEmpty = [...valuesWithoutEmpty];
  features.filter(f => f.type === FeatureType.TickerDate).forEach(f => {
    if (!!valuesByFeatureId[f.id]) return;
    const value = f.valueType === FeatureValueType.Tag ? 0 : undefined;
    const tickerDateFeatureValue: FeatureValue = {
      featureId: f.id,
      featureName: f.name,
      type: f.type!,
      valueType: f.valueType!,
      userId: f.userId!,
      value: value,
    };
    valuesByFeatureId[f.id] = tickerDateFeatureValue;
    valuesWithEmpty.push(tickerDateFeatureValue);
  });

  valuesWithEmpty = valuesWithEmpty.sort((a, b) => a.featureName.localeCompare(b.featureName));

  const finalValues = showEmptyFeatures ? valuesWithEmpty : valuesWithoutEmpty;

  const filteredFeatures = valuesWithEmpty.filter(f => f.type === FeatureType.TickerDate && (!collection || f.userId === user?.id));

  const renderTickerDateFeatureInput = (t: TickerDateFeaturesWithIdAndIndex) => {
    if (t.tickerDateFeatureValue) {
      return (
        <TickerDateFeatureInput
          tickerDateId={userTickerDate.TickerDate.id}
          features={filteredFeatures}
          feature={t.tickerDateFeatureValue}
          key={`${t.tickerDateFeatureValue.featureId}-${userTickerDate.TickerDate.id}`}
          isPublic={isPublic || false}
        />
      )
    } else {
      return (
        <TickerDateFeatureInput
          tickerDateId={userTickerDate.TickerDate.id}
          features={filteredFeatures}
          isPublic={isPublic || false}
          key={Math.random()}
        />
      );
    }
  };

  const isYourData = selectedFeatureSetOption?.value === -2;

  let filteredValues: FeatureValue[];

  if (selectedFeatureSet) {
    filteredValues = selectedFeatureSet.features.map((f: any) => valuesByFeatureId[f.featureId]).filter((f: FeatureValue) => !!f).filter((f: FeatureValue) => !!f.value || showEmptyFeatures);
  } else {
    filteredValues = finalValues.filter(
      v => !featureSearchInput || featureSearchInput === '' || v.featureName.toLowerCase().indexOf(featureSearchInput.toLowerCase()) !== -1
    );
    if (isYourData) {
      filteredValues = filteredValues.filter(v => v.userId === user?.id);
    }
  }

  const columns = [
    {
      key: 'data',
      header: () => isYourData ? 'Your data' : 'All data',
      cell: (o: TickerDateFeaturesWithIdAndIndex) => renderTickerDateFeatureInput(o),
    },
  ];

  let filteredAndNewValues = [
    ...filteredValues.map((v, i) => ({ id: i, index: i, tickerDateFeatureValue: v })),
    ...(collection ? [] : [{ id: -1, index: -1 }]),
  ];

  return (
    <div>
      <Form<FeatureSearchFormProps> defaultValues={{ input: featureSearchInput }}>
        <SearchInput field='input' onChange={setFeatureSearchInput} placeholder='Search data' className='mt-6' />
      </Form>
      <Table<TickerDateFeaturesWithIdAndIndex> columns={columns} data={filteredAndNewValues} />
    </div>
  );
};

export default TickerDateFeatures;
