import { scannerFormFieldsToInput } from "components/Scanner/util/scannerFormFieldsToInput";
import Form from "components/common/Form";
import Modal from "components/common/Modal";
import { ScannerFiltersInput } from "graphql/generated";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import * as yup from 'yup';
import { ButtonStyle } from "../Button";
import Label from "../Form/Label";
import Submit from "../Form/Submit";
import TextInput from "../Form/TextInput";
import { FiltersFormFields } from "./schema";

interface SaveFiltersModalProps {
  show: boolean,
  onClose?: () => void,
  filtersFormFields: FiltersFormFields;
  handleSaveFilters: (input: ScannerFiltersInput) => void;
}

interface SaveFiltersFormFields {
  id?: string;
  name?: string;
  updating?: boolean;
}
const validationSchema = yup.object({
  id: yup.number().integer(),
  name: yup.string().required(),
  updating: yup.boolean(),
}).required();

const SaveFiltersModal: FC<SaveFiltersModalProps> = ({ filtersFormFields, show, onClose, handleSaveFilters }) => {
  const defaultValues: SaveFiltersFormFields = {
    id: filtersFormFields.loadedFiltersId,
    name: filtersFormFields.loadedFiltersName,
    updating: false,
  }

  const preUpdate = (formMethods: UseFormReturn<SaveFiltersFormFields, any>) => {
    formMethods.setValue('updating', true);
  };

  const preCreate = (formMethods: UseFormReturn<SaveFiltersFormFields, any>) => {
    formMethods.setValue('updating', false);
  };

  const saveFilters = (inputs: SaveFiltersFormFields) => {
    const variables: ScannerFiltersInput = {
      ...scannerFormFieldsToInput(filtersFormFields),
      id: inputs.updating ? Number(filtersFormFields.loadedFiltersId) : undefined,
      name: inputs.name,
    };

    handleSaveFilters(variables);
    if (onClose) onClose();
  };

  const onUpdate = (fields: SaveFiltersFormFields, formMethods: UseFormReturn<SaveFiltersFormFields, any>) => {
    fields.updating = true;
    saveFilters(fields as SaveFiltersFormFields);
  };

  const onCreate = (fields: SaveFiltersFormFields, formMethods: UseFormReturn<SaveFiltersFormFields, any>) => {
    fields.updating = false;
    saveFilters(fields as SaveFiltersFormFields)
  };

  const saveFiltersModalStyles = {
    content: {
      width: '50%', // Adjust the width as needed
      height: '50%', // Adjust the height as needed
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Modal show={show} onClose={onClose} style={saveFiltersModalStyles}>
      <div className='flex mb-5'>
        <span className="material-icons cursor-pointer ml-auto" onClick={onClose}>close</span>
      </div>
      <Form<SaveFiltersFormFields> validationSchema={validationSchema} defaultValues={defaultValues} className='mb-10'>
        {formMethods => {
          const name = formMethods.watch('name');

          return (
            <div>
              <Label label='Name' />
              <TextInput field='name' placeholder='Name your filters' className='w-full mb-6' />
              {(!filtersFormFields.loadedFiltersName || filtersFormFields.loadedFiltersName === name) ? (
                <Submit preSubmit={preUpdate} onSubmit={onUpdate} style={ButtonStyle.Primary} className='flex-shrink-0'>Save</Submit>
              ) : (
                <div className='flex gap-4'>
                  <Submit preSubmit={preUpdate} onSubmit={onUpdate} style={ButtonStyle.Primary} className='flex-shrink-0'>Rename and save</Submit>
                  <Submit<SaveFiltersFormFields> preSubmit={preCreate} onSubmit={onCreate} style={ButtonStyle.Alternative} className='flex-shrink-0'>Save as new</Submit>
                </div>
              )}
            </div>
          )
        }}
      </Form>
    </Modal>
  );
};

export default SaveFiltersModal;