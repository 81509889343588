import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import EditorFormInput from "components/common/Editor/EditorFormInput";
import Form, { FormRef } from "components/common/Form";
import { SaveTickerDateNotesMutation, SaveTickerDateNotesMutationVariables } from "graphql/generated";
import useDebounce from "hooks/useDebounce";
import { forwardRef, RefObject, useImperativeHandle, useRef, useState } from "react";
import { NotesFormInput, validationSchema } from "./schema";

const SAVE_TICKER_DATE_NOTES_MUTATION = gql`
  mutation SaveTickerDateNotes($tickerDateId: Int!, $notes: String!) {
    saveTickerDateNotes(tickerDateId: $tickerDateId, notes: $notes)
  }
`;

interface TickerDateNotesInputProps {
  tickerDateId: number;
  notes: string;
  readOnly?: boolean;
  onChange?: (value: any, ref: RefObject<FormRef<NotesFormInput>>) => void;
}

export interface TickerDateNotesInputRef {
  formRef: RefObject<FormRef<NotesFormInput>>;
}

const TickerDateNotesInput = forwardRef<TickerDateNotesInputRef, TickerDateNotesInputProps>(({ tickerDateId, notes, readOnly, onChange }, ref) => {
  const [saveMutation] = useMutation<SaveTickerDateNotesMutation, SaveTickerDateNotesMutationVariables>(SAVE_TICKER_DATE_NOTES_MUTATION);
  const [formInputs, setFormInputs] = useState<Partial<NotesFormInput>>({});
  const formRef = useRef<FormRef<NotesFormInput>>(null);

  useImperativeHandle(ref, () => ({
    formRef,
  }));

  const onSave = async (fields: NotesFormInput) => {
    if (!formInputs.notes) return;
    await saveMutation({
      variables: {
        tickerDateId,
        notes: fields.notes,
      }
    }).catch((err: any) => {
      formRef.current?.setError('notes', { message: 'Something went wrong...' });
      Sentry.captureException(err);
    });
  };

  useDebounce(() => {
    formRef.current?.handleSubmit(onSave, (error: any) => console.log(error))();
  }, [formInputs], 1000);

  const handleChange = (value: any) => {
    if (readOnly) return;
    setFormInputs({ ...formInputs, notes: value });
    if (onChange) onChange(value, formRef);
  };

  return (
    <Form<NotesFormInput> validationSchema={validationSchema} defaultValues={{ notes: notes }} ref={formRef}>
      <EditorFormInput field='notes' className='mb-20' readOnly={readOnly} onChange={handleChange} />
    </Form>
  );
});

export default TickerDateNotesInput;
