import { DescriptiveStatsPayload, Feature } from "graphql/generated";
import { FC } from "react";
import { formatRawValue } from "util/valueFormat";
import Table, { Column } from "../Table";

interface FeatureValuesStatsTableProps {
  feature: Feature;
  stats: DescriptiveStatsPayload;
}

interface FeatureValuesStatsRow {
  id: number;
  name: string;
  value: string;
}

const FeatureValuesStatsTable: FC<FeatureValuesStatsTableProps> = ({ feature, stats }) => {

  const data: FeatureValuesStatsRow[] = [
    { name: 'Count', value: "" + stats.count },
    { name: 'Mean', value: formatRawValue(stats.mean, feature.valueType) },
    { name: 'Std', value: formatRawValue(stats.std, feature.valueType) },
    { name: 'Min', value: formatRawValue(stats.min, feature.valueType) },
    { name: '25th percentile', value: formatRawValue(stats.p25, feature.valueType) },
    { name: 'Median', value: formatRawValue(stats.median, feature.valueType) },
    { name: '75th percentile', value: formatRawValue(stats.p75, feature.valueType) },
    { name: 'Max', value: formatRawValue(stats.max, feature.valueType) },
  ].map((d, i) => ({
    ...d,
    id: i,
  }));

  const columns: Column<FeatureValuesStatsRow>[] = [
    {
      key: 'name',
      header: () => 'Descriptive Statistic',
      cell: (o: FeatureValuesStatsRow) => o.name,
    },
    {
      key: 'value',
      header: () => 'Value',
      cell: (o: FeatureValuesStatsRow) => o.value,
    },
  ];

  return (
    <Table<FeatureValuesStatsRow> columns={columns} data={data} />
  );
}

export default FeatureValuesStatsTable;