import { DEFAULT_OPERATOR, DEFAULT_TAG_OPERATOR } from "components/common/FiltersForm";
import { OPERATOR_LABELS } from "components/common/FiltersForm/FilterInput";
import { FilterFields, FilterType, FiltersFormFields } from "components/common/FiltersForm/schema";
import Option from 'components/common/Form/Option';
import { FilterSearchParamsType, FiltersSearchParamsContext, FiltersSearchParamsType } from "contexts/FiltersSearchParamsContext";
import { Feature, FeatureType, FeatureValueType, TargetVariable } from "graphql/generated";
import moment from "moment";
import { FC, ReactNode } from "react";
import { useSearchParams } from "react-router-dom";
import { formatRawValue } from "util/valueFormat";
import { scannerFormFieldsToInput } from "../util/scannerFormFieldsToInput";

interface FiltersSearchParamsProps {
  prefix: string;
  children: ReactNode;
}

export const serializeFiltersFormFields = (fields: FiltersFormFields) => {
  const mappedFilters: FiltersSearchParamsType[] = scannerFormFieldsToInput(fields).filtersExpressions.map((x, index) => {
    const tickersFilters = fields.filtersExpressions[index].filters.find(x => x.type === FilterType.Tickers);
    const tickerNames = tickersFilters?.value ? (tickersFilters?.value as Option[]).map(t => t.label) : undefined;

    return {
      tickerIds: x.tickerIds ? x.tickerIds : undefined,
      tickerNames: tickerNames ? tickerNames : undefined,
      assetTypes: x.assetTypes ? x.assetTypes : undefined,
      date: x.date ? x.date : undefined,
      dateOperator: x.dateOperator ? x.dateOperator : undefined,
      signalTime: x.signalTime ? x.signalTime.map(x => moment.utc(x).format('HH:mm')) : undefined,
      signalTimeOperator: x.signalTimeOperator ? x.signalTimeOperator : undefined,
      featureFilters: x.featureFilters ? x.featureFilters.map(x => ({
        id: x.featureId,
        operator: x.operator,
        value: x.value,
      })) : undefined,
      targetVariableFilters: x.targetVariableFilters ? x.targetVariableFilters.map(x => ({
        id: x.targetVariableId,
        operator: x.operator,
        value: x.value,
      })) : undefined,
    }
  });
  return JSON.stringify(mappedFilters);
};

export const filtersFormFieldsFromSearchParams = (filters: FiltersSearchParamsType[], features: Feature[], targetVariables: TargetVariable[]): FiltersFormFields => {
  return {
    filtersExpressions: filters.map(x => {
      const featureFiltersById: { [key: number]: FilterSearchParamsType } = x.featureFilters ? x.featureFilters.reduce((p, c) => ({ ...p, [c.id]: c }), {}) : {};
      const targetVariableFiltersById: { [key: number]: FilterSearchParamsType } = x.targetVariableFilters ? x.targetVariableFilters.reduce((p, c) => ({ ...p, [c.id]: c }), {}) : {};

      const filtersTemplate: FilterFields[] = [
        {
          type: FilterType.Tickers,
          name: 'Tickers',
          value: (x.tickerIds && x.tickerNames) ? x.tickerIds.map((y, index) => ({ value: y, label: x.tickerNames![index] })) : undefined,
          modified: (x.tickerIds !== undefined && x.tickerNames !== undefined),
        },
        {
          type: FilterType.AssetTypes,
          name: 'Asset types',
          value: x.assetTypes ? x.assetTypes.map(x => ({ value: x, label: x })) : undefined,
          modified: x.assetTypes !== undefined,
        },
        {
          type: FilterType.Date,
          name: 'Date',
          operator: x.dateOperator ? { value: x.dateOperator, label: OPERATOR_LABELS[x.dateOperator] } : DEFAULT_OPERATOR,
          value: x.date ? x.date[0] : undefined,
          value2: x.date ? x.date[1] : undefined,
          modified: x.dateOperator !== undefined || x.date !== undefined,
        },
        {
          type: FilterType.SignalTime,
          name: 'Signal time',
          operator: x.signalTimeOperator ? { value: x.signalTimeOperator, label: OPERATOR_LABELS[x.signalTimeOperator] } : DEFAULT_OPERATOR,
          value: x.signalTime ? x.signalTime[0] : undefined,
          value2: x.signalTime ? x.signalTime[1] : undefined,
          modified: x.signalTimeOperator !== undefined || x.signalTime !== undefined,
        },
        ...[
          ...features.map(x => {
            const featureFilter: FilterSearchParamsType | undefined = featureFiltersById[x.id];
            return {
              type: FilterType.Feature,
              id: x.id,
              name: `${x.name}${x.type === FeatureType.Signal ? ' (Signal)' : ''}`,
              tag: x.valueType === FeatureValueType.Tag,
              operator: featureFilter?.operator ? { value: featureFilter.operator, label: OPERATOR_LABELS[featureFilter.operator] } : (x.valueType === FeatureValueType.Tag ? DEFAULT_TAG_OPERATOR : DEFAULT_OPERATOR),
              value: (featureFilter?.value && featureFilter?.value.length >= 1) ? "" + formatRawValue(featureFilter.value[0], x.valueType) : undefined,
              value2: (featureFilter?.value && featureFilter?.value.length >= 2) ? "" + formatRawValue(featureFilter.value[1], x.valueType) : undefined,
              modified: (featureFilter?.operator !== undefined || featureFilter?.value !== undefined),
            }
          }),
          ...targetVariables.map(x => {
            const targetVariableFilter: FilterSearchParamsType | undefined = targetVariableFiltersById[x.id];

            return {
              type: FilterType.TargetVariable,
              id: x.id,
              name: x.name,
              operator: targetVariableFilter?.operator ? { value: targetVariableFilter.operator, label: OPERATOR_LABELS[targetVariableFilter.operator] } : DEFAULT_OPERATOR,
              value: (targetVariableFilter?.value && targetVariableFilter?.value.length >= 1) ? "" + formatRawValue(targetVariableFilter.value[0], FeatureValueType.Percentage) : undefined,
              value2: (targetVariableFilter?.value && targetVariableFilter?.value.length >= 2) ? "" + formatRawValue(targetVariableFilter.value[1], FeatureValueType.Percentage) : undefined,
              modified: (targetVariableFilter?.operator !== undefined || targetVariableFilter?.value !== undefined),
            }
          }),
        ].sort((a, b) => a.name.localeCompare(b.name)),
      ];

      return {
        filters: filtersTemplate,
      }
    }),
  }
};

const FiltersSearchParams: FC<FiltersSearchParamsProps> = ({ prefix, children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const SCANNER_FILTERS_ID_SEARCH_PARAM_KEY = `${prefix}sid`;
  const SCANNER_FILTERS_NAME_SEARCH_PARAM_KEY = `${prefix}sn`;
  const FILTERS_SEARCH_PARAM_KEY = `${prefix}filters`;
  const CURSOR_ID_SEARCH_PARAM_KEY = `${prefix}cid`;
  const CURSOR_DATE_SEARCH_PARAM_KEY = `${prefix}cd`;
  const TAKE_SEARCH_PARAM_KEY = `${prefix}tk`;
  const VIEWING_TICKER_DATE_ID_SEARCH_PARAM_KEY = `${prefix}vid`;
  const VIEWING_TICKER_DATE_TICKER_SEARCH_PARAM_KEY = `${prefix}vt`;
  const VIEWING_TICKER_DATE_DATE_SEARCH_PARAM_KEY = `${prefix}vd`;
  const TAB_SEARCH_PARAM_KEY = `${prefix}tab`;

  const setViewingTicker = (id?: number, ticker?: string, date?: string) => {
    if (id !== undefined) {
      searchParams.set(VIEWING_TICKER_DATE_ID_SEARCH_PARAM_KEY, "" + id);
    } else {
      searchParams.delete(VIEWING_TICKER_DATE_ID_SEARCH_PARAM_KEY);
    }

    if (ticker !== undefined) {
      searchParams.set(VIEWING_TICKER_DATE_TICKER_SEARCH_PARAM_KEY, ticker);
    } else {
      searchParams.delete(VIEWING_TICKER_DATE_TICKER_SEARCH_PARAM_KEY);

    }

    if (date !== undefined) {
      searchParams.set(VIEWING_TICKER_DATE_DATE_SEARCH_PARAM_KEY, date);
    } else {
      searchParams.delete(VIEWING_TICKER_DATE_DATE_SEARCH_PARAM_KEY);
    }

    setSearchParams(searchParams);
  };

  const setFilters = (fields?: FiltersFormFields) => {
    if (fields !== undefined) {
      searchParams.set(FILTERS_SEARCH_PARAM_KEY, serializeFiltersFormFields(fields));
    } else {
      searchParams.delete(FILTERS_SEARCH_PARAM_KEY);
    }
    setSearchParams(searchParams);
  };

  const setCursor = (id?: number, date?: string) => {
    if (id !== undefined) {
      searchParams.set(CURSOR_ID_SEARCH_PARAM_KEY, "" + id);
    } else {
      searchParams.delete(CURSOR_ID_SEARCH_PARAM_KEY);
    }

    if (date !== undefined) {
      searchParams.set(CURSOR_DATE_SEARCH_PARAM_KEY, date);
    } else {
      searchParams.delete(CURSOR_DATE_SEARCH_PARAM_KEY);
    }

    setSearchParams(searchParams);
  };

  const setTake = (take?: number) => {
    if (take !== undefined) {
      searchParams.set(TAKE_SEARCH_PARAM_KEY, "" + take);
    } else {
      searchParams.delete(TAKE_SEARCH_PARAM_KEY);
    }

    setSearchParams(searchParams);
  };

  const setScannerFilters = (id?: number, name?: string) => {
    if (id !== undefined) {
      searchParams.set(SCANNER_FILTERS_ID_SEARCH_PARAM_KEY, "" + id);
    } else {
      searchParams.delete(SCANNER_FILTERS_ID_SEARCH_PARAM_KEY);
    }

    if (name !== undefined) {
      searchParams.set(SCANNER_FILTERS_NAME_SEARCH_PARAM_KEY, name);
    } else {
      searchParams.delete(SCANNER_FILTERS_NAME_SEARCH_PARAM_KEY);
    }

    setSearchParams(searchParams);
  };

  const setActiveTab = (tab?: number) => {
    if (tab !== undefined) {
      searchParams.set(TAB_SEARCH_PARAM_KEY, "" + tab);
    } else {
      searchParams.delete(TAB_SEARCH_PARAM_KEY);
    }

    setSearchParams(searchParams);
  }

  const reset = () => {
    searchParams.delete(SCANNER_FILTERS_ID_SEARCH_PARAM_KEY);
    searchParams.delete(SCANNER_FILTERS_NAME_SEARCH_PARAM_KEY);
    searchParams.delete(FILTERS_SEARCH_PARAM_KEY);
    searchParams.delete(CURSOR_ID_SEARCH_PARAM_KEY);
    searchParams.delete(CURSOR_DATE_SEARCH_PARAM_KEY);
    searchParams.delete(TAKE_SEARCH_PARAM_KEY);
    searchParams.delete(VIEWING_TICKER_DATE_ID_SEARCH_PARAM_KEY);
    searchParams.delete(VIEWING_TICKER_DATE_TICKER_SEARCH_PARAM_KEY);
    searchParams.delete(VIEWING_TICKER_DATE_DATE_SEARCH_PARAM_KEY);
    searchParams.delete(TAB_SEARCH_PARAM_KEY);
    setSearchParams(searchParams);
  };

  const scannerFiltersId = searchParams.get(SCANNER_FILTERS_ID_SEARCH_PARAM_KEY);
  const scannerFiltersName = searchParams.get(SCANNER_FILTERS_NAME_SEARCH_PARAM_KEY);
  const cursorId = searchParams.get(CURSOR_ID_SEARCH_PARAM_KEY);
  const cursorDate = searchParams.get(CURSOR_DATE_SEARCH_PARAM_KEY);
  const take = searchParams.get(TAKE_SEARCH_PARAM_KEY);
  const viewingTickerDateId = searchParams.get(VIEWING_TICKER_DATE_ID_SEARCH_PARAM_KEY);
  const viewingTickerDateTicker = searchParams.get(VIEWING_TICKER_DATE_TICKER_SEARCH_PARAM_KEY);
  const viewingTickerDateDate = searchParams.get(VIEWING_TICKER_DATE_DATE_SEARCH_PARAM_KEY);
  const filters = searchParams.get(FILTERS_SEARCH_PARAM_KEY);
  const activeTab = searchParams.get(TAB_SEARCH_PARAM_KEY);

  const value = {
    scannerFiltersId: scannerFiltersId ? Number(scannerFiltersId) : undefined,
    scannerFiltersName: scannerFiltersName ? scannerFiltersName : undefined,
    cursorId: cursorId ? Number(cursorId) : undefined,
    cursorDate: cursorDate ? cursorDate : undefined,
    take: take ? Number(take) : undefined,
    viewingTickerDateId: viewingTickerDateId ? Number(viewingTickerDateId) : undefined,
    viewingTickerDateTicker: viewingTickerDateTicker ? viewingTickerDateTicker : undefined,
    viewingTickerDateDate: viewingTickerDateDate ? viewingTickerDateDate : undefined,
    filters: filters ? JSON.parse(filters) : undefined,
    activeTab: activeTab ? Number(activeTab) : undefined,
    setViewingTicker,
    setFilters,
    setCursor,
    setTake,
    setScannerFilters,
    setActiveTab,
    reset,
  };

  return (
    <FiltersSearchParamsContext.Provider value={value}>
      {children}
    </FiltersSearchParamsContext.Provider>
  );
};

export default FiltersSearchParams;