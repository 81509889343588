import { gql, useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import Button, { ButtonStyle } from "components/common/Button";
import Error from 'components/common/Error';
import Table, { Column } from "components/common/Table";
import { CollectionsQuery, CollectionsQueryVariables } from "graphql/generated";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const COLLECTIONS_QUERY = gql`
  query Collections {
    collections {
      id
      name
      public
      userId
    }
  }
`;

interface CollectionsListProps {

}

interface CollectionRow {
  id: number;
  name: string;
  public: boolean;
}


const CollectionsList: FC<CollectionsListProps> = () => {
  const { loading, error, data } = useQuery<CollectionsQuery, CollectionsQueryVariables>(COLLECTIONS_QUERY);
  const navigate = useNavigate();

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const columns: Column<CollectionRow>[] = [
    {
      key: 'name',
      header: () => 'name',
      cell: (o: CollectionRow) => o.name,
    },
    {
      key: 'public',
      header: () => 'Permissions',
      cell: (o: CollectionRow) => o.public ? "Public" : "Private",
    },
    {
      key: 'view',
      header: () => 'View',
      cell: (o: CollectionRow) => <Button style={ButtonStyle.Alternative} onClick={() => navigate(`/collection/${o.id}`)}>View</Button>,
    },
  ];

  return (
    <Table<CollectionRow> columns={columns} data={data.collections} />
  );
};

export default CollectionsList;
