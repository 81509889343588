import { scannerFormFieldsToInput } from "components/Scanner/util/scannerFormFieldsToInput";
import SignalGridChart from "components/Signals/SignalGridChart";
import { SIGNALS_FROM_FILTERS_QUERY, ViewingSignal } from "components/Signals/SignalsTableFromFilters";
import Button, { ButtonStyle } from "components/common/Button";
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import BackendGrid from "components/common/Grid/BackendGrid";
import { DateIdCursorInput, QuerySignalsFromFiltersArgs, Signal, SignalWithTagsPayload } from "graphql/generated";
import useBreakpoints, { Breakpoints } from "hooks/useBreakpoints";
import { FC } from "react";

interface SignalsChartsFromFiltersProps {
  inputs: FiltersFormFields;
  viewingSignal?: ViewingSignal;
  onViewClick: (o: Signal) => void;
  onSignalAdded: (signal: Signal) => void;
  addedSignalIds: Set<number>;
  addedAllSignalsFromFilters: boolean;
  chartColumnsPerPage?: number;
  chartRowsPerPage?: number;
}

const SignalsChartsFromFilters: FC<SignalsChartsFromFiltersProps> = ({ inputs, viewingSignal, onViewClick, onSignalAdded, addedSignalIds, addedAllSignalsFromFilters, chartColumnsPerPage, chartRowsPerPage }) => {
  const breakpoints = useBreakpoints();

  const inputVariables = scannerFormFieldsToInput(inputs);
  
  const renderCell = (payload: SignalWithTagsPayload) => {
    return (
      <>
        <SignalGridChart signal={payload.signal} onEditClick={onViewClick} />
        {!addedAllSignalsFromFilters && (
          <>
            {addedSignalIds.has(payload.id) ?
              <Button onClick={() => onSignalAdded(payload.signal)} style={ButtonStyle.Alternative}>Remove</Button>
              :
              <Button onClick={() => onSignalAdded(payload.signal)} style={ButtonStyle.Primary}>Add</Button>
            }
          </>
        )}

      </>
    );
  };

  let maxColumns = 4;
  if ([Breakpoints.lg, Breakpoints.md].includes(breakpoints.active)) {
    maxColumns = 2;
  } else if ([Breakpoints.xs, Breakpoints.sm].includes(breakpoints.active)) {
    maxColumns = 1;
  }

  const columns = chartColumnsPerPage ? Math.min(chartColumnsPerPage, maxColumns) : maxColumns;
  const rows = chartRowsPerPage ? chartRowsPerPage : 3;
  const pageSize = columns * rows;
  const variables: Partial<QuerySignalsFromFiltersArgs> = { input: inputVariables };

  return (
    <div className='my-10' >
      <BackendGrid<SignalWithTagsPayload, Partial<QuerySignalsFromFiltersArgs>, DateIdCursorInput>
        variables={variables}
        query={SIGNALS_FROM_FILTERS_QUERY}
        pageSize={pageSize}
        columns={columns}
        dataKey='signalsFromFilters'
        mapObjectToCursor={(object: SignalWithTagsPayload) => ({ id: object.signal.id, date: object.signal.TickerDate.date })}
        renderCell={renderCell}
        className="gap-4"
      />
    </div>
  );
}

export default SignalsChartsFromFilters;
