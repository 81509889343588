import { gql } from "@apollo/client";

export const SIGNAL_FEATURE_VALUES_FROM_FILTERS_QUERY = gql`
query SignalFeatureValuesFromFilters($filters: ScannerFiltersInput!, $featureId: Int!, $minValue: Float, $maxValue: Float, $targetVariableValueType: TargetVariableValueType!) {
  signalFeatureValuesFromFilters(filters: $filters, featureId: $featureId, minValue: $minValue, maxValue: $maxValue, targetVariableValueType: $targetVariableValueType) {
    values {
      value
      date
      targetVariables {
        id
        name
        value
      }
    }
    stats {
      count
      mean
      std
      min
      p25
      median
      p75
      max
    }
  }
}
`;