import { useMutation } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import Button, { ButtonStyle } from "components/common/Button";
import Form from "components/common/Form";
import DateInput from "components/common/Form/DateInput";
import Label from "components/common/Form/Label";
import Select from "components/common/Form/Select";
import AsyncSelect from "components/common/Form/Select/AsyncSelect";
import Submit from "components/common/Form/Submit";
import loadTickers from "graphql/functions/loadTickers";
import { BacktestStatsDocument, BacktestTrade, BacktestTradesDocument, RemoveBacktestTradesMutation, RemoveBacktestTradesMutationVariables, SaveBacktestTradeMutation, SaveBacktestTradeMutationVariables, TickerDateDetailsDocument, TradeDirection } from "graphql/generated";
import { REMOVE_BACKTEST_TRADES_MUTATION } from "graphql/mutations/removeBacktestTrades.mutation";
import { SAVE_BACKTEST_TRADE_MUTATION } from "graphql/mutations/saveBacktestTrade.mutation";
import { FC } from "react";
import { SaveBacktestTradeFormInputs, validationSchema } from "./schema";

interface SaveBacktestTradeFormProps {
  backtestId: number;
  backtestTrade?: Partial<BacktestTrade>;
  setViewingBacktestTrade: (t?: BacktestTrade) => void;
  className?: string;
}

const SaveBacktestTradeForm: FC<SaveBacktestTradeFormProps> = ({ backtestId, backtestTrade, setViewingBacktestTrade, className }) => {
  const [saveBacktestTradeMutation] = useMutation<SaveBacktestTradeMutation, SaveBacktestTradeMutationVariables>(SAVE_BACKTEST_TRADE_MUTATION, {
    refetchQueries: [
      getOperationName(BacktestTradesDocument)!,
      getOperationName(TickerDateDetailsDocument)!,
      getOperationName(BacktestStatsDocument)!,
    ],
  });

  const [removeBacktestTradesMutation] = useMutation<RemoveBacktestTradesMutation, RemoveBacktestTradesMutationVariables>(REMOVE_BACKTEST_TRADES_MUTATION, {
    refetchQueries: [
      getOperationName(BacktestTradesDocument)!,
      getOperationName(TickerDateDetailsDocument)!,
      getOperationName(BacktestStatsDocument)!,
    ],
  });

  const handleSave = async (fields: SaveBacktestTradeFormInputs) => {
    await saveBacktestTradeMutation({
      variables: {
        backtestId: backtestId,
        tradeId: backtestTrade?.id,
        ticker: fields.ticker.label.toString(),
        date: fields.date,
        direction: fields.direction.value as TradeDirection,
      }
    });
  };

  const handleDelete = async () => {
    await removeBacktestTradesMutation({
      variables: {
        tradeIds: [backtestTrade?.id!],
      },
    });
    setViewingBacktestTrade(undefined);
  };

  const ticker = backtestTrade?.TickerDate?.ticker.ticker;
  const defaultValues: Partial<SaveBacktestTradeFormInputs> = {
    ticker: ticker ? { value: ticker, label: ticker } : undefined,
    date: backtestTrade?.TickerDate?.date,
    direction: backtestTrade?.direction ? { label: backtestTrade.direction, value: backtestTrade.direction } : undefined,
  };

  const directionOptions = Object.keys(TradeDirection).map(t => ({ value: t, label: t }));

  return (
    <Form<SaveBacktestTradeFormInputs> validationSchema={validationSchema} defaultValues={defaultValues} className={className}>
      <div>
        <AsyncSelect field='ticker' label='Ticker' loadOptions={loadTickers} disabled={!!backtestTrade} />
      </div>
      <div>
        <Label label='Date' />
        <DateInput field='date' placeholder='Select date' />
      </div>
      <Label label='Direction' />
      <Select field='direction' options={directionOptions} />
      <div className='flex gap-2 mt-6'>
        {backtestTrade && <Submit<SaveBacktestTradeFormInputs> onSubmit={handleSave} style={ButtonStyle.Primary}>Update</Submit>}
        {backtestTrade && <Button onClick={() => setViewingBacktestTrade(undefined)} style={ButtonStyle.Alternative}>Cancel update</Button>}
        {backtestTrade && <Button onClick={handleDelete} style={ButtonStyle.Alternative}>Delete</Button>}
        {!backtestTrade && <Submit<SaveBacktestTradeFormInputs> onSubmit={handleSave} style={ButtonStyle.Primary}>Create</Submit>}
      </div>
    </Form>
  );
};

export default SaveBacktestTradeForm;
