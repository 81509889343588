import { useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import { DocumentNode } from "graphql";
import { useState } from "react";
import Table, { Column, ObjectWithId } from ".";
import Error from '../Error';

interface VariablesWithOptionalCursor<CursorType> {
  take?: number | null | undefined;
  cursor?: CursorType | null | undefined;
}

interface BackendTableProps<T extends ObjectWithId, VariablesType extends VariablesWithOptionalCursor<CursorType>, CursorType> {
  variables: VariablesType;
  query: DocumentNode;
  pageSize: number;
  columns: Column<T>[];
  dataKey: string;
  mapObjectToCursor: (object: T) => CursorType;
  onClickNext?: (cursor: CursorType, take: number) => void;
  onClickPrevious?: (cursor: CursorType, take: number) => void;
}

const BackendTable = <T extends ObjectWithId, VariablesType extends VariablesWithOptionalCursor<CursorType>, CursorType>({ variables, query, pageSize, columns, dataKey, mapObjectToCursor, onClickNext, onClickPrevious }: BackendTableProps<T, VariablesType, CursorType>) => {
  const [pagination, setPagination] = useState<{ take?: number, cursor?: CursorType, firstFetch: boolean, previousData?: T[] }>({ firstFetch: true });

  const take = pagination.take || variables.take || pageSize + 1;

  const { loading, error, data } = useQuery(query, {
    variables: { ...variables, take: take, cursor: pagination.cursor || variables.cursor },
    fetchPolicy: 'network-only',
  });

  if (loading && !pagination.previousData) {
    return <span>Loading...</span>
  }

  if (error || (!data && !pagination.previousData)) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  type DataKeys = keyof typeof data;
  const dataKeyCasted = dataKey as DataKeys;
  const objects: any[] | undefined = data ? data[dataKeyCasted] as any[] : undefined;

  const fetchedForward = take > 0;
  let displayData = objects || pagination.previousData!;
  if (objects && objects.length > pageSize) {
    if (fetchedForward === false) {
      displayData = objects.slice(1, undefined);
    } else {
      displayData = objects.slice(0, -1);
    }
  }

  const handleClickNext = (objects && (fetchedForward === false || (fetchedForward === true && objects.length > pageSize))) ?
    () => {
      const obj = objects![fetchedForward !== false ? objects!.length - 2 : objects!.length - 1];
      const cursor = mapObjectToCursor(obj);
      setPagination({
        take: pageSize + 1,
        cursor: cursor,
        firstFetch: false,
        previousData: displayData,
      });
      if (onClickNext) onClickNext(cursor, pageSize + 1);
    }
    :
    undefined;

  const handleClickPrevious = (objects && ((fetchedForward === true && !pagination.firstFetch) || (fetchedForward === false && objects.length > pageSize) || (fetchedForward === true && pagination.firstFetch && variables.cursor))) ?
    () => {
      const obj = objects![fetchedForward ? 0 : 1];
      const cursor = mapObjectToCursor(obj);
      setPagination({
        take: -(pageSize + 1),
        cursor: cursor,
        firstFetch: false,
        previousData: displayData,
      });
      if (onClickPrevious) onClickPrevious(cursor, -(pageSize + 1));
    }
    :
    undefined;

  return (
    <Table<T> columns={columns} data={displayData} onClickNext={handleClickNext} onClickPrevious={handleClickPrevious} />
  );
};

export default BackendTable;