import BacktestDetails from "components/Backtests/BacktestDetails";
import { FC, useEffect } from "react";
import { useMatches } from "react-router-dom";

const Backtest: FC = () => {
  const [match] = useMatches();
 
  useEffect(() => {
    document.title = `Backtest - Charts and Stats`;
  });

  return (
    <div className='p-6'>
      <BacktestDetails id={Number(match.params.backtest_id)} />
    </div>
  );
}

export default Backtest;
