import Form from "components/common/Form";
import Label from "components/common/Form/Label";
import Submit from "components/common/Form/Submit";
import Modal from "components/common/Modal";
import { FC } from "react";
import { FieldValues } from "react-hook-form";
import * as yup from 'yup';
import TextInput from "../Form/TextInput";
import useLocalStorageState from "hooks/useLocalStorageState";
import Button, { ButtonStyle } from "../Button";

interface AdminModalProps {
  show: boolean,
  onClose: () => void;
}

const validationSchema = yup.object({
  viewedUserId: yup.number().integer().required(),
});

const AdminModal: FC<AdminModalProps> = ({ show, onClose }) => {
  const [viewedUserId, setViewedUserId] = useLocalStorageState<string | undefined>(undefined, 'viewedUserId');
  const handleSubmit = (fields: FieldValues) => {
    setViewedUserId(fields.viewedUserId);
    window.location.reload();
  };

  const handleClear = () => {
    setViewedUserId(undefined);
    window.location.reload();
  }

  const customStyles = {
    content: {
      width: '50%', // Adjust the width as needed
      height: '50%', // Adjust the height as needed
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Modal show={show} style={customStyles} onClose={onClose}>
      <div className='flex mb-5'>
        <span className="material-icons cursor-pointer ml-auto" onClick={onClose}>close</span>
      </div>
      <Form defaultValues={{ viewedUserId }} validationSchema={validationSchema}>
        <Label label='Viewed user id' />
        <TextInput field='viewedUserId' placeholder={"Enter user id"} />
        <div className='flex gap-2 mt-4'>
          <Submit onSubmit={handleSubmit}>Load</Submit>
          <Button onClick={handleClear} style={ButtonStyle.Alternative}>Clear</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AdminModal;