import Option from 'components/common/Form/Option';
import * as yup from 'yup';

export interface SaveBacktestTradeFormInputs {
  ticker: Option;
  date: string;
  notes?: string;
  direction: Option;
}

export const validationSchema = yup.object({
  ticker: yup.object({
    label: yup.string(),
    value: yup.string(),
  }).required("Please select a ticker"),
  date: yup.lazy(v => yup.string().required("Must be a date").trim().matches(/^\d{4}-\d{2}-\d{2}$/, 'Must be a date')),
  notes: yup.string(),
  direction: yup.object({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
}).required();
