import { useQuery } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import * as Sentry from '@sentry/react';
import { apolloClient } from "App";
import Button, { ButtonStyle } from "components/common/Button";
import Error from 'components/common/Error';
import Form from "components/common/Form";
import Label from "components/common/Form/Label";
import Option from 'components/common/Form/Option';
import RadioGroup from "components/common/Form/RadioGroup";
import { Tabs } from "flowbite-react";
import { SignalTargetVariablesDocument, SignalTargetVariablesQuery, SignalTargetVariablesQueryVariables, TargetVariable, TargetVariableValueType } from "graphql/generated";
import { SIGNAL_TARGET_VARIABLES_QUERY } from "graphql/queries/signalTargetVariables.query";
import { FC, useState } from "react";
import SignalTargetVariablesChart from "./chart";
import SignalTargetVariablesTable from "./table";

interface SignalTargetVariablesProps {
  signalId: number;
  targetVariables: TargetVariable[];
  className?: string;
}

const SignalTargetVariables: FC<SignalTargetVariablesProps> = ({ signalId, targetVariables, className }) => {
  const [targetVariableValueType, setTargetVariableValueType] = useState<TargetVariableValueType>(TargetVariableValueType.Percentage);

  const { loading, error, data } = useQuery<SignalTargetVariablesQuery, SignalTargetVariablesQueryVariables>(SIGNAL_TARGET_VARIABLES_QUERY, {
    variables: {
      signalId,
      targetVariableValueType,
    },
  });

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const targetVariableValueTypeOptions = [
    {
      label: 'Percentage',
      value: TargetVariableValueType.Percentage,
    },
    {
      label: 'ATR',
      value: TargetVariableValueType.Atr,
    },
  ];

  const handleTargetVariableValueTypeChange = (option: Option) => setTargetVariableValueType(option.value as TargetVariableValueType);

  const signalTargetVariables = data.signalTargetVariables;

  const signalTargetVariableNames = new Set(signalTargetVariables.map(x => x.TargetVariable!.name));
  const missingTargetVariableNames = new Set<string>();
  targetVariables.forEach(x => {
    if (!signalTargetVariableNames.has(x.name)) {
      missingTargetVariableNames.add(x.name.replace(' high', '').replace(' low', '').replace(' close', ''));
    }
  });

  const refreshSignalTargetVariables = async () => {
    await apolloClient.refetchQueries({
      include: [
        getOperationName(SignalTargetVariablesDocument)!
      ],
    });
  };

  return (
    <div className={className}>
      {missingTargetVariableNames.size > 0 && (
        <>
          <Label label='Missing target variables' />
          {[...missingTargetVariableNames].map(x => (
            <div key={x} className='text-sm'>
              {x}
            </div>
          ))}
          <Button style={ButtonStyle.Alternative} onClick={refreshSignalTargetVariables} className='mt-4'>Refresh</Button>
        </>
      )}
      <Form defaultValues={{ 'atr-radio': { label: targetVariableValueType, value: targetVariableValueType } }} className="mt-20">
        <Label label='Value type' />
        <RadioGroup field='atr-radio' options={targetVariableValueTypeOptions} inline onChange={handleTargetVariableValueTypeChange} />
      </Form>
      <Tabs.Group className='mt-2'>
        <Tabs.Item title='Table (all targets)'>
          <SignalTargetVariablesTable
            signalTargetVariables={signalTargetVariables}
            targetVariableValueType={targetVariableValueType}
            allowedTargetVariableBases={[
              'Future 1 min',
              'Future 2 min',
              'Future 5 min',
              'Future 10 min',
              'Future 15 min',
              'Future 30 min',
              'Future 1 hour',
              'Future 2 hour',
              'Future 4 hour',
              'Future intraday',
              'Future intraday until end of AH',
              'Future today and tomorrow',
              'Future today and 2 days after',
              'Future today and 3 days after',
              'Future today and 7 days after',
              'Future today and 14 days after',
            ]}
          />
        </Tabs.Item>
        <Tabs.Item title='Chart'>
          <SignalTargetVariablesChart
            signalTargetVariables={signalTargetVariables}
            title='Continuous targets'
            targetVariableValueType={targetVariableValueType}
            allowedTargetVariableBases={[
              'Future 1 min',
              'Future 2 min',
              'Future 5 min',
              'Future 10 min',
              'Future 15 min',
              'Future 30 min',
              'Future 1 hour',
              'Future 2 hour',
              'Future 4 hour',
              'Future intraday',
              'Future intraday until end of AH',
              'Future today and tomorrow',
              'Future today and 2 days after',
              'Future today and 3 days after',
              'Future today and 7 days after',
              'Future today and 14 days after',
            ]}
          />
        </Tabs.Item>
      </Tabs.Group>
    </div>
  );
}

export default SignalTargetVariables;