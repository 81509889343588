import { NotesFormInput } from 'components/TickerDate/TickerDateNotesInput/schema';
import { FormRef } from 'components/common/Form';
import { TabsRef } from 'flowbite-react';
import { Backtest, BacktestTrade, BacktestTradeExecution, BarPayload, Collection, Feature, Signal, TargetVariable, UserTickerDate } from 'graphql/generated';
import React, { RefObject, useContext } from 'react';

export type TickerDateColumnContextType = {
  userTickerDate: UserTickerDate;
  features: Feature[];
  targetVariables: TargetVariable[];
  collection?: Partial<Collection>;
  backtest?: Partial<Backtest>;
  backtestTrade?: Partial<BacktestTrade>;
  selectedSignal?: Signal;
  selectedExecution?: BacktestTradeExecution;
  selectedBacktestTrade?: BacktestTrade;
  intradayBars: BarPayload[];
  handleSignalBarClick?: (bar: BarPayload, tabsRef: RefObject<TabsRef>) => void;
  handleSignalEditClick?: (o?: Signal) => void;
  handleSignalSaved?: () => void;
  handleBacktestTradeEditClick?: (o?: BacktestTrade) => void;
  handleBacktestTradeSaved?: () => void;
  handleExecutionBarClick?: (bar: BarPayload, tabsRef: RefObject<TabsRef>) => void;
  handleExecutionEditClick?: (o?: BacktestTradeExecution) => void;
  handleExecutionSaved?: () => void;
  handleDailyChartStateChanged: (state: object, id: string) => void;
  handleIntradayChartStateChanged: (state: object, id: string) => void;
  handleNotesChanged: (value: any, ref: RefObject<FormRef<NotesFormInput>>) => void;
  setSelectedDate: (date: string) => void;
  refreshMarks: () => void;

  // Remove after consolidating Scanners
  isPublic?: boolean;
  signal?: Signal;
};

export const useTickerDateColumnContext = (): TickerDateColumnContextType => {
  const context = useContext(TickerDateColumnContext);
  if (!context) {
    throw new Error('useTickerDateColumnContext must be used within a TickerDateColumnContextProvider');
  }
  return context;
};

export const TickerDateColumnContext = React.createContext<TickerDateColumnContextType | undefined>(undefined);
