import { gql } from "@apollo/client";

export const BACKTEST_STATS_QUERY = gql`
  query BacktestStats($id: Int!) {
    backtestStats(id: $id) {
      numberOfTrades
      numberOfTradesWithErrors
      winRate
      earliestTradeDate
      latestTradeDate
      statsRisk {
        netProfitValues
        totalNetProfit
        avgWin
        avgLoss
        largestWin
        largestLoss
        profitFactor
      }
    }
  }
`;