import { ApolloCache, DefaultContext, MutationFunctionOptions, OperationVariables } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { FormRef } from "components/common/Form";
import { FeatureValueType, SaveSignalMutation, SaveSignalMutationVariables, Signal, TickerDate } from "graphql/generated";
import moment from "moment";
import { formattedValueToRawNumber } from "util/valueFormat";
import { ISignalInputs } from "../schema";

export const saveSignal = async (
  fields: ISignalInputs,
  selectedSignal: Signal | undefined,
  tickerDate: TickerDate,
  formRef: React.RefObject<FormRef<ISignalInputs>>,
  saveSignalMutation: (options?: MutationFunctionOptions<SaveSignalMutation, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => any,
  onSave?: () => void,
) => {
  const timestamp = moment.tz(`${moment(tickerDate.date).format('YYYY-MM-DD')} ${fields.time}`, 'America/New_York').toDate();

  const variables: SaveSignalMutationVariables = {
    signal: {
      id: selectedSignal?.id,
      tickerDateId: tickerDate.id,
      price: formattedValueToRawNumber(fields.price)!,
      name: fields.name,
      timestamp,
    },
    notes: fields.notes,
    features: fields.features ? Object.values(fields.features).filter(x => !!x).map(feature => {
      const isValueNaN = isNaN(Number(feature.feature.value));
      return {
        featureId: isValueNaN ? undefined : Number(feature.feature.value),
        featureName: isValueNaN ? feature.feature.value as string : undefined,
        value: feature.type.value as FeatureValueType === FeatureValueType.Tag ? Number(feature.type.value !== 0) : formattedValueToRawNumber(feature.value)!,
        valueType: feature.type.value as FeatureValueType,
      };
    }) : [],
    tags: (fields.tags || []).map(t => {
      const isValueNaN = isNaN(Number(t.value));

      return {
        featureId: isValueNaN ? undefined : Number(t.value),
        featureName: isValueNaN ? t.value as string : undefined,
        value: 1,
        valueType: FeatureValueType.Tag,
      }
    }),
  };

  await saveSignalMutation({ variables }).then(() => {
    if (onSave) onSave();
  }).catch((err: any) => {
    formRef.current?.setError('error', { message: 'Something went wrong...' });
    Sentry.captureException(err);
  });
};