import { DeepPartial, EventType, FieldPath } from "react-hook-form";
import { FiltersFormFields } from "../schema";
import { FiltersSearchParamsContextType } from "contexts/FiltersSearchParamsContext";

export const handleFormChange = (context: FiltersSearchParamsContextType) => (
  values: DeepPartial<FiltersFormFields>,
  info: {
    name?: FieldPath<FiltersFormFields>;
    type?: EventType;
    value?: unknown;
  },
) => {
  if (info.name === 'selectedFiltersOption' || !info.name) {
    if (values.selectedFiltersOption && values.selectedFiltersOption.value) {
      const option = values.selectedFiltersOption;
      context.setScannerFilters(option.value as number | undefined, option.label);
    } else {
      context.setScannerFilters();
    }
  } else {
    context.setFilters(values as FiltersFormFields);
  }
};