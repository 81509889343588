import { gql } from "@apollo/client";
import { apolloClient } from "App";
import { ErrorCallback, HistoryCallback, LibrarySymbolInfo, PeriodParams, ResolutionString } from "charting_library";
import { Session, TickerDateChartBarsQuery, TickerDateChartBarsQueryVariables } from "graphql/generated";
import { timespanMultiplierFromResolution } from "util/timespanMultiplierFromResolution";
import TickerDateDatafeed from "..";

const TICKER_DATE_CHART_BARS_QUERY = gql`
  query TickerDateChartBars($input: ChartBarsInput!, $collectionId: Int) {
    tickerDateChartBars(input: $input, collectionId: $collectionId) {
      nextTime
      bars {
        ticker
        timeSeconds
        open
        high
        low
        close
        volume
        volumeWeighted
      }
    }
  }
`;

export const getBars = async (
  datafeed: TickerDateDatafeed,
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  periodParams: PeriodParams,
  onResult: HistoryCallback,
  onError: ErrorCallback
) => {
  const [timespan, multiplier] = timespanMultiplierFromResolution(resolution);

  if (!timespan || multiplier === undefined || Number.isNaN(multiplier)) {
    onError('Invalid resolution');
    return;
  }

  const result = await apolloClient.query<TickerDateChartBarsQuery, TickerDateChartBarsQueryVariables>({
    query: TICKER_DATE_CHART_BARS_QUERY,
    variables: {
      input: {
        timespan,
        multiplier,
        from: periodParams.from,
        to: periodParams.to,
        tickerDateId: datafeed.tickerDate.id,
        countBack: periodParams.countBack,
        firstDataRequest: periodParams.firstDataRequest,
        session: symbolInfo.subsession_id as Session,
      },
      collectionId: datafeed.collection?.id,
    }
  });

  if (result.data.tickerDateChartBars.bars?.length > 0) {
    onResult(result.data.tickerDateChartBars.bars.map(b => ({
      time: b.timeSeconds * 1000,
      open: b.open,
      high: b.high,
      low: b.low,
      close: b.close,
      volume: b.volume,
    })));
  } else {
    onResult([], { noData: true, nextTime: result.data.tickerDateChartBars.nextTime })
  }
};
