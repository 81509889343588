import { gql, useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import Error from 'components/common/Error';
import { FormRef } from "components/common/Form";
import { UserContext } from "contexts/UserContext";
import { Tabs } from "flowbite-react";
import { Feature, FeaturesAndFeatureSetsQuery, FeaturesAndFeatureSetsQueryVariables, FeatureSet } from "graphql/generated";
import { FC, useContext, useRef, useState } from "react";
import FeatureForm from "../FeatureForm";
import FeatureSetForm from "../FeatureSetForm";
import { FeatureSetFormInputsProps } from "../FeatureSetForm/schema";
import FeatureSetTable from "../FeatureSetTable";
import FeaturesTable from "../FeaturesTable";

const FEATURES_AND_FEATURE_SETS_QUERY = gql`
  query FeaturesAndFeatureSets {
    features {
      id
      name
      userId
      type
      valueType
    }
    featureSets {
      id
      name
      features
    }
  }
`;

interface FeatureAndFeatureSetsProps {

}

const FeatureAndFeatureSets: FC<FeatureAndFeatureSetsProps> = () => {
  const [editingFeature, setEditingFeature] = useState<Feature | undefined>(undefined);
  const [editingFeatureSet, setEditingFeatureSet] = useState<FeatureSet | undefined>(undefined);
  const { loading, error, data } = useQuery<FeaturesAndFeatureSetsQuery, FeaturesAndFeatureSetsQueryVariables>(FEATURES_AND_FEATURE_SETS_QUERY);
  const { user } = useContext(UserContext);
  const formRef = useRef<FormRef<FeatureSetFormInputsProps>>(null);

  if (!user) return null;

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const handleFeatureSetEditClick = (f: FeatureSet | undefined) => setEditingFeatureSet(f);

  const features = data.features.filter(f => f.userId === user.id)

  return (
    <Tabs.Group>
      <Tabs.Item title="Data values">
        <FeaturesTable features={features} onEditClick={setEditingFeature} editingFeature={editingFeature} />
        {editingFeature && <FeatureForm key={editingFeature.id} editingFeature={editingFeature} setEditingFeature={setEditingFeature} />}
      </Tabs.Item>
      <Tabs.Item title="Data views">
        <p className='mb-4'>
          Data view defines which data values should be showed on a ticker or a signal.
          <br />You can create multiple views and switch between when working on different setups.
        </p>
        <FeatureSetTable features={data.features} featureSets={data.featureSets} editingFeatureSet={editingFeatureSet} onEditClick={handleFeatureSetEditClick} />
        <FeatureSetForm features={data.features} featureSets={data.featureSets} editingFeatureSet={editingFeatureSet} ref={formRef} setEditingFeatureSet={handleFeatureSetEditClick} />
      </Tabs.Item>
    </Tabs.Group>
  );
};

export default FeatureAndFeatureSets;
