import BacktestTradeExecutionForm, { ExecutionFormRef } from "components/Backtests/BacktestTradeExecutionForm";
import BacktestTradeExecutionsTable from "components/Backtests/BacktestTradeExecutionsTable";
import BacktestTradesTable from "components/Backtests/BacktestTradesTable";
import SignalForm, { SignalFormRef } from "components/Signals/SignalForm";
import SignalTargetVariables from "components/Signals/SignalTargetVariables";
import DailyChart, { DailyChartRef } from "components/common/Chart/DailyChart";
import IntradayChart, { IntradayChartRef } from "components/common/Chart/IntradayChart";
import { useTickerDateColumnContext } from "contexts/TickerDateColumnContext";
import { UserContext } from "contexts/UserContext";
import { Tabs, TabsRef } from "flowbite-react";
import { BarPayload } from "graphql/generated";
import { RefObject, forwardRef, useContext, useImperativeHandle, useRef } from "react";
import { Tab } from ".";
import TickerDateData from "../TickerDateData";
import TickerDateNotesInput, { TickerDateNotesInputRef } from "../TickerDateNotesInput";
import SignalsTab from "./SignalsTab";

interface ColumnProps {
  tabs: Tab[];
  defaultTab: Tab;
}

export interface ColumnRef {
  tabsRef: RefObject<TabsRef>;
  dailyChartRef?: RefObject<DailyChartRef>;
  intradayChartRef?: RefObject<IntradayChartRef>;
  signalFormRef: RefObject<SignalFormRef>;
  executionFormRef: RefObject<ExecutionFormRef>;
  notesRef: RefObject<TickerDateNotesInputRef>;
}

const Column = forwardRef<ColumnRef, ColumnProps>(({ tabs, defaultTab }, ref) => {
  const { user } = useContext(UserContext);
  const context = useTickerDateColumnContext();
  const { collection, userTickerDate, features, backtest, backtestTrade, targetVariables } = context;

  const tabsRef = useRef<TabsRef>(null);

  const dailyChartRef = useRef<DailyChartRef>(null);
  const intradayChartRef = useRef<IntradayChartRef>(null);
  const signalFormRef = useRef<SignalFormRef>(null);
  const executionFormRef = useRef<ExecutionFormRef>(null);
  const notesRef = useRef<TickerDateNotesInputRef>(null);

  useImperativeHandle(ref, () => ({
    tabsRef,
    dailyChartRef,
    intradayChartRef,
    signalFormRef,
    executionFormRef,
    notesRef,
  }));

  const isPublic = !user || (collection && collection.userId !== user.id);

  const renderTab = (tab: Tab) => {
    switch (tab) {
      case Tab.DailyChart:
        return (
          <Tabs.Item title="Daily chart" active={defaultTab === Tab.DailyChart} key={tab}>
            <div style={{ minHeight: 720 }}>
              <DailyChart
                tickerDate={userTickerDate.TickerDate}
                ref={dailyChartRef}
                onStateChange={context.handleDailyChartStateChanged}
                collection={collection}
                isPublic={isPublic}
                backtestTrade={backtestTrade}
                onDateChange={context.setSelectedDate}
              />
            </div>
          </Tabs.Item>
        );
      case Tab.IntradayChart:
        return (
          <Tabs.Item title="Intraday chart" active={defaultTab === Tab.IntradayChart} key={tab}>
            <div style={{ minHeight: 720 }}>
              <IntradayChart
                tickerDate={userTickerDate.TickerDate}
                onSelectSignalBar={context.handleSignalBarClick ? (b: BarPayload) => context.handleSignalBarClick!(b, tabsRef) : undefined}
                onSelectExecutionBar={context.handleExecutionBarClick ? (b: BarPayload) => context.handleExecutionBarClick!(b, tabsRef) : undefined}
                intradayBars={context.intradayBars}
                ref={intradayChartRef}
                onStateChange={context.handleIntradayChartStateChanged}
                collection={collection}
                isPublic={isPublic}
                backtestTrade={backtestTrade}
                onDateChange={context.setSelectedDate}
              />
            </div>
          </Tabs.Item>
        );
      case Tab.Notes:
        return (
          <Tabs.Item title="Notes" active={defaultTab === Tab.Notes} key={tab}>
            <div style={{ minHeight: 720 }}>
              <TickerDateNotesInput
                tickerDateId={userTickerDate.TickerDate.id}
                notes={userTickerDate.notes || ''}
                onChange={context.handleNotesChanged}
                ref={notesRef}
                readOnly={isPublic}
              />
            </div>
          </Tabs.Item>
        );
      case Tab.Data:
        return (
          <Tabs.Item title="Data" active={defaultTab === Tab.Data} key={tab}>
            <div style={{ minHeight: 720 }}>
              <TickerDateData userTickerDate={userTickerDate} collection={collection} />
            </div>
          </Tabs.Item>
        );
      case Tab.Signals:
        return (
          <Tabs.Item title="Signals" active={defaultTab === Tab.Signals} key={tab}>
            <SignalsTab signalFormRef={signalFormRef} isPublic={isPublic} />
          </Tabs.Item>
        );
      case Tab.Signal:
        return (
          <Tabs.Item title="Signal" active={defaultTab === Tab.Signal} key={tab}>
            <SignalForm
              tickerDate={context.signal!.TickerDate}
              intradayBars={context.intradayBars}
              features={features}
              selectedSignal={context.signal!}
              ref={signalFormRef}
              onSave={context.handleSignalSaved}
              isPublic={isPublic || false}
            />
            <SignalTargetVariables
              signalId={context.signal!.id}
              targetVariables={targetVariables}
            />
          </Tabs.Item>
        );
      case Tab.Executions:
        return (
          <Tabs.Item title="Executions" active={defaultTab === Tab.Executions} key={tab}>
            <div style={{ minHeight: 720 }}>
              <BacktestTradeExecutionsTable
                backtest={backtest!}
                editingExecution={context.selectedExecution}
                onEditClick={context.handleExecutionEditClick!}
                executions={backtestTrade!.BacktestTradeExecutions!}
              />
              <BacktestTradeExecutionForm
                backtest={backtest!}
                backtestTrade={backtestTrade!}
                tickerDate={userTickerDate.TickerDate}
                collection={collection}
                intradayBars={context.intradayBars}
                selectedExecution={context.selectedExecution}
                ref={executionFormRef}
                onSave={context.handleExecutionSaved}
                isPublic={isPublic || false}
                key={context.selectedExecution?.id} />
            </div>
          </Tabs.Item>
        );
      case Tab.Backtests:
        return (
          <Tabs.Item title="Backtests" active={defaultTab === Tab.Backtests} key={tab}>
            <div style={{ minHeight: 720 }}>
              <BacktestTradesTable
                tickerDateId={userTickerDate.TickerDate.id}
                selectedBacktestTrade={context.selectedBacktestTrade!}
                setSelectedBacktestTrade={context.handleBacktestTradeEditClick!}
                executionFormRef={executionFormRef}
              />
            </div>
          </Tabs.Item>
        );
    }
  };

  return (
    <div>
      <Tabs.Group ref={tabsRef}>
        {tabs.map(renderTab)}
      </Tabs.Group>
    </div>
  );
});

export default Column;