import { FeatureValueType, ScannerFiltersQuery } from "graphql/generated";
import moment from "moment";
import { UseFormReturn } from "react-hook-form";
import { formatRawValue } from "util/valueFormat";
import { OPERATOR_LABELS } from "../FilterInput";
import { ASSET_TYPES } from "../asset_types";
import { FilterFields, FilterType, FiltersFormFields } from "../schema";

const handleSaveFiltersSelected = (formMethods: UseFormReturn<FiltersFormFields, any>, reset: (formMethods: UseFormReturn<FiltersFormFields, any>) => void, filtersTemplate: FilterFields[], filtersQuery: ScannerFiltersQuery) => () => {
  const scannerFiltersId = formMethods.getValues().selectedFiltersOption?.value;
  if (!scannerFiltersId) return;

  const scannerFilters = filtersQuery.scannerFilters.find(t => "" + t.id === "" + scannerFiltersId);
  if (!scannerFilters) return;

  reset(formMethods);

  formMethods.setValue('selectedFiltersOption', { value: scannerFilters.id, label: scannerFilters.name });
  formMethods.setValue('loadedFiltersId', "" + scannerFilters.id);
  formMethods.setValue('loadedFiltersName', scannerFilters.name);
  formMethods.setValue('filtersExpressions', Array(scannerFilters.FiltersExpressions.length).fill({ filters: filtersTemplate }));

  scannerFilters.FiltersExpressions.forEach((filtersExpression, filtersExpressionIndex) => {
    const keyPrefix = `filtersExpressions.${filtersExpressionIndex}.filters`;
    if (filtersExpression.assetTypes && filtersExpression.assetTypes.length > 0) {
      const filterIndex = filtersTemplate.findIndex(x => x.type === FilterType.AssetTypes)!;
      formMethods.setValue(`${keyPrefix}.${filterIndex}.value` as any, filtersExpression.assetTypes.map(t => ({ value: t, label: ASSET_TYPES[t] })));
      formMethods.setValue(`${keyPrefix}.${filterIndex}.modified` as any, true);
    }
    if (filtersExpression.Tickers && filtersExpression.Tickers.length > 0) {
      const filterIndex = filtersTemplate.findIndex(x => x.type === FilterType.Tickers)!;
      formMethods.setValue(`${keyPrefix}.${filterIndex}.value` as any, filtersExpression.Tickers.map(t => ({ label: t.ticker, value: `${t.id}` })));
      formMethods.setValue(`${keyPrefix}.${filterIndex}.modified` as any, true);
    }
    if (filtersExpression.date && filtersExpression.date.length > 0 && filtersExpression.dateOperator) {
      const filterIndex = filtersTemplate.findIndex(x => x.type === FilterType.Date)!;
      const [value, value2] = filtersExpression.date;
      if (value) formMethods.setValue(`${keyPrefix}.${filterIndex}.value` as any, value);
      if (value2) formMethods.setValue(`${keyPrefix}.${filterIndex}.value2` as any, value2);
      formMethods.setValue(`${keyPrefix}.${filterIndex}.operator` as any, { label: OPERATOR_LABELS[filtersExpression.dateOperator], value: filtersExpression.dateOperator });
      formMethods.setValue(`${keyPrefix}.${filterIndex}.modified` as any, true);
    }
    if (filtersExpression.signalTime && filtersExpression.signalTime.length > 0 && filtersExpression.signalTimeOperator) {
      const filterIndex = filtersTemplate.findIndex(x => x.type === FilterType.SignalTime)!;
      const [value, value2] = filtersExpression.signalTime;
      if (value) formMethods.setValue(`${keyPrefix}.${filterIndex}.value` as any, moment.utc(value).format('HH:mm'));
      if (value2) formMethods.setValue(`${keyPrefix}.${filterIndex}.value2` as any, moment.utc(value2).format('HH:mm'));
      formMethods.setValue(`${keyPrefix}.${filterIndex}.operator` as any, { label: OPERATOR_LABELS[filtersExpression.signalTimeOperator], value: filtersExpression.signalTimeOperator });
      formMethods.setValue(`${keyPrefix}.${filterIndex}.modified` as any, true);
    }

    filtersExpression.FeatureFilters.forEach(featureFilter => {
      if (featureFilter.value === null || featureFilter.value === undefined || !featureFilter.operator) return;

      const filterIndex = filtersTemplate.findIndex(x => x.type === FilterType.Feature && x.id === featureFilter.Feature?.id);
      if (filterIndex === -1) return;

      const [value, value2] = featureFilter.value;
      if (value) formMethods.setValue(`${keyPrefix}.${filterIndex}.value` as any, formatRawValue(value, featureFilter.Feature?.valueType));
      if (value2) formMethods.setValue(`${keyPrefix}.${filterIndex}.value2` as any, formatRawValue(value2, featureFilter.Feature?.valueType));
      formMethods.setValue(`${keyPrefix}.${filterIndex}.operator` as any, featureFilter.operator);
      formMethods.setValue(`${keyPrefix}.${filterIndex}.operator` as any, { label: OPERATOR_LABELS[featureFilter.operator], value: featureFilter.operator });
      formMethods.setValue(`${keyPrefix}.${filterIndex}.modified` as any, true);
    });

    filtersExpression.TargetVariableFilters.forEach(targetVariableFilter => {
      if (targetVariableFilter.value === null || targetVariableFilter.value === undefined || !targetVariableFilter.operator) return;

      const filterIndex = filtersTemplate.findIndex(x => x.type === FilterType.TargetVariable && x.id === targetVariableFilter.TargetVariable?.id);
      if (filterIndex === -1) return;

      const [value, value2] = targetVariableFilter.value;
      if (value) formMethods.setValue(`${keyPrefix}.${filterIndex}.value` as any, formatRawValue(value, FeatureValueType.Percentage));
      if (value2) formMethods.setValue(`${keyPrefix}.${filterIndex}.value2` as any, formatRawValue(value2, FeatureValueType.Percentage));
      formMethods.setValue(`${keyPrefix}.${filterIndex}.operator` as any, { label: OPERATOR_LABELS[targetVariableFilter.operator], value: targetVariableFilter.operator });
      formMethods.setValue(`${keyPrefix}.${filterIndex}.modified` as any, true);
    });
  });

  formMethods.trigger();
};

export default handleSaveFiltersSelected;