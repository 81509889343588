import Modal from "components/common/Modal";
import { FC } from "react";
import ChangeDateForm from "./changeDateForm";

interface ChangeDateModalProps {
  show: boolean,
  date: string,
  onClose: () => void;
  onDateChange?: (date: string) => void;
}

const ChangeDateModal: FC<ChangeDateModalProps> = ({ show, onDateChange, onClose, date }) => {
  const handleSubmit = (date: string) => {
    if (onDateChange) onDateChange(date);
    onClose();
  };

  const customStyles = {
    content: {
      width: '50%', // Adjust the width as needed
      height: '50%', // Adjust the height as needed
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Modal show={show} style={customStyles} onClose={onClose}>
      <div className='flex mb-5'>
        <span className="material-icons cursor-pointer ml-auto" onClick={onClose}>close</span>
      </div>
      <ChangeDateForm date={date} onSubmit={handleSubmit} />
    </Modal>
  );
};

export default ChangeDateModal;