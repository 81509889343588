import { useState } from "react";
import Table, { Column, ObjectWithId } from ".";

interface PaginatedTableProps<T extends ObjectWithId> {
  columns: Column<T>[];
  data: T[];
  pageSize: number;
  onClickNext?: () => void;
  footerData?: T[];
  onClickPrevious?: () => void;
}

const PaginatedTable = <T extends ObjectWithId>({ data, columns, onClickNext, footerData, pageSize, onClickPrevious }: PaginatedTableProps<T>) => {
  const [page, setPage] = useState<number>(0);

  const leftIndex = page * pageSize;
  const rightIndex = (page + 1) * pageSize;
  const displayData = data.slice(leftIndex, rightIndex);

  const handleClickNext = page < Math.ceil(data.length / pageSize) - 1 ?
    () => {
      setPage(page+1);
      if (onClickNext) onClickNext();
    }
    :
    undefined;

  const handleClickPrevious = page > 0 ?
    () => {
      setPage(page-1);
      if (onClickPrevious) onClickPrevious();
    }
    :
    undefined;

  return (
    <Table<T> columns={columns} data={displayData} onClickNext={handleClickNext} onClickPrevious={handleClickPrevious} footerData={footerData} />
  );
};

export default PaginatedTable;