import { FilterFields, FilterType, FiltersExpressionFields, FiltersFormFields } from "components/common/FiltersForm/schema";
import Option from 'components/common/Form/Option';
import { FilterOperator, FiltersExpressionInput, ScannerFiltersInput } from "graphql/generated";
import { formattedValueToRawNumber, timeStringToDate } from "util/valueFormat";

const mapStringValues = (fields: FilterFields | undefined) => {
  if (!fields || !fields.operator || !fields.value) return undefined;

  const isBoolean = typeof fields.value === 'boolean';
  const isString = typeof fields.value === 'string';

  if (!isString && !isBoolean) return undefined;

  if ([FilterOperator.Between, FilterOperator.Outside].includes(fields.operator.value as any)) {
    if (typeof fields.value2 !== 'string') return undefined;
    return [
      isString ? formattedValueToRawNumber(fields.value as string) : Number(fields.value),
      isString ? formattedValueToRawNumber(fields.value2) : Number(fields.value),
    ];
  } else {
    return [
      isString ? formattedValueToRawNumber(fields.value as string) : Number(fields.value),
    ];
  }
};

const mapDateValues = (fields: FilterFields | undefined) => {
  if (!fields || !fields.operator || !fields.value || typeof fields.value !== 'string') return undefined;

  if ([FilterOperator.Between, FilterOperator.Outside].includes(fields.operator.value as any)) {
    if (typeof fields.value2 !== 'string') return undefined;
    return [
      fields.value,
      fields.value2,
    ];
  } else {
    return [
      fields.value,
    ];
  }
};

const mapSignalTimeValues = (fields: FilterFields | undefined) => {
  if (!fields || !fields.operator || !fields.value || typeof fields.value !== 'string') return undefined;

  if ([FilterOperator.Between, FilterOperator.Outside].includes(fields.operator.value as any)) {
    if (typeof fields.value2 !== 'string') return undefined;
    return [
      timeStringToDate(fields.value),
      timeStringToDate(fields.value2),
    ];
  } else {
    return [
      timeStringToDate(fields.value),
    ];
  }
};

const mapOperator = (fields: FilterFields | undefined) => {
  if (fields && fields.operator && fields.value) {
    return fields.operator.value as FilterOperator;
  } else {
    return undefined;
  }
};

const filtersExpressionFieldsToInput = (filtersExpression: FiltersExpressionFields): FiltersExpressionInput => {
  const assetTypesFilters = filtersExpression.filters.find(x => x.type === FilterType.AssetTypes);
  const tickersFilters = filtersExpression.filters.find(x => x.type === FilterType.Tickers);
  const dateFilters = filtersExpression.filters.find(x => x.type === FilterType.Date);
  const signalTimeFilters = filtersExpression.filters.find(x => x.type === FilterType.SignalTime);
  const featureFilters = filtersExpression.filters.filter(x => x.type === FilterType.Feature).filter(x => x.value && x.operator);
  const targetVariableFilters = filtersExpression.filters.filter(x => x.type === FilterType.TargetVariable).filter(x => x.value && x.operator);

  return {
    assetTypes: assetTypesFilters?.value ? (assetTypesFilters?.value as Option[]).map(t => t.value as string) : undefined,
    tickerIds: tickersFilters?.value ? (tickersFilters?.value as Option[]).map(t => typeof t.value === 'string' ? parseInt(t.value) : t.value) : undefined,
    date: mapDateValues(dateFilters),
    dateOperator: mapOperator(dateFilters),
    signalTime: mapSignalTimeValues(signalTimeFilters),
    signalTimeOperator: mapOperator(signalTimeFilters),
    featureFilters: featureFilters.map(x => ({
      featureId: x.id!,
      value: mapStringValues(x)! as number[],
      operator: mapOperator(x)!,
    })),
    targetVariableFilters: targetVariableFilters.map(x => ({
      targetVariableId: x.id!,
      value: mapStringValues(x)! as number[],
      operator: mapOperator(x)!,
    })),
  };
};

export const scannerFormFieldsToInput = (inputs: FiltersFormFields) => {
  const inputVariables: ScannerFiltersInput = {
    id: inputs.loadedFiltersId ? Number(inputs.loadedFiltersId) : undefined,
    filtersExpressions: inputs.filtersExpressions.map(x => filtersExpressionFieldsToInput(x)),
  };

  return inputVariables;
};