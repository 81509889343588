import { gql } from "@apollo/client";

export const COLLECTION_QUERY = gql`
  query Collection($id: Int!) {
    collection(id: $id) {
      id
      name
      public
      userId
    }
  }
`;