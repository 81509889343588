import { FeatureInputs, featureValidationSchema } from 'components/common/FeatureInput/schema';
import Option from 'components/common/Form/Option';
import { BarPayload } from 'graphql/generated';
import moment from 'moment';
import * as yup from 'yup';

export interface ISignalInputs {
  price: string;
  time: string;
  features: { [key: string]: FeatureInputs };
  tags: Option[];
  notes: string;
  error?: string;
  name?: string;
}

const TIME_ERROR_MESSAGE = 'Must be time: HH:mm';
const TIME_REGEX = /^\d{2}:\d{2}(:\d{2})?$/;

const mapRules = (map: any, rule: any) => {
  if (!map) return {};
  return Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {})
};

export const getBarFromTime = (bars: BarPayload[], time: string) => {
  if (!time || !TIME_REGEX.test(time)) return;

  const startOfDay = moment.unix(bars[0].timeSeconds).tz("America/New_York").startOf('day');
  const secondsOfDayInput = moment(time, 'HH:mm').diff(moment().startOf('day'), 'seconds');
  const unixSecondsOfDayInput = startOfDay.add(secondsOfDayInput, 'seconds').set('seconds', 0).unix();
  const bar = bars.find(b => b.timeSeconds === unixSecondsOfDayInput);
  return bar;
}

export const validationSchema = (bars: BarPayload[]) => {
  return yup.object({
    price: yup.number().typeError('Must be a number').when('time', (time, schema) => {
      const bar = getBarFromTime(bars, time);
      if (!bar) return schema;
      return schema.min(bar.low).max(bar.high).typeError('Must be a number');
    }),
    time: yup.string().required(TIME_ERROR_MESSAGE).typeError(TIME_ERROR_MESSAGE).trim().matches(TIME_REGEX, TIME_ERROR_MESSAGE),
    features: yup.lazy(map => yup.object(mapRules(map, featureValidationSchema))),
    tags: yup.array().of(yup.object({
      label: yup.string().required(),
      value: yup.string().required(),
    })),
    notes: yup.string(),
    name: yup.string(),
  }).required();
}