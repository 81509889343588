import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import { scannerFormFieldsToInput } from "components/Scanner/util/scannerFormFieldsToInput";
import Button, { ButtonStyle } from "components/common/Button";
import Error from 'components/common/Error';
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import Form, { FormRef } from "components/common/Form";
import CheckboxInput from "components/common/Form/CheckboxInput";
import Label from "components/common/Form/Label";
import Submit from "components/common/Form/Submit";
import TextInput from "components/common/Form/TextInput";
import { CollectionQuery, CollectionQueryVariables, SaveCollectionMutation, SaveCollectionMutationVariables, Signal, TickerDate } from "graphql/generated";
import { COLLECTION_QUERY } from "graphql/queries/collection.query";
import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router";
import FeaturesTable from "./FeaturesTable";
import FilterSignalsModal from "./FilterSignalsModal";
import FilterTickerDatesModal from "./FilterTickerDatesModal";
import SelectedTickerDates from "./SelectedTickerDates";
import { CollectionEditFormInputs, validationSchema } from "./schema";

interface CollectionEditFormProps {
  id: number;
}

const SAVE_COLLECTION_MUTATION = gql`
  mutation SaveCollection($id: Int, $name: String!, $isPublic: Boolean!, $tickerDateIdsToAdd: [Int!], $tickerDateIdsToRemove: [Int!], $tickerDateFilters: ScannerFiltersInput, $removeExistingTickerDates: Boolean, $signalFilters: ScannerFiltersInput, $removeExistingSignals: Boolean, $signalIds: [Int!], $featureIds: [Int!]) {
    saveCollection(id: $id, name: $name, isPublic: $isPublic, tickerDateIdsToAdd: $tickerDateIdsToAdd, tickerDateIdsToRemove: $tickerDateIdsToRemove, tickerDateFilters: $tickerDateFilters, removeExistingTickerDates: $removeExistingTickerDates, signalFilters: $signalFilters, signalIds: $signalIds, removeExistingSignals: $removeExistingSignals, featureIds: $featureIds)
  }
`;

const CollectionEditForm: FC<CollectionEditFormProps> = ({ id }) => {
  const [tickerDateFiltersModalShow, setTickerDateFiltersModalShow] = useState<boolean>(false);
  const [signalFiltersModalShow, setSignalFiltersModalShow] = useState<boolean>(false);
  const { loading, error, data } = useQuery<CollectionQuery, CollectionQueryVariables>(COLLECTION_QUERY, { variables: { id } });
  const formRef = useRef<FormRef<CollectionEditFormInputs>>(null);
  const [saveCollectionMutation] = useMutation<SaveCollectionMutation, SaveCollectionMutationVariables>(SAVE_COLLECTION_MUTATION);
  const navigate = useNavigate();

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const collection = data.collection;

  const defaultValues = {
    name: collection.name,
    public: collection.public,
  };

  const handleTickerDateRemoved = (tickerDate: TickerDate) => {
    let tickerDateIdsToRemove = formRef?.current?.getValues('tickerDateIdsToRemove') || [];

    if (tickerDateIdsToRemove.includes(tickerDate.id)) {
      tickerDateIdsToRemove = tickerDateIdsToRemove.filter(x => x !== tickerDate.id);
    } else {
      tickerDateIdsToRemove.push(tickerDate.id);
    }

    formRef?.current?.setValue('tickerDateIdsToRemove', tickerDateIdsToRemove);
  };

  const handleTickerDateAdded = (tickerDate: TickerDate) => {
    let tickerDateIdsToAdd = formRef?.current?.getValues('tickerDateIdsToAdd') || [];

    if (tickerDateIdsToAdd.includes(tickerDate.id)) {
      tickerDateIdsToAdd = tickerDateIdsToAdd.filter(x => x !== tickerDate.id);
    } else {
      tickerDateIdsToAdd.push(tickerDate.id);
    }

    formRef?.current?.setValue('tickerDateIdsToAdd', tickerDateIdsToAdd);
  };

  const handleAllTickerDatesFromFiltersAdded = (filters?: FiltersFormFields) => {
    formRef?.current?.setValue('tickerDateFilters', filters);
  };

  const handleCloseTickerDatesFiltersModal = () => setTickerDateFiltersModalShow(false);

  const handleSignalAdded = (signal: Signal) => {
    let signalIdsToAdd = formRef?.current?.getValues('signalIdsToAdd') || [];

    if (signalIdsToAdd.includes(signal.id)) {
      signalIdsToAdd = signalIdsToAdd.filter(x => x !== signal.id);
    } else {
      signalIdsToAdd.push(signal.id);
    }

    formRef?.current?.setValue('signalIdsToAdd', signalIdsToAdd);
  };

  const handleAllSignalsFromFiltersAdded = (filters?: FiltersFormFields) => {
    formRef?.current?.setValue('signalFilters', filters);
  };

  const handleCloseSignalsFiltersModal = () => setSignalFiltersModalShow(false);

  const handleAllFeaturesAdded = (ids?: number[]) => {
    formRef?.current?.setValue('allFeaturesAdded', !!ids);
    formRef?.current?.setValue('featureIds', ids || []);
  };

  const handleFeatureAdded = (id: number) => {
    let featureIds = formRef?.current?.getValues('featureIds') || [];

    if (featureIds.includes(id)) {
      featureIds = featureIds.filter(x => x !== id);
    } else {
      featureIds.push(id);
    }

    formRef?.current?.setValue('featureIds', featureIds);
  };

  const handleSave = async (fields: CollectionEditFormInputs) => {
    await saveCollectionMutation({
      variables: {
        id: collection.id,
        name: fields.name,
        isPublic: fields.public,
        tickerDateIdsToAdd: fields.tickerDateFilters ? undefined : fields.tickerDateIdsToAdd,
        tickerDateIdsToRemove: fields.removeExistingTickerDates === true ? undefined : fields.tickerDateIdsToRemove,
        removeExistingTickerDates: fields.removeExistingTickerDates,
        tickerDateFilters: fields.tickerDateFilters ? scannerFormFieldsToInput(fields.tickerDateFilters) : undefined,

        signalIds: fields.signalFilters ? undefined : fields.signalIdsToAdd,
        removeExistingSignals: fields.removeExistingSignals,
        signalFilters: fields.signalFilters ? scannerFormFieldsToInput(fields.signalFilters) : undefined,

        featureIds: fields.featureIds,
      },
    });

    navigate(`/collection/${collection.id}`);
  };

  return (
    <Form<CollectionEditFormInputs> validationSchema={validationSchema} defaultValues={defaultValues} ref={formRef}>
      {(formMethods) => {
        const removedTickerDateIds = new Set(formMethods.watch('tickerDateIdsToRemove') || []);
        const addedTickerDateIds = new Set(formMethods.watch('tickerDateIdsToAdd') || []);
        const tickerDateFilters = formMethods.watch('tickerDateFilters') || undefined;
        const addedSignalIds = new Set(formMethods.watch('signalIdsToAdd') || []);
        const signalFilters = formMethods.watch('signalFilters') || undefined;
        const featureIds = new Set(formMethods.watch('featureIds') || []);
        const allFeaturesAdded = formMethods.watch('allFeaturesAdded');

        return <>
          <h1 className="mb-4 text-2xl font-bold tracking-tight leading-none text-gray-900 lg:mb-6 md:text-5xl xl:text-4xl dark:text-white">Edit {collection.name}</h1>

          <Label label='Name' />
          <TextInput field='name' />

          <Label label='Public?' />
          <CheckboxInput field='public' />

          <SelectedTickerDates id={collection.id} className='mt-8' onRemoveTickerDate={handleTickerDateRemoved} removedTickerDateIds={removedTickerDateIds} />

          <FilterTickerDatesModal onTickerDateAdded={handleTickerDateAdded} addedTickerDateIds={addedTickerDateIds} show={tickerDateFiltersModalShow} onClose={handleCloseTickerDatesFiltersModal} onAllTickerDatesFromFiltersAdded={handleAllTickerDatesFromFiltersAdded} addedAllTickersFromFilters={!!tickerDateFilters} />

          <div>
            <Button style={ButtonStyle.Alternative} onClick={() => setTickerDateFiltersModalShow(true)}>Add tickers from filters</Button>
          </div>
          {tickerDateFilters && <div>Selected all tickers from the above filters</div>}
          {addedTickerDateIds.size > 0 && !tickerDateFilters && <div>Selected {addedTickerDateIds.size} new tickers</div>}

          <Label label='Remove all selected signals' />
          <CheckboxInput field='removeExistingSignals' />
          <div>
            <Button style={ButtonStyle.Alternative} onClick={() => setSignalFiltersModalShow(true)}>Add signals from filters</Button>
          </div>

          <FilterSignalsModal onSignalAdded={handleSignalAdded} addedSignalIds={addedSignalIds} show={signalFiltersModalShow} onClose={handleCloseSignalsFiltersModal} onAllSignalsFromFiltersAdded={handleAllSignalsFromFiltersAdded} addedAllSignalsFromFilters={!!signalFilters} />


          <Label label='Features' className='mt-5' />
          <FeaturesTable featureIds={featureIds} allFeaturesAdded={allFeaturesAdded} onAllFeaturesAdded={handleAllFeaturesAdded} onFeatureAdded={handleFeatureAdded} />

          <div className='mt-5'>
            <Submit<CollectionEditFormInputs> onSubmit={handleSave}>Save</Submit>
          </div>
        </>
      }}
    </Form>
  )
};

export default CollectionEditForm;
