import { Column, ObjectWithId } from './index';

interface RowProps<T extends ObjectWithId> {
  columns: Column<T>[];
  object: T;
  rowIndex: number;
}

const Row = <T extends ObjectWithId>({ columns, object, rowIndex }: RowProps<T>) => {
  return (
    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
      {columns.map((column, columnIndex) => {
        const style = {
          color: column.textColor ? column.textColor(object) : '#6B7280',
          backgroundColor: column.backgroundColor ? column.backgroundColor(object) : 'white',
        };

        return (
          <td className="py-4 px-6" key={`${column.key}-${object.id}`} style={style}>
            {column.cell(object, rowIndex, columnIndex)}
          </td>
        );
      })}
    </tr>
  );
};

export default Row;