import { gql, useQuery } from "@apollo/client";
import Button, { ButtonStyle } from "components/common/Button";
import Form from "components/common/Form";
import SearchInput from "components/common/Form/SearchInput";
import { Column } from "components/common/Table";
import PaginatedTable from "components/common/Table/PaginatedTable";
import { Feature, FeatureType, FeatureValueCountsQuery, FeatureValueCountsQueryVariables } from "graphql/generated";
import { FC, useState } from "react";
import * as yup from 'yup';

const FEATURE_VALUE_COUNTS = gql`
  query FeatureValueCounts($featureIds: [Int!]!) {
    featureValueCounts(featureIds: $featureIds) {
      id
      count
    }
  }
`;

interface FeaturesTableProps {
  features: Feature[];
  editingFeature?: Feature;
  onEditClick: (feature: Feature) => void;
}

interface SearchFormInputs {
  input: string;
}

const searchValidationSchema = yup.object({
  input: yup.string().required(),
}).required();

const FeaturesTable: FC<FeaturesTableProps> = ({ features, editingFeature, onEditClick }) => {
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);

  const displayedFeatures = features.filter(f => !searchInput || searchInput === '' || f.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1);
  const { loading, error, data } = useQuery<FeatureValueCountsQuery, FeatureValueCountsQueryVariables>(FEATURE_VALUE_COUNTS, {
    variables: {
      featureIds: displayedFeatures.map(f => f.id),
    },
  });

  const featureValueCountsById: { [key: number]: number } = {};
  if (!loading && !error && data) {
    data.featureValueCounts.forEach(fv => featureValueCountsById[fv.id] = fv.count);
  }

  const columns: Column<Feature>[] = [
    {
      key: 'name',
      header: () => 'Name',
      cell: (o: Feature) => o.name,
    },
    {
      key: 'type',
      header: () => 'Type',
      cell: (o: Feature) => o.type === FeatureType.TickerDate ? 'Daily data' : "Signal data",
    },
    {
      key: 'Value type',
      header: () => 'Value type',
      cell: (o: Feature) => o.valueType,
    },
    {
      key: 'count',
      header: () => 'Number of values',
      cell: (o: Feature) => loading ? 'Loading...' : featureValueCountsById[o.id] || 0,
    },
    {
      key: 'edit',
      header: () => '',
      cell: (o: Feature) => {
        const editing = editingFeature?.id === o.id;
        return (
          <Button disabled={editing} onClick={() => onEditClick(o)} style={ButtonStyle.Alternative}>{editing ? 'Editing' : 'Edit'}</Button>
        );
      }
    }
  ];

  return (
    <>
      <Form<SearchFormInputs> validationSchema={searchValidationSchema}>
        <SearchInput<SearchFormInputs> field='input' placeholder="Search..." onChange={input => setSearchInput(input)} />
      </Form>
      <PaginatedTable<Feature> columns={columns} data={displayedFeatures} pageSize={5} key={`paginated-features-table-${searchInput}`} />
    </>
  );
};

export default FeaturesTable;
