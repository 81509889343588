import { gql, useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import { IChartingLibraryWidget } from "charting_library";
import TradingViewChart, { TradingViewButton, TradingViewChartRef } from "components/common/Chart/TradingViewChart";
import DelayRenderUntilVisible from "components/common/DelayRenderUntilVisible";
import Error from 'components/common/Error';
import { BacktestTrade, BarPayload, Collection, IntradayChartStateQuery, IntradayChartStateQueryVariables, Signal, TickerDate } from "graphql/generated";
import moment from "moment";
import { RefObject, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import ChangeDateModal from "./changeDateModal";

const INTRADAY_CHART_STATE_QUERY = gql`
  query IntradayChartState($tickerDateId: Int!, $collectionId: Int) {
    intradayChartState: userTickerDateChartState(tickerDateId: $tickerDateId, collectionId: $collectionId, index: 1) {
      state
    }
    intradayChartTemplate: userChartStudyTemplate(collectionId: $collectionId, index: 1) {
      template
    }
  }
`;

interface IntradayChartProps {
  tickerDate: TickerDate;
  intradayBars: BarPayload[];
  isPublic?: boolean;
  selectedSignal?: Signal;
  collection?: Partial<Collection>;
  backtestTrade?: Partial<BacktestTrade>;
  onSelectSignalBar?: (b: BarPayload) => void;
  onSelectExecutionBar?: (b: BarPayload) => void;
  onStateChange?: (state: object, id: string) => void;
  onDateChange?: (date: string) => void;
}

export const ON_SELECT_SIGNAL_BAR_MESSAGE = 'onSelectSignalBar';
export const ON_SELECT_EXECUTION_BAR_MESSAGE = 'onSelectExecutionBar';
export const ON_DATE_CHANGE_MESSAGE = 'onDateChange';

export interface IntradayChartRef {
  intradayChartRef: RefObject<TradingViewChartRef>;
}

const IntradayChart = forwardRef<IntradayChartRef, IntradayChartProps>(({ tickerDate, intradayBars, isPublic, selectedSignal, onSelectSignalBar, onSelectExecutionBar, onStateChange, collection, backtestTrade, onDateChange }, ref) => {
  const intradayChartRef = useRef<TradingViewChartRef>(null);
  const [widgetObject, setWidgetObject] = useState<IChartingLibraryWidget | undefined>(undefined);
  const [showChangeDateModal, setShowChangeDateModal] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    intradayChartRef: intradayChartRef,
  }));

  const onSelectBarCallback = (widgetObject: IChartingLibraryWidget, callback: (b: BarPayload) => void) => {
    widgetObject.activeChart().requestSelectBar().then((time) => {
      const selectedDate = moment.utc(time * 1000).tz("America/New_York").format('YYYY-MM-DD');
      const date = moment(tickerDate.date).format('YYYY-MM-DD');
      if (selectedDate !== date) {
        widgetObject.showNoticeDialog({
          title: `Date must be ${date}`,
          body: `You selected a bar on ${selectedDate}, but you can only create signals for ${date} on this page.`,
          callback: () => { },
        });
      } else {
        const timeSeconds = moment.unix(time).add(Number(widgetObject.activeChart().resolution()) - 1, 'minutes').unix();
        const bar = intradayBars.find(b => b.timeSeconds === timeSeconds);
        if (bar) callback(bar);
      }
    }).catch((error) => {
      if (error !== 'cancelled') throw error;
    })
  };

  useEffect(() => {
    if (!widgetObject) return;

    const listener = (event: MessageEvent<any>) => {
      if (event.data.id !== intradayChartRef.current?.id) return;

      if (event.data.event === ON_SELECT_SIGNAL_BAR_MESSAGE && onSelectSignalBar !== undefined) {
        onSelectBarCallback(widgetObject, onSelectSignalBar)
      }

      if (event.data.event === ON_SELECT_EXECUTION_BAR_MESSAGE && onSelectExecutionBar !== undefined) {
        onSelectBarCallback(widgetObject, onSelectExecutionBar)
      }

      if (event.data.event === ON_DATE_CHANGE_MESSAGE && onDateChange !== undefined) {
        setShowChangeDateModal(true);
      }
    };
    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  });

  const { loading, error, data } = useQuery<IntradayChartStateQuery, IntradayChartStateQueryVariables>(INTRADAY_CHART_STATE_QUERY, {
    variables: {
      tickerDateId: tickerDate.id,
      collectionId: collection?.id,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const intradayTimeframe = {
    from: moment.tz(tickerDate.date, 'America/New_York').unix(),
    to: moment.tz(tickerDate.date, 'America/New_York').add(1, 'day').unix(),
  }

  const customButtons: TradingViewButton[] = [];
  if (!isPublic && onSelectSignalBar !== undefined) {
    customButtons.push({
      text: 'Select bar for a signal',
      title: 'Select bar for a signal',
      onClick: (id: string) => window.parent.postMessage({ event: ON_SELECT_SIGNAL_BAR_MESSAGE, id }),
    });
  }

  if (!isPublic && onSelectExecutionBar !== undefined) {
    customButtons.push({
      text: 'Select bar for an execution',
      title: 'Select bar for an execution',
      onClick: (id: string) => window.parent.postMessage({ event: ON_SELECT_EXECUTION_BAR_MESSAGE, id }),
    });
  }

  if (onDateChange !== undefined) {
    customButtons.push({
      text: 'Change date',
      title: 'Change date',
      onClick: (id: string) => window.parent.postMessage({ event: ON_DATE_CHANGE_MESSAGE, id }),
    });
  }


  return (
    <>
      <DelayRenderUntilVisible>
        <TradingViewChart
          tickerDate={tickerDate}
          backtestTrade={backtestTrade}
          collection={collection}
          defaultResolution={'1'}
          resolutions={['1', '2', '5', '15', '30', '60']}
          hasIntraday={true}
          ref={intradayChartRef}
          timeframe={intradayTimeframe}
          stateIndex={1}
          initialState={data.intradayChartState.state || undefined}
          initialStudyTemplate={data.intradayChartTemplate.template || undefined}
          isPublic={isPublic}
          selectedSignal={selectedSignal}
          setWidgetObject={setWidgetObject}
          onStateChange={onStateChange}
          customButtons={customButtons}
          key={tickerDate.id}
        />
      </DelayRenderUntilVisible>
      <ChangeDateModal
        date={tickerDate.date}
        show={showChangeDateModal}
        onClose={() => setShowChangeDateModal(false)}
        onDateChange={onDateChange}
      />
    </>
  );
});

export default IntradayChart;