import Option from 'components/common/Form/Option';
import { FilterOperator } from 'graphql/generated';
import { formattedValueOptionalValidator, formattedValueValidator, timeValidator } from 'util/valueFormat';
import * as yup from 'yup';

export enum FilterType {
  AssetTypes = 'AssetTypes',
  Tickers = 'Tickers',
  Date = 'Date',
  SignalTime = 'SignalTime',
  Feature = 'Feature',
  TargetVariable = 'TargetVariable',
}

export interface FilterFields {
  type: FilterType;
  name: string;
  id?: number;
  operator?: Option;
  value?: string | Option[] | boolean;
  value2?: string;
  modified?: boolean;
  tag?: boolean;
}

export interface FiltersExpressionFields {
  filters: FilterFields[];
}

export interface FiltersFormFields {
  selectedFiltersOption?: Option;
  loadedFiltersId?: string;
  loadedFiltersName?: string;
  filtersExpressions: FiltersExpressionFields[];
}

const requiredSchemaFromFilterType = (type: FilterType) => {
  switch (type) {
    case FilterType.AssetTypes:
      return yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required(),
      }));
    case FilterType.Tickers:
      return yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required(),
      }));
    case FilterType.Date:
      return yup.string().trim().matches(/^\d{4}-\d{2}-\d{2}$/, 'Must be a date');
    case FilterType.SignalTime:
      return timeValidator;
    case FilterType.Feature:
      return formattedValueValidator;
    case FilterType.TargetVariable:
      return formattedValueValidator;
    default:
      return yup.mixed();
  }
};

const optionalSchemaFromFilterType = (type: FilterType) => {
  switch (type) {
    case FilterType.AssetTypes:
      return yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required(),
      }));
    case FilterType.Tickers:
      return yup.array(yup.object({
        label: yup.string().required(),
        value: yup.string().required(),
      }));
    case FilterType.Date:
      return yup.lazy(v => v === '' ? yup.string() : yup.string().trim().matches(/^\d{4}-\d{2}-\d{2}$/, 'Must be a date'));
    case FilterType.SignalTime:
      return yup.lazy(v => {
        return (v === '' || v === undefined) ? yup.string() : timeValidator;
      });
    case FilterType.Feature:
      return formattedValueOptionalValidator;
    case FilterType.TargetVariable:
      return formattedValueOptionalValidator;
    default:
      return yup.mixed();
  }
};

export const validationSchema = yup.object({
  selectedFiltersOption: yup.object({
    label: yup.string(),
    value: yup.number(),
  }).nullable(),
  loadedFiltersId: yup.lazy(v => v === '' ? yup.string() : yup.number().typeError('Must be a number')),
  loadedFiltersName: yup.string(),
  filtersExpressions: yup.array(yup.object({
    filters: yup.array(yup.object({
      type: yup.string().oneOf(Object.keys(FilterType)),
      id: yup.number(),
      operator: yup.object({
        label: yup.string().notRequired(),
        value: yup.string().notRequired(),
      }).notRequired(),
      value: yup.mixed().when('type', (type) => optionalSchemaFromFilterType(type)),
      value2: yup.mixed().when(['type', 'value', 'operator'], ([type, value, operator]) => {
        if ([FilterOperator.Between, FilterOperator.Outside].includes(operator.value)) {
          if (value !== '') {
            return requiredSchemaFromFilterType(type);
          }
        }
        return optionalSchemaFromFilterType(type);
      }),
    })),
  })),
}).required();