import { gql } from "@apollo/client";

export const SIGNAL_FILTER_CHART_STATS_QUERY = gql`
query SignalFilterChartStats($input: ScannerFiltersInput!, $targetVariableIds: [Int!]!, $targetVariableValueType: TargetVariableValueType!) {
  signalFilterChartStats(input: $input, targetVariableIds: $targetVariableIds, targetVariableValueType: $targetVariableValueType) {
    numberOfObjects
    targetVariableStatValues {
      targetVariableId
      values {
        type
        value
      }
    }
  }
}
`;