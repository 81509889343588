import { gql, useMutation } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import * as Sentry from "@sentry/react";
import Button, { ButtonStyle } from "components/common/Button";
import Form, { FormRef } from "components/common/Form";
import Error from "components/common/Form/Error";
import Label from "components/common/Form/Label";
import Select from "components/common/Form/Select";
import Submit from "components/common/Form/Submit";
import TextInput from "components/common/Form/TextInput";
import { Feature, FeaturesAndFeatureSetsDocument, FeaturesDocument, FeatureValueType, RemoveFeatureMutation, RemoveFeatureMutationVariables, SaveFeatureMutation, SaveFeatureMutationVariables } from "graphql/generated";
import { FC, useRef } from "react";
import { FeatureFormInputs, validationSchema } from "./schema";

const SAVE_FEATURE_MUTATION = gql`
  mutation SaveFeature($featureId: Int!, $name: String!, $valueType: FeatureValueType!) {
    updateFeature(featureId: $featureId, name: $name, valueType: $valueType)
  }
`;

const REMOVE_FEATURE_MUTATION = gql`
  mutation RemoveFeature($featureId: Int!) {
    removeFeature(featureId: $featureId)
  }
`;

interface FeatureFormProps {
  editingFeature: Feature;
  setEditingFeature: (feature?: Feature) => void;
}

const FeatureForm: FC<FeatureFormProps> = ({ editingFeature, setEditingFeature }) => {
  const [saveFeatureMutation] = useMutation<SaveFeatureMutation, SaveFeatureMutationVariables>(SAVE_FEATURE_MUTATION, {
    refetchQueries: [
      getOperationName(FeaturesDocument)!,
      getOperationName(FeaturesAndFeatureSetsDocument)!,
    ],
  });
  const [removeFeatureMutation] = useMutation<RemoveFeatureMutation, RemoveFeatureMutationVariables>(REMOVE_FEATURE_MUTATION, {
    refetchQueries: [
      getOperationName(FeaturesDocument)!,
      getOperationName(FeaturesAndFeatureSetsDocument)!,
    ],
  });
  const valueTypeOptions = Object.keys(FeatureValueType).filter(t => t !== FeatureValueType.Tag).map(t => ({ value: t, label: t }));

  const formRef = useRef<FormRef<FeatureFormInputs>>(null);

  const handleSave = async (fields: FeatureFormInputs) => {
    await saveFeatureMutation({
      variables: {
        featureId: editingFeature?.id,
        name: fields.name,
        valueType: fields.valueType.value as FeatureValueType,
      },
    }).then(() => {
      setEditingFeature(undefined);
    }).catch((err: any) => {
      formRef.current?.setError('error', { message: 'Something went wrong...' });
      Sentry.captureException(err);
    });
  };

  const handleDelete = async () => {
    await removeFeatureMutation({
      variables: {
        featureId: editingFeature?.id,
      },
    }).then(() => {
      setEditingFeature(undefined);
    }).catch((err: any) => {
      formRef.current?.setError('error', { message: 'Something went wrong...' });
      Sentry.captureException(err);
    });
  };

  const defaultValues = {
    name: editingFeature.name,
    valueType: {
      label: editingFeature.valueType! as string,
      value: editingFeature.valueType! as string,
    },
  };

  return (
    <Form<FeatureFormInputs> defaultValues={defaultValues} validationSchema={validationSchema} ref={formRef}>
      <Label label='name' />
      <TextInput field='name' />
      {editingFeature.valueType !== FeatureValueType.Tag && (
        <>
          <Label label='valueType' />
          <Select field='valueType' options={valueTypeOptions} />
        </>
      )}
      <Submit onSubmit={handleSave}>Save</Submit>
      <Button onClick={handleDelete} style={ButtonStyle.Alternative}>Delete</Button>
      <Error field='error' />
    </Form>
  );
};

export default FeatureForm;
