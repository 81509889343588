import { useMutation } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import TickerDateColumns, { Tab } from "components/TickerDate/TickerDateColumns";
import Button, { ButtonStyle } from "components/common/Button";
import { Column } from "components/common/Table";
import BackendTable from "components/common/Table/BackendTable";
import { TickerDateColumnContext } from "contexts/TickerDateColumnContext";
import { Tooltip } from "flowbite-react";
import { BacktestStatsDocument, BacktestTrade, BacktestTradesDocument, BacktestTradesQueryVariables, DateIdCursorInput, RemoveAllBacktestTradesMutation, RemoveAllBacktestTradesMutationVariables, RemoveAllIncompleteBacktestTradesMutation, RemoveAllIncompleteBacktestTradesMutationVariables } from "graphql/generated";
import { REMOVE_ALL_BACKTEST_TRADES_MUTATION } from "graphql/mutations/removeAllBacktestTrades.mutation";
import { REMOVE_ALL_INCOMPLETE_BACKTEST_TRADES_MUTATION } from "graphql/mutations/removeAllIncompleteBacktestTrades.mutation";
import { BACKTEST_TRADES_QUERY } from "graphql/queries/backtestTrades.query";
import { FC, ReactNode, RefObject, useContext } from "react";
import { formatRiskValue } from "util/valueFormat";
import BacktestTradeExecutionForm, { ExecutionFormRef } from "../BacktestTradeExecutionForm";
import BacktestTradeExecutionsTable from "../BacktestTradeExecutionsTable";
import SaveBacktestTradeForm from "../SaveBacktestTradeForm";

interface BacktestTradesTableProps {
  backtestId?: number;
  tickerDateId?: number;
  selectedBacktestTrade?: BacktestTrade;
  setSelectedBacktestTrade: (trade?: BacktestTrade | undefined) => void;
  executionFormRef?: RefObject<ExecutionFormRef>;
}

const wrapComponentWithErrorTooltip = (component: ReactNode, backtestTrade: BacktestTrade) => {
  const notes = [];
  if (backtestTrade.riskPriceMissing) notes.push('Risk price missing on one or more executions');
  if (backtestTrade.riskSizeInsufficient) notes.push('Risk size is not sufficient for adds - increase risk size or make risk tighter');
  if (backtestTrade.riskSizeNotDecreasing) notes.push('Risk is not decreasing on covers');
  if (backtestTrade.positionNotClosed) notes.push('Position is not closed');

  const tooltipContent = (
    <>
      {notes.map(n => (
        <div>{n}</div>
      ))}
    </>
  );

  return (
    <>
      {component}
      {notes.length > 0 && (
        <div className='inline-block ml-1'>
          <Tooltip content={tooltipContent}>
            <svg className="w-[16px] h-[16px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
          </Tooltip>
        </div>
      )}
    </>
  );
};

const BacktestTradesTable: FC<BacktestTradesTableProps> = ({ backtestId, tickerDateId, selectedBacktestTrade, setSelectedBacktestTrade, executionFormRef }) => {
  const context = useContext(TickerDateColumnContext);

  const [removeAllBacktestTradesMutation] = useMutation<RemoveAllBacktestTradesMutation, RemoveAllBacktestTradesMutationVariables>(REMOVE_ALL_BACKTEST_TRADES_MUTATION, {
    refetchQueries: [
      getOperationName(BacktestTradesDocument)!,
      getOperationName(BacktestStatsDocument)!,
    ],
  });

  const [removeAllIncompleteBacktestTradesMutation] = useMutation<RemoveAllIncompleteBacktestTradesMutation, RemoveAllIncompleteBacktestTradesMutationVariables>(REMOVE_ALL_INCOMPLETE_BACKTEST_TRADES_MUTATION, {
    refetchQueries: [
      getOperationName(BacktestTradesDocument)!,
      getOperationName(BacktestStatsDocument)!,
    ],
  });

  const handleViewClick = (o: BacktestTrade) => {
    setSelectedBacktestTrade(o);
  };

  const columns: Column<BacktestTrade>[] = [
    ...(tickerDateId === undefined ? [{
      key: 'ticker',
      header: () => 'Ticker',
      cell: (o: BacktestTrade) => wrapComponentWithErrorTooltip(o.TickerDate.ticker.ticker, o),
    }] : []),
    ...(tickerDateId === undefined ? [{
      key: 'date',
      header: () => 'Date',
      cell: (o: BacktestTrade) => o.TickerDate.date,
    }] : []),
    ...(tickerDateId ? [{
      key: 'backtest',
      header: () => 'Backtest',
      cell: (o: BacktestTrade) => wrapComponentWithErrorTooltip(o.Backtest.name, o),
    }] : []),
    {
      key: 'netProfitRisk',
      header: () => 'Net profit R',
      cell: (o: BacktestTrade) => formatRiskValue(o.netProfitRisk),
    },
    {
      key: 'maxRiskSize',
      header: () => 'Max size',
      cell: (o: BacktestTrade) => formatRiskValue(o.maxRiskSize),
    },
    {
      key: 'view',
      header: () => '',
      cell: (o: BacktestTrade) => {
        const viewing = selectedBacktestTrade?.id === o.id;
        return (
          <Button disabled={viewing} onClick={() => handleViewClick(o)} style={ButtonStyle.Alternative}>{viewing ? 'Viewing' : 'View'}</Button>
        );
      }
    }
  ];

  const variables: Partial<BacktestTradesQueryVariables> = { backtestId, tickerDateId };

  const handleDeleteAllTrades = async () => {
    await removeAllBacktestTradesMutation({
      variables: {
        backtestId,
        tickerDateId,
      }
    });
    setSelectedBacktestTrade(undefined);
  };

  const handleDeleteAllIncompleteTrades = async () => {
    await removeAllIncompleteBacktestTradesMutation({
      variables: {
        backtestId,
        tickerDateId,
      }
    });
    setSelectedBacktestTrade(undefined);
  };

  return (
    <>
      <BackendTable<BacktestTrade, Partial<BacktestTradesQueryVariables>, DateIdCursorInput>
        variables={variables}
        query={BACKTEST_TRADES_QUERY}
        pageSize={5}
        columns={columns}
        dataKey='backtestTrades'
        mapObjectToCursor={(object: BacktestTrade) => ({ id: object.id, date: object.TickerDate.date })}
      />
      <div className='flex gap-2'>
        <Button style={ButtonStyle.Alternative} onClick={handleDeleteAllTrades}>Delete all trades</Button>
        <Button style={ButtonStyle.Alternative} onClick={handleDeleteAllIncompleteTrades}>Delete all incomplete trades</Button>
      </div>
      {(backtestId || selectedBacktestTrade) && <SaveBacktestTradeForm backtestId={(backtestId || selectedBacktestTrade?.backtestId)!} backtestTrade={selectedBacktestTrade} key={selectedBacktestTrade?.id} setViewingBacktestTrade={setSelectedBacktestTrade} className='mt-20' />}
      {selectedBacktestTrade && context && (
        <>
          <div>Executions</div>
          <BacktestTradeExecutionsTable
            backtest={selectedBacktestTrade.Backtest}
            editingExecution={context.selectedExecution}
            onEditClick={context.handleExecutionEditClick!}
            executions={selectedBacktestTrade.BacktestTradeExecutions!}
          />
          <BacktestTradeExecutionForm
            backtest={selectedBacktestTrade.Backtest}
            backtestTrade={selectedBacktestTrade}
            tickerDate={context.userTickerDate.TickerDate}
            intradayBars={context.intradayBars}
            selectedExecution={context.selectedExecution}
            ref={executionFormRef}
            onSave={context.handleExecutionSaved}
            isPublic={false}
            key={context.selectedExecution?.id} />
        </>
      )}
      {!context && selectedBacktestTrade && (
        <div className='mt-20'>
          <TickerDateColumns
            backtestTradeId={selectedBacktestTrade.id}
            ticker={selectedBacktestTrade.TickerDate.ticker.ticker}
            date={selectedBacktestTrade.TickerDate.date}
            tabs={[Tab.DailyChart, Tab.IntradayChart, Tab.Notes, Tab.Data, Tab.Signals, Tab.Executions]}
          />
        </div>
      )}
    </>
  );
};

export default BacktestTradesTable;
