import { gql } from '@apollo/client';
import { scannerFormFieldsToInput } from 'components/Scanner/util/scannerFormFieldsToInput';
import Button, { ButtonStyle } from 'components/common/Button';
import { FiltersFormFields } from 'components/common/FiltersForm/schema';
import BackendTable from 'components/common/Table/BackendTable';
import { FiltersSearchParamsContext } from 'contexts/FiltersSearchParamsContext';
import { DateIdCursorInput, QuerySignalsFromFiltersArgs, Signal, SignalWithTagsPayload } from 'graphql/generated';
import moment from 'moment';
import { FC, useContext } from 'react';
import NextPrevSignalControls from '../SignalNextPrevControls';

export const SIGNALS_FROM_FILTERS_QUERY = gql`
  query SignalsFromFilters($input: ScannerFiltersInput!, $take: Int!, $cursor: DateIdCursorInput) {
    signalsFromFilters(input: $input, take: $take, cursor: $cursor) {
      id
      signal {
        id
        TickerDate {
          id
          ticker {
            id
            ticker
          }
          date
        }
        price
        name
        timestamp
        TargetVariables {
          value
          TargetVariable {
            id
            name
          }
        }
        notes
      }
      tags
    }
  }
`;

export interface ViewingSignal {
  id: number;
  date: string;
  ticker: string;
}

interface SignalsTableFromFiltersProps {
  inputs: FiltersFormFields;
  viewingSignal?: ViewingSignal;
  onEditClick: (o: Signal) => void;
  pageSize?: number;
}

const SignalsTableFromFilters: FC<SignalsTableFromFiltersProps> = ({ inputs, viewingSignal, onEditClick, pageSize }) => {
  const filtersSearchParamsContext = useContext(FiltersSearchParamsContext);

  const inputVariables = scannerFormFieldsToInput(inputs);

  const columns = [
    {
      key: 'ticker',
      header: () => 'Ticker',
      cell: (o: SignalWithTagsPayload) => o.signal.TickerDate.ticker.ticker,
    },
    {
      key: 'date',
      header: () => 'Date',
      cell: (o: SignalWithTagsPayload) => o.signal.TickerDate.date,
    },
    {
      key: 'name',
      header: () => 'Name',
      cell: (o: SignalWithTagsPayload) => o.signal.name,
    },
    {
      key: 'price',
      header: () => 'Price',
      cell: (o: SignalWithTagsPayload) => o.signal.price,
    },
    {
      key: 'time',
      header: () => 'Time',
      cell: (o: SignalWithTagsPayload) => {
        return moment.utc(o.signal.timestamp).tz('America/New_York').format('HH:mm');
      },
    },
    {
      key: 'tags',
      header: () => 'Tags',
      cell: (o: SignalWithTagsPayload) => o.tags.join(', '),
    },
    {
      key: 'view',
      header: () => '',
      cell: (o: SignalWithTagsPayload) => <Button disabled={viewingSignal?.id === o.id} onClick={() => onEditClick(o.signal)} style={ButtonStyle.Alternative}>{viewingSignal?.id === o.id ? 'Viewing' : 'View'}</Button>
    }
  ];


  const handleClick = (cursor: DateIdCursorInput, take: number) => {
    filtersSearchParamsContext.setCursor(cursor.id, cursor.date);
    filtersSearchParamsContext.setTake(take);
  };

  const variables: Partial<QuerySignalsFromFiltersArgs> = { input: inputVariables };
  if (filtersSearchParamsContext.cursorId && filtersSearchParamsContext.cursorDate) {
    variables.take = filtersSearchParamsContext.take;
    variables.cursor = {
      id: filtersSearchParamsContext.cursorId,
      date: filtersSearchParamsContext.cursorDate,
    };
  }

  const mapObjectToCursor = (object: SignalWithTagsPayload) => ({ id: object.signal.id, date: object.signal.TickerDate.date });

  return (
    <div className='my-10' >
      <BackendTable<SignalWithTagsPayload, Partial<QuerySignalsFromFiltersArgs>, DateIdCursorInput>
        variables={variables}
        query={SIGNALS_FROM_FILTERS_QUERY}
        pageSize={pageSize || 5}
        columns={columns}
        dataKey={'signalsFromFilters'}
        mapObjectToCursor={mapObjectToCursor}
        onClickNext={handleClick}
        onClickPrevious={handleClick}
      />
      {viewingSignal && <NextPrevSignalControls variables={inputVariables} signalId={viewingSignal.id} date={viewingSignal.date} onViewClick={onEditClick} />}
    </div>
  );
}

export default SignalsTableFromFilters;