import { gql, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import Error from 'components/common/Error';
import { FeatureValue } from 'components/common/FeatureInput';
import Form from 'components/common/Form';
import CheckboxInput from 'components/common/Form/CheckboxInput';
import Label from 'components/common/Form/Label';
import Option from 'components/common/Form/Option';
import Select from 'components/common/Form/Select';
import TickerDateFeatures from 'components/TickerDate/TickerDateFeatures';
import { UserContext } from 'contexts/UserContext';
import { Collection, FeatureSet, FeatureSetsAndValuesQuery, FeatureSetsAndValuesQueryVariables, FeaturesQuery, FeaturesQueryVariables, UserTickerDate } from 'graphql/generated';
import { FEATURES_QUERY } from 'graphql/queries/features.query';
import useLocalStorageState from 'hooks/useLocalStorageState';
import { FC, useContext } from 'react';

const FEATURE_SETS_AND_VALUES_QUERY = gql`
  query FeatureSetsAndValues($ticker: String!, $date: Date!, $collectionId: Int) {
    featureSets(collectionId: $collectionId) {
      id
      name
      features
    }
    tickerDateFeatures(ticker: $ticker, date: $date, collectionId: $collectionId) {
      value
      Feature {
        id
        name
        userId
        type
        valueType
      }
      TickerDate {
        id
        ticker {
          id
          ticker
        }
        date
      }
    }
  }
`;

export interface TickerDateDataProps {
  userTickerDate: UserTickerDate;
  collection?: Partial<Collection>;
}

interface SelectFeatureSetFormProps {
  featureSet: Option;
  showEmptyFeatures: Boolean;
}

const TickerDateData: FC<TickerDateDataProps> = ({ userTickerDate, collection }) => {
  const { user } = useContext(UserContext);

  let [selectedFeatureSetOption, setSelectedFeatureSetOption] = useLocalStorageState<Option | undefined>(undefined, 'defaultFeatureSet');
  const [showEmptyFeatures, setShowEmptyFeatures] = useLocalStorageState<boolean | undefined>(undefined, 'showEmptyFeatures');

  const { loading, error, data } = useQuery<FeaturesQuery, FeaturesQueryVariables>(FEATURES_QUERY);
  const { loading: valuesLoading, error: valuesError, data: valuesData } = useQuery<FeatureSetsAndValuesQuery, FeatureSetsAndValuesQueryVariables>(FEATURE_SETS_AND_VALUES_QUERY, {
    variables: {
      ticker: userTickerDate.TickerDate.ticker.ticker,
      date: userTickerDate.TickerDate.date,
      collectionId: collection?.id
    },
  });

  if (!user && !collection) return null;

  if (loading || valuesLoading) {
    return <span>Loading...</span>
  }

  if (error || !data || valuesError || !valuesData) {
    if (error) {
      Sentry.captureException(error);
      return <Error error={error} />
    } else if (valuesError) {
      Sentry.captureException(valuesError);
      return <Error error={valuesError} />
    } else {
      return <Error />
    }
  }

  const featureSetOptions = valuesData.featureSets.map(f => ({ value: f.id, label: f.name }));
  featureSetOptions.push({ value: -2, label: 'View your data' });
  featureSetOptions.push({ value: -1, label: 'View all data' });

  if (collection) {
    selectedFeatureSetOption = featureSetOptions[featureSetOptions.length - 1];
  }

  const featureSetsById: { [key: number]: FeatureSet } = {};
  valuesData.featureSets.forEach(f => featureSetsById[f.id] = f);
  const selectedFeatureSet: FeatureSet | undefined = selectedFeatureSetOption ? featureSetsById[selectedFeatureSetOption.value as number] : undefined;

  const handleFeatureSetChange = (value: Option) => setSelectedFeatureSetOption(value);
  const handleShowEmptyFeaturesChange = (value: boolean) => setShowEmptyFeatures(value);

  const key = `selectedFeatreSet-${selectedFeatureSet?.id || 0}`;

  const values: FeatureValue[] = valuesData.tickerDateFeatures.map(v => ({
    featureId: v.Feature.id,
    featureName: v.Feature.name,
    value: v.value,
    type: v.Feature.type!,
    valueType: v.Feature.valueType!,
    userId: v.Feature.userId!
  }));

  const isPublic = !user || (collection && collection.userId !== user.id);

  return (
    <div>
      {!collection ? (
        <Form<SelectFeatureSetFormProps> defaultValues={{ featureSet: selectedFeatureSetOption, showEmptyFeatures }}>
          <Label label='Data view' />
          <Select field='featureSet' options={featureSetOptions} onChange={handleFeatureSetChange} />
          <div className='flex center'>
            <Label label='Show empty data points' />
            <CheckboxInput className='mt-4 ml-2' field='showEmptyFeatures' onChange={handleShowEmptyFeaturesChange} />
          </div>
        </Form>
      ) : undefined}

      <div key={key}>
        <TickerDateFeatures features={data.features} collection={collection} selectedFeatureSetOption={selectedFeatureSetOption} selectedFeatureSet={selectedFeatureSet} userTickerDate={userTickerDate} values={values} showEmptyFeatures={showEmptyFeatures} isPublic={isPublic} />
      </div>
    </div>
  );
};

export default TickerDateData;
