import { scannerFormFieldsToInput } from "components/Scanner/util/scannerFormFieldsToInput";
import { SIGNALS_FROM_FILTERS_QUERY, ViewingSignal } from "components/Signals/SignalsTableFromFilters";
import Button, { ButtonStyle } from "components/common/Button";
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import BackendTable from "components/common/Table/BackendTable";
import { DateIdCursorInput, QuerySignalsFromFiltersArgs, Signal, SignalWithTagsPayload } from "graphql/generated";
import moment from "moment";
import { FC } from "react";

interface SignalsTableFromFiltersProps {
  inputs: FiltersFormFields;
  viewingSignal?: ViewingSignal;
  onViewClick: (o: Signal) => void;
  onSignalAdded: (signal: Signal) => void;
  addedSignalIds: Set<number>;
  addedAllSignalsFromFilters: boolean;
  pageSize?: number;
}

const SignalsTableFromFilters: FC<SignalsTableFromFiltersProps> = ({ inputs, viewingSignal, onViewClick, onSignalAdded, addedSignalIds, addedAllSignalsFromFilters, pageSize }) => {

  const inputVariables = scannerFormFieldsToInput(inputs);

  const columns = [
    {
      key: 'ticker',
      header: () => 'Ticker',
      cell: (o: SignalWithTagsPayload) => o.signal.TickerDate.ticker.ticker,
    },
    {
      key: 'date',
      header: () => 'Date',
      cell: (o: SignalWithTagsPayload) => o.signal.TickerDate.date,
    },
    {
      key: 'name',
      header: () => 'Name',
      cell: (o: SignalWithTagsPayload) => o.signal.name,
    },
    {
      key: 'price',
      header: () => 'Price',
      cell: (o: SignalWithTagsPayload) => o.signal.price,
    },
    {
      key: 'time',
      header: () => 'Time',
      cell: (o: SignalWithTagsPayload) => moment(o.signal.timestamp).tz('America/New_York').format('HH:mm'),
    },
    {
      key: 'tags',
      header: () => 'Tags',
      cell: (o: SignalWithTagsPayload) => o.tags.join(', '),
    },
    {
      key: 'view',
      header: () => '',
      cell: (o: SignalWithTagsPayload) => <Button disabled={viewingSignal?.id === o.id} onClick={() => onViewClick(o.signal)} style={ButtonStyle.Alternative}>{viewingSignal?.id === o.id ? 'Viewing' : 'View'}</Button>
    },
    ...(!addedAllSignalsFromFilters ? [{
      key: 'add',
      header: () => '',
      cell: (o: SignalWithTagsPayload) => {
        if (addedSignalIds.has(o.signal.id)) {
          return (
            <Button onClick={() => onSignalAdded(o.signal)} style={ButtonStyle.Alternative}>Remove</Button>
          );
        } else {
          return (
            <Button onClick={() => onSignalAdded(o.signal)} style={ButtonStyle.Primary}>Add</Button>
          );
        }
      },
    }] : []),
  ];

  const variables: Partial<QuerySignalsFromFiltersArgs> = { input: inputVariables };

  return (
    <div className='my-10' >
      <BackendTable<SignalWithTagsPayload, Partial<QuerySignalsFromFiltersArgs>, DateIdCursorInput>
        variables={variables}
        query={SIGNALS_FROM_FILTERS_QUERY}
        pageSize={pageSize || 5}
        columns={columns}
        dataKey='signalsFromFilters'
        mapObjectToCursor={(object: SignalWithTagsPayload) => ({ id: object.signal.id, date: object.signal.TickerDate.date })}
      />
    </div >
  );
}

export default SignalsTableFromFilters;
