import { useMutation } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import Button, { ButtonStyle } from "components/common/Button";
import Form from "components/common/Form";
import Label from "components/common/Form/Label";
import Submit from "components/common/Form/Submit";
import TextInput from "components/common/Form/TextInput";
import { Backtest, BacktestDocument, BacktestStatsDocument, BacktestTradesDocument, BacktestsDocument, CopyBacktestMutation, CopyBacktestMutationVariables, FeatureValueType, RemoveBacktestMutation, RemoveBacktestMutationVariables, SaveBacktestMutation, SaveBacktestMutationVariables, TickerDateDetailsDocument } from "graphql/generated";
import { COPY_BACKTEST_MUTATION } from "graphql/mutations/copyBacktest.mutation";
import { REMOVE_BACKTEST_MUTATION } from "graphql/mutations/removeBacktest.mutation";
import { SAVE_BACKTEST_MUTATION } from "graphql/mutations/saveBacktest.mutation";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { formatRawValue, formattedValueToRawNumber } from "util/valueFormat";
import { SaveBacktestFormInputs, validationSchema } from "./schema";

interface SaveBacktestFormProps {
  backtest?: Partial<Backtest>;
}

const SaveBacktestForm: FC<SaveBacktestFormProps> = ({ backtest }) => {
  const navigate = useNavigate();

  const [saveBacktestMutation] = useMutation<SaveBacktestMutation, SaveBacktestMutationVariables>(SAVE_BACKTEST_MUTATION, {
    refetchQueries: [
      getOperationName(BacktestsDocument)!,
      getOperationName(BacktestDocument)!,
      getOperationName(BacktestTradesDocument)!,
      getOperationName(TickerDateDetailsDocument)!,
      getOperationName(BacktestStatsDocument)!,
    ],
  });

  const [removeBacktestMutation] = useMutation<RemoveBacktestMutation, RemoveBacktestMutationVariables>(REMOVE_BACKTEST_MUTATION, {
    refetchQueries: [
      getOperationName(BacktestsDocument)!,
    ],
  });

  const [copyBacktestMutation] = useMutation<CopyBacktestMutation, CopyBacktestMutationVariables>(COPY_BACKTEST_MUTATION, {
    refetchQueries: [
      getOperationName(BacktestsDocument)!,
    ],
  });


  const handleSubmit = async (fields: SaveBacktestFormInputs) => {
    await saveBacktestMutation({
      variables: {
        id: backtest?.id,
        name: fields.name,
        feesPercentagePerExecution: formattedValueToRawNumber(fields.feesPercentagePerExecution),
        feesPercentagePerTrade: formattedValueToRawNumber(fields.feesPercentagePerTrade),
      }
    });
  };

  const defaultValues: Partial<SaveBacktestFormInputs> = {
    name: backtest?.name,
    feesPercentagePerExecution: formatRawValue(backtest?.feesPercentagePerExecution, FeatureValueType.Percentage),
    feesPercentagePerTrade: formatRawValue(backtest?.feesPercentagePerTrade, FeatureValueType.Percentage),
  };

  const handleDeleteBacktest = async () => {
    if (!backtest) return;

    await removeBacktestMutation({
      variables: {
        backtestId: backtest.id!,
      },
    });

    navigate('/backtests');
  };

  const handleCopyBacktest = async () => {
    if (!backtest) return;

    const newBacktestId = await copyBacktestMutation({
      variables: {
        backtestId: backtest.id!,
      },
    });

    if (newBacktestId.data?.copyBacktest) {
      navigate(`/backtest/${newBacktestId.data?.copyBacktest}`);
    }
  };

  return (
    <Form<SaveBacktestFormInputs> validationSchema={validationSchema} defaultValues={defaultValues}>
      <Label label='Name' />
      <TextInput field="name" />

      <Label label='Fee % per execution' />
      <TextInput field="feesPercentagePerExecution" />

      <Label label='Fee % per trade' />
      <TextInput field="feesPercentagePerTrade" />

      <div className='flex gap-2 mt-6'>
        <Submit onSubmit={handleSubmit}>Save</Submit>
        {backtest && <Button style={ButtonStyle.Alternative} onClick={handleDeleteBacktest}>Delete</Button>}
        {backtest && <Button style={ButtonStyle.Alternative} onClick={handleCopyBacktest}>Copy</Button>}
      </div>

    </Form>
  );
};

export default SaveBacktestForm;
