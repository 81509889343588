import { gql } from "@apollo/client";

export const COLLECTION_TICKER_DATES_QUERY = gql`
query CollectionTickerDates($collectionId: Int!, $take: Int!, $cursor: DateIdCursorInput) {
  collectionTickerDates(id: $collectionId, take: $take, cursor: $cursor) {
    id
    date
    ticker {
      id
      ticker
    }
    open
    high
    low
    close
    volume
    volumeWeighted
    adjustmentCoefficient
    assetType
    marketCap
  }
}
`;