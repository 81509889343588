import SignalStats from "components/Signals/SignalStats";
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import Form from "components/common/Form";
import Label from "components/common/Form/Label";
import Option from 'components/common/Form/Option';
import RadioGroup from "components/common/Form/RadioGroup";
import { TargetVariableValueType } from "graphql/generated";
import { FC, useState } from "react";
import TickerFilterStats from "../TickerFilterStats";

interface StatisticsProps {
  inputs: FiltersFormFields;
}

enum StatsType {
  Ticker = 'Ticker',
  Signal = 'Signal'
}

const Statistics: FC<StatisticsProps> = ({ inputs }) => {
  const [statsType, setStatsType] = useState<StatsType>(StatsType.Ticker);
  const [targetVariableValueType, setTargetVariableValueType] = useState<TargetVariableValueType>(TargetVariableValueType.Percentage);

  const statsTypeOptions = [
    {
      label: 'Daily tickers',
      value: StatsType.Ticker,
    },
    {
      label: 'Signals',
      value: StatsType.Signal,
    },
  ];

  const targetVariableValueTypeOptions = [
    {
      label: 'Percentage',
      value: TargetVariableValueType.Percentage,
    },
    {
      label: 'ATR',
      value: TargetVariableValueType.Atr,
    },
  ];

  const handleTargetVariableValueTypeChange = (option: Option) => setTargetVariableValueType(option.value as TargetVariableValueType);

  return (
    <>
      <Form defaultValues={{ statsType: statsTypeOptions[0], atrRadio: targetVariableValueTypeOptions[0] }} className="mb-10">
        <Label label='Statistics type' />
        <RadioGroup field='statsType' inline onChange={(option) => setStatsType(option.value as StatsType)} options={statsTypeOptions} />
        <Label label='Value type' />
        <RadioGroup field='atrRadio' options={targetVariableValueTypeOptions} inline onChange={handleTargetVariableValueTypeChange} />
      </Form>
      {statsType === StatsType.Ticker && <TickerFilterStats inputs={inputs} targetVariableValueType={targetVariableValueType} />}
      {statsType === StatsType.Signal && <SignalStats inputs={inputs} targetVariableValueType={targetVariableValueType} />}
    </>
  );
};

export default Statistics