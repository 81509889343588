import { FetchResult, gql, useMutation } from '@apollo/client';
import Form from 'components/common/Form';
import Label from 'components/common/Form/Label';
import Submit from 'components/common/Form/Submit';
import TextInput from 'components/common/Form/TextInput';
import { UserContext } from 'contexts/UserContext';
import { LoginMutation } from 'graphql/generated';
import { FC, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        email
        name
        admin
      }
    }
  }
`;

interface LoginFormFields {
  email: string;
  password: string;
}

const validationSchema = yup.object({
  email: yup.string().required().email(),
  password: yup.string().required(),
});

const Login: FC = () => {
  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const { user, login } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/scanner');
    }
  });

  const handleSubmit = async (fields: LoginFormFields) => {
    if (!login) return () => { };

    const res: FetchResult<LoginMutation> = await loginMutation({ variables: { ...fields } });

    if (res.data) {
      login(res.data.login.token, res.data.login.user);
      navigate('/scanner');
    }
  }

  return (
    <div className='p-6'>
      <Form<LoginFormFields>
        className="flex flex-col gap-4 max-w-md m-auto"
        validationSchema={validationSchema}
      >
        <div>
          <div className="mb-2 block">
            <Label label="Your email" />
          </div>
          <TextInput field='email' type='email' />
        </div>
        <div>
          <div className="mb-2 block">
            <Label label="Your password" />
          </div>
          <TextInput field="password" type='password' />
        </div>
        <Submit onSubmit={handleSubmit}>
          Login
        </Submit>
      </Form>
    </div>
  );
}

export default Login;