import SignalFiltersForm from "components/Signals/SignalFiltersForm";
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import Modal from "components/common/Modal";
import { Tabs, TabsRef } from "flowbite-react";
import { Signal } from "graphql/generated";
import { FC, useRef, useState } from "react";
import SignalsTab from "./SignalsTab";

interface FilterSignalsModalProps {
  show: boolean;
  onSignalAdded: (signal: Signal) => void;
  onAllSignalsFromFiltersAdded: (filters?: FiltersFormFields) => void;
  addedSignalIds: Set<number>;
  addedAllSignalsFromFilters: boolean;
  onClose: () => void;
}

const FilterSignalsModal: FC<FilterSignalsModalProps> = ({ show, onClose, onSignalAdded, addedSignalIds, onAllSignalsFromFiltersAdded, addedAllSignalsFromFilters }) => {
  const ref = useRef<TabsRef>(null);

  const handleSignalLoad = (inputs: FiltersFormFields, tab?: number) => {
    setInputs(inputs);
    ref.current?.setActiveTab(tab !== undefined ? tab : 1);
  }

  const [inputs, setInputs] = useState<FiltersFormFields | undefined>(undefined);

  return (
    <Modal show={show} onClose={onClose}>
      <Tabs.Group ref={ref}>
        <Tabs.Item title="Filters" active>
          <SignalFiltersForm handleSignalLoad={handleSignalLoad} />
        </Tabs.Item>
        <Tabs.Item title="Tickers" disabled={!inputs}>
          {inputs && <SignalsTab inputs={inputs} onSignalAdded={onSignalAdded} addedSignalIds={addedSignalIds} onAllSignalsFromFiltersAdded={onAllSignalsFromFiltersAdded} closeModal={onClose} addedAllSignalsFromFilters={addedAllSignalsFromFilters} />}
        </Tabs.Item>
      </Tabs.Group>
    </Modal>
  );
};

export default FilterSignalsModal;