import Footer from "components/common/Footer";
import { FC, useEffect } from "react";
import { NavLink } from "react-router-dom";

const Terms: FC = () => {
  useEffect(() => {
    document.title = `Charts and Stats`;
  });

  return (
    <div className='p-10 max-w-5xl mx-auto'>
      <h1 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
        Privacy Policy
      </h1>

      <h2 className="mb-4 text-xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-6">
        1. Data Collection
      </h2>

      <div>
        We collect the following types of data from our users:
        <ul className='list-disc list-inside'>
          <li>Authentication data for account access.</li>
          <li>Website settings for customization.</li>
          <li>Contact information to provide support.</li>
          <li>Usage data to improve our Service.</li>
        </ul>
      </div>

      <h2 className="mb-4 text-xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-6">
        2. Data Collection Methods
      </h2>

      <div>
        We collect data through the use of cookies and local storage technologies. These technologies help us provide and improve our Service, as well as troubleshoot any issues you may encounter.
      </div>

      <h2 className="mb-4 text-xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-6">
        3. Purpose of Data Collection
      </h2>

      <div>
        We collect and process data for the following purposes:
        <ul className='list-disc list-inside'>
          <li>Providing and enhancing our Service.</li>
          <li>Marketing and promotional activities.</li>
          <li>Troubleshooting issues you may encounter while using our Service.</li>
        </ul>
      </div>

      <h2 className="mb-4 text-xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-6">
        4. Contact Information
      </h2>

      <div>
        If you have any questions or concerns about our data collection practices or this Privacy Policy, please contact us at <a href='mailto:stan@chartsandstats.com' className='cursor-pointer text-blue-700 underline'>stan@chartsandstats.com</a>.
      </div>

      <div className='mt-6'>
        This Privacy Policy is an integral part of our <NavLink to='/terms' className='text-blue-700' onClick={() => window.scrollTo(0, 0)}>Terms of Service Agreement</NavLink> and is subject to the same governing law and jurisdiction.
      </div>

      <div className='mt-6'>
        Last Updated: 2023-09-06
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
