import { Dispatch, useEffect, useState } from "react";

export default function useLocalStorageState<T>(defaultValue: T, key: string) {
  const [value, setValue] = useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null && stickyValue !== undefined
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  useEffect(() => {
    if (value !== null && value !== undefined) {
      window.localStorage.setItem(key, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(key);
    }
  }, [key, value]);
  return [value as T, setValue] as [T, Dispatch<T>];
}
