import useOnScreen from "hooks/useOnScreen";
import { FC, ReactNode, useEffect, useState } from "react";

interface DelayRenderUntilVisibleProps {
  children: ReactNode;
}

const DelayRenderUntilVisible: FC<DelayRenderUntilVisibleProps> = ({ children }) => {
  const [visibleAtLeastOnce, setVisibleAtLeastOnce] = useState<boolean>(false);
  const [ref, isVisible] = useOnScreen();

  useEffect(() => {
    if (!visibleAtLeastOnce && isVisible) {
      setVisibleAtLeastOnce(true);
    }
  }, [isVisible]);

  useEffect(() => {
    if (!visibleAtLeastOnce && isVisible) {
      setVisibleAtLeastOnce(true);
    }
  }, [children]);

  return (
    <div ref={ref}>
      {visibleAtLeastOnce && children}
    </div>
  );
};

export default DelayRenderUntilVisible;