import { useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import ChangeDateForm from "components/common/Chart/IntradayChart/changeDateForm";
import Error from 'components/common/Error';
import Form, { FormRef } from "components/common/Form";
import Option from "components/common/Form/Option";
import RadioGroup from "components/common/Form/RadioGroup";
import { TickerDateColumnContext, TickerDateColumnContextType } from "contexts/TickerDateColumnContext";
import { TabsRef } from "flowbite-react";
import { BacktestTrade, BacktestTradeExecution, BarPayload, BarTimespan, Collection, Signal, TickerDateDetailsQuery, TickerDateDetailsQueryVariables } from "graphql/generated";
import { TICKER_DATE_DETAILS_QUERY } from "graphql/queries/tickerDateDetails.query";
import useLocalStorageState from "hooks/useLocalStorageState";
import moment from "moment";
import { FC, RefObject, useEffect, useRef, useState } from "react";
import { NotesFormInput } from "../TickerDateNotesInput/schema";
import Column, { ColumnRef } from "./column";

export enum Tab {
  DailyChart = 'DailyChart',
  IntradayChart = 'IntradayChart',
  Notes = 'Notes',
  Data = 'Data',
  Signals = 'Signals',
  Signal = 'Signal',
  Backtests = 'Backtests',
  Executions = 'Backtest executions',
}

interface TickerDateColumnProps {
  tabs: Tab[];
  ticker: string;
  date: string;
  signalId?: number;
  backtestTradeId?: number;
  collection?: Partial<Collection>;
  hideChangeDateOnError?: boolean;
}

const TickerDateColumns: FC<TickerDateColumnProps> = ({ tabs, ticker, date, collection, signalId, backtestTradeId, hideChangeDateOnError }) => {
  const [selectedSignal, setSelectedSignal] = useState<Signal | undefined>(undefined);
  const [selectedExecution, setSelectedExecution] = useState<BacktestTradeExecution | undefined>(undefined);
  const [selectedBacktestTrade, setSelectedBacktestTrade] = useState<BacktestTrade | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState<string>(date);
  const [wideScreenColumns, setWideScreenColumns] = useLocalStorageState<number>(2, 'wideScreenColumns');

  const firstColumnRef = useRef<ColumnRef>(null);
  const secondColumnRef = useRef<ColumnRef>(null);
  const mobileColumnRef = useRef<ColumnRef>(null);
  const columnRefs = [firstColumnRef, secondColumnRef, mobileColumnRef];

  useEffect(() => {
    setSelectedDate(date);
  }, [date, ticker]);

  const { loading, error, data } = useQuery<TickerDateDetailsQuery, TickerDateDetailsQueryVariables>(TICKER_DATE_DETAILS_QUERY, {
    variables: {
      ticker,
      date: selectedDate,
      collectionId: collection?.id,
      signalId: signalId || -1,
      skipSignal: signalId === undefined,
      backtestTradeId: backtestTradeId || -1,
      skipBacktestTrade: backtestTradeId === undefined,
      intradayBarsInput: {
        ticker: ticker,
        from: selectedDate,
        to: selectedDate,
        adjusted: false,
        timespan: BarTimespan.Minute,
        multiplier: 1,
      },
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <div style={{ minHeight: 820 }}>Loading...</div>
  }

  if (error || !data) {
    Sentry.captureException(error);

    const userMessageError = error?.graphQLErrors.find(e => !!e.extensions.userMessage);
    if (userMessageError?.extensions.userMessage === 'No data') {
      return (
        <div style={{ minHeight: 820 }}>
          <div>No data for {selectedDate}</div>
          {!hideChangeDateOnError && <ChangeDateForm date={selectedDate} onSubmit={setSelectedDate} />}
        </div>
      );
    } else {
      return <Error error={error} style={{ minHeight: 820 }} />
    }
  }

  const handleSignalBarClick = (bar: BarPayload, tabsRef: RefObject<TabsRef>) => {
    columnRefs.forEach(columnRef => {
      const formMethods = columnRef.current?.signalFormRef.current?.formMethods;
      if (formMethods) {
        formMethods.setValue('price', "" + bar.close);
        formMethods.setValue('time', moment.unix(bar.timeSeconds).tz("America/New_York").format('HH:mm'));
      }
    });

    let index = tabs.indexOf(Tab.Signals);
    if (index === -1) index = tabs.indexOf(Tab.Signal);
    tabsRef.current?.setActiveTab(index);
  };

  const handleSignalEditClick = (o?: Signal) => {
    setSelectedSignal(o);

    if (!o) {
      columnRefs.forEach(columnRef => {
        const resetForm = columnRef.current?.signalFormRef.current?.resetForm;
        if (resetForm) resetForm();
      });
    }
  };

  const handleSignalSaved = () => {
    setSelectedSignal(undefined);
    columnRefs.forEach(columnRef => {
      columnRef.current?.dailyChartRef?.current?.dailyChartRef?.current?.widget?.activeChart().refreshMarks();
      columnRef.current?.intradayChartRef?.current?.intradayChartRef?.current?.widget?.activeChart().refreshMarks();
    });
  };

  const handleExecutionBarClick = (bar: BarPayload, tabsRef: RefObject<TabsRef>) => {
    columnRefs.forEach(columnRef => {
      const formMethods = columnRef.current?.executionFormRef.current?.formMethods;
      if (formMethods) {
        formMethods.setValue('price', "" + bar.close);
        formMethods.setValue('time', moment.unix(bar.timeSeconds).tz("America/New_York").format('HH:mm'));
      }
    });

    if (tabs.indexOf(Tab.Executions) !== -1) {
      tabsRef.current?.setActiveTab(tabs.indexOf(Tab.Executions));
    }
  };

  const handleExecutionEditClick = (o?: BacktestTradeExecution) => {
    setSelectedExecution(o);

    if (!o) {
      columnRefs.forEach(columnRef => {
        const resetForm = columnRef.current?.executionFormRef.current?.resetForm;
        if (resetForm) resetForm();
      });
    }
  };

  const handleExecutionSaved = () => {
    setSelectedExecution(undefined);
    columnRefs.forEach(columnRef => {
      columnRef.current?.dailyChartRef?.current?.dailyChartRef?.current?.widget?.activeChart().refreshMarks();
      columnRef.current?.intradayChartRef?.current?.intradayChartRef?.current?.widget?.activeChart().refreshMarks();
    });
  };

  const handleBacktestTradeEditClick = (o?: BacktestTrade) => {
    setSelectedBacktestTrade(o);
  };

  const handleBacktestTradeSaved = () => { };

  const handleNotesChanged = (value: any, formRef: RefObject<FormRef<NotesFormInput>>) => {
    columnRefs.forEach(columnRef => {
      if (!columnRef.current?.notesRef.current?.formRef.current) return;

      if (columnRef.current?.notesRef.current?.formRef !== formRef) {
        columnRef.current?.notesRef.current?.formRef.current.setValue('notes', value);
      }
    });
  };

  const handleDailyChartStateChanged = (state: object, id: string) => {
    // columnRefs.forEach(columnRef => {
    //   if (!columnRef.current?.dailyChartRef?.current?.dailyChartRef.current) return;

    //   const columnRefId = columnRef.current?.dailyChartRef?.current?.dailyChartRef.current.id;
    //   if (columnRefId !== id) {
    //     columnRef.current?.dailyChartRef?.current?.dailyChartRef.current.widget?.load(state);
    //   }
    // });
  };

  const handleIntradayChartStateChanged = (state: object, id: string) => {
    // columnRefs.forEach(columnRef => {
    //   if (!columnRef.current?.intradayChartRef?.current?.intradayChartRef.current) return;

    //   const columnRefId = columnRef.current?.intradayChartRef?.current?.intradayChartRef.current.id;
    //   if (columnRefId !== id) {
    //     columnRef.current?.intradayChartRef?.current?.intradayChartRef.current.widget?.load(state);
    //   }
    // });
  };

  const refreshMarks = () => {
    columnRefs.forEach(columnRef => {
      const dailyChartWidget = columnRef.current?.dailyChartRef?.current?.dailyChartRef.current?.widget;
      const intradayChartWidget = columnRef.current?.intradayChartRef?.current?.intradayChartRef.current?.widget;
      if (dailyChartWidget) {
        dailyChartWidget.activeChart().clearMarks();
        dailyChartWidget.activeChart().refreshMarks();
      }
      if (intradayChartWidget) {
        intradayChartWidget.activeChart().clearMarks();
        intradayChartWidget.activeChart().refreshMarks();
      }
    });
  };

  const hasSignalsTab = tabs.indexOf(Tab.Signal) !== -1 || tabs.indexOf(Tab.Signals) !== -1;
  const hasBacktestsTab = tabs.indexOf(Tab.Backtests) !== -1;
  const hasExecutionsTab = tabs.indexOf(Tab.Executions) !== -1;

  const context: TickerDateColumnContextType = {
    userTickerDate: data!.userTickerDate,
    selectedSignal,
    selectedExecution,
    selectedBacktestTrade,
    collection,
    features: data!.features,
    targetVariables: data!.targetVariables,
    intradayBars: data.intradayBars,
    signal: data!.signal,
    backtestTrade: data!.backtestTrade,
    handleSignalBarClick: hasSignalsTab ? handleSignalBarClick : undefined,
    handleSignalEditClick: hasSignalsTab ? handleSignalEditClick : undefined,
    handleSignalSaved: hasSignalsTab ? handleSignalSaved : undefined,
    handleExecutionBarClick: hasExecutionsTab ? handleExecutionBarClick : undefined,
    handleExecutionEditClick: hasBacktestsTab || hasExecutionsTab ? handleExecutionEditClick : undefined,
    handleExecutionSaved: hasBacktestsTab || hasExecutionsTab ? handleExecutionSaved : undefined,
    handleBacktestTradeEditClick: hasBacktestsTab ? handleBacktestTradeEditClick : undefined,
    handleBacktestTradeSaved: hasBacktestsTab ? handleBacktestTradeSaved : undefined,
    handleNotesChanged,
    handleDailyChartStateChanged,
    handleIntradayChartStateChanged,
    refreshMarks,
    setSelectedDate,
  };

  const firstColumn = (
    <Column
      tabs={tabs}
      defaultTab={Tab.DailyChart}
      ref={firstColumnRef}
    />
  );

  const secondColumn = (
    <Column
      tabs={tabs}
      defaultTab={Tab.IntradayChart}
      ref={secondColumnRef}
    />
  );

  const mobileColumn = (
    <Column
      tabs={tabs}
      defaultTab={Tab.DailyChart}
      ref={mobileColumnRef}
    />
  );

  const columnsOptions: Option[] = [{ value: 1, label: '1 column' }, { value: 2, label: '2 columns' }];
  const handleColumnsChanged = (option: Option) => setWideScreenColumns(option.value as number);

  return (
    <TickerDateColumnContext.Provider value={context}>
      <h3 className="text-3xl font-bold dark:text-white mb-6">{ticker} {selectedDate}</h3>
      <Form className='hidden xl:block mb-8' defaultValues={{ wideScreenColumns: { label: "" + wideScreenColumns, value: wideScreenColumns } }}>
        <RadioGroup field='wideScreenColumns' options={columnsOptions} inline onChange={handleColumnsChanged} />
      </Form>
      <div className={`grid gap-4 hidden xl:grid ${wideScreenColumns === 1 ? 'grid-cols-1' : 'grid-cols-2'}`}>
        {firstColumn}
        {wideScreenColumns === 2 && secondColumn}
      </div>
      <div className='block xl:hidden'>
        {mobileColumn}
      </div>
    </TickerDateColumnContext.Provider>
  )
};

export default TickerDateColumns;
