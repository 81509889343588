import { apolloClient } from 'App';
import Button, { ButtonStyle } from 'components/common/Button';
import { QueryTickerDatesFromFiltersArgs, ScannerFiltersInput, TickerDate, TickerDatesFromFiltersQuery } from "graphql/generated";
import { TICKER_DATES_FROM_FILTERS_QUERY } from 'graphql/queries/tickerDatesFromFilters.query';
import { FC } from "react";
import { ViewingTickerDate } from "routes/Scanner";

interface TickerDatesNextPrevControlsProps {
  variables: ScannerFiltersInput;
  viewingTickerDate: ViewingTickerDate;
  onViewClick: (o: TickerDate) => void;
}

const TickerDatesNextPrevControls: FC<TickerDatesNextPrevControlsProps> = ({ variables, viewingTickerDate, onViewClick }) => {
  const handleClick = async (take: number) => {
    const result = await apolloClient.query<TickerDatesFromFiltersQuery, QueryTickerDatesFromFiltersArgs>({
      query: TICKER_DATES_FROM_FILTERS_QUERY,
      variables: {
        input: variables,
        take: take,
        cursor: {
          id: viewingTickerDate.id,
          date: viewingTickerDate?.date,
        },
      },
    });

    if (result.data?.tickerDatesFromFilters && result.data.tickerDatesFromFilters.length >= 1) {
      const tickerDate = result.data.tickerDatesFromFilters[0].tickerDate;
      onViewClick(tickerDate);
    }
  };

  const handlePreviousTickerClick = () => handleClick(-1);
  const handleNextTickerClick = () => handleClick(1);

  return (
    <>
      <Button onClick={handlePreviousTickerClick} style={ButtonStyle.Alternative} className='mr-2'>Previous ticker</Button>
      <Button onClick={handleNextTickerClick} style={ButtonStyle.Alternative}>Next ticker</Button>
    </>
  );
};

export default TickerDatesNextPrevControls;