import { useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import Button, { ButtonStyle } from "components/common/Button";
import Error from 'components/common/Error';
import Form from "components/common/Form";
import SearchInput from "components/common/Form/SearchInput";
import { Column } from "components/common/Table";
import PaginatedTable from "components/common/Table/PaginatedTable";
import { Feature, FeatureType, FeaturesQuery, FeaturesQueryVariables } from "graphql/generated";
import { FEATURES_QUERY } from "graphql/queries/features.query";
import { FC, useState } from "react";
import * as yup from 'yup';

interface FeaturesTableProps {
  featureIds: Set<number>;
  allFeaturesAdded: boolean;
  onAllFeaturesAdded: (ids?: number[]) => void;
  onFeatureAdded: (id: number) => void;
}

interface SearchFormInputs {
  input: string;
}

const searchValidationSchema = yup.object({
  input: yup.string().required(),
}).required();

const FeaturesTable: FC<FeaturesTableProps> = ({ featureIds, allFeaturesAdded, onAllFeaturesAdded, onFeatureAdded }) => {
  const { loading, error, data } = useQuery<FeaturesQuery, FeaturesQueryVariables>(FEATURES_QUERY);
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const columns: Column<Feature>[] = [
    {
      key: 'name',
      header: () => 'Name',
      cell: (o: Feature) => o.name,
    },
    {
      key: 'type',
      header: () => 'Type',
      cell: (o: Feature) => o.type === FeatureType.TickerDate ? 'Daily data' : "Signal data",
    },
    {
      key: 'Value type',
      header: () => 'Value type',
      cell: (o: Feature) => o.valueType,
    },
    ...(!allFeaturesAdded ? [{
      key: 'add',
      header: () => '',
      cell: (o: Feature) => {
        if (featureIds.has(o.id)) {
          return (
            <Button onClick={() => onFeatureAdded(o.id)} style={ButtonStyle.Alternative}>Remove</Button>
          );
        } else {
          return (
            <Button onClick={() => onFeatureAdded(o.id)} style={ButtonStyle.Primary}>Add</Button>
          );
        }
      },
    }] : []),
  ];

  const features = data.features;
  const displayedFeatures = features.filter(f => !searchInput || searchInput === '' || f.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1);

  const handleAllFeaturesAdded = () => {
    onAllFeaturesAdded(features.map(x => x.id));
  };

  const handleUndoAllFeaturesAdded = () => {
    onAllFeaturesAdded();
  };

  return (
    <>
      {allFeaturesAdded ? (
        <Button style={ButtonStyle.Alternative} onClick={handleUndoAllFeaturesAdded}>Undo select all</Button>
      ) : (
        <Button style={ButtonStyle.Primary} onClick={handleAllFeaturesAdded}>Select all</Button>
      )}
      <Form<SearchFormInputs> validationSchema={searchValidationSchema}>
        <SearchInput<SearchFormInputs> field='input' placeholder="Search..." onChange={input => setSearchInput(input)} />
      </Form>
      <PaginatedTable<Feature> columns={columns} data={displayedFeatures} pageSize={5} key={`paginated-features-table-${searchInput}`} />
    </>
  );
};

export default FeaturesTable;