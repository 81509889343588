import { ErrorMessage } from '@hookform/error-message';
import { FC } from 'react';

interface ErrorProps {
  field: string;
}

const Error: FC<ErrorProps> = ({ field }) => {
  return (
    <ErrorMessage
      name={field}
      render={({ message }) => <p className='text-red-700 text-sm'>{message}</p>}
    />
  )
}

export default Error;
