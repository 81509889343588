import { FC } from "react";

const NotFound: FC = () => {
  return (
    <div className='p-6'>
      <p>Page not found</p>
    </div>
  );
};

export default NotFound;