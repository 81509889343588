import { FiltersFormFields } from 'components/common/FiltersForm/schema';
import { FilterOperator } from 'graphql/generated';
import React from 'react';

export type FilterSearchParamsType = {
  id: number;
  operator?: FilterOperator;
  value: number[];
}

export type FiltersSearchParamsType = {
  tickerIds?: number[];
  tickerNames?: string[];
  assetTypes?: string[];
  date?: string[];
  dateOperator?: FilterOperator;
  signalTime?: string[];
  signalTimeOperator?: FilterOperator;
  featureFilters?: FilterSearchParamsType[];
  targetVariableFilters?: FilterSearchParamsType[];
}

export type FiltersSearchParamsContextType = {
  scannerFiltersId?: number;
  scannerFiltersName?: string;
  filters?: FiltersSearchParamsType[];
  cursorId?: number;
  cursorDate?: string;
  take?: number;
  viewingTickerDateId?: number;
  viewingTickerDateTicker?: string;
  viewingTickerDateDate?: string;
  activeTab?: number;
  setViewingTicker: (id?: number, ticker?: string, date?: string) => void;
  setFilters: (fields?: FiltersFormFields) => void;
  setCursor: (id?: number, date?: string) => void;
  setTake: (take?: number) => void;
  setScannerFilters: (id?: number, name?: string) => void;
  setActiveTab: (tab?: number) => void;
  reset: () => void;
};


const defaultValue: FiltersSearchParamsContextType = {
  setViewingTicker: () => {},
  setFilters: () => {},
  setCursor: () => {},
  setTake: () => {},
  setScannerFilters: () => {},
  setActiveTab: () => {},
  reset: () => {},
}

const FiltersSearchParamsContext = React.createContext(defaultValue);

export { FiltersSearchParamsContext };
