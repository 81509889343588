import Option from 'components/common/Form/Option';
import { FC, useId } from "react";
import { Controller, useFormContext } from 'react-hook-form';

interface RadioGroupProps {
  field: string;
  options: Option[];
  inline?: boolean;
  className?: string;
  onChange?: (option: Option) => void;
}

interface InputElementProps {
  field: string;
  value: Option;
  option: Option;
  wrappedOnChange: (o: Option) => void;
}

const InputElement: FC<InputElementProps> = ({field, value, option, wrappedOnChange}) => {
  const id = useId();
  return (
    <div className="flex items-center" >
      <input checked={value?.value == option.value} onChange={() => wrappedOnChange(option)} type="radio" value={option.value} name={`${field}-${id}`} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
      <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{option.label}</label>
    </div>
  )
};

const RadioGroup: FC<RadioGroupProps> = ({ field, options, inline, className, onChange }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={field}
      render={({ field: { onChange: controllerOnChange, value } }) => {

        const wrappedOnChange = (o: Option) => {
          controllerOnChange(o);
          if (onChange) onChange(o);
        };

        return (
          <div className={`${inline ? 'flex gap-2' : ''} ${className}`}>
            {options.map((option) => <InputElement field={field} value={value} option={option} wrappedOnChange={wrappedOnChange} key={option.value} />)}
          </div>
        );
      }}
    />
  );
};

export default RadioGroup;
