import Footer from "components/common/Footer";
import { FC, useEffect } from "react";
import { NavLink } from "react-router-dom";

const Terms: FC = () => {
  useEffect(() => {
    document.title = `Charts and Stats - Terms of Service`;
  });

  return (
    <div className='p-10 max-w-5xl mx-auto'>
      <h1 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
        Terms of Service Agreement
      </h1>

      <div>
        This Terms of Service Agreement ("Agreement") is entered into between GROWTH CONSULTING OÜ, a company registered in Estonia under registry code 14314959, and VAT number EE101998370, with its principal place of business at Sepapaja tn 6, 15551 Tallinn, Estonia ("Company" or "We"), and you, the user ("User" or "You"). This Agreement governs your use of the "Charts and Stats" service ("Service") provided by the Company.
      </div>

      <h2 className="mb-4 text-xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-6">
        1. Acceptance of Terms
      </h2>

      <div>
        By accessing or using our Service, you agree to be bound by and comply with these Terms of Service. If you do not agree to these terms, please do not use our Service.
      </div>

      <h2 className="mb-4 text-xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-6">
        2. Description of Service
      </h2>

      <div>
        Our Service, "Charts and Stats," provides data and backtesting tools for traders. You may download and export data obtained from Charts and Stats for your personal use, but you are prohibited from re-distributing it in any form.
      </div>

      <h2 className="mb-4 text-xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-6">
        3. User Registration
      </h2>

      <div>
        To access certain features of our Service, you may be required to register for an account. You agree to provide accurate and up-to-date information during the registration process and to keep your account information secure.
      </div>

      <h2 className="mb-4 text-xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-6">
        4. Privacy and Data Collection
      </h2>

      <div>
        We collect and process data in accordance with our <NavLink to='/privacy' className='text-blue-700' onClick={() => window.scrollTo(0, 0)}>Privacy Policy</NavLink>, which is incorporated by reference into this Agreement. By using our Service, you consent to our data collection and processing practices.
      </div>

      <h2 className="mb-4 text-xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-6">
        5. Intellectual Property
      </h2>

      <div>
        All content and materials provided through our Service, including but not limited to text, graphics, logos, and software, are the property of GROWTH CONSULTING OÜ and protected by intellectual property laws. You may not reproduce, distribute, or create derivative works without our prior written consent.
      </div>

      <h2 className="mb-4 text-xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-6">
        6. Governing Law
      </h2>

      <div>
        This Agreement shall be governed by and construed in accordance with the laws of Estonia. Any disputes arising from this Agreement shall be subject to the exclusive jurisdiction of the courts of Estonia.
      </div>

      <h2 className="mb-4 text-xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-6">
        7. Subscription Terms
      </h2>

      <div>
        If you subscribe to our service, please be aware that charged subscriptions are not refundable. If you encounter any issues with your subscription, please contact us at <a href='mailto:stan@chartsandstats.com' className='cursor-pointer text-blue-700 underline'>stan@chartsandstats.com</a> for assistance.
      </div>

      <h2 className="mb-4 text-xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-6">
        8. Termination
      </h2>

      <div>
        We reserve the right to terminate or suspend your access to our Service at our discretion, with or without cause and with or without notice.
      </div>

      <h2 className="mb-4 text-xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-6">
        9. Changes to Terms
      </h2>

      <div>
        We reserve the right to modify these Terms of Service at any time. Updated terms will be posted on our website, and it is your responsibility to review them periodically.
      </div>

      <h2 className="mb-4 text-xl tracking-tight font-extrabold text-gray-900 dark:text-white mt-6">
        10. Contact Us
      </h2>

      <div>
        If you have any questions or concerns about this Agreement or our Service, please contact us at <a href='mailto:stan@chartsandstats.com' className='cursor-pointer text-blue-700 underline'>stan@chartsandstats.com</a>.
      </div>

      <div className='mt-10'>
        By using our Service, you acknowledge that you have read, understood, and agreed to the terms and conditions outlined in this Agreement.
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
