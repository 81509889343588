import { useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import Error from 'components/common/Error';
import Table, { Column } from "components/common/Table";
import { BacktestQueryVariables, BacktestStatsQuery, FeatureValueType } from "graphql/generated";
import { BACKTEST_STATS_QUERY } from "graphql/queries/backtestStats.query";
import moment from "moment";
import { FC } from "react";
import { formatRawValue, formatRiskValue } from "util/valueFormat";

interface StatRow {
  id: string;
  name: string;
  value: string;
}

interface BacktestStatsProps {
  id: number;
}

const BacktestStats: FC<BacktestStatsProps> = ({ id }) => {
  const { loading, error, data } = useQuery<BacktestStatsQuery, BacktestQueryVariables>(BACKTEST_STATS_QUERY, {
    variables: { id },
  });

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  if (data.backtestStats.numberOfTrades === 0 || !data.backtestStats.statsRisk) {
    return <div>No trades</div>;
  }

  const columns: Column<StatRow>[] = [
    {
      key: 'statistic',
      header: () => 'Statistic',
      cell: (o: StatRow) => o.name,
    },
    {
      key: 'value',
      header: () => 'Value',
      cell: (o: StatRow) => o.value,
    },
  ];

  const tableData: StatRow[] = [
    {
      id: 'numberOfTrades',
      name: 'Number of trades',
      value: formatRawValue(data.backtestStats.numberOfTrades, FeatureValueType.Number),
    },
    ...(data.backtestStats.numberOfTradesWithErrors > 0 ? [{
      id: 'numberOfTradesWithErrors',
      name: 'Number of trades with errors',
      value: formatRawValue(data.backtestStats.numberOfTradesWithErrors, FeatureValueType.Number),
    }] : []),
    {
      id: 'winRate',
      name: 'Win rate',
      value: formatRawValue(data.backtestStats.winRate, FeatureValueType.Percentage),
    },
    {
      id: 'earliestTradeDate',
      name: 'Earliest trade date',
      value: moment(data.backtestStats.earliestTradeDate).format('YYYY-MM-DD'),
    },
    {
      id: 'latestTradeDate',
      name: 'Latest trade date',
      value: moment(data.backtestStats.latestTradeDate).format('YYYY-MM-DD'),
    },
    {
      id: 'totalNetProfit',
      name: 'Net profit',
      value: formatRiskValue(data.backtestStats.statsRisk.totalNetProfit),
    },
    {
      id: 'avgWin',
      name: 'Avg win',
      value: formatRiskValue(data.backtestStats.statsRisk.avgWin),
    },
    {
      id: 'avgLoss',
      name: 'Avg loss',
      value: formatRiskValue(data.backtestStats.statsRisk.avgLoss),
    },
    {
      id: 'largestWin',
      name: 'Largest win',
      value: formatRiskValue(data.backtestStats.statsRisk.largestWin),
    },
    {
      id: 'largestLoss',
      name: 'Largest loss',
      value: formatRiskValue(data.backtestStats.statsRisk.largestLoss),
    },
    {
      id: 'profitFactor',
      name: 'Profit factor',
      value: (data.backtestStats.statsRisk.profitFactor === null || data.backtestStats.statsRisk.profitFactor === undefined) ? "" : formatRawValue(data.backtestStats.statsRisk.profitFactor, FeatureValueType.Number),
    },
  ];

  return (
    <Table<StatRow> columns={columns} data={tableData} />
  );
};

export default BacktestStats;
