import { UserPayload } from 'graphql/generated';
import React from 'react';

const defaultValue: {
  user?: UserPayload,
  login: (token: string, user: UserPayload) => void,
  logout: () => void
} = {
  user: undefined,
  login: () => { },
  logout: () => { },
};

const UserContext = React.createContext(defaultValue);

export { UserContext };
