import SignalForm, { SignalFormRef } from "components/Signals/SignalForm";
import SignalTargetVariables from "components/Signals/SignalTargetVariables";
import SignalsTable from "components/Signals/SignalsTable";
import { useTickerDateColumnContext } from "contexts/TickerDateColumnContext";
import { FC, RefObject, useState } from "react";

interface SignalsTabProps {
  signalFormRef: RefObject<SignalFormRef>;
  isPublic?: boolean;
}

const SignalsTab: FC<SignalsTabProps> = ({ signalFormRef, isPublic }) => {
  const [showTargetVariables, setShowTargetVariables] = useState<boolean>(false);
  const context = useTickerDateColumnContext();

  if (showTargetVariables) {
    return (
      <div style={{ minHeight: 720 }}>
        <div className='font-bold text-lg'>Signal future returns</div>
        <div onClick={() => setShowTargetVariables(false)} className='cursor-pointer text-blue-700 text-sm mt-2'>Back to Signals</div>
        <SignalTargetVariables
          signalId={context.selectedSignal!.id}
          targetVariables={context.targetVariables}
          className="mt-10"
        />
      </div>
    );
  } else {
    return (
      <div style={{ minHeight: 720 }}>
        <SignalsTable
          editingSignal={context.selectedSignal}
          onEditClick={context.handleSignalEditClick!}
          queryVariables={({ tickerDateId: context.userTickerDate.TickerDate.id, collectionId: context.collection?.id })}
        />
        <SignalForm
          tickerDate={context.userTickerDate.TickerDate}
          collection={context.collection}
          intradayBars={context.intradayBars}
          features={context.features}
          selectedSignal={context.selectedSignal}
          ref={signalFormRef}
          onSave={context.handleSignalSaved}
          isPublic={isPublic || false}
          key={context.selectedSignal?.id}
        />
        {context.selectedSignal && <div onClick={() => setShowTargetVariables(true)} className='cursor-pointer text-blue-700 mt-10 mr-2 text-sm'>Show future returns for the selected signal</div>}
      </div>
    );
  }
};

export default SignalsTab;