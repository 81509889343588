import { useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import { scannerFormFieldsToInput } from "components/Scanner/util/scannerFormFieldsToInput";
import Error from 'components/common/Error';
import FeatureValuesDistributionChart from "components/common/FeatureValuesStats/FeatureValuesDistributionChart";
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import { Feature, FeatureValueType, SignalFeatureValuesFromFiltersQuery, SignalFeatureValuesFromFiltersQueryVariables, TargetVariableValueType } from "graphql/generated";
import { SIGNAL_FEATURE_VALUES_FROM_FILTERS_QUERY } from "graphql/queries/signalFeatureValuesFromFilters.query";
import { FC } from "react";

interface SignalFeatureStatsProps {
  inputs: FiltersFormFields;
  feature: Feature;
  minValue?: number;
  maxValue?: number;
  targetVariableValueType: TargetVariableValueType;
}

const SignalFeatureStats: FC<SignalFeatureStatsProps> = ({ inputs, feature, minValue, maxValue, targetVariableValueType }) => {
  const inputVariables = scannerFormFieldsToInput(inputs);

  const { loading, error, data } = useQuery<SignalFeatureValuesFromFiltersQuery, SignalFeatureValuesFromFiltersQueryVariables>(SIGNAL_FEATURE_VALUES_FROM_FILTERS_QUERY, {
    variables: {
      filters: inputVariables,
      featureId: feature.id,
      minValue: feature.valueType !== FeatureValueType.Tag ? minValue : undefined,
      maxValue: feature.valueType !== FeatureValueType.Tag ? maxValue : undefined,
      targetVariableValueType,
    }
  });

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const values = data.signalFeatureValuesFromFilters.values;

  return (
    <FeatureValuesDistributionChart feature={feature} values={values} stats={data.signalFeatureValuesFromFilters.stats} />
  );
};

export default SignalFeatureStats;