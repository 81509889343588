import Option from 'components/common/Form/Option';
import * as yup from 'yup';

export interface FeatureSetFormInputsProps {
  name: string;
  features: { [key: string]: Option };
  updating?: boolean;
  selectedFeatureSetId?: number;
  error?: string;
}

const mapRules = (map: any, rule: any) => {
  if (!map) return {};
  return Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {})
};

export const validationSchema = yup.object({
  name: yup.string().required('Enter name'),
  features: yup.lazy(map => yup.object(mapRules(map, yup.object({
    label: yup.string().required(),
    value: yup.number().required('Select data point'),
  })))),
  updating: yup.bool(),
  selectedFeatureSetId: yup.number(),
}).required();