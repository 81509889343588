import { FC, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { components, MultiValueProps } from "react-select";

const DragItemTypes = {
  LABEL: 'LABEL',
};

interface SortableItemProps {
  index: number;
  swapItems: (dragIndex: number, hoverIndex: number) => void;
}

const SortableMultiValue: FC<SortableItemProps & MultiValueProps<unknown, true>> = (props) => {
  const { index, swapItems } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<any, unknown, { handlerId: string | symbol }>({
    accept: DragItemTypes.LABEL,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()!,
      };
    },
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      // implement your own behaviour, below example taken from http://gaearon.github.io/react-dnd/examples-sortable-simple.html
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // when you want to swap items run
      swapItems(dragIndex, hoverIndex);

      // note: we're mutating the monitor item here!
      monitor.getItem().index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DragItemTypes.LABEL,
    item: { index, ref },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const opacityClass = isDragging ? 'opacity-0' : 'opacity-100';

  return (
    <div
      ref={ref}
      onMouseDown={(e) => e.stopPropagation()}
      data-handler-id={handlerId}
      className={opacityClass}
      style={{ margin: 2 }}
    >
      <components.MultiValue {...props} innerProps={{ style: { margin: 0 } }} />
    </div>
  );
};

export default SortableMultiValue;