import Option from 'components/common/Form/Option';

export const ASSET_TYPES: { [key: string]: string } = {
  CS: 'Common Stock',
  PFD: 'Preferred Stock',
  WARRANT: 'Warrant',
  RIGHT: 'Rights',
  BOND: 'Corporate Bond',
  ETF: 'Exchange Traded Fund',
  ETN: 'Exchange Traded Note',
  ETV: 'Exchange Traded Vehicle',
  SP: 'Structured Product',
  ADRC: 'American Depository Receipt Common',
  ADRP: 'American Depository Receipt Preferred',
  ADRW: 'American Depository Receipt Warrants',
  ADRR: 'American Depository Receipt Rights',
  FUND: 'Fund',
  BASKET: 'Basket',
  UNIT: 'Unit',
  LT: 'Liquidating Trust',
  OS: 'Ordinary Shares',
  GDR: 'Global Depository Receipts',
  OTHER: 'Other Security Type',
  NYRS: 'New York Registry Shares',
  AGEN: 'Agency Bond',
  EQLK: 'Equity Linked Bond',
  ETS: 'Single-security ETF'
};

export const ASSET_TYPE_OPTIONS: Option[] = [
  { value: 'CS', label: ASSET_TYPES['CS'] },
  { value: 'PFD', label: ASSET_TYPES['PFD'] },
  { value: 'WARRANT', label: ASSET_TYPES['WARRANT'] },
  { value: 'RIGHT', label: ASSET_TYPES['RIGHT'] },
  { value: 'BOND', label: ASSET_TYPES['BOND'] },
  { value: 'ETF', label: ASSET_TYPES['ETF'] },
  { value: 'ETN', label: ASSET_TYPES['ETN'] },
  { value: 'ETV', label: ASSET_TYPES['ETV'] },
  { value: 'SP', label: ASSET_TYPES['SP'] },
  { value: 'ADRC', label: ASSET_TYPES['ADRC'] },
  { value: 'ADRP', label: ASSET_TYPES['ADRP'] },
  { value: 'ADRW', label: ASSET_TYPES['ADRW'] },
  { value: 'ADRR', label: ASSET_TYPES['ADRR'] },
  { value: 'FUND', label: ASSET_TYPES['FUND'] },
  { value: 'BASKET', label: ASSET_TYPES['BASKET'] },
  { value: 'UNIT', label: ASSET_TYPES['UNIT'] },
  { value: 'LT', label: ASSET_TYPES['LT'] },
  { value: 'OS', label: ASSET_TYPES['OS'] },
  { value: 'GDR', label: ASSET_TYPES['GDR'] },
  { value: 'OTHER', label: ASSET_TYPES['OTHER'] },
  { value: 'NYRS', label: ASSET_TYPES['NYRS'] },
  { value: 'AGEN', label: ASSET_TYPES['AGEN'] },
  { value: 'EQLK', label: ASSET_TYPES['EQLK'] },
  { value: 'ETS', label: ASSET_TYPES['ETS'] }
];
