import { ApolloQueryResult, gql } from "@apollo/client";
import { apolloClient } from "App";
import { GetMarksCallback, LibrarySymbolInfo, Mark, MarkConstColors, ResolutionString } from "charting_library";
import { ChartExecutionsQuery, ChartExecutionsQueryVariables, ChartSignalsQuery, ChartSignalsQueryVariables } from "graphql/generated";
import moment from "moment";
import 'moment-timezone';
import TickerDateDatafeed from "..";

const CHART_SIGNALS_QUERY = gql`
  query ChartSignals($ticker: String!, $from: Int!, $to: Int!, $collectionId: Int) {
    chartSignals(ticker: $ticker, from: $from, to: $to, collectionId: $collectionId) {
      signalId
      time
      name
      price
      tags
    }
  }
`;

const CHART_EXECUTIONS_QUERY = gql`
  query ChartExecutions($ticker: String!, $from: Int!, $to: Int!, $backtestTradeId: Int!, $collectionId: Int) {
    chartExecutions(ticker: $ticker, from: $from, to: $to, backtestTradeId: $backtestTradeId, collectionId: $collectionId) {
      executionId
      backtestTradeId
      type
      positionRiskSize
      riskPrice
      time
      price
      signalId
    }
  }
`;

const roundTimestamp = (timestamp: number, resolution: ResolutionString) => {
  const multiplier = Number(resolution);

  if (Number.isNaN(multiplier)) {
    return timestamp;
  }

  const rounding = moment.duration(multiplier, 'minutes' as moment.unitOfTime.DurationConstructor);
  const result = moment(Math.floor((+timestamp * 1000) / +rounding) * +rounding).unix();
  return result;
};

export const getMarks = async (
  datafeed: TickerDateDatafeed,
  symbolInfo: LibrarySymbolInfo,
  from: number,
  to: number,
  onDataCallback: GetMarksCallback<Mark>,
  resolution: ResolutionString
) => {
  const ticker = symbolInfo.ticker!;
  const promises = [];
  const chartSignalsQuery = apolloClient.query<ChartSignalsQuery, ChartSignalsQueryVariables>({
    query: CHART_SIGNALS_QUERY,
    variables: {
      ticker,
      from,
      to,
      collectionId: datafeed.collection?.id,
    },
    fetchPolicy: 'network-only',
  });
  promises.push(chartSignalsQuery);

  if (datafeed.backtestTrade) {
    const chartExecutionsQuery = apolloClient.query<ChartExecutionsQuery, ChartExecutionsQueryVariables>({
      query: CHART_EXECUTIONS_QUERY,
      variables: {
        ticker,
        from,
        to,
        collectionId: datafeed.collection?.id,
        backtestTradeId: datafeed.backtestTrade?.id!,
      },
      fetchPolicy: 'network-only',
    });
    promises.push(chartExecutionsQuery);
  }
  
  const promisesRes = await Promise.all(promises);
  const res = promisesRes[0] as ApolloQueryResult<ChartSignalsQuery>;
  const executionsRes = promisesRes[1] as ApolloQueryResult<ChartExecutionsQuery>;

  if (res.error || (executionsRes !== undefined && executionsRes.error)) {
    onDataCallback([]);
    return;
  }

  let marks: Mark[] = [];

  const signalMarks = res.data.chartSignals.map(s => {
    const timeFormat = s.time % 60 === 0 ? 'HH:mm' : 'HH:mm:ss';
    return {
      id: `signal-${ticker}-${s.signalId}`,
      time: roundTimestamp(s.time, resolution),
      color: (s.signalId === datafeed.selectedSignal?.id ? 'yellow' : 'blue') as MarkConstColors,
      label: 'Signal',
      text: `Signal: $${s.price} ${moment.utc(s.time * 1000).tz('America/New_York').format(timeFormat)}${s.name ? ' ' + s.name : ''}${s.tags.length > 0 ? ` Tags: ${s.tags.join(', ')}`: ''}`,
      labelFontColor: s.signalId === datafeed.selectedSignal?.id ? 'yellow' : 'blue',
      minSize: 5,
    }
  });

  marks.push(...signalMarks);

  if (executionsRes) {
    const executionMarks = executionsRes.data.chartExecutions.map(s => {
      const timeFormat = s.time % 60 === 0 ? 'HH:mm' : 'HH:mm:ss';
      return {
        id: `execution-${ticker}-${s.executionId}`,
        time: roundTimestamp(s.time, resolution),
        color: 'yellow' as MarkConstColors,
        label: 'Execution',
        text: `Execution: $${s.price} ${moment.utc(s.time * 1000).tz('America/New_York').format(timeFormat)} ${s.type} ${s.positionRiskSize}`,
        labelFontColor: 'yellow',
        minSize: 5,
      }
    });
  
    marks.push(...executionMarks);
  }

  if (['60', '30', '15', '5', '2', '1'].includes(resolution)) {
    const intradayDateLimit = moment.utc(datafeed.tickerDate.date).add(2, 'days');
    marks = marks.filter(m => moment.unix(m.time).isBefore(intradayDateLimit));
  }

  onDataCallback(marks);
};
