import { ReactNode } from "react";
import Button, { ButtonStyle } from "../Button";
import FooterRow from './FooterRow';
import Header from "./Header";
import Row from "./Row";

export type ObjectWithId = {
  id: string | number;
}

export type Column<T extends ObjectWithId> = {
  key: string,
  header: () => ReactNode,
  cell: (object: T, rowIndex: number, columnIndex: number) => ReactNode,
  textColor?: (object: T) => string;
  backgroundColor?: (object: T) => string;
};

interface TableProps<T extends ObjectWithId> {
  columns: Column<T>[];
  data: T[];
  onClickNext?: () => void;
  footerData?: T[];
  onClickPrevious?: () => void;
}

const Table = <T extends ObjectWithId>({ columns, data, onClickNext, footerData, onClickPrevious }: TableProps<T>) => {
  return (
    <div>
      <div className="relative shadow-md sm:rounded-lg my-5">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <Header columns={columns} />
          <tbody>
            {data.map((o, index) => <Row columns={columns} object={o} rowIndex={index} key={o.id} />)}
          </tbody>

          {footerData && (
            <tfoot>
              {footerData.map((o, index) => <FooterRow columns={columns} object={o} rowIndex={data.length + index} key={o.id} />)}
            </tfoot>
          )}
        </table>
      </div>
      <div className="flex justify-center select-none gap-2">
        <Button onClick={onClickPrevious} style={ButtonStyle.Alternative} className={`inline-flex items-center ${onClickPrevious ? 'visible' : 'invisible'}`}>
          Previous
        </Button>

        <Button onClick={onClickNext} style={ButtonStyle.Alternative} className={`inline-flex items-center ${onClickNext ? 'visible' : 'invisible'}`}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default Table;