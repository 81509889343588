import { gql, useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import Error from 'components/common/Error';
import FeatureValuesDistributionChart from "components/common/FeatureValuesStats/FeatureValuesDistributionChart";
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import { Feature, FeatureValueType, TargetVariableValueType, TickerDateFeatureValuesFromFiltersQuery, TickerDateFeatureValuesFromFiltersQueryVariables } from "graphql/generated";
import { FC } from "react";
import { scannerFormFieldsToInput } from "../util/scannerFormFieldsToInput";

const TICKER_DATE_FEATURE_VALUES_FROM_FILTERS_QUERY = gql`
  query TickerDateFeatureValuesFromFilters($filters: ScannerFiltersInput!, $featureId: Int!, $minValue: Float, $maxValue: Float, $targetVariableValueType: TargetVariableValueType!) {
    tickerDateFeatureValuesFromFilters(filters: $filters, featureId: $featureId, minValue: $minValue, maxValue: $maxValue, targetVariableValueType: $targetVariableValueType) {
      values {
        value
        date
        targetVariables {
          id
          name
          value
        }
      }
      stats {
        count
        mean
        std
        min
        p25
        median
        p75
        max
      }
    }
  }
`;

interface TickerFilterFeatureStatsProps {
  filters: FiltersFormFields;
  feature: Feature;
  minValue?: number;
  maxValue?: number;
  targetVariableValueType: TargetVariableValueType;
}

const TickerFilterFeatureStats: FC<TickerFilterFeatureStatsProps> = ({ filters, feature, minValue, maxValue, targetVariableValueType }) => {
  const { loading, error, data } = useQuery<TickerDateFeatureValuesFromFiltersQuery, TickerDateFeatureValuesFromFiltersQueryVariables>(TICKER_DATE_FEATURE_VALUES_FROM_FILTERS_QUERY, {
    variables: {
      filters: scannerFormFieldsToInput(filters),
      featureId: feature.id,
      minValue: feature.valueType !== FeatureValueType.Tag ? minValue : undefined,
      maxValue: feature.valueType !== FeatureValueType.Tag ? maxValue : undefined,
      targetVariableValueType,
    }
  });

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const values = data.tickerDateFeatureValuesFromFilters.values;

  return (
    <FeatureValuesDistributionChart feature={feature} values={values} stats={data.tickerDateFeatureValuesFromFilters.stats} />
  );
}

export default TickerFilterFeatureStats;