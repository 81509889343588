import { gql, useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import { IChartingLibraryWidget } from "charting_library";
import TradingViewChart, { TradingViewButton, TradingViewChartRef } from "components/common/Chart/TradingViewChart";
import DelayRenderUntilVisible from "components/common/DelayRenderUntilVisible";
import Error from 'components/common/Error';
import { ChartHeight, TickerDatesLayoutContext } from "contexts/TickerDatesLayoutContext";
import { FeatureValueType, TickerDate, TickerDateGridChartStateQuery, TickerDateGridChartStateQueryVariables } from "graphql/generated";
import moment from "moment";
import { RefObject, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { formatRawValue } from "util/valueFormat";

const GRID_CHART_STATE_QUERY = gql`
  query TickerDateGridChartState($tickerDateId: Int!) {
    userTickerDateChartState(tickerDateId: $tickerDateId, index: 1) {
      state
    }
    userChartStudyTemplate(index: 1) {
      template
    }
  }
`;

export const ON_VIEW_TICKER_DATE_DATA = 'onViewTickerDateData';
export const ON_ADD_TICKER_DATE_DATA = 'onAddTickerDateData';

interface TickerDateGridChartProps {
  tickerDate: TickerDate;
  tags?: string[];
  onViewClick: (o: TickerDate) => void;
  onAddDataClick?: (o: TickerDate) => void;
}

export interface TickerDateGridChartRef {
  gridChartRef: RefObject<TradingViewChartRef>;
}

const TickerDateGridChart = forwardRef<TickerDateGridChartRef, TickerDateGridChartProps>(({ tickerDate, tags, onViewClick, onAddDataClick }, ref) => {
  const gridChartRef = useRef<TradingViewChartRef>(null);
  const [widgetObject, setWidgetObject] = useState<IChartingLibraryWidget | undefined>(undefined);
  const layoutContext = useContext(TickerDatesLayoutContext);

  useImperativeHandle(ref, () => ({
    gridChartRef: gridChartRef,
  }));

  useEffect(() => {
    if (!widgetObject) return;

    const listener = (event: MessageEvent<any>) => {
      if (event.data.id !== gridChartRef.current?.id) return;

      if (event.data.event === ON_VIEW_TICKER_DATE_DATA) {
        onViewClick(tickerDate);
      } else if (event.data.event === ON_ADD_TICKER_DATE_DATA) {
        if (onAddDataClick) onAddDataClick(tickerDate);
      }
    };
    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  });

  const { loading, error, data } = useQuery<TickerDateGridChartStateQuery, TickerDateGridChartStateQueryVariables>(GRID_CHART_STATE_QUERY, {
    variables: {
      tickerDateId: tickerDate.id,
    },
  });

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const intradayTimeframe = {
    from: moment.tz(tickerDate.date, 'America/New_York').unix(),
    to: moment.tz(tickerDate.date, 'America/New_York').add(1, 'day').unix(),
  }

  const customButtons: TradingViewButton[] = [
    {
      title: 'View data',
      text: 'View data',
      onClick: (id: string) => window.parent.postMessage({ event: ON_VIEW_TICKER_DATE_DATA, id }),
    },
  ];

  if (onAddDataClick) {
    customButtons.push({
      title: 'Add data',
      text: 'Add data',
      onClick: (id: string) => window.parent.postMessage({ event: ON_ADD_TICKER_DATE_DATA, id }),
    });
  }

  const marketCapString = tickerDate.marketCap ? ` Market cap: ${formatRawValue(tickerDate.marketCap, FeatureValueType.Number)}` : '';
  const volumeString = tickerDate.volume ? ` Volume: ${formatRawValue(tickerDate.volume, FeatureValueType.Number)}` : '';

  return (
    <div>
      <DelayRenderUntilVisible>
        <TradingViewChart
          tickerDate={tickerDate}
          defaultResolution={'1'}
          resolutions={['1']}
          hasIntraday={true}
          ref={gridChartRef}
          timeframe={intradayTimeframe}
          stateIndex={1}
          initialState={data.userTickerDateChartState.state || undefined}
          initialStudyTemplate={data.userChartStudyTemplate.template || undefined}
          minHeight={layoutContext.chartHeight === ChartHeight.Large ? 720 : 420}
          zoomEnabled={false}
          drawingEnabled={false}
          setWidgetObject={setWidgetObject}
          toggleZoomButtonEnabled={false}
          customButtons={customButtons}
        />
      </DelayRenderUntilVisible>
      <div>
        {tickerDate.ticker.ticker} {tickerDate.date}{marketCapString}{volumeString}
      </div>
      <div>
        {tags && tags.length > 0 ? `Tags: ${tags.join(", ")}` : 'No tags'}
      </div>
    </div>
  );
});

export default TickerDateGridChart;