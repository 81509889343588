import { filtersFormFieldsFromSearchParams } from "components/Scanner/FiltersSearchParams";
import Button, { ButtonStyle } from "components/common/Button";
import Form from "components/common/Form";
import Option from 'components/common/Form/Option';
import Select from "components/common/Form/Select";
import Submit from "components/common/Form/Submit";
import { FiltersSearchParamsContext } from "contexts/FiltersSearchParamsContext";
import { Feature, FilterOperator, ScannerFiltersInput, ScannerFiltersQuery, TargetVariable } from "graphql/generated";
import { FC, useContext, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import FilterInput from "./FilterInput";
import FiltersModal from "./FiltersModal";
import SaveFiltersModal from "./SaveFiltersModal";
import { handleFormChange } from "./functions/handleFormChange";
import handleSaveFiltersSelected from "./functions/handleSaveFiltersSelected";
import { FiltersFormFields, validationSchema } from "./schema";

export const DEFAULT_OPERATOR = { label: FilterOperator.Above, value: FilterOperator.Above };
export const DEFAULT_TAG_OPERATOR = { label: FilterOperator.Equal, value: FilterOperator.Equal };

interface FiltersFormProps {
  savedFilters: ScannerFiltersQuery;
  features: Feature[];
  targetVariables: TargetVariable[];
  handleLoadData: (inputs: FiltersFormFields, tab?: number) => void;
  handleSaveFilters: (input: ScannerFiltersInput) => Promise<number | undefined>;
}

const FiltersForm: FC<FiltersFormProps> = ({ savedFilters, features, targetVariables, handleLoadData, handleSaveFilters }) => {
  const filtersSearchParamsContext = useContext(FiltersSearchParamsContext);

  const [filtersExpressionModalIndex, setFiltersExpressionModalIndex] = useState(-1);
  const [showSaveFiltersModal, setShowSaveFiltersModal] = useState<boolean>(false);

  const savedFiltersOptions: Option[] = savedFilters.scannerFilters.map((t) => ({ value: t.id, label: t.name }));

  const cleanValues: FiltersFormFields = useMemo(() => filtersFormFieldsFromSearchParams([{}], features, targetVariables), [features, targetVariables]);
  const defaultValues: FiltersFormFields = useMemo(() =>
    filtersFormFieldsFromSearchParams(filtersSearchParamsContext.filters || [{}], features, targetVariables),
    [filtersSearchParamsContext, features, targetVariables]);

  const onLoad = (fields: FiltersFormFields, formMethods: UseFormReturn<FiltersFormFields, any>) => {
    filtersSearchParamsContext.setCursor();
    filtersSearchParamsContext.setViewingTicker();
    handleLoadData(fields);
  };

  const reset = (formMethods: UseFormReturn<FiltersFormFields, any>) => {
    formMethods.reset({
      selectedFiltersOption: undefined,
      loadedFiltersId: '',
      loadedFiltersName: '',
      filtersExpressions: cleanValues.filtersExpressions,
    });

    filtersSearchParamsContext.reset();
  };

  return (
    <Form<FiltersFormFields> validationSchema={validationSchema} onChange={handleFormChange(filtersSearchParamsContext)} defaultValues={defaultValues}>
      {formMethods => {
        const filtersExpressions = formMethods.watch('filtersExpressions');
        const onModalClose = (values: FiltersFormFields | undefined) => {
          setFiltersExpressionModalIndex(-1);
          if (values) {
            for (const [key, value] of Object.entries(values)) {
              if (key.startsWith('filtersExpressions')) {
                formMethods.setValue(key as any, value);
              }
            }
          }
        };

        const handleSaveFiltersWrapped = async (input: ScannerFiltersInput) => {
          const id = await handleSaveFilters(input);
          if (!id) return;
          formMethods.setValue('selectedFiltersOption', { label: input.name!, value: id });
          formMethods.setValue('loadedFiltersId', "" + id);
          formMethods.setValue('loadedFiltersName', input.name!);
        }

        const addFilterExpressions = () => {
          formMethods.setValue('filtersExpressions', [...filtersExpressions, cleanValues.filtersExpressions[0]]);
        };

        const removeFiltersExpressionByIndex = (index: number) => {
          const copy = [...filtersExpressions];
          copy.splice(index, 1);
          formMethods.setValue('filtersExpressions', copy);
        };

        return (
          <>
            <div className="relative">
              <Select field='selectedFiltersOption' label='Saved filters' options={savedFiltersOptions} onChange={handleSaveFiltersSelected(formMethods, reset, cleanValues.filtersExpressions[0].filters, savedFilters)} className='mr-20' />
              <Button style={ButtonStyle.Alternative} onClick={() => setShowSaveFiltersModal(true)} className="absolute right-0 bottom-0">Save</Button>
            </div>

            <div className="mt-10">
              {filtersExpressions.map((expression, filtersExpressionIndex) => {
                return (
                  <div key={filtersExpressionIndex} >
                    {filtersExpressionIndex >= 1 && (
                      <div className='my-4'>OR</div>
                    )}
                    <div className='bg-slate-50 p-6'>
                      <div className='font-bold'>Filter group #{filtersExpressionIndex + 1}</div>
                      <div className='grid gap-10 xl:grid-cols-2 grid-cols-1 mt-4'>
                        {expression.filters.map((filter, filterIndex) => {
                          if (!filter.modified) return null;
                          return (
                            <FilterInput type={filter.type} name={filter.name} filtersExpressionIndex={filtersExpressionIndex} filterIndex={filterIndex} key={`${filtersExpressionIndex}-${filterIndex}`} tag={filter.tag} />
                          );
                        })}
                      </div>
                      <Button onClick={() => setFiltersExpressionModalIndex(filtersExpressionIndex)} style={ButtonStyle.Alternative} className='mt-6'>Add filters</Button>
                      {filtersExpressions.length > 1 && <Button onClick={() => removeFiltersExpressionByIndex(filtersExpressionIndex)} style={ButtonStyle.Alternative}>Remove filter group #{filtersExpressionIndex + 1}</Button>}
                    </div>
                  </div>
                );
              })}
            </div>
            <FiltersModal filtersExpressionIndex={filtersExpressionModalIndex} show={filtersExpressionModalIndex !== -1} onClose={onModalClose} defaultValues={formMethods.getValues()} />
            <SaveFiltersModal show={showSaveFiltersModal} filtersFormFields={formMethods.getValues()} handleSaveFilters={handleSaveFiltersWrapped} onClose={() => setShowSaveFiltersModal(false)} />
            <Button onClick={addFilterExpressions} style={ButtonStyle.Alternative} className="mt-10">Add alternative filter group</Button>
            <div className='mt-10'>
              <Submit<FiltersFormFields> onSubmit={onLoad} className='mr-2'>Load</Submit>
              <Button onClick={() => reset(formMethods)} style={ButtonStyle.Alternative}>Clear</Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}

export default FiltersForm;