import { FC, useId } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { createFilter } from 'react-select';
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import Error from '../Error';
import Label from '../Label';
import Option from '../Option';

interface AsyncSelectProps {
  field: string;
  label?: string;
  isMulti?: boolean;
  loadOptions: LoadOptions<Option, any, any>;
  onChange?: (value: any) => void;
  className?: string;
  disabled?: boolean;
}

const AsyncSelect: FC<AsyncSelectProps> = ({ field, label, isMulti, onChange, loadOptions, className, disabled }) => {
  const { control } = useFormContext(); // retrieve all hook methods
  const id = useId();

  return (
    <div className={className}>
      {label && <Label htmlFor={id} label={label} />}

      <Controller
        control={control}
        name={field}
        render={({ field: { onChange: controllerOnChange, value } }) => {
          return (
            <AsyncPaginate
              value={value || null}
              loadOptions={loadOptions}
              onChange={(val: any) => {
                controllerOnChange(val);
                if (onChange) onChange(val);
              }}
              isMulti={isMulti || false}
              filterOption={createFilter({ ignoreAccents: false })}
              isDisabled={disabled}
            />
          )
        }}
      />

      <Error field={`${field}.value`} />
    </div>
  );
}

export default AsyncSelect;