import { gql, useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import { IChartingLibraryWidget } from "charting_library";
import TradingViewChart, { TradingViewButton, TradingViewChartRef } from "components/common/Chart/TradingViewChart";
import DelayRenderUntilVisible from "components/common/DelayRenderUntilVisible";
import Error from 'components/common/Error';
import { BacktestTrade, Collection, DailyChartStateQuery, DailyChartStateQueryVariables, Signal, TickerDate } from "graphql/generated";
import moment from "moment";
import { RefObject, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

const DAILY_CHART_STATE_QUERY = gql`
  query DailyChartState($tickerDateId: Int!, $collectionId: Int) {
    dailyChartState: userTickerDateChartState(tickerDateId: $tickerDateId, collectionId: $collectionId, index: 0) {
      state
    }
    dailyChartTemplate: userChartStudyTemplate(collectionId: $collectionId, index: 0) {
      template
    }
  }
`;

interface DailyChartProps {
  tickerDate: TickerDate;
  backtestTrade?: Partial<BacktestTrade>;
  isPublic?: boolean;
  selectedSignal?: Signal;
  collection?: Partial<Collection>;
  onStateChange?: (state: object, id: string) => void;
  onDateChange?: (date: string) => void;
}

export interface DailyChartRef {
  dailyChartRef: RefObject<TradingViewChartRef>;
}

export const ON_SELECT_DAILY_BAR_MESSAGE = 'onSelectDailyBar';

const DailyChart = forwardRef<DailyChartRef, DailyChartProps>(({ tickerDate, isPublic, selectedSignal, onStateChange, collection, backtestTrade, onDateChange }, ref) => {
  const dailyChartRef = useRef<TradingViewChartRef>(null);
  const [widgetObject, setWidgetObject] = useState<IChartingLibraryWidget | undefined>(undefined);

  useImperativeHandle(ref, () => ({
    dailyChartRef: dailyChartRef,
  }));

  const onSelectBarCallback = (widgetObject: IChartingLibraryWidget, callback: (date: string) => void) => {
    widgetObject.activeChart().requestSelectBar().then((time) => {
      const selectedDate = moment.utc(time * 1000).format('YYYY-MM-DD');
      callback(selectedDate);
    }).catch((error) => {
      if (error !== 'cancelled') throw error;
    })
  };

  useEffect(() => {
    if (!widgetObject) return;

    const listener = (event: MessageEvent<any>) => {
      if (event.data.id !== dailyChartRef.current?.id) return;

      if (event.data.event === ON_SELECT_DAILY_BAR_MESSAGE && onDateChange !== undefined) {
        onSelectBarCallback(widgetObject, onDateChange);
      }
    };
    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  });

  const { loading, error, data } = useQuery<DailyChartStateQuery, DailyChartStateQueryVariables>(DAILY_CHART_STATE_QUERY, {
    variables: {
      tickerDateId: tickerDate.id,
      collectionId: collection?.id,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const dailyTimeframe = {
    from: moment.tz(tickerDate.date, 'America/New_York').subtract(3, 'months').unix(),
    to: moment.tz(tickerDate.date, 'America/New_York').unix(),
  };

  const customButtons: TradingViewButton[] = [];
  if (onDateChange !== undefined) {
    customButtons.push({
      text: 'Select daily bar',
      title: 'Select daily bar',
      onClick: (id: string) => window.parent.postMessage({ event: ON_SELECT_DAILY_BAR_MESSAGE, id }),
    });
  }

  return (
    <DelayRenderUntilVisible>
      <TradingViewChart
        tickerDate={tickerDate}
        collection={collection}
        backtestTrade={backtestTrade}
        defaultResolution={'1D'}
        resolutions={['1D', '1W']}
        hasIntraday={false}
        timeframe={dailyTimeframe}
        ref={dailyChartRef}
        stateIndex={0}
        initialState={data.dailyChartState.state || undefined}
        initialStudyTemplate={data.dailyChartTemplate.template || undefined}
        isPublic={isPublic}
        selectedSignal={selectedSignal}
        onStateChange={onStateChange}
        setWidgetObject={setWidgetObject}
        customButtons={customButtons}
      />
    </DelayRenderUntilVisible>
  );
});

export default DailyChart;