import { ViewingSignal } from "components/Signals/SignalsTableFromFilters";
import TickerDateColumns, { Tab } from "components/TickerDate/TickerDateColumns";
import Button, { ButtonStyle } from "components/common/Button";
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import TickerDatesLayoutForm from "components/common/TickerDatesLayoutForm";
import { TickerDatesLayout, TickerDatesLayoutContext } from "contexts/TickerDatesLayoutContext";
import { Signal } from "graphql/generated";
import { FC, useState } from "react";
import SignalsChartsFromFilters from "./SignalsChartsFromFilters";
import SignalsTableFromFilters from "./SignalsTableFromFilters";

interface SignalsTabProps {
  inputs: FiltersFormFields;
  onSignalAdded: (signal: Signal) => void;
  onAllSignalsFromFiltersAdded: (filters?: FiltersFormFields) => void;
  addedSignalIds: Set<number>;
  addedAllSignalsFromFilters: boolean;
  closeModal: () => void;
}

const SignalsTab: FC<SignalsTabProps> = ({ inputs, onSignalAdded, addedSignalIds, onAllSignalsFromFiltersAdded, closeModal, addedAllSignalsFromFilters }) => {
  const [viewingSignal, setViewingSignal] = useState<ViewingSignal | undefined>(undefined);

  const handleViewClick = (o: Signal) => {
    setViewingSignal({ id: o.id, ticker: o.TickerDate.ticker.ticker, date: o.TickerDate.date });
  };

  const handleAllTickerDatesAdded = () => {
    onAllSignalsFromFiltersAdded(inputs);
    closeModal();
  };

  const handleUndoAllTickerDatesAdded = () => {
    onAllSignalsFromFiltersAdded();
  };

  return (
    <>
      {addedAllSignalsFromFilters ? (
        <Button style={ButtonStyle.Alternative} onClick={handleUndoAllTickerDatesAdded}>Undo select all</Button>
      ) : (
        <Button style={ButtonStyle.Primary} onClick={handleAllTickerDatesAdded}>Select all</Button>
      )}

      <TickerDatesLayoutForm keyPrefix="collection-edit-signals-tab">
        <TickerDatesLayoutContext.Consumer>
          {({ layout, tableResultsPerPage, chartColumnsPerPage, chartRowsPerPage }) => {
            return (
              <>
                {layout === TickerDatesLayout.Table && <SignalsTableFromFilters inputs={inputs} onViewClick={handleViewClick} viewingSignal={viewingSignal} onSignalAdded={onSignalAdded} addedSignalIds={addedSignalIds} addedAllSignalsFromFilters={addedAllSignalsFromFilters} pageSize={tableResultsPerPage} />}
                {layout === TickerDatesLayout.Charts && <SignalsChartsFromFilters inputs={inputs} onViewClick={handleViewClick} viewingSignal={viewingSignal} onSignalAdded={onSignalAdded} addedSignalIds={addedSignalIds} addedAllSignalsFromFilters={addedAllSignalsFromFilters} chartColumnsPerPage={chartColumnsPerPage} chartRowsPerPage={chartRowsPerPage} />}
                {viewingSignal && <TickerDateColumns signalId={viewingSignal.id} ticker={viewingSignal.ticker} date={viewingSignal.date} tabs={[Tab.DailyChart, Tab.IntradayChart, Tab.Notes, Tab.Data, Tab.Signal]} />}
              </>
            )
          }}
        </TickerDatesLayoutContext.Consumer>
      </TickerDatesLayoutForm>
    </>
  );
};

export default SignalsTab;