import Option from 'components/common/Form/Option';
import TextInput from "components/common/Form/TextInput";
import loadTickers from "graphql/functions/loadTickers";
import { FilterOperator } from "graphql/generated";
import { FC, ReactNode } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import CheckboxInput from "../Form/CheckboxInput";
import DateInput from "../Form/DateInput";
import Select from "../Form/Select";
import AsyncSelect from "../Form/Select/AsyncSelect";
import { ASSET_TYPE_OPTIONS } from "./asset_types";
import { FilterType, FiltersFormFields } from "./schema";

interface FilterInputProps {
  type: FilterType;
  name: string;
  filtersExpressionIndex: number;
  filterIndex: number;
  tag?: boolean;
}

export const OPERATOR_LABELS = {
  [FilterOperator.Above]: 'Above',
  [FilterOperator.AboveOrEqual]: 'Above or equal',
  [FilterOperator.Below]: 'Below',
  [FilterOperator.BelowOrEqual]: 'Below or equal',
  [FilterOperator.Between]: 'Between',
  [FilterOperator.Outside]: 'Outside',
  [FilterOperator.Equal]: 'Equal',
  [FilterOperator.NotEqual]: 'Not equal',
  [FilterOperator.DoesNotExist]: 'Does not exist',
}

const OPERATOR_OPTIONS: Option[] = [
  { value: FilterOperator.Above, label: OPERATOR_LABELS[FilterOperator.Above] },
  { value: FilterOperator.AboveOrEqual, label: OPERATOR_LABELS[FilterOperator.AboveOrEqual] },
  { value: FilterOperator.Below, label: OPERATOR_LABELS[FilterOperator.Below] },
  { value: FilterOperator.BelowOrEqual, label: OPERATOR_LABELS[FilterOperator.BelowOrEqual] },
  { value: FilterOperator.Between, label: OPERATOR_LABELS[FilterOperator.Between] },
  { value: FilterOperator.Outside, label: OPERATOR_LABELS[FilterOperator.Outside] },
  { value: FilterOperator.Equal, label: OPERATOR_LABELS[FilterOperator.Equal] },
  { value: FilterOperator.NotEqual, label: OPERATOR_LABELS[FilterOperator.NotEqual] },
  { value: FilterOperator.DoesNotExist, label: OPERATOR_LABELS[FilterOperator.DoesNotExist] },
];

const TAG_OPERATOR_OPTIONS: Option[] = [
  { value: FilterOperator.Equal, label: OPERATOR_LABELS[FilterOperator.Equal] },
  { value: FilterOperator.DoesNotExist, label: OPERATOR_LABELS[FilterOperator.DoesNotExist] },
];

const FilterInputLabel = ({ children }: { children: ReactNode }) => {
  return (
    <div className={`block text-sm font-medium text-gray-900 dark:text-white`}>{children}</div>
  )
};

const FilterInput: FC<FilterInputProps> = ({ type, name, filtersExpressionIndex, filterIndex, tag }) => {
  const fieldPrefix = `filtersExpressions.${filtersExpressionIndex}.filters.${filterIndex}`;
  const fieldOperator = `${fieldPrefix}.operator`;
  const fieldValue = `${fieldPrefix}.value`;
  const fieldValue2 = `${fieldPrefix}.value2`;
  const fieldModified = `${fieldPrefix}.modified`;

  const formMethods = useFormContext<FiltersFormFields>();
  const operatorValue = useWatch({ name: fieldOperator });
  const modified = useWatch({ name: fieldModified });
  const requireTwoValues = [FilterOperator.Between, FilterOperator.Outside].includes(operatorValue?.value);
  const doesNotExistOperator = operatorValue?.value === FilterOperator.DoesNotExist;

  const handleChange = () => {
    formMethods.setValue(`filtersExpressions.${filtersExpressionIndex}.filters.${filterIndex}.modified`, true);
  };

  const handleReset = () => {
    formMethods.setValue(fieldValue as any, '');
    formMethods.setValue(fieldValue2 as any, '');
    formMethods.setValue(fieldOperator as any, tag ? { label: FilterOperator.Equal, value: FilterOperator.Equal } : { label: FilterOperator.Above, value: FilterOperator.Above });
    formMethods.setValue(fieldModified as any, false);
  };

  const resetIcon = <span className={`material-icons cursor-pointer absolute right-0 ${modified ? 'visible' : 'invisible'}`} onClick={handleReset}>restart_alt</span>;

  if (type === FilterType.AssetTypes) {
    return (
      <div className="relative pr-10 grid gap-6 grid-cols-4 items-center">
        <FilterInputLabel>Asset type</FilterInputLabel>
        <Select field={fieldValue} options={ASSET_TYPE_OPTIONS} isMulti onChange={handleChange} className='col-span-3'/>
        {resetIcon}
      </div>
    );
  }

  if (type === FilterType.Tickers) {
    return (
      <div className="relative pr-10 grid gap-6 grid-cols-4 items-center">
        <FilterInputLabel>Tickers</FilterInputLabel>
        <AsyncSelect field={fieldValue} loadOptions={loadTickers} isMulti onChange={handleChange} className='col-span-3' />
        {resetIcon}
      </div>
    );
  }

  if (type === FilterType.Date) {
    return (
      <div className="relative pr-10 grid gap-6 grid-cols-4 items-center">
        <FilterInputLabel>Date</FilterInputLabel>
        <Select field={fieldOperator} options={OPERATOR_OPTIONS} onChange={handleChange} />
        {requireTwoValues ? (
          <>
            <DateInput field={fieldValue} placeholder='Select date' onChange={handleChange} />
            <DateInput field={fieldValue2} placeholder='Select date' onChange={handleChange} />
          </>
        ) : (
          <>
            {!doesNotExistOperator && <DateInput field={fieldValue} placeholder='Select date' onChange={handleChange} className='col-span-2' />}
            {doesNotExistOperator && <CheckboxInput field={fieldValue} onChange={handleChange} className='col-span-2' />}
          </>
        )}
        {resetIcon}
      </div>
    );
  }

  if (type === FilterType.SignalTime) {
    return (
      <div className="relative pr-10 grid gap-6 grid-cols-4 items-center">
        <FilterInputLabel>Signal time</FilterInputLabel>
        <Select field={fieldOperator} options={OPERATOR_OPTIONS} onChange={handleChange} />
        {requireTwoValues ? (
          <>
            <TextInput field={fieldValue} placeholder="Time, e.g. 13:23" onChange={handleChange} />
            <TextInput field={fieldValue2} placeholder="Time, e.g. 13:23" onChange={handleChange} />
          </>
        ) : (
          <>
            {!doesNotExistOperator && <TextInput field={fieldValue} placeholder="Time, e.g. 13:23" onChange={handleChange} className='col-span-2'/>}
            {doesNotExistOperator && <CheckboxInput field={fieldValue} onChange={handleChange} className='col-span-2'/>}
          </>
        )}
        {resetIcon}
      </div>
    );
  }

  if ([FilterType.Feature, FilterType.TargetVariable].includes(type)) {
    return (
      <div className={`relative pr-10 grid gap-6 grid-cols-4 items-center`}>
        <FilterInputLabel>{name}</FilterInputLabel>
        <Select field={fieldOperator} options={tag ? TAG_OPERATOR_OPTIONS : OPERATOR_OPTIONS} onChange={handleChange} />
        {requireTwoValues ? (
          <>
            <TextInput field={fieldValue} placeholder='Value' onChange={handleChange} />
            <TextInput field={fieldValue2} placeholder='Value' onChange={handleChange} />
          </>
        ) : (
          <>
            {!doesNotExistOperator && !tag && <TextInput field={fieldValue} placeholder='Value' onChange={handleChange} className='col-span-2'/>}
            {(doesNotExistOperator || tag) && <CheckboxInput field={fieldValue} onChange={handleChange} className='col-span-2' />}
          </>
        )}
        {resetIcon}
      </div>
    );
  }

  return null;
};

export default FilterInput;