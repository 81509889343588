import { LibrarySymbolInfo, ResolutionString, SubscribeBarsCallback } from "charting_library";
import TickerDateDatafeed from "..";

export const subscribeBars = (
  datafeed: TickerDateDatafeed,
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  onTick: SubscribeBarsCallback,
  listenerGuid: string,
  onResetCacheNeededCallback: () => void
) => {
};