import { gql, useMutation } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import * as Sentry from "@sentry/react";
import Form, { FormRef } from "components/common/Form";
import { FeaturesDocument, FeatureSetsAndValuesDocument, FeatureValueType, RemoveTickerDateFeatureMutation, RemoveTickerDateFeatureMutationVariables, SaveTickerDateFeatureMutation, SaveTickerDateFeatureMutationVariables, TickerDatesFromFiltersDocument } from "graphql/generated";
import { FC, useEffect, useRef } from "react";
import { formatRawValue, formattedValueToRawNumber } from "util/valueFormat";
import * as yup from 'yup';
import FeatureInput, { FeatureInputProps } from ".";
import Error from "../Form/Error";
import { FeatureInputs, featureValidationSchema } from "./schema";


const REMOVE_TICKER_DATE_FEATURE_MUTATION = gql`
  mutation RemoveTickerDateFeature($featureId: Int!, $tickerDateId: Int!) {
    removeTickerDateFeature(featureId: $featureId, tickerDateId: $tickerDateId)
  }
`;

const SAVE_TICKER_DATE_FEATURE_MUTATION = gql`
  mutation SaveTickerDateFeature($input: FeatureInput!, $tickerDateId: Int!) {
    saveTickerDateFeature(input: $input, tickerDateId: $tickerDateId)
  }
`;

interface TickerDateFeatureInputProps extends Omit<FeatureInputProps, 'field'> {
  tickerDateId: number;
}

const TickerDateFeatureInput: FC<TickerDateFeatureInputProps> = (props) => {
  const defaultValue: { [key: string]: Partial<FeatureInputs> } = {};
  if (props.feature) {
    defaultValue.feature = {
      feature: {
        label: props.feature.featureName,
        value: props.feature.featureId,
      },
      type: {
        label: props.feature.valueType,
        value: props.feature.valueType,
      },
      value: props.feature.valueType === FeatureValueType.Tag ? Number(props.feature.value) === 1 : formatRawValue(props.feature.value, props.feature.valueType),
    }
  };

  const [removeMutation] = useMutation<RemoveTickerDateFeatureMutation, RemoveTickerDateFeatureMutationVariables>(REMOVE_TICKER_DATE_FEATURE_MUTATION, {
    refetchQueries: [
      getOperationName(FeatureSetsAndValuesDocument)!,
      getOperationName(TickerDatesFromFiltersDocument)!,
    ],
  });
  const [saveMutation] = useMutation<SaveTickerDateFeatureMutation, SaveTickerDateFeatureMutationVariables>(SAVE_TICKER_DATE_FEATURE_MUTATION, {
    refetchQueries: [
      getOperationName(FeaturesDocument)!,
      getOperationName(FeatureSetsAndValuesDocument)!,
      getOperationName(TickerDatesFromFiltersDocument)!,
    ],
  });

  const formRef = useRef<FormRef<TickerDateFeatureForm>>(null);

  useEffect(() => {
    formRef.current?.reset(defaultValue);
  }, [props.feature]);

  const onSave = async ({ feature }: TickerDateFeatureForm) => {
    const isValueNaN = isNaN(Number(feature.feature.value));

    const rawValue = feature.type.value as FeatureValueType === FeatureValueType.Tag ? Number(feature.type.value !== 0) : formattedValueToRawNumber(feature.value)!;

    await saveMutation({
      variables: {
        input: {
          featureId: isValueNaN ? undefined : Number(feature.feature.value),
          featureName: isValueNaN ? feature.feature.value as string : undefined,
          value: rawValue,
          valueType: feature.type.value as FeatureValueType,
        },
        tickerDateId: props.tickerDateId,
      }
    }).catch((err: any) => {
      formRef.current?.setError('feature', { message: 'Something went wrong...' });
      Sentry.captureException(err);
    });
  };


  const handleSave = (field: string) => {
    if (onSave) {
      formRef.current?.handleSubmit(onSave, (err) => { })();
    }
  };

  let onDelete;
  if (props.feature) {
    onDelete = async () => {
      await removeMutation({
        variables: {
          tickerDateId: props.tickerDateId,
          featureId: props.feature!.featureId,
        },
      }).catch((err: any) => {
        formRef.current?.setError('feature', { message: 'Something went wrong...' });
        Sentry.captureException(err);
      });
    };
  }

  return (
    <Form<TickerDateFeatureForm> validationSchema={validationSchema} defaultValues={defaultValue} ref={formRef}>
      <FeatureInput field='feature' {...props} onSave={handleSave} onDelete={onDelete} isPublic={false} />
      <Error field='feature' />
    </Form>
  );
};

interface TickerDateFeatureForm {
  feature: FeatureInputs;
}

export const validationSchema = yup.object({
  feature: featureValidationSchema,
});


export default TickerDateFeatureInput;