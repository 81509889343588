import { getOperationName } from "@apollo/client/utilities";
import { apolloClient } from "App";
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import RequireAuthentication from "components/common/RequireAuthentication";
import TickerDatesLayoutForm from "components/common/TickerDatesLayoutForm";
import FiltersSearchParams, { serializeFiltersFormFields } from "components/Scanner/FiltersSearchParams";
import Statistics from "components/Scanner/Statistics";
import TickerDatesChartsFromFilters from "components/Scanner/TickerDatesChartsFromFilters";
import TickerDatesTableFromFilters from "components/Scanner/TickerDatesTableFromFilters";
import TickerFiltersForm from "components/Scanner/TickerFiltersForm";
import TickerDateColumns, { Tab } from "components/TickerDate/TickerDateColumns";
import { FiltersSearchParamsContext } from "contexts/FiltersSearchParamsContext";
import { TickerDatesLayout, TickerDatesLayoutContext } from "contexts/TickerDatesLayoutContext";
import { Tabs, TabsRef } from "flowbite-react";
import { SignalFeatureValuesFromFiltersDocument, SignalFilterChartStatsDocument, SignalFilterStatsDocument, SignalsFromFiltersDocument, TickerDate, TickerDateFeatureValuesFromFiltersDocument, TickerDatesFromFiltersDocument, TickerFilterStatsDocument } from "graphql/generated";
import { FC, useContext, useEffect, useRef, useState } from "react";

interface TickerDatesTabProps {
  inputs: FiltersFormFields;
}

export interface ViewingTickerDate {
  id: number;
  ticker: string;
  date: string;
}

const TickerDatesTab: FC<TickerDatesTabProps> = ({ inputs }) => {
  const [viewingTickerDate, setViewingTickerDate] = useState<ViewingTickerDate | undefined>(undefined);
  const filtersSearchParamsContext = useContext(FiltersSearchParamsContext);

  let mergedViewingTickerDate: ViewingTickerDate | undefined = viewingTickerDate;
  if (!mergedViewingTickerDate) {
    if (filtersSearchParamsContext.viewingTickerDateId !== undefined && filtersSearchParamsContext.viewingTickerDateTicker !== undefined && filtersSearchParamsContext.viewingTickerDateDate !== undefined) {
      mergedViewingTickerDate = {
        id: filtersSearchParamsContext.viewingTickerDateId,
        ticker: filtersSearchParamsContext.viewingTickerDateTicker,
        date: filtersSearchParamsContext.viewingTickerDateDate,
      };
    }
  }

  const handleViewClick = (o: TickerDate) => {
    setViewingTickerDate({ id: o.id, ticker: o.ticker.ticker, date: o.date });
    filtersSearchParamsContext.setViewingTicker(o.id, o.ticker.ticker, o.date);
  };

  return (
    <TickerDatesLayoutForm keyPrefix="scanner-tab" searchParamsPrefix="fl">
      <TickerDatesLayoutContext.Consumer>
        {({ layout, tableResultsPerPage, chartColumnsPerPage, chartRowsPerPage }) => {
          return (
            <>
              {layout === TickerDatesLayout.Table && <TickerDatesTableFromFilters inputs={inputs} onViewClick={handleViewClick} viewingTickerDate={mergedViewingTickerDate} pageSize={tableResultsPerPage} />}
              {layout === TickerDatesLayout.Charts && <TickerDatesChartsFromFilters inputs={inputs} onViewClick={handleViewClick} viewingTickerDate={mergedViewingTickerDate} chartColumnsPerPage={chartColumnsPerPage} chartRowsPerPage={chartRowsPerPage} />}
              {mergedViewingTickerDate && <TickerDateColumns ticker={mergedViewingTickerDate.ticker} date={mergedViewingTickerDate.date} tabs={[Tab.DailyChart, Tab.IntradayChart, Tab.Notes, Tab.Data, Tab.Signals, Tab.Backtests]} />}
            </>
          );
        }}
      </TickerDatesLayoutContext.Consumer>
    </TickerDatesLayoutForm>
  );
};

const ScannerInner: FC = () => {
  const [inputs, setInputs] = useState<FiltersFormFields | undefined>(undefined);
  const [loadTimes, setLoadTimes] = useState(0);
  const filtersSearchParamsContext = useContext(FiltersSearchParamsContext);
  const ref = useRef<TabsRef>(null);

  const key = inputs ? serializeFiltersFormFields(inputs) + `-${loadTimes}` : undefined;

  const handleTickersLoaded = async (inputs: FiltersFormFields, tab?: number) => {
    setLoadTimes(loadTimes + 1);
    setInputs(inputs);
    ref.current?.setActiveTab(tab !== undefined ? tab : 1);

    await apolloClient.refetchQueries({
      include: [
        getOperationName(SignalFeatureValuesFromFiltersDocument)!,
        getOperationName(SignalFilterChartStatsDocument)!,
        getOperationName(SignalFilterStatsDocument)!,
        getOperationName(SignalsFromFiltersDocument)!,
        getOperationName(TickerDateFeatureValuesFromFiltersDocument)!,
        getOperationName(TickerDatesFromFiltersDocument)!,
        getOperationName(TickerFilterStatsDocument)!,
      ],
    });
  }

  const activeTab = filtersSearchParamsContext.activeTab || 0;

  return (
    <>
      <Tabs.Group ref={ref} onActiveTabChange={filtersSearchParamsContext.setActiveTab}>
        <Tabs.Item title="Filters" active={activeTab === 0}>
          <TickerFiltersForm onTickersLoad={handleTickersLoaded} />
        </Tabs.Item>
        <Tabs.Item title="Tickers" disabled={!inputs} active={activeTab === 1}>
          {inputs && <TickerDatesTab inputs={inputs} key={`ticker-dates-tab-${key}`} />}
        </Tabs.Item>
        <Tabs.Item title="Statistics" disabled={!inputs} active={activeTab === 2}>
          {inputs && <Statistics inputs={inputs} key={`statistics-tab-${key}`} />}
        </Tabs.Item>
      </Tabs.Group>
    </>
  )
};

const Scanner: FC = () => {
  useEffect(() => {
    document.title = `Scanner - Charts and Stats`;
  });

  return (
    <div className='p-6'>
      <RequireAuthentication>
        <FiltersSearchParams prefix='sc'>
          <ScannerInner />
        </FiltersSearchParams>
      </RequireAuthentication>
    </div>
  );
}

export default Scanner;
