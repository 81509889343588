import { gql, useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import Button, { ButtonStyle } from "components/common/Button";
import Error from 'components/common/Error';
import { Column } from "components/common/Table";
import PaginatedTable from "components/common/Table/PaginatedTable";
import { BacktestsQuery, BacktestsQueryVariables } from "graphql/generated";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import SaveBacktestForm from "../SaveBacktestForm";
import Form from "components/common/Form";
import SearchInput from "components/common/Form/SearchInput";

const BACKTESTS_QUERY = gql`
  query Backtests {
    backtests {
      id
      name
    }
  }
`;

interface BacktestsListProps {

}

interface BacktestRow {
  id: number;
  name: string;
}

interface SearchFormInputs {
  input: string;
}

const searchValidationSchema = yup.object({
  input: yup.string().required(),
}).required();

const BacktestsList: FC<BacktestsListProps> = () => {
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);
  const { loading, error, data } = useQuery<BacktestsQuery, BacktestsQueryVariables>(BACKTESTS_QUERY);
  const navigate = useNavigate();

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const columns: Column<BacktestRow>[] = [
    {
      key: 'name',
      header: () => 'name',
      cell: (o: BacktestRow) => o.name,
    },
    {
      key: 'view',
      header: () => 'View',
      cell: (o: BacktestRow) => <Button style={ButtonStyle.Alternative} onClick={() => navigate(`/backtest/${o.id}`)}>View</Button>,
    },
  ];

  const displayedData = data.backtests.filter(f => !searchInput || f.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1);

  return (
    <>
      <h1 className="mb-4 text-2xl font-bold tracking-tight leading-none text-gray-900 lg:mb-6 md:text-5xl xl:text-4xl dark:text-white">Backtests</h1>
      <Form<SearchFormInputs> validationSchema={searchValidationSchema} className='mb-10'>
        <SearchInput<SearchFormInputs> field='input' placeholder="Search..." onChange={input => setSearchInput(input)} />
      </Form>
      <PaginatedTable<BacktestRow> columns={columns} data={displayedData} pageSize={5} />
      <SaveBacktestForm />
    </>
  );
};

export default BacktestsList;
