import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Descendant } from "slate";
import Editor from ".";
import Error from "../Form/Error";
import deserialize from "./deserialize";

interface EditorFormInputProps {
  field: string;
  label?: string;
  readOnly?: boolean;
  className?: string;
  onChange?: (input: string) => void;
}

const emptyNotes: Descendant[] = [{
  type: 'paragraph',
  children: [{ text: '' }],
}];

const EditorFormInput: FC<EditorFormInputProps> = ({ field, onChange: propsOnChange, readOnly, className }) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name={field}
        render={({ field: { onChange, value } }) => {
          let parsedValue = emptyNotes;
          if (value) {
            const document = new DOMParser().parseFromString(value, 'text/html');
            const deserialized = deserialize(document.body) as Descendant[] | null;
            if (deserialized) parsedValue = deserialized;
          }

          return (
            <Editor
              className={className}
              value={parsedValue}
              readOnly={readOnly}
              onChange={(v: any) => {
                onChange(v);
                if (propsOnChange) propsOnChange(v);
              }}
            />
          );
        }}
      />
      <Error field={field} />
    </>
  );
};

export default EditorFormInput;