import { ChangeEvent, FC } from "react";
import { useFormContext } from "react-hook-form";
import Error from './Error';

interface TextInputProps {
  field: string;
  placeholder?: string;
  readOnly?: boolean;
  onChange?: (input: string) => void;
  className?: string;
  type?: string;
}

const TextInput: FC<TextInputProps> = ({ field, placeholder, readOnly, onChange, className, type }) => {
  const { register } = useFormContext();

  let wrappedOnChange = undefined;
  if (onChange) {
    wrappedOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange!(event.target.value);
    };
  }

  return (
    <div className={className}>
      <input {...register(field, { onChange: wrappedOnChange })} type={type || 'text'} placeholder={placeholder} disabled={readOnly} className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`} />
      <Error field={field} />
    </div>
  );
}

export default TextInput;