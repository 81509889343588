import { gql } from "@apollo/client";

export const SIGNAL_TARGET_VARIABLES_QUERY = gql`
  query SignalTargetVariables($signalId: Int!, $collectionId: Int, $targetVariableValueType: TargetVariableValueType!) {
    signalTargetVariables(signalId: $signalId, collectionId: $collectionId, targetVariableValueType: $targetVariableValueType) {
      signalId
      targetVariableId
      value
      TargetVariable {
        id
        name
      }
    }
  }
`;
