import { gql, useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import chroma from 'chroma-js';
import { scannerFormFieldsToInput } from "components/Scanner/util/scannerFormFieldsToInput";
import Error from 'components/common/Error';
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import Form, { FormRef } from "components/common/Form";
import Label from "components/common/Form/Label";
import Option from "components/common/Form/Option";
import Select from "components/common/Form/Select";
import Table, { Column } from "components/common/Table";
import { SignalFilterStatsQuery, SignalFilterStatsQueryVariables, StatsBucketPayload, TargetVariable, TargetVariableValueType } from "graphql/generated";
import { FC, useRef, useState } from "react";

const SIGNAL_FILTER_STATS_QUERY = gql`
  query SignalFilterStats($input: ScannerFiltersInput!, $targetVariableIds: [Int!]!, $targetVariableValueType: TargetVariableValueType!) {
    signalFilterStats(input: $input, targetVariableIds: $targetVariableIds, targetVariableValueType: $targetVariableValueType) {
      numberOfObjects
      columnNames
      buckets {
        name
        values
      }
      footer {
        name
        values
      }
    }
  }
`;

interface SignalFormInputs {
  targetVariables: Option[];
}

interface SignalStatsTableProps {
  inputs: FiltersFormFields;
  targetVariables: TargetVariable[];
  targetVariableValueType: TargetVariableValueType;
}

function transpose(matrix: any[][]) {
  if (matrix.length === 0) return matrix;
  return matrix[0].map((col, i) => matrix.map(row => row[i]));
}

const SignalStatsTable: FC<SignalStatsTableProps> = ({ inputs, targetVariables, targetVariableValueType }) => {
  const formRef = useRef<FormRef<SignalFormInputs>>(null);
  const defaultTargetVariables = targetVariables.filter(t => ['Future intraday high', 'Future intraday low', 'Future intraday close'].includes(t.name));
  const defaultTargetVariableOptions = defaultTargetVariables.map(t => ({ label: t.name, value: t.id }));
  const [selectedTargetVariables, setSelectedTargetVariables] = useState<Option[] | undefined>(defaultTargetVariableOptions);

  const targetVariableBasesOrder = [
    'Future 1 min',
    'Future 2 min',
    'Future 5 min',
    'Future 10 min',
    'Future 15 min',
    'Future 30 min',
    'Future 1 hour',
    'Future 2 hour',
    'Future 4 hour',
    'Future intraday',
    'Future intraday until end of AH',
    'Future today and tomorrow',
    'Future today and 2 days after',
    'Future today and 3 days after',
    'Future today and 7 days after',
    'Future today and 14 days after',
  ];

  const targetVariableOrder: string[] = [];
  targetVariableBasesOrder.forEach(base => {
    targetVariableOrder.push(`${base} high`);
    targetVariableOrder.push(`${base} low`);
    targetVariableOrder.push(`${base} close`);
  });

  const targetVariableIndexByName: { [key: string]: number } = targetVariableOrder.reduce((result, item, index) => ({ ...result, [item]: index }), {});
  const targetVariableIdByName: { [key: string]: number } = targetVariables.reduce((p, c) => ({ ...p, [c.name]: c.id }), {});
  const options = targetVariables.map(t => ({ value: t.id, label: t.name })).sort((a, b) => targetVariableIndexByName[a.label] - targetVariableIndexByName[b.label]);
  const selectedTargetVariablesOrDefault = selectedTargetVariables ? [...selectedTargetVariables].sort((a, b) => targetVariableIndexByName[a.label] - targetVariableIndexByName[b.label]) : options;

  const inputVariables = scannerFormFieldsToInput(inputs);

  const { loading, error, data, previousData } = useQuery<SignalFilterStatsQuery, SignalFilterStatsQueryVariables>(SIGNAL_FILTER_STATS_QUERY, {
    variables: {
      input: inputVariables,
      targetVariableValueType,
      targetVariableIds: selectedTargetVariablesOrDefault.map(t => t.value as number),
    },
  });

  if (loading && !previousData) {
    return <span>Loading...</span>
  }

  if (error || (!data && !previousData)) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const signalFilterStats = (data?.signalFilterStats || previousData?.signalFilterStats)!;

  const displayData = signalFilterStats.buckets.map((b: any) => ({
    ...b,
    id: b.name
  }));

  const footerData = signalFilterStats.footer.map((b: any) => ({
    ...b,
    id: b.name
  }));

  type StatsBucketPayloadWithId = typeof displayData[0];

  const textColorScale = chroma.scale(['#6B7280', 'white']).domain([0, 0.1]);
  const bgColorScale = chroma.scale(['#E1EFFE', '#1C64F2']);

  const transposed = transpose(signalFilterStats.buckets.map(b => b.values));
  const maxValues = transposed.map(c => Math.max(...c));
  const minValues = transposed.map(c => Math.min(...c));

  const columns: Column<StatsBucketPayloadWithId>[] = [
    {
      key: 'bucket',
      header: () => '',
      cell: (o: StatsBucketPayload) => o.name,
    },

    ...signalFilterStats.columnNames.map((column: any, index: any) => ({
      key: column,
      header: () => column,
      cell: (o: StatsBucketPayload, rowIndex: number, columnIndex: number) => {
        if (rowIndex >= displayData.length) {
          if (o.name === 'Count') {
            return "" + o.values[index];
          } else if (targetVariableValueType === TargetVariableValueType.Percentage) {
            return `${(o.values[index] * 100).toFixed(2)}%`;
          } else {
            return `${(o.values[index]).toFixed(2)} ATRs`;
          }
        } else {
          return `${(o.values[index] * 100).toFixed(2)}%`;
        }
      },
      textColor: (o: StatsBucketPayload) => textColorScale((o.values[index] - minValues[index]) / (maxValues[index] - minValues[index])).hex(),
      backgroundColor: (o: StatsBucketPayload) => bgColorScale((o.values[index] - minValues[index]) / (maxValues[index] - minValues[index])).hex(),
    }))
  ];

  const handleChange = (o: any) => {
    if (Array.isArray(o)) {
      const sortedOptions = [...o];
      sortedOptions.sort((a, b) => targetVariableIndexByName[a.label] - targetVariableIndexByName[b.label]);
      const alreadySorted = o.every((element, index) => element === sortedOptions[index]);
      if (alreadySorted) {
        setSelectedTargetVariables(o);
      } else {
        setSelectedTargetVariables(o);
        formRef.current?.setValue('targetVariables', sortedOptions);
      }
    } else {
      setSelectedTargetVariables([o]);
    }
  };

  const setEndOfDayReturns = () => {
    const options = ([] as string[]).concat(...[
      'Future intraday',
    ].map(x => [`${x} high`, `${x} low`, `${x} close`])).map(x => ({ label: x, value: targetVariableIdByName[x] }));

    formRef.current?.setValue('targetVariables', options);
    handleChange(options);
  };

  const set1To5Minutes = () => {
    const options = ([] as string[]).concat(...[
      'Future 1 min',
      'Future 2 min',
      'Future 5 min',
    ].map(x => [`${x} high`, `${x} low`, `${x} close`])).map(x => ({ label: x, value: targetVariableIdByName[x] }));

    formRef.current?.setValue('targetVariables', options);
    handleChange(options);
  };

  const set10To30Minutes = () => {
    const options = ([] as string[]).concat(...[
      'Future 10 min',
      'Future 15 min',
      'Future 30 min',
    ].map(x => [`${x} high`, `${x} low`, `${x} close`])).map(x => ({ label: x, value: targetVariableIdByName[x] }));

    formRef.current?.setValue('targetVariables', options);
    handleChange(options);
  };

  const set1To4Hours = () => {
    const options = ([] as string[]).concat(...[
      'Future 1 hour',
      'Future 2 hour',
      'Future 4 hour',
    ].map(x => [`${x} high`, `${x} low`, `${x} close`])).map(x => ({ label: x, value: targetVariableIdByName[x] }));

    formRef.current?.setValue('targetVariables', options);
    handleChange(options);
  };

  const setDailyReturns = () => {
    const options = ([] as string[]).concat(...[
      'Future today and tomorrow',
      'Future today and 2 days after',
      'Future today and 3 days after',
      'Future today and 7 days after',
      'Future today and 14 days after'
    ].map(x => [`${x} high`, `${x} low`, `${x} close`])).map(x => ({ label: x, value: targetVariableIdByName[x] }));

    formRef.current?.setValue('targetVariables', options);
    handleChange(options);
  };

  return (
    <>
      <div>Number of signals: {data?.signalFilterStats.numberOfObjects}</div>
      <Form<SignalFormInputs> defaultValues={{ targetVariables: selectedTargetVariablesOrDefault }} ref={formRef}>
        <Label label="Future returns" />
        <div className='text-sm my-2'>
          <span>Templates: </span>
          <span onClick={setEndOfDayReturns} className='cursor-pointer text-blue-700 mr-2'>End of Day</span>
          <span onClick={set1To5Minutes} className='cursor-pointer text-blue-700 mr-2'>1 to 5 mins</span>
          <span onClick={set10To30Minutes} className='cursor-pointer text-blue-700 mr-2'>10 to 30 mins</span>
          <span onClick={set1To4Hours} className='cursor-pointer text-blue-700 mr-2'>1 to 4 hours</span>
          <span onClick={setDailyReturns} className='cursor-pointer text-blue-700 mr-2'>Daily</span>
        </div>
        <Select field='targetVariables' options={options} isMulti onChange={handleChange} />
      </Form>
      <Table<StatsBucketPayloadWithId> columns={columns} data={displayData} footerData={footerData} />
    </>
  )
};

export default SignalStatsTable;