import RequireAuthentication from "components/common/RequireAuthentication";
import FeaturesAndFeatureSets from "components/Features/FeaturesAndFeatureSets";
import { FC, useEffect } from "react";

const Features: FC = () => {

  useEffect(() => {
    document.title = `Data - Charts and Stats`;
  });

  return (
    <div className='p-6'>
      <RequireAuthentication>
        <FeaturesAndFeatureSets />
      </RequireAuthentication>
    </div>
  );
}

export default Features;
