import { FC } from "react";

interface LabelProps {
  htmlFor?: string;
  label: string;
  className?: string;
}

const Label: FC<LabelProps> = ({ htmlFor, label, className }) => {
  return (
    <label htmlFor={htmlFor} className={`block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-5 ${className}`}>{label}</label>
  )
}

export default Label;
