import { IBasicDataFeed } from "charting_library";
import { BacktestTrade, Collection, Signal, TickerDate } from "graphql/generated";
import { getBars } from "./functions/getBars";
import { getMarks } from "./functions/getMarks";
import { getTimescaleMarks } from "./functions/getTimescaleMarks";
import { onReady } from "./functions/onReady";
import { resolveSymbol } from "./functions/resolveSymbol";
import { searchSymbols } from "./functions/searchSymbols";
import { subscribeBars } from "./functions/subscribeBars";
import { unsubscribeBars } from "./functions/unsubscribeBars";

export type Resolution = '1D' | '1W' | '60' | '30' | '15' | '5' | '2' | '1';

class TickerDateDatafeed implements IBasicDataFeed {
  tickerDate: TickerDate;
  collection?: Partial<Collection>;
  selectedSignal?: Signal;
  resolutions: Resolution[];
  hasIntraday: boolean;
  backtestTrade?: Partial<BacktestTrade>;

  constructor(tickerDate: TickerDate, collection: Partial<Collection> | undefined, resolutions: Resolution[], hasIntraday: boolean, selectedSignal?: Signal, backtestTrade?: Partial<BacktestTrade>) {
    this.tickerDate = tickerDate;
    this.collection = collection;
    this.resolutions = resolutions;
    this.hasIntraday = hasIntraday;
    this.selectedSignal = selectedSignal;
    this.backtestTrade = backtestTrade;
  }

  onReady = onReady.bind(null, this);
  searchSymbols = searchSymbols.bind(null, this);
  resolveSymbol = resolveSymbol.bind(null, this);
  getBars = getBars.bind(null, this);
  subscribeBars = subscribeBars.bind(null, this);
  unsubscribeBars = unsubscribeBars.bind(null, this);
  getMarks = getMarks.bind(null, this);
  getTimescaleMarks = getTimescaleMarks.bind(null, this);
};

export default TickerDateDatafeed;
