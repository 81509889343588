import { Column, ObjectWithId } from './index';

interface HeaderProps<T extends ObjectWithId> {
  columns: Column<T>[];
}

const Header = <T extends ObjectWithId>({ columns }: HeaderProps<T>) => {
  return (
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        {columns.map(column => (
          <th scope="col" className="py-3 px-6" key={column.key}>
            {column.header()}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default Header;