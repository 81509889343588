import { ReactNode } from "react";
import { FieldValues, useFormContext, UseFormReturn } from "react-hook-form";
import Button, { ButtonStyle } from "../Button";

interface SubmitProps<T extends FieldValues> {
  children: ReactNode;
  style?: ButtonStyle;
  className?: string;
  preSubmit?: (formMethods: UseFormReturn<T, any>) => void;
  onSubmit?: (fields: T, formMethods: UseFormReturn<T, any>) => void;
  disabled?: boolean;
}

const Submit = <T extends FieldValues>({ children, preSubmit, onSubmit, style, className, disabled }: SubmitProps<T>) => {
  const formMethods = useFormContext<T>();

  const handleClick = () => {
    if (preSubmit) {
      preSubmit(formMethods);
    }
    if (onSubmit) {
      formMethods.handleSubmit((val) => onSubmit!(val, formMethods), (errors) => console.log('onSubmit errors', errors))();
    }
  };

  return (
    <Button type='submit' onClick={handleClick} style={style} className={className} disabled={disabled}>{children}</Button>
  );
}

export default Submit;
