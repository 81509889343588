import { SearchSymbolsCallback } from "charting_library";
import TickerDateDatafeed from "..";

export const searchSymbols = (
  datafeed: TickerDateDatafeed,
  userInput: string,
  exchange: string,
  symbolType: string,
  onResult: SearchSymbolsCallback
) => {
  setTimeout(() => {
    onResult([]);
  }, 50);
};