import * as Sentry from "@sentry/react";
import Error from "components/common/Error";
import Navbar from "components/common/Navbar";
import { createBrowserRouter, Outlet } from "react-router-dom";
import Backtest from "./Backtest";
import Backtests from "./Backtests";
import Collection from "./Collection";
import Collections from "./Collections";
import EditCollection from "./EditCollection";
import Features from "./Features";
import Home from "./Home";
import Login from "./Login";
import NotFound from "./NotFound";
import Scanner from "./Scanner";
import Terms from "./Terms";
import Privacy from "./Privacy";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter
);

const errorComponent = <Error className='p-6' />
const errorBoundary = process.env.NODE_ENV === 'production' ? <Sentry.ErrorBoundary fallback={errorComponent} /> : errorComponent;

const router = sentryCreateBrowserRouter([
  {
    element: (
      <>
        <Navbar />
        <div className='my-2'>
          <Outlet />
        </div>
      </>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
        errorElement: errorBoundary,
      },
      {
        path: "/terms",
        element: <Terms />,
        errorElement: errorBoundary,
      },
      {
        path: "/privacy",
        element: <Privacy />,
        errorElement: errorBoundary,
      },
      {
        path: "/login",
        element: <Login />,
        errorElement: errorBoundary,
      },
      {
        path: "/scanner",
        element: <Scanner />,
        errorElement: errorBoundary,
      },
      {
        path: "/data",
        element: <Features />,
        errorElement: errorBoundary,
      },
      ...(process.env.NODE_ENV !== 'production' ? [{
        path: "/collections",
        element: <Collections />,
        errorElement: errorBoundary,
      },
      {
        path: "/collection/:collection_id/edit",
        element: <EditCollection />,
        errorElement: errorBoundary,
      },
      {
        path: "/collection/:collection_id",
        element: <Collection />,
        errorElement: errorBoundary,
      }] : []),
      {
        path: "/backtests",
        element: <Backtests />,
        errorElement: errorBoundary,
      },
      {
        path: "/backtest/:backtest_id",
        element: <Backtest />,
        errorElement: errorBoundary,
      },
      {
        path: '*',
        element: <NotFound />,
        errorElement: errorBoundary,
      }
    ],
    errorElement: errorBoundary,
  },
]);

export default router;