import React from 'react';

export enum TickerDatesLayout {
  Charts = 'Charts',
  Table = 'Table',
}

export enum ChartHeight {
  Small = 'Small',
  Large = 'Large',
}

export type TickerDatesLayoutContextType = {
  layout: TickerDatesLayout;
  tableResultsPerPage: number;
  chartColumnsPerPage: number;
  chartRowsPerPage: number;
  chartHeight: ChartHeight;
};

const defaultValue: TickerDatesLayoutContextType = {
  layout: TickerDatesLayout.Table,
  tableResultsPerPage: 5,
  chartColumnsPerPage: 3,
  chartRowsPerPage: 3,
  chartHeight: ChartHeight.Small,
}

const TickerDatesLayoutContext = React.createContext(defaultValue);

export { TickerDatesLayoutContext };
