import { gql } from '@apollo/client';
import Button, { ButtonStyle } from 'components/common/Button';
import BackendTable from 'components/common/Table/BackendTable';
import { QuerySignalsArgs, Signal, SignalWithTagsPayload, TimestampIdCursorInput } from 'graphql/generated';
import moment from 'moment';
import { FC } from 'react';

const SIGNALS_QUERY = gql`
  query Signals($tickerDateId: Int!, $collectionId: Int, $take: Int!, $cursor: TimestampIdCursorInput) {
    signals(tickerDateId: $tickerDateId, collectionId: $collectionId, take: $take, cursor: $cursor) {
      id
      signal {
        id
        TickerDate {
          id
          ticker {
            id
            ticker
          }
          date
        }
        price
        timestamp
        name
        TargetVariables {
          signalId
          targetVariableId
          value
          TargetVariable {
            id
            name
          }
        }
        notes
      }
      tags
    }
  }
`;

interface SignalsTableProps {
  editingSignal?: Signal;
  onEditClick: (o: Signal) => void;
  queryVariables: Partial<QuerySignalsArgs>;
}

const SignalsTable: FC<SignalsTableProps> = ({ editingSignal, onEditClick, queryVariables }) => {
  const columns = [
    {
      key: 'time',
      header: () => 'Time',
      cell: (o: SignalWithTagsPayload) => moment(o.signal.timestamp).tz('America/New_York').format('HH:mm'),
    },
    {
      key: 'price',
      header: () => 'Price',
      cell: (o: SignalWithTagsPayload) => o.signal.price,
    },
    {
      key: 'tags',
      header: () => 'Tags',
      cell: (o: SignalWithTagsPayload) => o.tags.join(', '),
    },
    {
      key: 'view',
      header: () => '',
      cell: (o: SignalWithTagsPayload) => <Button disabled={editingSignal?.id === o.id} onClick={() => onEditClick(o.signal)} style={ButtonStyle.Alternative}>{editingSignal?.id === o.id ? 'Viewing' : 'View'}</Button>
    }
  ];

  const mapObjectToCursor = (object: SignalWithTagsPayload) => ({ id: object.signal.id, timestamp: object.signal.timestamp });

  return (
    <BackendTable<SignalWithTagsPayload, Partial<QuerySignalsArgs>, TimestampIdCursorInput>
      variables={queryVariables}
      query={SIGNALS_QUERY}
      pageSize={5}
      columns={columns}
      dataKey={'signals'}
      mapObjectToCursor={mapObjectToCursor}
    />
  );
}

export default SignalsTable;