import { OnReadyCallback, ResolutionString } from "charting_library";
import TickerDateDatafeed from "..";

export const onReady = (datafeed: TickerDateDatafeed, callback: OnReadyCallback) => {
  setTimeout(() => {
    callback({
      supported_resolutions: datafeed.resolutions as ResolutionString[],
      supports_marks: true,
      supports_timescale_marks: true,
    });
  }, 50);
};
