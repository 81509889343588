import Button, { ButtonStyle } from "components/common/Button";
import Form from "components/common/Form";
import SearchInput from "components/common/Form/SearchInput";
import { Column } from "components/common/Table";
import PaginatedTable from "components/common/Table/PaginatedTable";
import { Feature, FeatureSet } from "graphql/generated";
import { FC, useState } from "react";
import * as yup from 'yup';

interface FeatureSetTableProps {
  features: Feature[];
  featureSets: FeatureSet[];
  editingFeatureSet?: FeatureSet;
  onEditClick: (featureSet: FeatureSet) => void;
}

interface SearchFormInputs {
  input: string;
}

const searchValidationSchema = yup.object({
  input: yup.string().required(),
}).required();

const FeatureSetTable: FC<FeatureSetTableProps> = ({ features, featureSets, editingFeatureSet, onEditClick }) => {
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);

  const featuresById: { [key: number]: Feature } = features.reduce((p, c) => ({ ...p, [c.id]: c }), {});
  const displayedFeatureSets = featureSets.filter(f => !searchInput || searchInput === '' || f.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1);

  const columns: Column<FeatureSet>[] = [
    {
      key: 'name',
      header: () => 'Name',
      cell: (o: FeatureSet) => o.name,
    },
    {
      key: 'Displayed data',
      header: () => 'data',
      cell: (o: FeatureSet) => {
        const featureSetFeatures: Feature[] = o.features.map((f: any) => featuresById[f.featureId]).filter((f: Feature | undefined) => !!f);
        const featureNames = featureSetFeatures.map((f: Feature) => f.name);
        return featureNames.join(", ");
      },
    },
    {
      key: 'edit',
      header: () => '',
      cell: (o: FeatureSet) => {
        const editing = editingFeatureSet?.id === o.id;
        return (
          <Button disabled={editing} onClick={() => onEditClick(o)} style={ButtonStyle.Alternative}>{editing ? 'Editing' : 'Edit'}</Button>
        );
      }
    },
  ];

  return (
    <>
      <Form<SearchFormInputs> validationSchema={searchValidationSchema}>
        <SearchInput<SearchFormInputs> field='input' placeholder="Search..." onChange={input => setSearchInput(input)} />
      </Form>
      <PaginatedTable<FeatureSet> columns={columns} data={displayedFeatureSets} pageSize={5} key={`paginated-featuresets-table-${searchInput}`} />
    </>
  );
};

export default FeatureSetTable;
