import { UserContext } from "contexts/UserContext";
import { FC, ReactNode, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface RequireAuthenticationProps {
  children: ReactNode;
}

const RequireAuthentication: FC<RequireAuthenticationProps> = ({children}) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  });

  return <>{children}</>;
};

export default RequireAuthentication;