import { gql, useQuery } from "@apollo/client";
import * as Sentry from '@sentry/react';
import TickerDateData from "components/TickerDate/TickerDateData";
import TickerDateNotesInput from "components/TickerDate/TickerDateNotesInput";
import Error from 'components/common/Error';
import Modal from "components/common/Modal";
import { TickerDateModalDetailsQuery, TickerDateModalDetailsQueryVariables } from "graphql/generated";
import { FC } from "react";

const TICKER_DATE_MODAL_DETAILS = gql`
  query TickerDateModalDetails($ticker: String!, $date: Date!) {
    userTickerDate(ticker: $ticker, date: $date) {
      notes
      TickerDate {
        id
        date
        ticker {
          id
          ticker
        }
        open
        high
        low
        close
        volume
        volumeWeighted
        adjustmentCoefficient
      }
    }
    targetVariables {
      id
      name
    }
    features {
      id
      name
      userId
      type
      valueType
    }
  }
`;

interface TickerDatesChartsFromFiltersModalProps {
  ticker: string;
  date: string;
  show: boolean;
  onClose: () => void;
}

const TickerDatesChartsFromFiltersModal: FC<TickerDatesChartsFromFiltersModalProps> = ({ ticker, date, show, onClose }) => {
  const { loading, error, data } = useQuery<TickerDateModalDetailsQuery, TickerDateModalDetailsQueryVariables>(TICKER_DATE_MODAL_DETAILS, {
    variables: {
      ticker,
      date,
    }
  });

  if (loading) {
    return <span>Loading...</span>
  }

  if (error || !data) {
    if (error) Sentry.captureException(error);
    return <Error error={error} />
  }

  const userTickerDate = data.userTickerDate;

  return (
    <Modal show={show} onClose={onClose}>
      <h3 className="text-3xl font-bold dark:text-white mb-6">{ticker} {date}</h3>
      <TickerDateData userTickerDate={userTickerDate} />
      <TickerDateNotesInput tickerDateId={userTickerDate.TickerDate.id} notes={userTickerDate.notes || ''} />
    </Modal>
  );
};

export default TickerDatesChartsFromFiltersModal;