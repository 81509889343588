import escapeHtml from 'escape-html';
import { Text } from 'slate';

const serialize = (node: any) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    if ((node as any).bold) {
      string = `<strong>${string}</strong>`
    }
    if ((node as any).italic) {
      string = `<em>${string}</em>`;
    }
    if ((node as any).underline) {
      string = `<u>${string}</u>`;
    }
    if ((node as any).href) {
      string = `<a href="${escapeHtml((node as any).href)}" target="_blank">${string}</a>`;
    }

    return string
  }

  const children = node.children.map((n: any) => serialize(n)).join('')

  let style = '';
  if (node.align) {
    if (node.type === 'image') {
      if (node.align === 'center') style += `margin-left: auto; margin-right: auto;`
      if (node.align === 'right') style += `margin-left: auto;`
    } else {
      style += `text-align: ${node.align};`
    }
  }

  const wrappedStyle = style ? ` style="${style}"` : '';

  let element;

  switch (node.type) {
    case 'block-quote':
      element = `<blockquote${wrappedStyle}>${children}</blockquote>`;
      break;
    case 'paragraph':
      element = `<p${wrappedStyle}>${children}</p>`;
      break;
    case 'heading-one':
      element = `<h1${wrappedStyle}>${children}</h1>`
      break;
    case 'heading-two':
      element = `<h2${wrappedStyle}>${children}</h2>`
      break;
    case 'list-item':
      element = `<li${wrappedStyle}>${children}</li>`
      break;
    case 'numbered-list':
      element = `<ol${wrappedStyle}>${children}</ol>`
      break;
    case 'bulleted-list':
      element = `<ul${wrappedStyle}>${children}</ul>`
      break;
    case 'image':
      element = `<img src='${escapeHtml(node.url)}'${wrappedStyle} />`
      break;
    default:
      element = children;
      break;
  }

  return element;
};

export default serialize;