import { gql, useMutation } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import FilterSignalsModal from "components/Collections/CollectionEditForm/FilterSignalsModal";
import { scannerFormFieldsToInput } from "components/Scanner/util/scannerFormFieldsToInput";
import Button, { ButtonStyle } from "components/common/Button";
import { FiltersFormFields } from "components/common/FiltersForm/schema";
import Form, { FormRef } from "components/common/Form";
import CheckboxInput from "components/common/Form/CheckboxInput";
import Label from "components/common/Form/Label";
import Select from "components/common/Form/Select";
import Submit from "components/common/Form/Submit";
import TextInput from "components/common/Form/TextInput";
import { Backtest, BacktestStatsDocument, BacktestTradesDocument, CreateBacktestExecutionsFromSignalsMutation, CreateBacktestExecutionsFromSignalsMutationVariables, ExecutionType, Signal, TickerDateDetailsDocument, TradeDirection } from "graphql/generated";
import { FC, useRef, useState } from "react";
import { formattedValueToRawNumber } from "util/valueFormat";
import { BacktestExecutionsFromSignalsFormInputs, validationSchema } from "./schema";

const CREATE_BACKTEST_EXECUTIONS_FROM_SIGNALS_MUTATION = gql`
  mutation CreateBacktestExecutionsFromSignals($backtestId: Int!, $signalIds: [Int!], $signalFilters: ScannerFiltersInput, $createNewTrades: Boolean, $type: ExecutionType!, $direction: TradeDirection!, $positionRiskSize: Float!) {
    createBacktestExecutionsFromSignals(
      backtestId: $backtestId
      signalFilters: $signalFilters,
      signalIds: $signalIds,
      createNewTrades: $createNewTrades,
      type: $type,
      direction: $direction,
      positionRiskSize: $positionRiskSize
    )
  }
`;

interface BacktestExecutionsFromSignalsFormProps {
  backtest: Partial<Backtest>;
}

const BacktestExecutionsFromSignalsForm: FC<BacktestExecutionsFromSignalsFormProps> = ({ backtest }) => {
  const formRef = useRef<FormRef<BacktestExecutionsFromSignalsFormInputs>>(null);
  const [signalFiltersModalShow, setSignalFiltersModalShow] = useState<boolean>(false);

  const [createBacktestExecutionsFromSignalsMutation] = useMutation<CreateBacktestExecutionsFromSignalsMutation, CreateBacktestExecutionsFromSignalsMutationVariables>(CREATE_BACKTEST_EXECUTIONS_FROM_SIGNALS_MUTATION, {
    refetchQueries: [
      getOperationName(TickerDateDetailsDocument)!,
      getOperationName(BacktestStatsDocument)!,
      getOperationName(BacktestTradesDocument)!,
    ],
  });

  const handleSubmit = async (fields: BacktestExecutionsFromSignalsFormInputs) => {
    await createBacktestExecutionsFromSignalsMutation({
      variables: {
        backtestId: backtest.id!,
        signalIds: fields.signalFilters ? undefined : fields.signalIds,
        createNewTrades: fields.createNewTrades,
        type: fields.type.value as ExecutionType,
        direction: fields.direction.value as TradeDirection,
        positionRiskSize: Number(formattedValueToRawNumber(fields.positionRiskSize)),
        signalFilters: fields.signalFilters ? scannerFormFieldsToInput(fields.signalFilters) : undefined,
      },
    });

    formRef.current?.reset({
      signalIds: undefined,
      signalFilters: undefined,
      createNewTrades: false,
      type: undefined,
      direction: undefined,
      positionRiskSize: "",
    });
  };

  const typeOptions = Object.keys(ExecutionType).map(t => ({ value: t, label: t }));
  const directionOptions = Object.keys(TradeDirection).map(t => ({ value: t, label: t }));

  const handleSignalAdded = (signal: Signal) => {
    let signalIds = formRef?.current?.getValues('signalIds') || [];

    if (signalIds.includes(signal.id)) {
      signalIds = signalIds.filter(x => x !== signal.id);
    } else {
      signalIds.push(signal.id);
    }

    formRef?.current?.setValue('signalIds', signalIds);
  };

  const handleAllSignalsFromFiltersAdded = (filters?: FiltersFormFields) => {
    formRef?.current?.setValue('signalFilters', filters);
  };

  const handleCloseSignalsFiltersModal = () => setSignalFiltersModalShow(false);

  return (
    <Form<BacktestExecutionsFromSignalsFormInputs> validationSchema={validationSchema} ref={formRef}>
      {(formMethods) => {
        const signalFilters = formMethods.watch('signalFilters') || undefined;
        const signalIds = new Set(formMethods.watch('signalIds') || []);

        return <>
          <Label label='Direction' />
          <Select field='direction' options={directionOptions} />

          <Label label='Execution type' />
          <Select field='type' options={typeOptions} />

          <Label label='Create new trades' />
          <CheckboxInput field='createNewTrades' />

          <Label label='Position risk size' />
          <TextInput field='positionRiskSize' />

          <Button style={ButtonStyle.Alternative} onClick={() => setSignalFiltersModalShow(true)}>Add signals from filters</Button>

          <FilterSignalsModal onSignalAdded={handleSignalAdded} addedSignalIds={signalIds} show={signalFiltersModalShow} onClose={handleCloseSignalsFiltersModal} onAllSignalsFromFiltersAdded={handleAllSignalsFromFiltersAdded} addedAllSignalsFromFilters={!!signalFilters} />

          <Submit onSubmit={handleSubmit}>Save</Submit>
        </>
      }}
    </Form>
  );
};

export default BacktestExecutionsFromSignalsForm;
