import { DescriptiveStatsPayload, Feature, FeatureValueForStatsPayload, FeatureValueType } from "graphql/generated";
import useOnScreen from "hooks/useOnScreen";
import moment from "moment";
import Plotly, { Data } from "plotly.js";
import { FC, useEffect, useId, useState } from "react";
import Plot from "react-plotly.js";
import Form from "../Form";
import Label from "../Form/Label";
import Option from "../Form/Option";
import Select from "../Form/Select";
import FeatureValuesStatsTable from "./FeatureValuesStatsTable";

interface FeatureValuesDistributionChartProps {
  feature: Feature;
  values: FeatureValueForStatsPayload[];
  stats: DescriptiveStatsPayload;
}

const FeatureValuesDistributionChart: FC<FeatureValuesDistributionChartProps> = ({ feature, values, stats }) => {
  const [selectedGroup, setSelectedGroup] = useState<'None' | 'Date' | 'Week' | 'Month' | 'Year'>('None');
  const options = ['None', 'Date', 'Week', 'Month', 'Year'].map(x => ({ label: x, value: x }));

  const [ref, isVisible] = useOnScreen();
  const plotId = useId();
  useEffect(() => {
    if (isVisible) Plotly.Plots.resize(plotId);
  }, [isVisible]);

  const handleChange = (option: Option) => {
    setSelectedGroup(option.value as any);
  };

  const valuesByTimeperiod: { [key: string]: number[] } = {};
  values.forEach(v => {
    const key = moment(v.date, 'YYYY-MM-DD').startOf(selectedGroup.toLowerCase() as moment.unitOfTime.StartOf).format('YYYY/MM/DD');
    if (!valuesByTimeperiod[key]) valuesByTimeperiod[key] = [];
    valuesByTimeperiod[key].push(v.value);
  });

  const sortedKeys = Object.keys(valuesByTimeperiod).sort();
  const data: Data[] = sortedKeys.map(key => ({
    y: valuesByTimeperiod[key],
    name: key,
    type: 'box',
    showlegend: false,
  }));

  return (
    <>
      <Form defaultValues={{ timeperiod: options[0] }}>
        <Label label='Group by timeframe' />
        <Select field='timeperiod' options={options} onChange={handleChange} />
      </Form>
      <div ref={ref}>
        {selectedGroup === 'None' ? (
          <>
            <Plot
              divId={plotId}
              data={[
                {
                  x: values.map(v => v.value),
                  type: 'histogram',
                  showlegend: false,
                },
              ]}
              layout={{
                title: `Distribution of ${feature.name} values`,
                autosize: true,
                height: 720,
                xaxis: {
                  tickformat: feature.valueType === FeatureValueType.Percentage ? ',.2%' : undefined,
                  automargin: true,
                },
                yaxis: {
                  automargin: true,
                },
              }}
              style={{ width: "100%", height: "100%" }}
              config={{ displayModeBar: false }}
              useResizeHandler
            />
            <FeatureValuesStatsTable feature={feature} stats={stats} />
          </>
        ) : (
          <Plot
            divId={plotId}
            data={data}
            layout={{
              title: `Distribution of ${feature.name} values by ${selectedGroup}`,
              autosize: true,
              height: 720,
              xaxis: {
                automargin: true,
              },
              yaxis: {
                tickformat: feature.valueType === FeatureValueType.Percentage ? ',.2%' : undefined,
                automargin: true,
              },
            }}
            style={{ width: "100%", height: "100%" }}
            config={{ displayModeBar: false }}
            useResizeHandler
          />
        )}
      </div>
    </>
  );
}

export default FeatureValuesDistributionChart;