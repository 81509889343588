import { FiltersFormFields } from 'components/common/FiltersForm/schema';
import * as yup from 'yup';

export interface CollectionEditFormInputs {
  name: string;
  public: boolean;

  tickerDateIdsToRemove: number[];
  tickerDateIdsToAdd: number[];
  tickerDateFilters?: FiltersFormFields;
  removeExistingTickerDates: boolean;

  signalIdsToAdd: number[];
  signalFilters?: FiltersFormFields;
  removeExistingSignals: boolean;

  featureIds: number[];
  allFeaturesAdded: boolean;
}

export const validationSchema = yup.object({
  name: yup.string().required(),
  public: yup.boolean(),

  tickerDateIdsToRemove: yup.array(yup.number().integer()),
  tickerDateIdsToAdd: yup.array(yup.number().integer()),
  // tickerDateFilters: already validated in the modal, no need to do it again
  removeExistingTickerDates: yup.boolean(),

  signalIdsToAdd: yup.array(yup.number().integer()),
  // signalFilters: already validated in the modal, no need to do it again
  removeExistingSignals: yup.boolean(),

  featureIds: yup.array(yup.number().integer()),
  allFeaturesAdded: yup.boolean(),
}).required();
